import React from "react";
import { useController } from "react-hook-form";

import { BaseFormComponentProps, HookFormComponentProps } from "../types";

export function withHookForm<P extends BaseFormComponentProps, T = any>(
  Component: React.ComponentType<P>,
) {
  const HookFormComponent = React.forwardRef<any, HookFormComponentProps<P>>(
    (props, ref) => {
      const { name, control, ...rest } = props as HookFormComponentProps<P>;

      const {
        field,
        fieldState: { error },
      } = useController({
        name,
        control,
      });

      const componentProps = {
        ...rest,
        name,
        control,
        value: field.value,
        onChange: field.onChange,
        onBlur: field.onBlur,
        error,
        ref,
      } as unknown as P;

      return <Component {...componentProps} />;
    },
  );

  HookFormComponent.displayName = `withHookForm(${
    Component.displayName || Component.name || "Component"
  })`;

  return HookFormComponent;
}
export function createHookFormComponent<
  P extends BaseFormComponentProps,
  T = any,
>(Component: React.ComponentType<P>) {
  return withHookForm<P, T>(Component);
}
