import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Quill from "quill";

import { RegularText, RichText, TextElementProps } from "./types";

const TextElement = ({ value, type = RegularText, link }: TextElementProps) => {
  const navigate = useNavigate();
  const quillRef = useRef<Quill>(null);
  const identifier = _.uniqueId();

  useEffect(() => {
    if (type !== RichText) return;

    const quill = new Quill(`#quill-read-${identifier}`, {
      readOnly: true,
      modules: {
        toolbar: null,
      },
    });

    quill.clipboard.dangerouslyPasteHTML(value || "");
    quillRef.current = quill;
  }, []);

  useEffect(() => {
    if (type !== RichText) return;
    if (quillRef.current) {
      quillRef.current.clipboard.dangerouslyPasteHTML(value || "");
    }
  }, [value]);

  if (type === RichText) {
    return (
      <div
        id={`quill-read-${identifier}`}
        className="quill-read-only"
        onClick={link ? () => navigate(link) : undefined}
      ></div>
    );
  }

  return <span>{value}</span>;
};

export default TextElement;
