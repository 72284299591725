import { useEffect, useState } from "react";

import { Spin } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  useGetCurrentUserQuery,
  useGetSurveyByIdQuery,
  useLazyGetSurveyStatisticsQuery,
} from "@/redux/service.js";
import { setShowGreyBackground } from "@/redux/utils/reducer.js";
import SectionProgressBar from "./SectionProgressBar.jsx";
import {
  PRME_ORGANIZATION_ID,
  Roles,
  SurveyActions,
  SurveyStatus,
} from "@/utilities/constants.jsx";
import { canUserPerformActionOnSurvey } from "@/utilities/permissions.js";
import SectionContent from "./SectionContent.jsx";
import BaseButton from "@/components_v2/Button/BaseButton.js";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PreviewIcon from "@/assets/icons/preview.svg?react";
import SendIcon from "@/assets/icons/send.svg?react";
import SubmissionConfirmationModal from "./SubmissionConfirmationModal.jsx";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer.js";
import { SurveyType } from "@/utilities/constants.jsx";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip.js";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner.js";

const SurveySectionForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const location = useLocation();
  const isSample = location.pathname.includes("sample");

  const [hasPermissions, setHasPermissions] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { data: survey, isLoading: isSurveyLoading } = useGetSurveyByIdQuery({
    surveyId,
    filters: { organization: isSample ? PRME_ORGANIZATION_ID : null },
  });
  const [
    getSurveyStatistics,
    { data: surveyStatistics, isLoading: isSurveyStatisticsLoading },
  ] = useLazyGetSurveyStatisticsQuery();
  const {
    data: currentUser,
    isSuccess: currentUserQuerySuccess,
    isLoading: isCurrentUserLoading,
  } = useGetCurrentUserQuery();

  useEffect(() => {
    dispatch(setShowGreyBackground(true));

    return () => dispatch(setShowGreyBackground(false));
  }, []);

  useEffect(() => {
    if (survey) {
      if (
        survey.status === SurveyStatus.ENDED.value ||
        survey.details?.isSubmitted
      ) {
        navigate(`/reports/${survey.id}/report`);
      } else {
        getSurveyStatistics({
          survey: survey.id,
          organization: isSample ? PRME_ORGANIZATION_ID : null,
        });
      }
    }

    if (currentUserQuerySuccess && survey) {
      if (currentUser.role === Roles.ADMIN.value || survey.details == null) {
        navigate("/");
        return;
      }

      const hasPermissions = canUserPerformActionOnSurvey(
        survey.permissions,
        currentUser.organizationRole,
      );
      if (!hasPermissions) {
        navigate("/");
      }
      setHasPermissions(hasPermissions);
    }
  }, [survey, currentUserQuerySuccess]);

  if (
    isSurveyLoading ||
    isSurveyStatisticsLoading ||
    isCurrentUserLoading ||
    !hasPermissions
  ) {
    return <BaseSpinner size="large" />;
  }

  return survey ? (
    <div className="survey-section-form-container">
      <div className="survey-section-form-header">
        <BaseButton
          variant="link"
          size="small"
          leadingIcon={<ArrowLeftOutlined />}
          onClick={() =>
            navigate(`/reports/${surveyId}${isSample ? "/sample" : ""}`)
          }
        >
          {t("buttons.backToSurveyOverview", {
            surveyType: SurveyType.getLabel(survey.type),
          })}
        </BaseButton>

        {!isSample && (
          <div className="survey-section-form-header-right-container">
            <BaseButton
              variant="tertiary"
              size="small"
              leadingIcon={
                <BaseSvgRenderer
                  width="20"
                  height="20"
                  component={PreviewIcon}
                />
              }
              onClick={() => navigate(`/reports/${survey.id}/report`)}
            >
              {t("previewSurvey")}
            </BaseButton>

            {canUserPerformActionOnSurvey(
              survey.permissions,
              currentUser.organizationRole,
              SurveyActions.SUBMIT.value,
            ) && (
              <BaseTooltip title={t("survey.labels.disabledSubmit")}>
                <BaseButton
                  size="small"
                  disabled={true}
                  leadingIcon={
                    <BaseSvgRenderer
                      width="20"
                      height="20"
                      component={SendIcon}
                    />
                  }
                  onClick={() => setOpenConfirmationModal(true)}
                >
                  {t("submitSurvey")}
                </BaseButton>
              </BaseTooltip>
            )}
          </div>
        )}
      </div>

      <div className="survey-section-form-content-container">
        {surveyStatistics && <SectionProgressBar sections={surveyStatistics} />}

        <SectionContent activeSurveyId={survey.id} />
      </div>

      {!isSample && (
        <SubmissionConfirmationModal
          onCancel={() => setOpenConfirmationModal(false)}
          survey={survey}
          open={openConfirmationModal}
        />
      )}
    </div>
  ) : (
    <div className="survey-section-form-container-empty">
      <h1 className="header-m-medium">{t("noActiveSurvey")}</h1>
    </div>
  );
};

export default SurveySectionForm;
