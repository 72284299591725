import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Roles } from "@/utilities/constants";
import BaseMenu from "./BaseMenu";
import { useGetCurrentUserQuery } from "@/redux/service";
import useMenuItems from "./hooks/useMenuItems";

const SidebarMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const { data: currentUser } = useGetCurrentUserQuery();
  const menuItems = useMenuItems(currentUser);

  const setSelectedKeysFromCurrentLocation = () => {
    const currentPath = location.pathname;

    let matchingKeys = menuItems
      .filter((item) => currentPath.startsWith(item.key))
      .map((item) => item.key);

    menuItems.forEach((item) => {
      if (item.children) {
        item.children.forEach((child) => {
          if (currentPath.startsWith(child.key)) {
            matchingKeys.push(child.key);
          }
        });
      }
    });

    matchingKeys.sort((a, b) => b.length - a.length);

    if (matchingKeys.length === 0) {
      const pathSegments = currentPath.split("/");
      for (let i = pathSegments.length; i > 0; i--) {
        const parentPath = pathSegments.slice(0, i).join("/") || "/";
        const parentMatch = menuItems.find((item) => item.key === parentPath);
        if (parentMatch) {
          matchingKeys = [parentPath];
          break;
        }
      }
    }

    const path = matchingKeys[0];
    if (path) {
      setSelectedKeys([path]);
    } else {
      setSelectedKeys([]);
    }
  };

  useEffect(() => {
    setSelectedKeysFromCurrentLocation();
  }, [location.pathname, menuItems]);

  const handleMenuItemClick = (itemKey: string) => {
    setSelectedKeys([itemKey]);
    navigate(itemKey);
  };

  const subMenuItems = useMemo(() => {
    return menuItems.filter((item) => item?.children).map((item) => item.key);
  }, [menuItems]);

  return (
    currentUser && (
      <BaseMenu
        defaultSelectedKeys={selectedKeys}
        defaultOpenKeys={menuItems
          .filter((item) => item?.children)
          .map((item) => item.key)}
        openKeys={subMenuItems}
        selectedKeys={selectedKeys}
        className={currentUser.role === Roles.ADMIN.value ? "mt-2" : "mt-4"}
        mode="inline"
        onClick={(e) => handleMenuItemClick(e.key)}
        items={menuItems.map((item) => {
          return {
            ...item,
            onTitleClick: ({ key, domEvent }) => {
              domEvent.stopPropagation();
              handleMenuItemClick(key);
            },
          };
        })}
      />
    )
  );
};

export default SidebarMenu;
