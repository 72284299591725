import { createContext, useContext } from "react";
import { DataBlockContextType } from "../../types/types";

export const DataBlockContext = createContext<DataBlockContextType | null>(
  null,
);

export const useDataBlockContext = () => {
  const context = useContext(DataBlockContext);
  if (!context) {
    throw new Error(
      "useDataBlockContext must be used within a DataBlockProvider",
    );
  }
  return context;
};
