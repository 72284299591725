import { useEffect, useRef, useState } from "react";

import { Input, Typography } from "antd";
import PropTypes from "prop-types";
import WarningOutlined from "@ant-design/icons/WarningOutlined";
import { useController } from "react-hook-form";
import "quill-mention/autoregister";
import "quill-mention/dist/quill.mention.css";
import "quill/dist/quill.snow.css";
import Quill from "quill";
import _ from "lodash";

import FieldError from "./FieldError.jsx";
import EyeIcon from "@/assets/icons/eye-icon.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer.js";

const { Text } = Typography;

const InputField = ({
  name,
  control,
  placeholder,
  htmlType,
  disabled,
  type,
  rows,
  isEdit,
  modules,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const [showPassword, setShowPassword] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const quillRef = useRef({
    id: _.uniqueId(),
    element: null,
  });

  /**
   * Initialize a Quill instance and set the handler for input
   */
  useEffect(() => {
    if (isEdit && type === "richtexteditor" && !quillRef.current.element) {
      const quill = new Quill(`#quill-${quillRef.current.id}`, {
        modules,
        theme: "snow",
      });
      quillRef.current.element = quill;

      const editorChangeHandler = () => {
        const content = quill.getSemanticHTML();
        field.onChange(content);
      };

      quill.on("text-change", () => {
        const content = quill.getSemanticHTML();

        field.onChange(content);
      });
      return () => {
        quill.off("text-change", editorChangeHandler);
      };
    }
  }, [isEdit, type]);

  /**
   * Workaround to set the initial value of the field in the form to the Quill input
   */
  useEffect(() => {
    if (
      quillRef.current.element &&
      isInitial &&
      !["", null].includes(field.value)
    ) {
      quillRef.current.element.clipboard.dangerouslyPasteHTML(field.value);
      quillRef.current.element.setSelection(
        quillRef.current.element.getLength(),
        quillRef.current.element.getLength(),
      );
      setIsInitial(false);
    }
  }, [quillRef, isInitial, field.value]);

  /**
   * Prepends a plus sign (+) to an input of type phone whose value does not have it at the start.
   */
  useEffect(() => {
    if (htmlType === "phone") {
      let value = field.value;
      if (!value) return;
      value = value.replace(/[^\d+\s]/g, "");
      if (value === "") {
        field.onChange("+");
      } else if (!value.startsWith("+")) {
        field.onChange("+" + value);
      } else {
        field.onChange(value);
      }
    }
  }, [field.value, quillRef]);

  const prefix = error ? <WarningOutlined type="smile" /> : <span />;

  const changeShowPassword = () => setShowPassword((oldVal) => !oldVal);
  const passwordIcon = showPassword ? (
    <span onClick={changeShowPassword}>
      <BaseSvgRenderer
        component={EyeIcon}
        style={{ transform: "rotate(180deg)" }}
      />
    </span>
  ) : (
    <span onClick={changeShowPassword}>
      <BaseSvgRenderer component={EyeIcon} />
    </span>
  );
  const suffix =
    htmlType === "password" || showPassword ? passwordIcon : <span />;

  const Component = type === "textarea" ? Input.TextArea : Input;

  return (
    <div>
      {isEdit ? (
        type === "richtexteditor" ? (
          <div id={`quill-${quillRef.current.id}`}></div>
        ) : (
          <Component
            prefix={prefix}
            suffix={suffix}
            status={error && "error"}
            {...(type === "textarea" ? { rows } : {})}
            placeholder={placeholder}
            type={showPassword ? "text" : htmlType}
            style={{
              borderRadius: 0,
              height: type === "textarea" ? null : "35px",
            }}
            {...field}
            disabled={disabled}
            {...props}
            onKeyPress={(event) => {
              if (htmlType === "number") {
                const invalidChars = ["e", "E", ".", "+", "-"];
                if (invalidChars.includes(event.key)) {
                  event.preventDefault();
                }
              }
            }}
          />
        )
      ) : (
        <Text>{field.value}</Text>
      )}
      {error && <FieldError errors={error} />}
    </div>
  );
};

InputField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  htmlType: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  rows: PropTypes.number,
  isEdit: PropTypes.bool,
  modules: PropTypes.object,
};

InputField.defaultProps = {
  htmlType: "text",
  placeholder: null,
  disabled: false,
  type: "input",
  rows: 4,
  isEdit: true,
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ script: "sub" }, { script: "super" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "formula", "image"],
      [{ align: "center" }, { align: "right" }],
      ["code-block"],
      ["clean"],
    ],
  },
};

export default InputField;
