import { useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { useLazyPaginateFilterDiscussionsQuery } from "../../../redux/service.js";
import { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../../redux/utils/reducer.js";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const itemRender = (_, type, originalElement, hasNext, hasPrevious) => {
  if (type === "prev") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasPrevious ? "black" : "silver",
        }}
      >
        <LeftOutlined /> Previous
      </a>
    );
  }
  if (type === "next") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasNext ? "black" : "silver",
        }}
      >
        Next <RightOutlined />
      </a>
    );
  }
  return originalElement;
};

const paginationFilters = {
  page: 1,
  pageSize: 10,
};

const CommunitySearchForm = (ChildComponent) => {
  return function SearchForm() {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const { topic } = useParams();
    const [
      paginateFilterDiscussions,
      {
        data: discussionsData,
        isSuccess: discussionsSuccess,
        isLoading: discussionsLoading,
      },
    ] = useLazyPaginateFilterDiscussionsQuery();
    const [pagination, setPagination] = useState(paginationFilters);
    const dispatch = useDispatch();

    const form = useForm({
      mode: "onChange",
      defaultValues: {
        topicName: topic,
        title: searchParams.get("title"),
        time: searchParams.get("time"),
        subjects: searchParams.get("subjects"),
        topics: searchParams.get("topics"),
        authors: searchParams.has("authors")
          ? searchParams.get("authors").split(",")
          : [],
        ordering: searchParams.has("ordering")
          ? searchParams.get("ordering")
          : "-published",
      },
    });

    const formSubscription = form.watch((data) => {
      let searchData = data;
      const authorIds = (data?.authors || []).map((i) => i?.value || i);
      searchData["authors"] =
        authorIds.length === 0 ? null : authorIds.join(",");

      paginateFilterDiscussions({
        ...paginationFilters,
        ...searchData,
        ordering: `${topic ? "-pinned," : ""}${data["ordering"]}`,
      });
      setSearchParams(
        Object.fromEntries(
          Object.entries(searchData).filter(
            ([k, v]) => k !== "topicName" && v != null,
          ),
        ),
      );
    });

    useEffect(() => {
      if (topic) {
        dispatch(
          setBreadcrumbs([
            {
              title: t("community"),
              to: "/community",
            },
            {
              title: topic.charAt(0).toUpperCase() + topic.slice(1),
            },
          ]),
        );
      }
      const formData = form.getValues();
      paginateFilterDiscussions({
        ...pagination,
        ...formData,
        ordering: `${topic ? "-pinned," : ""}${formData["ordering"]}`,
      });

      return () => {
        formSubscription.unsubscribe();
        if (topic) dispatch(setBreadcrumbs(null));
      };
    }, [pagination]);

    const handleSearch = (values) => {
      paginateFilterDiscussions({
        ...paginationFilters,
        ...values,
        ordering: `${topic ? "-pinned," : ""}${values["ordering"]}`,
      });
    };

    const handleEnterPress = (event) => {
      if (event.key === "Enter") {
        handleSearch(form.getValues());
      }
    };

    return (
      <div>
        <ChildComponent
          form={form}
          handleSearch={handleSearch}
          handleEnterPress={handleEnterPress}
          discussionsData={discussionsData}
          discussionsSuccess={discussionsSuccess}
          discussionsLoading={discussionsLoading}
        />
        {discussionsSuccess &&
          (discussionsData.next || discussionsData.previous) && (
            <Pagination
              rootClassName="mt-4 text-center"
              total={discussionsData.count}
              itemRender={(...props) =>
                itemRender(
                  ...props,
                  discussionsData.next != null,
                  discussionsData.previous != null,
                )
              }
              showSizeChanger
              pageSize={pagination.pageSize}
              onChange={(page, pageSize) => setPagination({ page, pageSize })}
            />
          )}
      </div>
    );
  };
};

export default CommunitySearchForm;
