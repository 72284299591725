import { App, Col, Row, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import PropTypes from "prop-types";

import {
  useFollowUserMutation,
  useGetCountriesQuery,
  useGetCurrentUserQuery,
  useGetUserByIdQuery,
  useLazyGetCommentsQuery,
  useLazyPaginateFilterDiscussionsQuery,
  useLazyPaginateFilterNarrativesQuery,
  useUnfollowUserMutation,
} from "@/redux/service.js";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import ContentTab, { EntityDetails } from "../../../components/ContentTab.jsx";
import { ProfileCommentDetails } from "./index.jsx";
import BaseTabs from "../../../components_v2/Tabs/BaseTabs.tsx";
import UserAvatar from "../../../components_v2/Avatar/UserAvatar.js";
import NarrativesIcon from "@/assets/icons/narratives-profile-icon.svg?react";
import DiscussionsIcon from "@/assets/icons/discussions-profile-icon.svg?react";
import HeartIcon from "@/assets/icons/heart-icon.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer.js";

const { Title, Text } = Typography;

const PublicProfile = ({ userId }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { notification } = App.useApp();

  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const { data: user, isSuccess, isLoading } = useGetUserByIdQuery(userId);
  const [followUser, { isLoading: followIsLoading }] = useFollowUserMutation();
  const [unfollowUser, { isLoading: unfollowIsLoading }] =
    useUnfollowUserMutation();
  const { data: currentUser } = useGetCurrentUserQuery();

  const handleFollowUser = () => {
    if (user.followerId) {
      unfollowUser({ userFollowerId: user.followerId, userId })
        .unwrap()
        .then(() => notification.success({ message: t("userUnfollowed") }));
    } else {
      followUser({
        follower: currentUser.id,
        followed: user.id,
      })
        .unwrap()
        .then(() => notification.success({ message: t("userFollowed") }));
    }
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return (
    isSuccess && (
      <div className="mb-4">
        <Row className="mt-3 mb-4 ms-3">
          <Col lg={4} md={4} sm={24}>
            <UserAvatar user={user} />
          </Col>
          <Col lg={20} md={20} sm={24}>
            <Row>
              <Col span={6}>
                <Title level={3} style={{ color: token.colorTextThird }}>
                  {user.fullName}
                </Title>
                <Text>{user.email}</Text>
              </Col>
              <Col span={18} style={{ textAlign: "right" }}>
                <PrimaryButton
                  disabled={followIsLoading || unfollowIsLoading}
                  type={user.followerId ? "secondary" : "primary"}
                  onClick={handleFollowUser}
                >
                  {t(user.followerId ? "unfollow" : "follow")}
                </PrimaryButton>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col span={6}>
                <div className="mb-2">
                  <Title level={5} style={{ color: token.colorTextThird }}>
                    {t("labels.organization")}
                  </Title>
                  <Text>{user.organization?.name}</Text>
                </div>
                <div>
                  <Title level={5} style={{ color: token.colorTextThird }}>
                    {t("labels.position")}
                  </Title>
                  <Text>{user.position}</Text>
                </div>
              </Col>
              <Col span={6}>
                {isCountriesSuccess && (
                  <div className="mb-2">
                    <Title level={5} style={{ color: token.colorTextThird }}>
                      {t("labels.location")}
                    </Title>
                    <Text>
                      {user.organization?.address}
                      {user.organization?.country
                        ? ", " + countries[user.organization.country]
                        : ""}
                    </Text>
                  </div>
                )}
                <div>
                  <Title level={5} style={{ color: token.colorTextThird }}>
                    {t("labels.memberSince")}
                  </Title>
                  <Text>
                    {DateTime.fromISO(user.createdAt).toFormat("dd MMM yyyy")}
                  </Text>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ borderTop: "1px solid rgba(211, 212, 219, 1)" }} />
        <Row style={{ margin: "auto" }} className="w-50 my-5">
          {[
            {
              icon: (
                <BaseSvgRenderer
                  component={NarrativesIcon}
                  width="50"
                  height="50"
                />
              ),
              label: t("labels.narratives", { plural: "s" }),
              count: user.narrativesCount,
            },
            {
              icon: (
                <BaseSvgRenderer
                  component={DiscussionsIcon}
                  width="50"
                  height="50"
                />
              ),
              label: t("discussions"),
              count: user.discussionsCount,
            },
            {
              icon: (
                <BaseSvgRenderer component={HeartIcon} width="50" height="50" />
              ),
              label: t("likes", { plural: user.likesCount === 1 ? "" : "s" }),
              count: user.likesCount,
            },
          ].map((i) => (
            <Col
              key={i.label}
              lg={8}
              md={8}
              sm={24}
              style={{ display: "flex" }}
            >
              {i.icon}
              <div className="ms-3">
                <Title level={1} style={{ color: token.colorTextThird }}>
                  {i.count}
                </Title>
                <Title level={4} style={{ color: token.colorTextThird }}>
                  {i.label}
                </Title>
              </div>
            </Col>
          ))}
        </Row>
        <BaseTabs
          type="card"
          defaultActiveKey="narratives"
          items={[
            {
              label: t("labels.narratives", { plural: "s" }),
              key: "narratives",
              children: (
                <ContentTab
                  filters={{ createdBy: userId }}
                  getPaginatedContent={useLazyPaginateFilterNarrativesQuery}
                  orderingField="published_date"
                  renderComponent={(narrative) => (
                    <EntityDetails entity={narrative} path="/narratives" />
                  )}
                />
              ),
            },
            {
              label: t("communityDiscussions"),
              key: "communityDiscussions",
              children: (
                <ContentTab
                  filters={{ authors: userId }}
                  getPaginatedContent={useLazyPaginateFilterDiscussionsQuery}
                  orderingField="published"
                  renderComponent={(discussion) => (
                    <EntityDetails entity={discussion} path="/discussions" />
                  )}
                />
              ),
            },
            {
              label: t("communityComments"),
              key: "communityComments",
              children: (
                <ContentTab
                  filters={{ createdBy: userId }}
                  getPaginatedContent={useLazyGetCommentsQuery}
                  orderingField="created_at"
                  renderComponent={(comment) => (
                    <ProfileCommentDetails entity={comment} />
                  )}
                />
              ),
            },
          ]}
        />
      </div>
    )
  );
};

PublicProfile.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default PublicProfile;
