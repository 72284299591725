import { UseFormReturn } from "react-hook-form";
import { useEffect, useState } from "react";

export function useFilterCount<T extends Record<string, any>>(
  form: UseFormReturn<T>,
  defaultFilters?: Partial<T>,
  excludeFields: string[] = [],
) {
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  useEffect(() => {
    const subscription = form.watch((formValues) => {
      if (Object.keys(form.formState.errors).length === 0) {
        const filtersCount = Object.keys(formValues).reduce((acc, key) => {
          if (
            excludeFields.includes(key) ||
            (defaultFilters && key in defaultFilters)
          ) {
            return acc;
          }
          const value = formValues[key as keyof typeof formValues];

          if (Array.isArray(value)) {
            acc += value.length;
          } else if (value != null) {
            acc += 1;
          }

          return acc;
        }, 0);

        setActiveFilterCount(filtersCount);
      }
    });

    return () => subscription.unsubscribe();
  }, [form, defaultFilters, excludeFields]);

  return activeFilterCount;
}
