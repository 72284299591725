import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { Spin, theme } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const pageSize = 10;

import {
  useGetCurrentUserQuery,
  useLazyGetSurveyInvitesQuery,
  useLazyPaginateFilterSurveysQuery,
} from "../../../../redux/service.js";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import {
  Roles,
  SurveyStatus,
  SurveyType,
} from "../../../../utilities/constants.jsx";
import Table from "../../../../components/Table.jsx";

const OrganizationSurveys = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const [paginateAndFilterSurveyInvites] = useLazyGetSurveyInvitesQuery();
  const [paginateFilterSurveys] = useLazyPaginateFilterSurveysQuery();
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (isCurrentUserSuccess) {
      const paginateFunction =
        currentUser.role === Roles.ADMIN.value
          ? paginateFilterSurveys
          : paginateAndFilterSurveyInvites;

      paginateFunction({ page: 1, pageSize })
        .unwrap()
        .then((data) => {
          if (currentUser.role === Roles.ADMIN.value) {
            setResponse(data);
          } else {
            setResponse({
              ...data,
              results: data.results.map((i) => ({ ...i.survey, details: i })),
            });
          }
        });
    }
  }, [isCurrentUserSuccess]);

  const handleTableChange = (pagination, filterObj) => {
    const paginateFunction =
      currentUser.role === Roles.ADMIN.value
        ? paginateFilterSurveys
        : paginateAndFilterSurveyInvites;

    paginateFunction({
      page: pagination.current,
      pageSize: pagination.pageSize,
      type: filterObj?.type,
      title: filterObj?.title,
      status: filterObj?.status,
    })
      .unwrap()
      .then((data) => {
        if (currentUser.role === Roles.ADMIN.value) {
          setResponse(data);
        } else {
          setResponse({
            ...data,
            results: data.results.map((i) => ({ ...i.survey, details: i })),
          });
        }
      });
  };

  const columns = [
    {
      title: t("table.title"),
      dataIndex: "title",
      key: "title",
      addSearch: true,
      ellipsis: true,
      render: (_, record) => {
        let navigateLink;
        if (currentUser?.role === Roles.ADMIN.value) {
          if (record.status === SurveyStatus.DRAFT.value) {
            navigateLink = `/reports/${record.id}/form`;
          } else {
            navigateLink = `/reports/${record.id}`;
          }
        } else {
          navigateLink = `/reports/${record.id}`;
        }

        return (
          <a
            style={{ color: token.linkColor, textDecoration: "underline" }}
            onClick={() => navigate(navigateLink)}
          >
            {record.title}
          </a>
        );
      },
    },
    {
      title: t("table.surveyType"),
      dataIndex: "survey.type",
      key: "type",
      render: (_, record) => SurveyType.getItemByValue(record.type)?.label,
      filters: SurveyType.asList().map((i) => ({
        text: i.label,
        value: i.value,
      })),
    },
    {
      title: t("table.startDate"),
      dataIndex: "survey.startDate",
      key: "startDate",
      render: (_, record) =>
        dayjs(record.startDate).format("DD MMM YYYY HH:mm"),
    },
    {
      title: t("table.deadline"),
      dataIndex: "deadline",
      key: "deadline",
      render: (_, record) =>
        dayjs(record?.softDeadline || record.hardDeadline).format(
          "DD MMM YYYY HH:mm",
        ),
    },
    ...(currentUser.role === Roles.USER.value
      ? [
          {
            title: t("table.isDue"),
            dataIndex: "isDue",
            key: "isDue",
            render: (_, record) => (
              <div
                className="py-1"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  backgroundColor: record.details.isSubmissionRequired
                    ? "rgba(2, 186, 60, 0.087)"
                    : "rgba(255, 191, 1, 0.185)",
                  color: record.details.isSubmissionRequired
                    ? "rgba(0, 107, 59, 0.906)"
                    : "rgba(120, 50, 0, 0.812)",
                }}
              >
                {record.details.isSubmitted
                  ? t("submitted")
                  : record.details.isSubmissionRequired
                    ? t("due")
                    : t("notDue")}
              </div>
            ),
          },
        ]
      : []),
    {
      title: t("table.status"),
      dataIndex: "survey.status",
      key: "status",
      render: (_, record) => {
        const surveyStatus = SurveyStatus.getItemByValue(record.status);
        if (!surveyStatus) return;

        return (
          <div
            className="py-1"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              backgroundColor: surveyStatus.background,
              color: surveyStatus.color,
            }}
          >
            {surveyStatus.label}
          </div>
        );
      },
      filters: SurveyStatus.asList().map((i) => ({
        text: i.label,
        value: i.value,
      })),
    },
    ...(isCurrentUserSuccess && currentUser.role === Roles.ADMIN.value
      ? [
          {
            title: t("table.invitesCount"),
            dataIndex: "invitesCount",
            key: "invitesCount",
          },
          {
            title: t("table.responsesCount"),
            dataIndex: "responsesCount",
            key: "responsesCount",
          },
          {
            title: t("buttons.edit"),
            dataIndex: "edit",
            key: "edit",
            render: (_, record) => (
              <PrimaryButton
                type="secondary"
                onClick={() => navigate(`/reports/${record.id}/form`)}
              >
                {t("buttons.edit")}
              </PrimaryButton>
            ),
          },
        ]
      : []),
  ];

  if (!response) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Table
      data={response.results}
      totalCount={response.count}
      isLoading={!response}
      columns={columns}
      handleTableChange={handleTableChange}
    />
  );
};

export default OrganizationSurveys;
