import { EnumFunctions } from "@/utilities/constants";

export const RequestStatus = {
  PENDING: {
    value: "PENDING",
    label: "Pending",
  },
  REVISION_NEEDED: {
    value: "REVISION_NEEDED",
    label: "Revision needed",
  },
  EXPIRED: {
    value: "EXPIRED",
    label: "Expired",
  },
  SUBMITTED: {
    value: "SUBMITTED",
    label: "Submitted",
  },
  ...EnumFunctions,
};
