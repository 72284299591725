import { useEffect, useMemo, useState } from "react";

import {
  SelectedOptionWithSlider,
  SliderDropdownProps,
  SliderFormValueOption,
  SliderSelectedOption,
} from "./types";
import BaseSlider from "../Slider/BaseSlider";
import BaseError from "../BaseError";
import CustomChoiceDropdown from "./CustomChoiceDropdown";
import BaseDropdown from "./BaseDropdown";

import "./styles.css";

const SliderDropdown = ({
  value,
  onChange,
  options,
  multiple,
  customInputOptions,
  helpText,
  error,
  ...props
}: SliderDropdownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<
    SliderSelectedOption[]
  >([]);

  useEffect(() => {
    if (!value) {
      setSelectedOptions([]);
    } else if (selectedOptions) {
      setSelectedOptions(
        value.map((i: SliderFormValueOption) => {
          const selectedOption = options.find(
            (option) => option.value === i.choice,
          );
          return {
            ...i,
            label: selectedOption?.label,
          };
        }),
      );
    }
  }, [value, options]);

  const handleChange = (values: (SliderFormValueOption | string)[]) => {
    const previousValues = Array.isArray(value) ? value : [];
    const selectedValues = values.map((value) => {
      // Properly handle both types in union
      const choiceValue = typeof value === "object" ? value?.choice : value;
      const previousValue = previousValues.find(
        (v) => (typeof v === "object" ? v.choice : v) === choiceValue,
      );
      const selectedOption = options.find((i) => i.value === choiceValue);

      if (typeof value === "object" && value !== null) {
        return {
          choice: value.choice,
          scale: previousValue?.scale,
          label: selectedOption?.label || "",
        };
      }
      return {
        choice: value,
        scale: previousValue?.scale,
        label: selectedOption?.label || "",
      };
    });

    setSelectedOptions(selectedValues);

    const formValues = selectedValues.map(({ choice, scale }) => ({
      choice,
      scale,
    }));

    onChange(multiple ? formValues : formValues[0]);
  };

  const handleSliderChange = (
    scaleValue: number,
    choiceValue: number | string,
  ) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.map((opt) =>
        opt.choice === choiceValue ? { ...opt, scale: scaleValue } : opt,
      ),
    );
    if (multiple) {
      onChange(
        selectedOptions.map((val) =>
          val.choice === choiceValue
            ? { choice: val.choice, scale: scaleValue }
            : val,
        ),
      );
    } else {
      onChange({
        choice: choiceValue,
        scale: scaleValue,
      });
    }
  };

  const DropdownComponent = customInputOptions?.show
    ? CustomChoiceDropdown
    : BaseDropdown;

  const sortOptionsByChoice = (options: SliderSelectedOption[]) => {
    return options.sort((a, b) => {
      if (typeof a.choice === "number" && typeof b.choice === "number") {
        return a.choice - b.choice;
      }
      return String(a.choice).localeCompare(String(b.choice));
    });
  };

  const selectedOptionWithSlider = useMemo(() => {
    return sortOptionsByChoice(selectedOptions).reduce<
      SelectedOptionWithSlider[]
    >((acc, option) => {
      let sliderOptions;
      if (customInputOptions?.show && typeof option.choice === "string") {
        sliderOptions = customInputOptions.relatedOptions;
      } else {
        sliderOptions = options.find(
          (i) => i.value === option.choice,
        )?.relatedOptions;
      }

      if (sliderOptions != null && sliderOptions.length != 0) {
        acc.push({
          ...option,
          sliderOptions,
        });
      }
      return acc;
    }, []);
  }, [customInputOptions, selectedOptions]);

  return (
    <div className="slider-dropdown">
      <DropdownComponent
        value={value}
        onChange={handleChange}
        options={options}
        multiple={multiple}
        {...props}
        showError={false}
      />
      <BaseError
        error={Array.isArray(error) ? error.filter((i) => i != null)[0] : error}
      />
      {selectedOptionWithSlider.length > 0 && (
        <div className="slider-dropdown-container">
          {helpText && (
            <div className="slider-dropdown-help-text">
              <p className="body-s-medium">{helpText}</p>
            </div>
          )}
          {selectedOptionWithSlider.map((option) => {
            return (
              <div
                key={option.choice}
                className="slider-dropdown-slider-container"
              >
                <div className="text-sm font-medium">{option.label}</div>

                <BaseSlider
                  options={option.sliderOptions}
                  value={option.scale}
                  onChange={(value) => handleSliderChange(value, option.choice)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SliderDropdown;
