import React from "react";
import * as yup from "yup";
import { TFunction } from "i18next/typescript/t";

import {
  DataTemplate,
  DataField,
  ValidationRule as ValidationRuleType,
  ValidationRule,
  ChoiceOption,
} from "./templates";
import { BaseUser, User } from "../../users/types/types";
import {
  BaseDropdownProps,
  InfiniteScrollDropdownProps,
  SliderDropdownProps,
} from "@/components_v2/FormFields/Dropdown/types";
import { BaseSliderProps } from "@/components_v2/FormFields/Slider/types";
import { SimpleModalProps } from "@/components_v2/Modal/types";
import {
  DataBlock,
  DataBlockReference,
  DynamicValue,
  FieldValue,
} from "./blocks";
import { FileRepositoryUploadProps } from "@/components_v2/FormFields/Upload/types";
import { RowSelectionProps } from "@/components_v2/Tables/types";
import { BaseCheckboxProps } from "@/components_v2/FormFields/Checkbox/types";
import { BaseInputProps } from "@/components_v2/FormFields/Input/types";
import { BaseDatePickerProps } from "@/components_v2/FormFields/DatePicker/types";
import { ColumnType } from "antd/es/table/interface";
import { DataBlockRequest } from "./requests";
import { BasePaginationQueryParams } from "@/types/pagination";
import { RequestStatus } from "../invites/constants";
import { EnumFunctions } from "@/utilities/constants";
import { HookFormComponentProps } from "@/components_v2/FormFields/ReactHookForm/types";
import { BaseErrorProps } from "@/components_v2/FormFields/types";
import { BaseData } from "@/types/base";

export type YupSchema =
  | yup.ArraySchema<[], yup.StringSchema>
  | yup.StringSchema
  | yup.AnySchema
  | yup.AnyObjectSchema;

export interface DataBlocksFiltersProps {
  defaultFilters?: Partial<DataBlocksFiltersState>;
  filterNames: string[];
  onFiltersChange: (filters: DataBlocksFiltersState) => void;
}

export interface DataBlocksFiltersState {
  dataTemplates: number[];
  sharingRights: string[];
  contributors: number[];
  languages: number[];
  search?: string;
  user?: number;
  orFilters?: string[];
  organizations?: number[];
  excludeOrganizations?: number[];
}

export type ColumnKey =
  | "title"
  | "type"
  | "organization"
  | "contributors"
  | "sharingRights"
  | "languages"
  | "updatedAt";
export type FilterKey =
  | "type"
  | "sharingRights"
  | "contributors"
  | "organizations"
  | "languages"
  | "search";

export type Tabs = {
  key: string;
  title: string;
  filters: FilterKey[];
  columns: ColumnKey[];
  defaultFilters: Record<string, any>;
};

export interface DataBlockTypeStepProps extends BaseErrorProps {
  templates: DataTemplate[];
  activeTemplateId?: number;
  handleTemplateChange: (value: number) => void;
}

export interface DataBlockDetailsStepProps {
  dataTemplate: DataTemplate;
  excludeFields?: (string | number)[];
}

export interface FieldDependencies {
  getUsers: (value: any) => Promise<any>;
  getSubjects: (value: any) => Promise<any>;
  getDataFiles: any;
  createDataFile: any;
  currentUser: User | undefined;
  t: TFunction;
  dataTemplate: DataTemplate;
}

export interface FieldTypeConfig {
  component:
    | React.ComponentType<any>
    | ((field: DataField) => React.ComponentType<any>);
  isComponentGetter?: boolean;
  getProps?: (
    field: DataField,
    dependencies?: FieldDependencies,
  ) =>
    | Omit<HookFormComponentProps<BaseInputProps>, "control" | "name">
    | Omit<HookFormComponentProps<BaseCheckboxProps>, "control" | "name">
    | (Omit<HookFormComponentProps<BaseDropdownProps>, "control" | "name"> & {
        mode?: "multiple" | "tags" | undefined;
      })
    | Omit<HookFormComponentProps<BaseDatePickerProps>, "control" | "name">
    | (Omit<InfiniteScrollDropdownProps<any>, "control" | "name"> & {
        mode?: "multiple" | "tags" | undefined;
      })
    | Omit<BaseSliderProps, "control" | "name">
    | (Omit<SliderDropdownProps, "control" | "name"> & {
        mode: "multiple" | undefined;
      })
    | Omit<FileRepositoryUploadProps<any>, "control" | "name">;
}

export interface DataBlockDefaultValues {
  dataTemplate?: number;
  request?: DataBlockRequest;
}

export interface CreateDataBlockProps extends SimpleModalProps {
  onSubmit?: (data: DataBlock) => void;
  defaultValues?: DataBlockDefaultValues;
  filters?: Partial<DataBlocksFiltersState>;
}

export interface FormFieldValue {
  field: number;
  value: DynamicValue;
}

export type DataBlockFormFields = {
  organization: number;
  dataTemplate: number;
  sharingRights: string;
  request?: number;
  fieldValues: FormFieldValue[];
};

export type DataBlockContextType = {
  dataBlockContextState: {
    isUploading: boolean;
  };
  setDataBlockContextState: React.Dispatch<
    React.SetStateAction<{
      isUploading: boolean;
    }>
  >;
};

export type NonNullableFormDynamicValue = NonNullable<DynamicValue>;

export interface DataBlockFormValidationSchema {
  fieldValues: {
    field: number;
    value: NonNullableFormDynamicValue;
  }[];
}

export type ValidationRulesFormSchema =
  yup.ObjectSchema<DataBlockFormValidationSchema>;

export interface ValidationContext {
  field: DataField | null;
  schema: YupSchema;
  rule: ValidationRuleType;
  t: TFunction;
}

export interface ValidationResult {
  schema: YupSchema;
  errors?: yup.ValidationError[];
}

export type SchemaGenerator = () => YupSchema;

export interface FormChoiceOption {
  label: string;
  value: number;
  relatedOptions: FormChoiceOption[];
}

export interface CreateDataBlockModalFooterProps {
  currentStep: number;
  isSubmissionStarted: boolean;
  isNextEnabled: boolean;
  handleBack: () => void;
  handleNext: (value?: boolean) => void;
}

export interface DataBlockActionButtonsProps {
  dataBlock: DataBlock;
  dataTemplate: DataTemplate;
  setOpenDeleteModal: (value: boolean) => void;
  setEditRequestModal: (value: DataBlockRequestEditModalsState) => void;
}

export interface DataBlockSidebarProps {
  dataBlock: DataBlock;
  dataTemplate: DataTemplate;
  showCtas?: boolean;
}

export interface DataBlockContentProps {
  dataBlock: DataBlock;
  dataTemplate: DataTemplate;
}

export interface DataBlockDetailsProps {
  dataBlock: DataBlock;
  dataTemplate: DataTemplate;
}

export interface AddContributorModalProps extends SimpleModalProps {
  dataBlock: DataBlock;
  currentContributorIds: number[];
}

export interface AddContributorsFormValues {
  contributors: number[];
}

export interface DisplayFieldValue
  extends Omit<FieldValue, "id" | "dataBlock" | "field"> {
  field: DataField;
  value: DynamicValue;
}

export interface ReferenceType {
  title: string;
  icon: React.FunctionComponent;
  getLink: (value: DataBlockReference) => string;
}

export type ReferencesTypes = {
  surveys: ReferenceType;
  surveyViews: ReferenceType;
  discussions: ReferenceType;
  comments: ReferenceType;
};

export type ContextStateKeys =
  keyof DataBlockContextType["dataBlockContextState"];

export interface ContextMapperValue<K extends ContextStateKeys> {
  field: K;
  value: DataBlockContextType["dataBlockContextState"][K];
}

export type ContextMapper = {
  [K: string]: ContextMapperValue<ContextStateKeys>;
};

export interface DataBlocksTableProps<
  T extends BaseData,
  P extends BaseData = T,
> extends RowSelectionProps {
  columnNames: string[];
  defaultFilters?: Partial<DataBlocksFiltersState>;
  filterNames: string[];
  additionalColumns?: Record<string, ColumnType<P>>;
}

export interface ValidationRuleForSharingRights
  extends Omit<ValidationRule, "id" | "ruleFields"> {
  id?: number;
  ruleFields?: number[];
}

export interface FormFieldsWithSharingRights
  extends Omit<DataField, "id" | "rules"> {
  id: string | number;
  name: string;
  rules?: ValidationRuleForSharingRights[];
}

export interface ChoiceOptionWithScaleLabel extends ChoiceOption {
  scaleLabel: string;
}

export interface DataBlockReferencesProps {
  dataBlock: DataBlock;
}

export interface DataBlockRequestsTableProps<T> extends RowSelectionProps {
  columnNames: string[];
  defaultFilters?: Partial<DataBlocksFiltersState>;
  filterNames: string[];
  additionalColumns?: Record<string, ColumnType<T>>;
  className?: string;
}

export type DataBlockPermissionsOptions = {
  dataBlock?: DataBlock;
  dataTemplate?: DataTemplate;
};

type RequestStatusValue = (typeof RequestStatus)[keyof Omit<
  typeof RequestStatus,
  keyof typeof EnumFunctions
>]["value"];

export interface DataBlockRequestFiltersState
  extends BasePaginationQueryParams {
  search?: string;
  user?: number;
  dataTemplates?: number[];
  status?: RequestStatusValue[];
}

export type DataBlockRequestFiltersProps = {
  setFilters: (value: DataBlockRequestFiltersState) => void;
  defaultFilters?: Partial<DataBlockRequestFiltersState>;
  filterNames: string[];
};

export enum ViewTypes {
  Tabs = "TABS",
  RequestsForm = "REQUESTS_FORM",
  EditRequestForm = "EDIT_REQUEST_FORM",
}

export enum RecipientType {
  User = "USER",
  UserInvite = "USER_INVITE",
}

export interface DataBlockRequestRecipient {
  email: string;
  type: RecipientType;
}

export interface UserRecipient extends BaseUser, DataBlockRequestRecipient {
  type: RecipientType.User;
}

export interface EmailRecipient extends DataBlockRequestRecipient {
  type: RecipientType.UserInvite;
}

export type Recipient = UserRecipient | EmailRecipient;

export interface EditRequestModalProps extends SimpleModalProps {
  request?: number;
}

export interface DataBlockRequestFormProps {
  form: any;
  currentStep: number;
  isSubmitting: boolean;
  showIndividualMessage: boolean;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  resetForm: () => void;
  handleShowIndividualMessageChange: (value: boolean) => void;
}

export interface DataBlockEditRequestFormProps {
  form: any;
  isSubmitting: boolean;
  dataBlockRequest?: DataBlockRequest;
  dataBlockTitle?: string;
  isFetching: boolean;
  handleSubmit: () => void;
}

export interface DataBlockRequestEditFormProps {
  formHook: DataBlockEditRequestFormProps;
}

export interface DataBlockRequestEditModalsState {
  open: boolean;
  request?: number;
}

export interface DataBlockRequestFormItem {
  email: string;
  role: string;
  message?: string;
}

export interface DataBlockRequestsTabProps {
  onCreateRequestsClick: () => void;
}

export interface DataBlockRequestsSurveyModalFooterProps {
  formHook: DataBlockRequestFormProps;
  onBackToTabs: () => void;
}

export interface DataBlockEditRequestsSurveyModalFooterProps {
  formHook: DataBlockEditRequestFormProps;
  onBackToTabs: () => void;
}

export enum activeTabs {
  dataBlocks = "dataBlocks",
  requests = "requests",
}

export enum UserInviteStatus {
  USER_INVITE = "USER_INVITE",
  USER = "USER",
}

export interface SurveyDataBlockModalProps extends SimpleModalProps {
  selectedBlocks: DataBlock[];
  setSelectedBlocks: (value: DataBlock[]) => void;
  filters: Partial<DataBlocksFiltersState>;
}

export interface DataBlocksTabProps {
  selectedBlocks: DataBlock[];
  setSelectedBlocks: (value: DataBlock[]) => void;
  filters: Partial<DataBlocksFiltersState>;
  onEditRequestClick: (value: number) => void;
  onCreateDataBlockClick: () => void;
}

export interface DataBlockEditRequestFormHookProps {
  request?: number;
  onSuccess: () => void;
}
