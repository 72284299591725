import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import Quill, { QuillOptions } from "quill";

import { BaseRichTextProps, QuillModules, QuillRef } from "./types";
import BaseError from "../BaseError";

import "./styles.css";

const baseModules: QuillModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      "bold" as const,
      "italic" as const,
      "underline" as const,
      "strike" as const,
      "blockquote" as const,
    ],
    [{ script: "sub" }, { script: "super" }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link" as const, "formula" as const, "image" as const],
    [{ align: "center" }, { align: "right" }],
    ["code-block" as const],
    ["clean" as const],
  ],
};

const BaseRichText = ({
  value,
  onChange,
  error,
  modules = baseModules,
}: BaseRichTextProps) => {
  const quillRef = useRef<QuillRef>({
    id: _.uniqueId(),
    element: null,
  });
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    if (!quillRef.current.element) {
      const options: QuillOptions = {
        modules: {
          toolbar: modules.toolbar,
        },
        theme: "snow",
      };
      const quill = new Quill(`#quill-${quillRef.current.id}`, options);
      quillRef.current.element = quill;

      const editorChangeHandler = () => {
        const content = quill.getSemanticHTML();
        onChange(content);
      };

      quill.on("text-change", () => {
        const content = quill.getSemanticHTML();

        onChange(content);
      });
      return () => {
        quill.off("text-change", editorChangeHandler);
      };
    }
  }, []);

  useEffect(() => {
    if (quillRef.current.element) {
      if (isInitial || value == null) {
        quillRef.current.element.clipboard.dangerouslyPasteHTML(value || "");
        quillRef.current.element.blur();
        setIsInitial(false);
      }
    }
  }, [quillRef, isInitial, value]);

  return (
    <div className={`base-rich-text ${error && "base-rich-text-error"}`}>
      <div
        id={`quill-${quillRef.current.id}`}
        className="base-rich-text-quill"
      ></div>
      <BaseError error={error} />
    </div>
  );
};

export default BaseRichText;
