import { BaseButtonProps } from "./types";

import "./styles.css";

const BaseButton = ({
  variant = "primary",
  size = "large",
  isFullWidth,
  leadingIcon,
  trailingIcon,
  children,
  disabled,
  onClick,
}: BaseButtonProps) => {
  return (
    <button
      className={`body-s-regular base-button base-button-${variant} base-button-${size} ${disabled ? "base-button-disabled" : ""} ${isFullWidth ? "base-button-full-width" : ""}`}
      onClick={disabled ? undefined : onClick}
    >
      {leadingIcon && <span>{leadingIcon}</span>}

      {children}

      {trailingIcon && <span>{trailingIcon}</span>}
    </button>
  );
};

export default BaseButton;
