import { BaseModalHeaderProps } from "../types";

import "../styles.css";

const BaseModalHeader = ({ title, subtitle }: BaseModalHeaderProps) => {
  return (
    <div className="base-modal-header">
      <h3 className="base-modal-header-title header-m-medium">{title}</h3>
      {subtitle && (
        <p className="base-modal-header-subtitle body-m-regular">{subtitle}</p>
      )}
    </div>
  );
};

export default BaseModalHeader;
