import { useEffect, useState } from "react";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";

import { BaseData } from "@/types/base";
import { QueryTableProps } from "./types";
import BaseTable from "./BaseTable";

import "./styles.css";

const QueryTable = <T extends BaseData, P extends BaseData = T>({
  query,
  filters = {},
  normalizeData,
  columns,
  ...props
}: QueryTableProps<T, P>) => {
  const defaultSortedColumn = columns.find((column) => column.defaultSortOrder);
  const [rows, setRows] = useState<P[]>([]);
  const [tableState, setTableState] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    ordering: defaultSortedColumn
      ? `${defaultSortedColumn.defaultSortOrder === "descend" ? "-" : ""}${defaultSortedColumn.dataIndex}`
      : undefined,
  });
  const { pagination, ordering } = tableState;

  const [trigger, { data, isSuccess, isLoading, isFetching }] = query();

  useEffect(() => {
    trigger({
      page: pagination.current,
      pageSize: pagination.pageSize,
      ordering,
      ...filters,
    });
  }, [pagination.current, pagination.pageSize, ordering, filters, trigger]);

  useEffect(() => {
    if (isSuccess && data?.results) {
      setRows(normalizeData ? normalizeData(data.results) : data.results);

      setTableState((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: data.count || 0,
        },
      }));
    }
  }, [data, isSuccess, normalizeData]);

  const handleTableChange = (
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<P> | SorterResult<P>[],
  ) => {
    const singleSorter = !Array.isArray(sorter) ? sorter : null;
    setTableState({
      pagination: {
        current: pagination.current || 1,
        pageSize: pagination.pageSize || 10,
        total: tableState.pagination.total,
      },
      ordering: singleSorter?.field
        ? `${singleSorter.order === "descend" ? "-" : ""}${singleSorter.field}`
        : undefined,
    });
  };

  return (
    <BaseTable<P>
      className="query-table"
      {...props}
      columns={columns}
      dataSource={rows}
      rowKey={(record: P) => record.id}
      loading={{ spinning: isLoading || isFetching, size: "large" }}
      onChange={handleTableChange}
      pagination={tableState.pagination}
    />
  );
};

export default QueryTable;
