import { useEffect, useState, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import {
  useGetCurrentUserQuery,
  useLazyGetSurveyStatisticsQuery,
} from "@/redux/service.js";
import {
  PRME_ORGANIZATION_ID,
  Roles,
  SurveyActions,
  SurveyStatus,
  SurveyType,
} from "@/utilities/constants.jsx";
import SubmissionConfirmationModal from "./SubmissionConfirmationModal.jsx";
import { canUserPerformActionOnSurvey } from "@/utilities/permissions.js";
import ProgressCard from "../../../../components_v2/Card/ProgressCard.js";
import BaseProgressBar from "../../../../components_v2/ProgressBars/BaseProgressBar.js";
import BaseButton from "../../../../components_v2/Button/BaseButton.js";
import BaseSvgRenderer from "../../../../components_v2/Svg/BaseSvgRenderer.js";
import AddDocumentIcon from "@/assets/icons/add-document.svg?react";
import PreviewIcon from "@/assets/icons/preview.svg?react";
import SendIcon from "@/assets/icons/send.svg?react";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner.js";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip.js";

import "./styles.css";

const SurveySectionsOverview = ({ survey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isSample = location.pathname.includes("sample");
  const [hasAccess, setHasAccess] = useState(false);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [getSurveyStatistics, surveyStatisticsResponse] =
    useLazyGetSurveyStatisticsQuery();
  const { data: currentUser, isSuccess: currentUserQuerySuccess } =
    useGetCurrentUserQuery();

  useEffect(() => {
    if (currentUserQuerySuccess && survey) {
      const hasAccess =
        currentUser.role !== Roles.ADMIN.value && survey.details != null;
      setHasAccess(hasAccess);
      if (!hasAccess) {
        navigate("/");
      }
    }
  }, [survey, currentUserQuerySuccess]);

  useEffect(() => {
    if (
      survey.status === SurveyStatus.ENDED.value ||
      survey.details?.isSubmitted
    ) {
      navigate(`/reports/${survey.id}/report/`);
    }

    getSurveyStatistics({
      survey: survey.id,
      organization: isSample ? PRME_ORGANIZATION_ID : null,
    });
  }, [survey]);

  const handlePreview = () => {
    navigate(`/reports/${survey.id}/report`);
  };

  const reportingPeriod = (
    <h3 className="survey-sections-overview-additional-info header-s-regular">
      <span>{t("reportingPeriod")}: </span>
      <span>
        {DateTime.fromISO(survey.startDate).toFormat("dd MMM yyyy")} -{" "}
      </span>
      <span>
        {DateTime.fromISO(survey.softDeadline || survey.hardDeadline).toFormat(
          "dd MMM yyyy",
        )}
      </span>
    </h3>
  );

  const progressBarData = useMemo(() => {
    if (!surveyStatisticsResponse.isSuccess) return undefined;

    let answeredOptionalQuestions = 0;
    let answeredRequiredQuestions = 0;
    let optionalQuestions = 0;
    let requiredQuestions = 0;

    surveyStatisticsResponse.data.forEach((survey) => {
      answeredOptionalQuestions += survey.answeredOptionalQuestionsCount;
      answeredRequiredQuestions += survey.answeredRequiredQuestionsCount;
      optionalQuestions += survey.optionalQuestionsCount;
      requiredQuestions += survey.requiredQuestionsCount;
    });

    const percentage = Math.round(
      ((answeredOptionalQuestions + answeredRequiredQuestions) /
        (optionalQuestions + requiredQuestions)) *
        100,
    );

    return {
      totalQuestions: optionalQuestions + requiredQuestions,
      totalAnsweredQuestions:
        answeredOptionalQuestions + answeredRequiredQuestions,
      label: (
        <>
          {t("survey.labels.required")}: {answeredRequiredQuestions}/
          {requiredQuestions} | {t("survey.labels.optional")}:{" "}
          {answeredOptionalQuestions}/{optionalQuestions}
        </>
      ),
      helpText: (
        <>
          {percentage}% {t("survey.labels.complete")}
        </>
      ),
      description: (
        <>
          {t("survey.labels.overallProgress")}:{" "}
          {answeredRequiredQuestions + answeredOptionalQuestions}/
          {requiredQuestions + optionalQuestions}{" "}
          {t("survey.labels.questionsAnswered")}
        </>
      ),
    };
  }, [surveyStatisticsResponse]);

  const getSectionButtonLabel = useCallback(
    (section) => {
      if (!section) return;

      if (isSample) {
        return t("survey.labels.view");
      }
      return section.answeredRequiredQuestionsCount === 0
        ? t("survey.labels.start")
        : t("survey.labels.continue");
    },
    [isSample],
  );

  const getSectionStatus = useCallback((section) => {
    if (section.answeredRequiredQuestionsCount > 0) {
      const requiredQuestionsLeft =
        section.requiredQuestionsCount - section.answeredRequiredQuestionsCount;
      const optionalQuestionsLeft =
        section.optionalQuestionsCount - section.answeredOptionalQuestionsCount;

      if (requiredQuestionsLeft > 0) {
        return "required-missing";
      }

      if (optionalQuestionsLeft > 0) {
        return "optional-missing";
      }

      if (requiredQuestionsLeft === 0 && optionalQuestionsLeft === 0) {
        return "completed";
      }
    }

    return "";
  }, []);

  if (!hasAccess) {
    return <BaseSpinner />;
  }

  return (
    <div className="survey-sections-overview">
      <div className="survey-sections-overview-header-container">
        <div className="survey-sections-overview-header">
          <h1 className="survey-sections-overview-heading header-l-medium">
            {isSample
              ? survey.title + " - " + t("survey.labels.sample")
              : survey.title}
          </h1>
          {!isSample && reportingPeriod}
        </div>

        <p className="survey-sections-overview-content body-m-regular">
          {survey.description}
        </p>

        {progressBarData ? (
          <BaseProgressBar
            value={progressBarData.totalAnsweredQuestions}
            total={progressBarData.totalQuestions}
            label={progressBarData.label}
            helpText={progressBarData.helpText}
            description={progressBarData.description}
          />
        ) : (
          <BaseSpinner size="small" />
        )}

        <div className="survey-sections-overview-actions-container">
          <div>
            {!isSample && (
              <BaseButton
                variant="tertiary"
                size="small"
                leadingIcon={
                  <BaseSvgRenderer
                    width="20"
                    height="20"
                    component={AddDocumentIcon}
                  />
                }
                onClick={() =>
                  window.open(`${location.pathname}/sample`, "_blank")
                }
              >
                {t("buttons.sampleSurvey", {
                  surveyType: SurveyType.getLabel(survey.type),
                })}
              </BaseButton>
            )}

            {!survey.createdAt.includes("2025") && (
              <BaseButton
                variant="tertiary"
                size="small"
                leadingIcon={
                  <BaseSvgRenderer
                    width="20"
                    height="20"
                    component={AddDocumentIcon}
                  />
                }
              >
                {t("buttons.generateViews")}
              </BaseButton>
            )}
          </div>

          {!isSample && (
            <div className="survey-sections-overview-actions-rigth-container">
              <BaseButton
                variant="tertiary"
                size="small"
                leadingIcon={
                  <BaseSvgRenderer
                    width="20"
                    height="20"
                    component={PreviewIcon}
                  />
                }
                onClick={handlePreview}
              >
                {t("previewSurvey")}
              </BaseButton>

              {canUserPerformActionOnSurvey(
                survey.permissions,
                currentUser.organizationRole,
                SurveyActions.SUBMIT.value,
              ) && (
                <BaseTooltip title={t("survey.labels.disabledSubmit")}>
                  <BaseButton
                    size="small"
                    disabled={true}
                    leadingIcon={
                      <BaseSvgRenderer
                        width="20"
                        height="20"
                        component={SendIcon}
                      />
                    }
                    onClick={() => setOpenConfirmationModal(true)}
                  >
                    {t("submitSurvey")}
                  </BaseButton>
                </BaseTooltip>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="survey-sections-overview-content-container">
        <div className="survey-sections-overview-content-header">
          <h3 className="header-m-medium">{t("survey.labels.principles")}</h3>

          <p className="body-m-regular">
            {t("survey.labels.clickToStartAnswering")}
          </p>
        </div>

        <div className="survey-sections-overview-content">
          {surveyStatisticsResponse.isSuccess ? (
            (surveyStatisticsResponse.data || [])
              .slice()
              .sort((a, b) => a.index - b.index)
              .map((section) => {
                const progressBarLabel = `${section.answeredRequiredQuestionsCount + section.answeredOptionalQuestionsCount}/${section.requiredQuestionsCount + section.optionalQuestionsCount} ${t("survey.labels.questionsAnswered")}`;
                return (
                  <ProgressCard
                    key={section.id}
                    title={section.title}
                    status={getSectionStatus(section)}
                    color={section.color}
                    iconSrc={section.icon}
                    label={progressBarLabel}
                    value={
                      section.answeredRequiredQuestionsCount +
                      section.answeredOptionalQuestionsCount
                    }
                    total={
                      section.requiredQuestionsCount +
                      section.optionalQuestionsCount
                    }
                    buttonLabel={getSectionButtonLabel(section)}
                    onCardClick={() =>
                      navigate(
                        `/reports/${section.survey}/section/${section.id}${isSample ? "/sample" : ""}`,
                      )
                    }
                  />
                );
              })
          ) : (
            <BaseSpinner size="small" />
          )}
        </div>
      </div>

      <SubmissionConfirmationModal
        onCancel={() => setOpenConfirmationModal(false)}
        survey={survey}
        open={openConfirmationModal}
      />
    </div>
  );
};

SurveySectionsOverview.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SurveySectionsOverview;
