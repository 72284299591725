import { useCallback, useMemo, useState } from "react";
import { ColumnType } from "antd/es/table/interface";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import QueryTable from "../../../../components_v2/Tables/QueryTable";
import {
  useLazyPaginateFilterDataBlocksQuery,
  usePaginateFilterDataTemplatesQuery,
} from "@/redux/service";
import { DataBlock, NormalizedDataBlock } from "../../types/blocks";
import { BaseUser } from "@/views/users/types/types";
import BaseAvatarGroup from "@/components_v2/Avatar/BaseAvatarGroup";
import { SharingRights } from "@/utilities/constants";
import BaseTag from "@/components_v2/Tags/BaseTag";
import {
  ColumnKey,
  DataBlocksFiltersState,
  DataBlocksTableProps,
} from "../../types/types";
import DataBlocksFilters from "./DataBlocksFilters";
import {
  ContentType,
  DataTemplate,
  MinDataTemplate,
} from "../../types/templates";
import UserAvatar from "@/components_v2/Avatar/UserAvatar";
import { useDataBlockRelatedData } from "../hooks/useDataBlockRelatedData";
import { useResponsive } from "@/hooks/useBreakpoints";
import {
  CollapsedAvatarGroupLabel,
  UserAvatarProps,
} from "@/components_v2/Avatar/types";
import SharingRightsTooltip from "@/components_v2/Tooltip/SharingRightsTooltip";

import "./styles.css";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip";
import { Organization } from "@/views/organizations/types/types";
import {
  getDataBlockContributors,
  getDataBlockTitle,
  getDataBlockLanguages,
} from "../hooks/useDataBlockField";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";
import ReceiveInviteIcon from "@/assets/icons/receive-invite.svg?react";

const { Text } = Typography;

const DataBlocksTable = <T extends DataBlock>({
  columnNames,
  filterNames,
  defaultFilters = {},
  rowSelection,
  additionalColumns,
}: DataBlocksTableProps<T, NormalizedDataBlock>) => {
  const { isMobile } = useResponsive();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [filters, setFilters] = useState<DataBlocksFiltersState>(
    defaultFilters as DataBlocksFiltersState,
  );
  const [pageContributorIds, setPageContributorIds] = useState<number[]>([]);
  const { data: templates } = usePaginateFilterDataTemplatesQuery({
    pagination: "off",
  }) as { data: DataTemplate[] | undefined };
  const { data: users } = useDataBlockRelatedData(
    pageContributorIds,
    ContentType.Users,
  ) as { data: BaseUser[] | null };

  const userIdMapping = useMemo(() => {
    if (!users) return;
    return users.reduce<Record<number, BaseUser>>((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {});
  }, [users]);

  const templateFieldsMapping = useMemo(() => {
    if (!templates) return;
    return templates.reduce<Record<number, DataTemplate>>((acc, template) => {
      acc[template.id] = template;
      return acc;
    }, {});
  }, [templates]);

  const columns: Record<string, ColumnType<NormalizedDataBlock>> = useMemo(
    () => ({
      title: {
        title: t("table.title"),
        fixed: !isMobile ? "left" : undefined,
        dataIndex: "title",
        sorter: true,
        width: 200,
        ellipsis: true,
        render: (_, value: NormalizedDataBlock) => (
          <div className="data-blocks-table-title-container">
            <a onClick={() => navigate(`/evidence/${value.id}`)}>
              {
                <Text
                  style={{ maxWidth: 200 }}
                  ellipsis={{
                    symbol: "...",
                    tooltip: true,
                  }}
                >
                  {value.title}
                </Text>
              }
            </a>
          </div>
        ),
      },
      type: {
        title: t("table.dataTemplateType"),
        dataIndex: "template",
        render: (value: MinDataTemplate) => {
          return value.title;
        },
      },
      request: {
        fixed: !isMobile ? "left" : undefined,
        align: "center",
        render: (value) => (
          <BaseTooltip
            title={value.request && t("datablocks.labels.createdViaInvite")}
          >
            {value.request != null && (
              <BaseSvgRenderer
                component={ReceiveInviteIcon}
                width="20"
                height="20"
                stroke="blue"
              />
            )}
          </BaseTooltip>
        ),
      },
      organization: {
        title: t("table.signatory"),
        dataIndex: "organization",
        render: (value: Organization) => {
          return value.name;
        },
      },
      contributors: {
        title: t("table.contributors"),
        dataIndex: "contributors",
        render: (value: number[]) => {
          if (!userIdMapping) return;
          const users = value
            .map((userId) => userIdMapping[userId])
            .filter((i) => !!i);
          return (
            <BaseAvatarGroup<UserAvatarProps & CollapsedAvatarGroupLabel>
              AvatarComponent={UserAvatar}
              avatars={users.map((user) => ({
                user,
                label: user.fullName,
                tooltip: user.fullName,
              }))}
            />
          );
        },
      },
      sharingRights: {
        title: (
          <div className="data-block-table-sharing-rights-header">
            <p>{t("sharingRights.label")}</p>
            <SharingRightsTooltip>
              <InfoCircleOutlined />
            </SharingRightsTooltip>
          </div>
        ),
        dataIndex: "sharingRights",
        render: (value: string) => {
          const sharingRights = SharingRights.getItemByValue(value);
          return (
            <BaseTag
              icon={sharingRights.icon}
              label={sharingRights.label}
              className={sharingRights.className}
            />
          );
        },
      },
      languages: {
        title: t("datablocks.labels.languages"),
        dataIndex: "languages",
        render: (value: string[]) => value.join(", "),
      },
      updatedAt: {
        title: t("table.lastModified"),
        dataIndex: "lastModified",
        key: "lastModified",
        sorter: true,
        defaultSortOrder: "descend",
        render: (_, value: DataBlock) =>
          DateTime.fromISO(value.updatedAt).toRelative(),
      },
      createdBy: {
        title: t("datablocks.labels.createdBy"),
        dataIndex: "createdBy",
        sorter: true,
        render: (value: BaseUser) => (
          <div className="data-blocks-table-avatar-container">
            <UserAvatar user={value} />
            <p>{value.fullName}</p>
          </div>
        ),
      },
      ...additionalColumns,
    }),
    [userIdMapping, isMobile, additionalColumns],
  );

  const mappedColumns = useMemo(
    () =>
      columnNames.map(
        (columnKey) =>
          columns[columnKey as ColumnKey] as ColumnType<NormalizedDataBlock>,
      ),
    [columns, columnNames],
  );

  const normalizeData = useCallback(
    (dataBlocks: DataBlock[]) => {
      const uniqueContributorIds = new Set<number>();
      const normalizedBlocks = dataBlocks.map(
        (block: DataBlock): NormalizedDataBlock => {
          const dataBlockTemplate = templateFieldsMapping![block.template.id];
          const contributors = getDataBlockContributors(block);
          contributors.forEach((id) => uniqueContributorIds.add(id));
          return {
            ...block,
            title: getDataBlockTitle(block),
            contributors,
            languages: getDataBlockLanguages(block, dataBlockTemplate),
          };
        },
      );
      setPageContributorIds(Array.from(uniqueContributorIds));
      return normalizedBlocks;
    },
    [templateFieldsMapping],
  );

  const handleRowClick = (record: NormalizedDataBlock) => {
    if (rowSelection?.enabled && rowSelection.onSelect) {
      const isCurrentlySelected = rowSelection.selectedRows?.some(
        (selectedBlock) => selectedBlock.id === record.id,
      );

      let newSelectedBlocks;
      if (isCurrentlySelected) {
        newSelectedBlocks =
          rowSelection.selectedRows?.filter(
            (block) => block.id !== record.id,
          ) || [];
      } else {
        if (rowSelection.multiple) {
          newSelectedBlocks = [...(rowSelection.selectedRows || []), record];
        } else {
          newSelectedBlocks = [record];
        }
      }

      rowSelection.onSelect(newSelectedBlocks);
    }
  };

  return (
    templateFieldsMapping && (
      <div className="data-blocks-table">
        <DataBlocksFilters
          defaultFilters={defaultFilters}
          filterNames={filterNames}
          onFiltersChange={setFilters}
        />
        <QueryTable<T, NormalizedDataBlock>
          query={useLazyPaginateFilterDataBlocksQuery}
          filters={filters}
          columns={mappedColumns}
          normalizeData={normalizeData}
          rowSelection={rowSelection}
          onRow={(record) => ({
            onClick: (event) => {
              if (
                event.target instanceof HTMLElement &&
                (event.target.closest("button") ||
                  event.target.closest("a") ||
                  event.target.closest('input[type="checkbox"]'))
              ) {
                return;
              }

              handleRowClick(record);
            },
          })}
        />
      </div>
    )
  );
};

export default DataBlocksTable;
