import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import {
  DataBlockDefaultValues,
  DataBlockRequestFiltersState,
} from "../types/types";
import RequestFilters from "../invites/components/RequestFilters";
import {
  useGetCurrentUserQuery,
  useLazyGetDataBlockRequestsQuery,
} from "@/redux/service";
import ListItemsCard from "@/components_v2/Card/ListItemsCard";
import { DataBlockRequest } from "../types/requests";
import BaseTabs from "@/components_v2/Tabs/BaseTabs";
import { CAN_CREATE_REQUESTS } from "../core/constants";
import RequestsAccordion from "../invites/components/RequestsAccordion";
import BaseButton from "@/components_v2/Button/BaseButton";
import CreateDataBlock from "../core/components/CreateDatablock";
import { RequestStatus } from "../invites/constants";
import RequestFormModal from "../invites/components/RequestFormModal";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import EmptyIcon from "@/assets/icons/empty.svg?react";
import DataBlockInviteIcon from "@/assets/icons/datablock-invites.svg?react";
import BaseTag from "@/components_v2/Tags/BaseTag";
import CalendarIcon from "@/assets/icons/calendar.svg?react";
import SurveyIcon from "@/assets/icons/survey-icon.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";

const DataBlockRequests = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getDataBlockRequests, { data, isSuccess, isFetching }] =
    useLazyGetDataBlockRequestsQuery();
  const { data: currentUser } = useGetCurrentUserQuery();

  const [filters, setFilters] = useState<DataBlockRequestFiltersState>({
    pagination: "off" as const,
    status: [RequestStatus.PENDING.value, RequestStatus.REVISION_NEEDED.value],
    user: undefined,
  });

  const [createDataBlock, setDataBlockCreate] = useState<{
    open: boolean;
    defaultValues?: DataBlockDefaultValues;
  }>({
    open: false,
    defaultValues: undefined,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setFilters((prevValue) => ({ ...prevValue, user: currentUser.id }));
    }
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    getDataBlockRequests(filters);
  }, [currentUser, filters]);

  const requestCards = useMemo(() => {
    if (isFetching) {
      return (
        <div className="data-block-requests-spinner-container">
          <BaseSpinner />
        </div>
      );
    }

    if (isSuccess) {
      const results = data as DataBlockRequest[];
      return results.map((request) => {
        const daysLeft = DateTime.fromISO(request.expiresAt).diffNow(
          "days",
        ).days;

        const helpTagContent = () => {
          const days = Math.ceil(daysLeft).toString();

          if (days === "0") return t("datablocks.labels.today");

          return `${days} ${t("datablocks.labels.daysLeft")}`;
        };

        return (
          <ListItemsCard
            key={request.id}
            headerContent={
              <div className="list-items-card-tags-container">
                <BaseTag label={request.dataTemplate.title} />

                {request.status === RequestStatus.PENDING.value && (
                  <BaseTag
                    label={helpTagContent()}
                    className="list-items-card-help"
                  />
                )}
              </div>
            }
            title={t(
              `datablocks.requestStatus.${request.status.toLowerCase()}`,
            )}
            description={`From ${request.createdBy.fullName}`}
            items={[
              {
                icon: <BaseSvgRenderer component={SurveyIcon} />,
                content: request.message,
              },
              {
                icon: <BaseSvgRenderer component={CalendarIcon} />,
                content: DateTime.fromISO(request.expiresAt).toFormat(
                  "dd MMM yyyy",
                ),
              },
            ]}
            buttonText={
              request.status === RequestStatus.PENDING.value
                ? t("datablocks.labels.createDataBlock")
                : t("datablocks.labels.updateDataBlock")
            }
            onButtonClick={() => {
              if (request.dataBlock) {
                navigate(`/evidence/${request.dataBlock}/edit`);
              } else {
                setDataBlockCreate({
                  open: true,
                  defaultValues: {
                    request: request,
                    dataTemplate: request.dataTemplate.id,
                  },
                });
              }
            }}
          />
        );
      });
    }

    return [];
  }, [isSuccess, isFetching, data]);

  const RequestsReceivedContainer = useMemo(() => {
    return (
      <div className="data-block-requests">
        <div className="datablock-requests-filters">
          <RequestFilters
            filterNames={["type", "search"]}
            setFilters={(filters) =>
              setFilters((prevValue) => ({ ...prevValue, ...filters }))
            }
          />
        </div>
        {Array.isArray(requestCards) && requestCards.length ? (
          <div className="datablock-requests-cards">{requestCards}</div>
        ) : (
          <div className="data-block-requests-empty-container">
            <BaseSvgRenderer component={EmptyIcon} />
            <h5 className="data-block-requests-empty-message body-m-regular">
              {t("datablocks.labels.noRequestsReceived")}
            </h5>
          </div>
        )}
      </div>
    );
  }, [createDataBlock, setDataBlockCreate, requestCards]);

  return (
    currentUser && (
      <div className="data-block-requests">
        <div className="data-block-requests-header">
          <h1 className="header-l-medium">{t("datablocks.labels.requests")}</h1>
          {currentUser.organizationRole &&
            CAN_CREATE_REQUESTS.includes(currentUser.organizationRole) && (
              <BaseButton
                size="small"
                leadingIcon={
                  <BaseSvgRenderer
                    width="20"
                    height="20"
                    stroke="white"
                    component={DataBlockInviteIcon}
                  />
                }
                onClick={() => setOpen(true)}
              >
                {t("datablocks.labels.sendRequests")}
              </BaseButton>
            )}
        </div>
        <p className="body-m-regular">
          {t("datablocks.labels.requestsDescription")}
        </p>

        {currentUser.organizationRole &&
        CAN_CREATE_REQUESTS.includes(currentUser.organizationRole) ? (
          <BaseTabs
            items={[
              {
                key: "received",
                label: t("datablocks.labels.requestsReceived"),
                children: RequestsReceivedContainer,
              },
              {
                key: "sent",
                label: t("datablocks.labels.requestsSent"),
                children: <RequestsAccordion />,
              },
            ]}
          />
        ) : (
          RequestsReceivedContainer
        )}

        <CreateDataBlock
          defaultValues={createDataBlock.defaultValues}
          open={createDataBlock.open}
          setOpen={(open) =>
            setDataBlockCreate({ open, defaultValues: undefined })
          }
        />
        <RequestFormModal open={open} setOpen={setOpen} />
      </div>
    )
  );
};

export default DataBlockRequests;
