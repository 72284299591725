import { useState } from "react";

import { CustomChoiceDropdownProps } from "./types";
import useCustomChoiceOptions from "./hooks/useCustomChoiceOptions";
import { withCustomChoiceInput } from "./hocs/withCustomChoice";
import BaseDropdown from "./BaseDropdown";

import "./styles.css";

export const CustomChoiceDropdown = ({
  options,
  value,
  onChange,
  customChoiceSchema,
  className,
  multiple,
  ...props
}: CustomChoiceDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { customOptions, addCustomOption } = useCustomChoiceOptions({
    initialOptions: options,
    initialValue: value,
    onChange,
    multiple,
  });

  const handleAddOption = (value: string) => {
    addCustomOption(value);
    if (!multiple) {
      setIsOpen(false);
    }
  };

  return (
    <BaseDropdown
      className={`custom-choice-dropdown ${className || ""}`}
      options={customOptions}
      value={value}
      onChange={onChange}
      open={isOpen}
      multiple={multiple}
      onDropdownVisibleChange={setIsOpen}
      {...props}
      dropdownRender={(menu) =>
        withCustomChoiceInput(menu, {
          onAddOption: handleAddOption,
          schema: customChoiceSchema,
        })
      }
    />
  );
};

export default CustomChoiceDropdown;
