import { useEffect, useRef, useState } from "react";

import { DateTime } from "luxon";
import InfiniteScroll from "react-infinite-scroller";
import { useTranslation } from "react-i18next";
import { Badge, Card, List, Popover, Select, theme, Typography } from "antd";
import { BellOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import {
  useGetUnreadNotificationsCountQuery,
  useLazyGetNotificationsByReadStatusQuery,
  useReadNotificationsMutation,
} from "../../redux/service.js";
import OutsideClickHandler from "./PopoverClickHandler.jsx";

import "./styles.css";
import UserAvatar from "../../components_v2/Avatar/UserAvatar.js";

const styles = {
  title: {
    borderBottom: "1px solid #E9F0FC",
  },
  titleBtn: {
    color: "#1A1F36",
  },
  contentList: {
    width: "400px",
    height: "400px",
    overflowY: "auto",
  },
  icon: {
    cursor: "pointer",
    innerIcon: {
      fontSize: "20px",
    },
  },
};

const { Text } = Typography;

const AnchorNotification = ({ text }) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);

  useEffect(() => {
    /**
     * Adds an onClick event handler to all anchor tags in the notification which uses navigate from React router instead
     * of the default anchor routing.
     */
    const container = containerRef.current;
    if (container) {
      const anchorTags = container.getElementsByTagName("a");
      for (let anchor of anchorTags) {
        anchor.addEventListener("click", (e) => {
          e.preventDefault();
          navigate(anchor.getAttribute("href"));
        });
      }
    }
  }, [navigate]);

  return <div ref={containerRef} dangerouslySetInnerHTML={{ __html: text }} />;
};

AnchorNotification.propTypes = {
  text: PropTypes.string.isRequired,
};

const Notifications = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showAllNotifications, setShowAllNotifications] = useState(false);
  const [trigger, result] = useLazyGetNotificationsByReadStatusQuery();
  const {
    data: unreadNotificationsCountData,
    isSuccess: isUnreadNotificationsCountRequestSuccess,
  } = useGetUnreadNotificationsCountQuery({}, { pollingInterval: 30000 });
  const [readNotificationsAction] = useReadNotificationsMutation();
  const [items, setItems] = useState([]);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const { token } = theme.useToken();
  const { t } = useTranslation();

  useEffect(() => {
    if (isUnreadNotificationsCountRequestSuccess) {
      setUnreadNotificationsCount(unreadNotificationsCountData.count);
    }
  }, [unreadNotificationsCountData]);

  const updateUnreadNotifications = () => {
    readNotificationsAction();
    setItems([]);
    setUnreadNotificationsCount(0);
  };

  const handlePopoverClose = (isOpen) => {
    if (isOpen) {
      readNotificationsAction();
      setUnreadNotificationsCount(0);
      setShowAllNotifications(false);
      setItems([]);
      setIsPopoverOpen(false);
    }
  };

  const setItemsNotifications = (data) => {
    const oldNotificationIds = items.map((i) => i.id);
    setItems((oldVal) => [
      ...oldVal,
      ...data.results.filter((i) => !oldNotificationIds.includes(i.id)),
    ]);
  };

  const refetchNotifications = () => {
    if (result?.data?.next || result.isUninitialized) {
      trigger({
        page: result.data?.next || 1,
        isRead: showAllNotifications,
      })
        .unwrap()
        .then(setItemsNotifications);
    }
  };

  useEffect(() => {
    if (isPopoverOpen) {
      trigger({
        page: result.data?.next || 1,
        isRead: showAllNotifications,
      })
        .unwrap()
        .then(setItemsNotifications);
    }
  }, [isPopoverOpen, showAllNotifications]);

  const handleChangeShowNotificationsFlag = () => {
    setItems([]);
    setShowAllNotifications((oldVal) => !oldVal);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={handlePopoverClose}
      isPopoverOpen={isPopoverOpen}
    >
      <Popover
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        open={isPopoverOpen}
        onClick={() => {
          setIsPopoverOpen((oldVal) => {
            if (oldVal) {
              readNotificationsAction();
            }

            return !oldVal;
          });
        }}
        title={
          <div className="d-flex align-items-center pb-2" style={styles.title}>
            <span className="me-2" style={styles.titleText}>
              {t("titles.notifications")}
            </span>
            <Select
              defaultValue="unread"
              style={{ width: 90 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={handleChangeShowNotificationsFlag}
              value={showAllNotifications ? "all" : "unread"}
              options={[
                { value: "unread", label: t("form.labels.unread") },
                { value: "all", label: t("form.labels.all") },
              ]}
            />
            {!showAllNotifications && (
              <span className="ms-auto">
                <a style={styles.titleBtn} onClick={updateUnreadNotifications}>
                  {t("notificationsMarkAllAsRead")} <CheckCircleOutlined />
                </a>
              </span>
            )}
          </div>
        }
        trigger="click"
        content={
          <div style={styles.contentList} className="notification-content">
            <InfiniteScroll
              pageStart={1}
              loadMore={refetchNotifications}
              hasMore={!!result?.data?.next}
              initialLoad={false}
              loader={
                <div className="loader" key={0}>
                  {t("messages.loading")}
                </div>
              }
              useWindow={false}
            >
              {items.map((item) => (
                <List.Item key={item.id}>
                  <Card
                    style={{ width: 300, boxShadow: "none" }}
                    bodyStyle={{ padding: "0" }}
                    bordered={false}
                  >
                    <Card.Meta
                      className="p-2"
                      style={{ width: 350, wordWrap: "break-word" }}
                      avatar={
                        <Badge
                          offset={[-40, 0]}
                          dot={!item.isRead}
                          color={token.linkColor}
                        >
                          <UserAvatar
                            user={
                              item.createdBy || {
                                id: null,
                                firstName: "PRME",
                                lastName: "Commons",
                              }
                            }
                          />
                        </Badge>
                      }
                      title={
                        <Text style={{ whiteSpace: "normal" }}>
                          <AnchorNotification text={item.title} />
                        </Text>
                      }
                      description={DateTime.fromISO(
                        item.createdAt,
                      ).toRelative()}
                    />
                  </Card>
                </List.Item>
              ))}
            </InfiniteScroll>
          </div>
        }
      >
        <Badge
          color={token.linkColor}
          count={unreadNotificationsCount}
          style={styles.icon}
        >
          <BellOutlined style={{ ...styles.icon, ...styles.icon.innerIcon }} />
        </Badge>
      </Popover>
    </OutsideClickHandler>
  );
};

export default Notifications;
