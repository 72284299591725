import { useMemo } from "react";
import { RowSelectionType } from "antd/es/table/interface";
import { Table } from "antd";

import { BaseData } from "../../types/base";
import { BaseTableProps } from "./types";

const DEFAULT_ROW_SELECTION = {
  enabled: false,
  multiple: false,
  selectedRows: [],
  onSelect: () => {},
};

const BaseTable = <T extends BaseData>({
  dataSource,
  columns,
  pagination,
  onChange,
  rowSelection,
  ...props
}: BaseTableProps<T>) => {
  const currentRowSelection = rowSelection || DEFAULT_ROW_SELECTION;

  const rowSelectionConfig = useMemo(() => {
    if (!currentRowSelection?.enabled) return undefined;

    const currentSelectedRows = currentRowSelection.selectedRows || [];
    const type = (
      currentRowSelection.multiple ? "checkbox" : "radio"
    ) as RowSelectionType;

    return {
      type,
      onSelect: (record: T, selected: boolean) => {
        if (!selected) {
          currentRowSelection.onSelect(
            currentSelectedRows.filter((i) => i.id !== record.id) as T[],
          );
        } else {
          currentRowSelection.onSelect([
            ...(currentSelectedRows as T[]),
            record as T,
          ]);
        }
      },
      hideSelectAll: true,
      selectedRowKeys: currentSelectedRows.map((i) => i.id),
      onSelectMultiple: (selected: boolean, selectedRows: T[]) => {
        if (!selected) {
          const unselectedRowIds = selectedRows.map((i) => i.id);
          currentRowSelection.onSelect(
            currentSelectedRows.filter(
              (i) => !unselectedRowIds.includes(i.id),
            ) as T[],
          );
        } else {
          currentRowSelection.onSelect([
            ...(currentSelectedRows as T[]),
            ...(selectedRows as T[]),
          ]);
        }
      },
    };
  }, [currentRowSelection]);

  return (
    <Table<T>
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelectionConfig}
      pagination={
        pagination && {
          ...pagination,
          showSizeChanger: true,
          position: ["bottomRight"],
          className: "custom-pagination",
        }
      }
      onChange={onChange}
      scroll={{ x: "max-content" }}
      {...props}
    />
  );
};

export default BaseTable;
