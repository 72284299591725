import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useGetCurrentUserQuery } from "@/redux/service";
import BaseSpinner from "../components_v2/Spiner/BaseSpinner";
import BaseButton from "../components_v2/Button/BaseButton";
import { Roles } from "@/utilities/constants";

import "./styles.css";

const AccessDenied = ({ message }: { message?: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="access-denied-container">
      <h1 className="header-l-medium">{t("labels.accessDenied")}</h1>
      <p className="body-m-regular">
        {message || "You don't have permission to access this page."}
      </p>
      <BaseButton size="small" onClick={() => navigate("/")}>
        {t("labels.returnToHome")}
      </BaseButton>
    </div>
  );
};

const ProtectedRoute = ({
  element,
  roles = [],
  organizationRoles = [],
}: {
  element: React.ReactElement;
  roles: string[];
  organizationRoles: string[];
}) => {
  const location = useLocation();
  const { data: currentUser, isSuccess } = useGetCurrentUserQuery();
  // @ts-expect-error no interface for state
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      checkAccess();
      setIsLoading(false);
    }
  }, [currentUser, roles, organizationRoles]);

  const checkAccess = () => {
    if (!currentUser) return;

    if (currentUser.role === Roles.ADMIN.value) {
      if (!roles || !roles.includes(Roles.ADMIN.value)) {
        setHasAccess(false);
        return;
      }

      setHasAccess(true);
      return;
    }

    if (currentUser.role === Roles.USER.value) {
      const hasAllowedUserRole = roles && roles.includes(Roles.USER.value);

      if (!hasAllowedUserRole) {
        setHasAccess(false);
        return;
      }

      setHasAccess(
        currentUser.organizationRole != null &&
          organizationRoles.includes(currentUser.organizationRole),
      );
      return;
    }

    setHasAccess(false);
  };

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect_to=${location.pathname}`} />;
  }

  if (isLoading) {
    return <BaseSpinner />;
  }

  if (!isSuccess) {
    return <AccessDenied message="Error loading user information" />;
  }

  return hasAccess ? element : <AccessDenied />;
};

export default ProtectedRoute;
