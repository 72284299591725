import { useTranslation } from "react-i18next";

import { FileElementProps, FileGroupElementProps } from "./types";

import "./styles.css";
import BaseButton from "../Button/BaseButton";
import { DownloadOutlined } from "@ant-design/icons";

export const FileElement = ({ value }: FileElementProps) => {
  const { t } = useTranslation();

  const handleDownloadFile = () => {
    const link = document.createElement("a");
    link.href = value.downloadUrl;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="file-element">
      <a
        className="file-element-item body-m-regular"
        href={value.file}
        target="_blank"
      >
        {value.name}
      </a>

      <BaseButton
        size="small"
        variant="link"
        leadingIcon={<DownloadOutlined />}
        onClick={handleDownloadFile}
      >
        {t("buttons.download")}
      </BaseButton>
    </div>
  );
};

export const FileGroupElement = ({ value }: FileGroupElementProps) => {
  return (
    <div>
      {value.map((val, index) => (
        <FileElement value={val} key={`${val.name}-${index}`} />
      ))}
    </div>
  );
};
