import { useState } from "react";
import { Drawer, Popover } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { FilterPopoverProps } from "./types";
import BaseButton from "../Button/BaseButton";

import { useResponsive } from "../../hooks/useBreakpoints";

import "./styles.css";

const FilterPopover = ({
  fields,
  activeFilterCount,
  title,
  handleClear,
  handleSearch,
}: FilterPopoverProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { isMobile } = useResponsive();

  const handleFiltersClear = () => {
    handleClear();
    setIsOpen(false);
  };

  const handleFilterSearch = () => {
    handleSearch();
    setIsOpen(false);
  };

  const popoverContent = (
    <div className="filters-popover-content">
      <div className="filters-popover-body">
        {fields.map((field, index) => (
          <div key={`${field.label}-${index}`} className="filter-field">
            <p className="body-s-medium">{field.label}</p>
            {field.component}
          </div>
        ))}
      </div>

      <div className="filters-popover-footer">
        <BaseButton
          variant="tertiary"
          size="small"
          onClick={handleFiltersClear}
          disabled={activeFilterCount === 0}
        >
          {t("buttons.clearFilters")}
        </BaseButton>

        <BaseButton
          variant="primary"
          size="small"
          icon={<SearchOutlined />}
          onClick={handleFilterSearch}
        >
          {t("buttons.search")}
        </BaseButton>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <div>
        <Drawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          placement="bottom"
          className="filter-drawer"
          title={title}
        >
          {popoverContent}
        </Drawer>

        <BaseButton
          variant="tertiary"
          size="small"
          leadingIcon={<FilterOutlined />}
          className="filter-popover-button"
          onClick={() => setIsOpen(true)}
        >
          {t("buttons.filters")}

          {activeFilterCount && activeFilterCount > 0 ? (
            <span className="filter-count">{activeFilterCount}</span>
          ) : null}
        </BaseButton>
      </div>
    );
  }

  return (
    <Popover
      content={popoverContent}
      title={title}
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}
      placement="bottomRight"
      classNames={{ root: "filters-popover" }}
    >
      <BaseButton
        variant="tertiary"
        size="small"
        leadingIcon={<FilterOutlined />}
        className="filter-popover-button"
      >
        {t("buttons.filters")}

        {activeFilterCount && activeFilterCount > 0 ? (
          <span className="filter-count">{activeFilterCount}</span>
        ) : null}
      </BaseButton>
    </Popover>
  );
};

export default FilterPopover;
