import { Avatar as AntdAvatar } from "antd";

import { BaseAvatarProps } from "./types";

import BaseTooltip from "../Tooltip/BaseTooltip";

const BaseAvatar = ({
  label,
  imageUrl,
  link,
  onClick,
  tooltip,
  size = "large",
  ...props
}: BaseAvatarProps) => {
  const handleClick = () => {
    if (link) {
      window.location.href = link;
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <BaseTooltip title={tooltip} placement="top">
      <AntdAvatar
        onClick={handleClick}
        size={size}
        {...props}
        src={imageUrl}
        alt={label}
        className="base-avatar"
      >
        {label?.slice(0, 2).toUpperCase()}
      </AntdAvatar>
    </BaseTooltip>
  );
};

export default BaseAvatar;
