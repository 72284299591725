import { useTranslation } from "react-i18next";

import BaseModal from "@/components_v2/Modal/BaseModal";
import { EditRequestModalProps } from "../../types/types";
import BaseButton from "@/components_v2/Button/BaseButton";
import useEditDataBlockRequestForm from "@/views/datablocks/invites/hooks/useEditDataBlockRequestForm";
import EditRequestForm from "@/views/datablocks/invites/components/EditRequestForm";

const EditRequestModal = ({
  open,
  setOpen,
  request,
}: EditRequestModalProps) => {
  const { t } = useTranslation();

  const formHook = useEditDataBlockRequestForm({
    request,
    onSuccess: () => {
      setOpen(false);
    },
  });

  return (
    <BaseModal
      title={t("datablocks.labels.editRequest")}
      open={open}
      onCancel={() => setOpen(false)}
      width={800}
      footer={
        <div className="edit-request-modal-footer">
          <BaseButton onClick={formHook.handleSubmit}>
            {t("datablocks.labels.send")}
          </BaseButton>
        </div>
      }
    >
      <EditRequestForm formHook={formHook} />
    </BaseModal>
  );
};

export default EditRequestModal;
