import { convertToFormData, createUrl } from "../../utilities";
import {
  DataBlock,
  DataBlockReferences,
  DataBlocksQueryParams,
  DataFilesQueryParams,
  PaginatedDataFiles,
} from "../../views/datablocks/types/blocks";
import {
  ChoiceOption,
  ChoiceOptionsQueryParams,
  DataTemplate,
  DataTemplatesQueryParams,
} from "../../views/datablocks/types/templates";
import { Tags, TagType } from "./types";
import { BuilderType } from "../../types/base";
import { PaginatedResponse } from "../../types/pagination";
import { DataBlockFormFields } from "../../views/datablocks/types/types";
import {
  DataBlockRequest,
  DataBlockRequestQueryParams,
} from "../../views/datablocks/types/requests";

const DATABLOCKS_URL = "/datablocks/blocks/";
const DATABLOCK_REQUESTS_URL = "/datablocks/requests/";
const DATA_TEMPLATES_URL = "/datablocks/templates/";
const DATAFILES_URL = "/datablocks/files/";
const DATACHOICES_URL = "/datablocks/choices/";

const isPaginatedResponse = <T>(
  response: T[] | PaginatedResponse<T[]>,
): response is PaginatedResponse<T[]> => {
  return "results" in response;
};

const dataBlocksService = (builder: BuilderType<Tags>) => ({
  paginateFilterDataBlocks: builder.query<
    DataBlock[] | PaginatedResponse<DataBlock[]>,
    DataBlocksQueryParams
  >({
    query: (queryParams) => ({
      url: createUrl(DATABLOCKS_URL, queryParams),
      method: "GET",
    }),
    providesTags: (result): TagType[] =>
      result
        ? [
            ...(
              (isPaginatedResponse(result)
                ? result.results
                : result) as DataBlock[]
            ).map(
              (i): TagType => ({
                type: "DataBlock",
                id: i.id,
              }),
            ),
            { type: "DataBlocks" },
          ]
        : [{ type: "DataBlocks" }],
  }),
  createDataBlock: builder.mutation<DataBlock, DataBlockFormFields>({
    query: (data) => ({
      url: DATABLOCKS_URL,
      method: "POST",
      data,
    }),
    invalidatesTags: [{ type: "DataBlocks" }, { type: "DataBlockRequests" }],
  }),
  getDataBlockById: builder.query<DataBlock, number | string | undefined>({
    query: (id) => ({
      url: `${DATABLOCKS_URL}${id}/`,
      method: "GET",
    }),
    providesTags: (result): TagType[] =>
      result
        ? [{ type: "DataBlock", id: result.id }, { type: "DataBlocks" }]
        : [{ type: "DataBlocks" }],
  }),
  updateDataBlock: builder.mutation({
    query: ({ id, data }) => ({
      url: `${DATABLOCKS_URL}${id}/`,
      method: "PUT",
      data: data,
    }),
    invalidatesTags: [{ type: "DataBlocks" }],
  }),
  patchDataBlock: builder.mutation({
    query: ({ id, data }) => ({
      url: `${DATABLOCKS_URL}${id}/`,
      method: "PATCH",
      data: data,
    }),
    invalidatesTags: [{ type: "DataBlocks" }],
  }),
  deleteDataBlock: builder.mutation({
    query: (id) => ({
      url: `${DATABLOCKS_URL}${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: [{ type: "DataBlocks" }],
  }),
  getDataBlockReferences: builder.query<DataBlockReferences, number>({
    query: (id) => ({
      url: `${DATABLOCKS_URL}${id}/references/`,
      method: "GET",
    }),
    providesTags: (result, _, args: number): TagType[] =>
      result
        ? [
            { type: "DataBlockReference", id: args },
            { type: "DataBlockReferences" },
          ]
        : [{ type: "DataBlockReferences" }],
  }),
  incrementDataBlockViews: builder.query({
    query: (id) => ({
      url: `${DATABLOCKS_URL}${id}/views/`,
      method: "GET",
    }),
  }),
  getDataBlockRequests: builder.query<
    DataBlockRequest[] | PaginatedResponse<DataBlockRequest[]>,
    DataBlockRequestQueryParams
  >({
    query: (queryParams) => ({
      url: createUrl(DATABLOCK_REQUESTS_URL, queryParams),
      method: "GET",
    }),
    providesTags: (result): TagType[] =>
      result
        ? [
            ...(
              (isPaginatedResponse(result)
                ? result.results
                : result) as DataBlockRequest[]
            ).map(
              (i): TagType => ({
                type: "DataBlockRequest",
                id: i.id,
              }),
            ),
            { type: "DataBlockRequests" },
          ]
        : [{ type: "DataBlockRequests" }],
  }),
  createDataBlockRequest: builder.mutation({
    query: (data) => ({
      url: DATABLOCK_REQUESTS_URL,
      method: "POST",
      data: data,
    }),
    invalidatesTags: [{ type: "DataBlockRequests" }],
  }),
  createBulkDataBlockRequests: builder.mutation({
    query: (data) => ({
      url: `${DATABLOCK_REQUESTS_URL}bulk/`,
      method: "POST",
      data: data,
    }),
    invalidatesTags: [{ type: "DataBlockRequests" }],
  }),
  getDataBlockRequestById: builder.query<
    DataBlockRequest,
    string | number | undefined
  >({
    query: (id) => ({
      url: `${DATABLOCK_REQUESTS_URL}${id}/`,
      method: "GET",
    }),
    providesTags: (result): TagType[] =>
      result
        ? [
            { type: "DataBlockRequest", id: result.id },
            { type: "DataBlockRequests" },
          ]
        : [{ type: "DataBlockRequests" }],
  }),
  deleteDataBlockRequest: builder.mutation({
    query: (id) => ({
      url: `${DATABLOCK_REQUESTS_URL}${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: [{ type: "DataBlockRequests" }],
  }),
  requestDataBlockEdit: builder.mutation({
    query: ({ id, data }) => ({
      url: `${DATABLOCK_REQUESTS_URL}${id}/edit/`,
      method: "POST",
      data,
    }),
    invalidatesTags: [{ type: "DataBlockRequests" }, { type: "DataBlocks" }],
  }),
  patchDataBlockRequest: builder.mutation({
    query: ({ id, data }) => ({
      url: `${DATABLOCK_REQUESTS_URL}${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: [{ type: "DataBlockRequests" }],
  }),

  paginateFilterDataTemplates: builder.query<
    DataTemplate[] | PaginatedResponse<DataTemplate[]>,
    DataTemplatesQueryParams
  >({
    query: (queryParams) => ({
      url: createUrl(DATA_TEMPLATES_URL, queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [
            ...(
              (isPaginatedResponse(result)
                ? result.results
                : result) as DataTemplate[]
            ).map(
              (template): TagType => ({
                type: "DataTemplate",
                id: template.id,
              }),
            ),
            { type: "DataTemplate", id: "PARTIAL-LIST" },
          ]
        : [{ type: "DataTemplates" }],
  }),
  getDataTemplateById: builder.query<DataTemplate, number>({
    query: (id) => ({
      url: `${DATA_TEMPLATES_URL}${id}/`,
      method: "GET",
    }),
    providesTags: (result): TagType[] =>
      result
        ? [{ type: "DataTemplate", id: result.id }]
        : [{ type: "DataTemplates" }],
  }),
  getDataFiles: builder.query<PaginatedDataFiles, DataFilesQueryParams>({
    query: (queryParams) => ({
      url: createUrl(DATAFILES_URL, queryParams),
      method: "GET",
    }),
    providesTags: (result): TagType[] =>
      result
        ? [
            ...(result?.results || result).map(
              (i): TagType => ({
                type: "DataFiles",
                id: i.id,
              }),
            ),
            { type: "DataFile", id: "PARTIAL-LIST" },
          ]
        : [{ type: "DataFiles" }],
  }),
  createDataFile: builder.mutation({
    query: (data) => ({
      url: createUrl(DATAFILES_URL),
      method: "POST",
      data: convertToFormData(data),
    }),
    invalidatesTags: [{ type: "DataFiles" }],
  }),
  deleteDataFile: builder.mutation({
    query: (fileId) => ({
      url: `${DATAFILES_URL}${fileId}/`,
      method: "DELETE",
    }),
    invalidatesTags: [{ type: "DataFiles" }],
  }),

  getChoiceOptions: builder.query<
    ChoiceOption[] | PaginatedResponse<ChoiceOption[]>,
    ChoiceOptionsQueryParams
  >({
    query: (queryParams) => ({
      url: createUrl(DATACHOICES_URL, queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [
            ...(
              (isPaginatedResponse(result)
                ? result.results
                : result) as ChoiceOption[]
            ).map(
              (template): TagType => ({
                type: "ChoiceOption",
                id: template.id,
              }),
            ),
            { type: "ChoiceOptions" },
          ]
        : [{ type: "ChoiceOptions" }],
  }),
  getChoiceOptionById: builder.query<ChoiceOption, number>({
    query: (id) => ({
      url: `${DATACHOICES_URL}${id}/`,
      method: "GET",
    }),
    providesTags: (result): TagType[] =>
      result
        ? [{ type: "ChoiceOption", id: result.id }]
        : [{ type: "ChoiceOptions" }],
  }),
});

export default dataBlocksService;
