import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Spin, Typography } from "antd";

import { useGetCurrentUserQuery } from "../../redux/service.js";
import { Roles } from "../../utilities/constants.jsx";
import OrganizationSurveys from "./components/survey-list/OrganizationSurveys.jsx";
import SignatorySurveys from "./components/survey-list/SignatorySurveys.jsx";
import { setShowGreyBackground } from "../../redux/utils/reducer.js";
import BaseTabs from "../../components_v2/Tabs/BaseTabs.tsx";

const { Title } = Typography;

const SurveyList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: currentUser, isLoading } = useGetCurrentUserQuery();

  useEffect(() => {
    dispatch(setShowGreyBackground(true));

    return () => dispatch(setShowGreyBackground(false));
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return currentUser?.role === Roles.ADMIN.value ? (
    <div>
      <div style={{ display: "flex" }}>
        <Title level={3} className="mb-4">
          {t("surveys")}
        </Title>
      </div>
      <OrganizationSurveys />
    </div>
  ) : (
    <BaseTabs
      type="card"
      defaultActiveKey="organizationReports"
      items={[
        {
          label: t("labels.myReports"),
          key: "organizationReports",
          children: <OrganizationSurveys />,
        },
        {
          label: t("labels.signatoryReports"),
          key: "signatoryReports",
          children: <SignatorySurveys />,
        },
      ]}
      className="surveys-tabs"
    />
  );
};

export default SurveyList;
