import React, { useState } from "react";
import { Input } from "antd";

import { BaseInputProps } from "./types";
import BaseError from "../BaseError";
import EyeIcon from "@/assets/icons/eye-icon.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";

import "./styles.css";

const BaseInput = ({
  type = "text",
  value,
  onChange,
  onBlur,
  placeholder,
  disabled,
  className,
  error,
  ...props
}: BaseInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const changeShowPassword = () => setShowPassword((oldVal) => !oldVal);
  const passwordIcon = showPassword ? (
    <span onClick={changeShowPassword}>
      <BaseSvgRenderer
        component={EyeIcon}
        style={{ transform: "rotate(180deg)" }}
      />
    </span>
  ) : (
    <span onClick={changeShowPassword}>
      <BaseSvgRenderer component={EyeIcon} />
    </span>
  );
  const suffix = type === "password" ? passwordIcon : <span />;

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "number") {
      const char = e.key;
      const currentValue = (e.target as HTMLInputElement).value;

      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
        ",",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
      ];

      if (!allowedKeys.includes(char)) {
        e.preventDefault();
        return;
      }

      if ((char === "." || char === ",") && currentValue.length === 0) {
        e.preventDefault();
        return;
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <>
      <Input
        className={`base-input ${className ? className : ""}`}
        suffix={suffix}
        status={error && "error"}
        placeholder={placeholder}
        type={showPassword ? "text" : type}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        {...props}
        onKeyUp={handleKeyPress}
      />
      <BaseError error={error} />
    </>
  );
};

export default BaseInput;
