import { useTranslation } from "react-i18next";
import { App, Flex, theme, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { useUpdateSurveyDetailsMutation } from "../../../../redux/service.js";
import BaseModal from "../../../../components_v2/Modal/BaseModal.js";
import BaseButton from "../../../../components_v2/Button/BaseButton.js";
import BaseSpinner from "../../../../components_v2/Spiner/BaseSpinner.js";

const { Title } = Typography;

const SubmissionConfirmationModal = ({ open, onCancel, survey }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const [submitSurvey, submitSurveyResponse] = useUpdateSurveyDetailsMutation();

  const handleSubmitSurvey = () => {
    submitSurvey({
      id: survey.details.id,
      data: { isSubmitted: true },
    })
      .unwrap()
      .then(() => {
        notification.success({ message: t("surveySubmitted") });
        navigate("/");
      })
      .catch((error) =>
        notification.error({ message: error.data.errors[0].detail }),
      )
      .finally(onCancel);
  };

  return (
    <BaseModal
      open={open}
      title={t("confirmSubmission")}
      onCancel={onCancel}
      footer={
        <Flex wrap justify="end" align="center" gap={"small"}>
          <BaseButton key="cancel" variant="tertiary" onClick={onCancel}>
            {t("buttons.cancel")}
          </BaseButton>

          <BaseButton
            key="confirm"
            onClick={handleSubmitSurvey}
            disabled={submitSurveyResponse.isLoading}
          >
            {submitSurveyResponse.isLoading && <BaseSpinner size="small" />}{" "}
            {t("buttons.confirm")}
          </BaseButton>
        </Flex>
      }
    >
      {submitSurveyResponse.isLoading
        ? t("reportIsBeingSubmitted")
        : t("submitConfirmation")}
    </BaseModal>
  );
};

SubmissionConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  survey: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SubmissionConfirmationModal;
