import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { App, Col, Row, theme, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormField from "../../../../components/form/FormField.jsx";
import Input from "../../../../components/form/Input.jsx";
import { handleErrors } from "../../../../utilities/index.js";
import PrimaryButton from "../../../../components/PrimaryButton.jsx";
import {
  useCreateSurveyViewMutation,
  useGetCurrentUserQuery,
  useLazyGetSurveyViewByIdQuery,
  useGetSurveyByIdQuery,
  useUpdateSurveyViewMutation,
} from "../../../../redux/service.js";
import SectionsForm from "./SectionsForm.jsx";
import { OptionType, SharingRights } from "../../../../utilities/constants.jsx";
import Select from "../../../../components/form/Select.jsx";

const { Title, Text } = Typography;

const getSurveyViewFormSchema = (t) =>
  yup
    .object()
    .shape({
      title: yup
        .string()
        .typeError(t("form.validation.titleNotValid"))
        .required(t("form.validation.titleRequired")),
      description: yup
        .string()
        .typeError(t("form.validation.descriptionNotValid")),
      attachedBlocks: yup
        .array()
        .typeError(t("form.validation.attachedBlocksNotValid")),
      attachedNarratives: yup
        .array()
        .typeError(t("form.validation.attachedBlocksNotValid")),
      answerIds: yup
        .array()
        .typeError(t("form.validation.attachedAnswersNotValid")),
      sharingRights: yup
        .string()
        .typeError(t("form.validation.sharingRightsNotValid"))
        .required(t("form.validation.sharingRightsRequired")),
    })
    .test(
      "at-least-one-attached-entity",
      t("form.validation.attachAnswerBlockOrNarrativeRequired"),
      function (values) {
        const { answers } = values;
        return answers.length !== 0;
      },
    );

const defaultValues = {
  title: null,
  answers: [],
  sharingRights: "",
  entitiesSharingRights: [],
  isDefault: false,
};

const SurveyViewForm = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { surveyId, surveyViewId } = useParams();
  const { notification } = App.useApp();
  const navigate = useNavigate();

  const { data: survey, isSuccess: isSurveySuccess } = useGetSurveyByIdQuery({
    surveyId,
  });
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const [createSurveyView] = useCreateSurveyViewMutation();
  const [updateSurveyView] = useUpdateSurveyViewMutation();
  const [getSurveyViewById, surveyViewResponse] =
    useLazyGetSurveyViewByIdQuery();
  const [defaultSelectedAnswers, setDefaultSelectedAnswers] = useState([]);

  const form = useForm({
    resolver: yupResolver(getSurveyViewFormSchema(t)),
    mode: "onChange",
    defaultValues,
  });
  const selectedAnswers = form.watch("answers");

  useEffect(() => {
    if (surveyViewId) {
      getSurveyViewById(surveyViewId)
        .unwrap()
        .then((data) => {
          form.reset({
            title: data.title,
            sharingRights: data.sharingRights,
            answers: data.answers.reduce((acc, surveyViewAnswer) => {
              acc[surveyViewAnswer.answer] = {
                ...surveyViewAnswer,
                blocks: surveyViewAnswer.blocks.map((i) => i.id),
                narratives: surveyViewAnswer.narratives.map((i) => i.id),
              };
              return acc;
            }, {}),
            isDefault: data.isDefault,
          });
          setDefaultSelectedAnswers(
            getDefaultSelectedAnswers(data.answers, data.isDefault),
          );
        });
    }
  }, [surveyViewId]);

  /*
    Get a list of keys consisting of answers and entities which are selected
     */
  const getDefaultSelectedAnswers = (surveyAnswers, isDefaultSurvey) => {
    return surveyAnswers.reduce((acc, surveyAnswer) => {
      const answerKeyName = `${surveyAnswer.section}-${surveyAnswer.question}-${surveyAnswer.answer}`;

      acc = acc.concat([
        ...surveyAnswer.blocks.map((i) => `${answerKeyName}-blocks-${i.id}`),
        ...surveyAnswer.narratives.map(
          (i) => `${answerKeyName}-narratives-${i.id}`,
        ),
      ]);

      if (
        (surveyAnswer.blocks.length === 0 &&
          surveyAnswer.narratives.length === 0) ||
        isDefaultSurvey
      ) {
        acc.push(answerKeyName);
      }

      return acc;
    }, []);
  };

  /*
    Get a count of entities (blocks, narratives and sections) that are currently selected
     */
  const entitiesCount = useMemo(() => {
    const count = { blocks: 0, narratives: 0, sections: [] };

    Object.values(selectedAnswers).forEach((i) => {
      count.blocks += i.blocks.length;
      count.narratives += i.narratives.length;
      if (!count.sections.includes(i.section)) {
        count.sections.push(i.section);
      }
    });
    return { ...count, sections: count.sections.length };
  }, [selectedAnswers]);

  const isDefaultSurvey = useMemo(
    () =>
      surveyViewId &&
      surveyViewResponse.isSuccess &&
      surveyViewResponse.data.isDefault,
    [surveyViewResponse],
  );

  /*
    Add sharing right the new sharing rights to the selected answers.
    If the view is not a default one, it takes only the selected answers and changes their sharing rights.
    If the view is a default one, it takes all initial answers in order to enable the user to change the sharing rights of an entity if they deselect it.
   */
  const addSharingRightsToSelectedAnswers = (newSharingRights, answers) => {
    const updatedAnswers = isDefaultSurvey
      ? surveyViewResponse.data.answers.map((i) => {
          if (i.answer in answers) {
            return answers[i.answer];
          } else {
            return i;
          }
        })
      : answers;

    return Object.values(updatedAnswers).map((surveyViewAnswer) => {
      const answerEntitySharingRights = newSharingRights.filter(
        (entity) => entity.answer === surveyViewAnswer.answer,
      );

      return {
        ...surveyViewAnswer,
        narratives: answerEntitySharingRights.filter(
          (i) => i.entityType === OptionType.NARRATIVE.value,
        ),
        blocks: answerEntitySharingRights.filter(
          (i) => i.entityType === OptionType.PROMPT.value,
        ),
      };
    });
  };

  const handleSubmit = async () => {
    const isValid = await form.trigger();

    if (!isValid) {
      if (form.formState.errors[""]) {
        notification.error({ message: form.formState.errors[""].message });
      }
      return;
    }

    const { title, sharingRights, entitiesSharingRights, answers } =
      form.getValues();

    const updatedAnswers = addSharingRightsToSelectedAnswers(
      entitiesSharingRights,
      answers,
    );

    const data = {
      survey: surveyId,
      organization: currentUser.organization.id,
      sharingRights,
      title,
      answers: updatedAnswers,
    };
    let surveyViewPromise;
    if (surveyViewId) {
      surveyViewPromise = updateSurveyView({ id: surveyViewId, data }).unwrap();
    } else {
      surveyViewPromise = createSurveyView(data).unwrap();
    }
    surveyViewPromise
      .then(() => {
        notification.success({
          message: t(surveyViewId ? "surveyViewUpdated" : "surveyViewCreated"),
        });
        navigate(`/reports/${surveyId}?tab=privacy`);
        form.reset();
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <div className="mb-5">
      <Row>
        <Col xs={24} sm={24} md={18} lg={18}>
          {!surveyViewId ||
            (surveyViewResponse.isSuccess && (
              <Title level={3} style={{ color: token.colorPrimaryText }}>
                {t(
                  isDefaultSurvey
                    ? "defaultSurveyViewTitle"
                    : "createSurveyView",
                )}
              </Title>
            ))}
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} style={{ display: "flex" }}>
          <PrimaryButton
            type="secondary"
            className="me-3"
            onClick={() => navigate(`/reports/${surveyId}?tab=privacy`)}
          >
            {t("buttons.cancel")}
          </PrimaryButton>
          <PrimaryButton onClick={handleSubmit}>
            {t("buttons.save")}
          </PrimaryButton>
        </Col>
      </Row>
      <div>{isDefaultSurvey && <Text>{t("defaultSurveyViewLabel")}</Text>}</div>
      <div className="mt-4">
        {isCurrentUserSuccess && (
          <Title level={2} style={{ color: token.colorBackgroundSecondary }}>
            {currentUser.organization?.name}
          </Title>
        )}
        {isSurveySuccess && (
          <Title level={3} className="mt-2">
            {survey.title}
          </Title>
        )}
      </div>
      <Row className="mt-2 pb-3" style={{ borderBottom: "1px solid #B9BBC6" }}>
        <Col sm={24} md={20} lg={20} style={{ paddingRight: "270px" }}>
          {!isDefaultSurvey && (
            <FormField
              label={t("form.labels.title")}
              isEdit={!isDefaultSurvey}
              required
              field={<Input name="title" control={form.control} />}
              labelStyles={{ color: "#7E808A", fontWeight: 500 }}
            />
          )}
        </Col>
        <Col sm={24} md={4} lg={4}>
          <FormField
            label={t("sharingRights.label")}
            required
            field={
              <Select
                name="sharingRights"
                control={form.control}
                defaultValue={null}
                style={{
                  width: "170px",
                  textAlign: "left",
                  background: "#00003B",
                }}
                disabled={
                  surveyViewResponse.isSuccess &&
                  surveyViewResponse.data.isDefault
                }
                options={SharingRights.asList()}
              />
            }
            labelStyles={{ color: "#7E808A", fontWeight: 400 }}
          />
        </Col>
      </Row>
      <div className="mt-3">
        <Row className="my-3">
          <Col sm={24} md={12} lg={12}>
            <Title style={{ fontSize: "18px", color: token.colorPrimaryText }}>
              {t("pleaseSelectWhatToInclude")}
            </Title>
          </Col>
          <Col
            sm={24}
            md={12}
            lg={12}
            style={{ display: "flex", justifyContent: "end" }}
            className="pe-5"
          >
            <div className="me-3">
              <Text style={{ color: "#60646C", textTransform: "uppercase" }}>
                {t("principles")}
              </Text>
              <Title level={4} style={{ color: token.colorPrimaryText }}>
                {entitiesCount.sections}
              </Title>
            </div>
            <div className="me-3">
              <Text style={{ color: "#60646C", textTransform: "uppercase" }}>
                {t("survey.labels.dataBlock")}
              </Text>
              <Title level={4} style={{ color: token.colorPrimaryText }}>
                {entitiesCount.blocks}
              </Title>
            </div>
            <div>
              <Text style={{ color: "#60646C", textTransform: "uppercase" }}>
                {t("labels.narratives", { plural: "s" })}
              </Text>
              <Title level={4} style={{ color: token.colorPrimaryText }}>
                {entitiesCount.narratives}
              </Title>
            </div>
          </Col>
        </Row>
        <SectionsForm
          surveyId={surveyId}
          form={form}
          defaultSelected={defaultSelectedAnswers}
        />
      </div>
    </div>
  );
};

export default SurveyViewForm;
