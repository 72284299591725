import { forwardRef, useEffect, useMemo, useState } from "react";
import { Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { LabeledValue } from "antd/es/select";
import type { DefaultOptionType } from "antd/es/select";
import { BaseSelectRef } from "rc-select/lib/BaseSelect";
import { useTranslation } from "react-i18next";

import { BaseDropdownProps, TagRender } from "./types";
import BaseTag from "../../Tags/BaseTag";
import BaseError from "../BaseError";

import "./styles.css";

const BaseDropdown = forwardRef<BaseSelectRef, BaseDropdownProps>(
  (
    {
      options,
      multiple,
      placeholder,
      showError = true,
      className,
      disabled,
      value,
      onChange,
      error,
      ...props
    }: BaseDropdownProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [filteredOptions, setFilteredOptions] =
      useState<DefaultOptionType[]>(options);
    const [_open, _setOpen] = useState(false);

    useEffect(() => {
      setFilteredOptions(options);
    }, [options]);

    useEffect(() => {
      if (props.open != null && props.open !== _open) {
        _setOpen(props.open);
      }
    }, [props.open]);

    const onOpenChange = (isOpen: boolean) => {
      _setOpen(isOpen);

      if (props.onDropdownVisibleChange) {
        props.onDropdownVisibleChange(isOpen);
      }
    };

    const processedValue = useMemo(() => {
      if (!value) return;

      let processedValue = value;
      if (Array.isArray(value)) {
        processedValue = value.map((item) => {
          if (item && typeof item === "object" && "choice" in item) {
            return item.choice;
          }
          return item;
        });
      }

      if (typeof value === "object" && value && "choice" in value) {
        processedValue = value.choice;
      }
      return processedValue;
    }, [value]);

    const tagRender: TagRender = (tagProps: LabeledValue) => {
      return (
        <BaseTag
          label={(tagProps?.label as string) || "Unknown"}
          size="small"
          onClick={() => _setOpen(!_open)}
        />
      );
    };

    const handleSearch = (
      input: string,
      option: DefaultOptionType | undefined,
    ) => {
      if (!option?.label) return false;
      return String(option.label).toLowerCase().includes(input.toLowerCase());
    };

    const groupedOptions = useMemo(() => {
      if (!filteredOptions) return [];

      const hasCategorizedOptions = filteredOptions.some(
        (option) => option.category && option.category !== "",
      );
      if (!hasCategorizedOptions) {
        return filteredOptions;
      }

      const categorizedOptions = filteredOptions.reduce((acc, option) => {
        const category = option.category || t("labels.uncategorized");
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push({
          label: option.label,
          value: option.value,
        });
        return acc;
      }, {});

      return Object.entries(categorizedOptions).map(
        ([category, categoryOptions]) => ({
          label: category,
          options: categoryOptions,
        }),
      );
    }, [filteredOptions]);

    return (
      <div>
        <Select
          className={`base-dropdown ${error && "base-dropdown-error"} ${className || ""}`}
          status={error && "error"}
          suffixIcon={<DownOutlined />}
          tagRender={tagRender}
          showSearch
          value={processedValue}
          onChange={onChange}
          filterOption={handleSearch}
          options={groupedOptions}
          placeholder={placeholder}
          disabled={disabled}
          mode={multiple ? "multiple" : undefined}
          maxTagCount={"responsive"}
          ref={ref}
          {...props}
          open={_open}
          onDropdownVisibleChange={onOpenChange}
        />
        {showError && error && <BaseError error={error} />}
      </div>
    );
  },
);

export default BaseDropdown;
