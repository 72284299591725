import { Col, Row } from "antd";

import { DataBlockTypeStepProps } from "../../types/types";
import BaseCard from "@/components_v2/Card/BaseCard";
import BaseError from "@/components_v2/FormFields/BaseError";
import { Duration } from "@/libraries/luxon";

import "./styles.css";
import { ClockCircleOutlined } from "@ant-design/icons";

const DataBlockTypeStep = ({
  templates,
  activeTemplateId,
  handleTemplateChange,
  error,
}: DataBlockTypeStepProps) => {
  return (
    <div className="data-block-type-step">
      <BaseError error={error} />

      <Row className="evidence-cards" gutter={[20, 20]}>
        {templates.map((template) => (
          <Col xs={24} sm={12} md={8} lg={8} key={template.id}>
            <BaseCard
              icon={template.icon}
              color={template.color}
              title={template.title}
              description={template.shortDescription}
              additionalDataIcon={<ClockCircleOutlined />}
              additionalData={
                template.estimatedCompletionTime
                  ? Duration.fromObject({
                      seconds: template.estimatedCompletionTime,
                    }).toHuman()
                  : undefined
              }
              onClick={() => handleTemplateChange(template.id)}
              isActive={activeTemplateId === template.id}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default DataBlockTypeStep;
