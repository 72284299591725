import { BaseErrorProps } from "./types";

const BaseError = ({ error }: BaseErrorProps) => {
  if (Array.isArray(error)) {
    return error.map((err) => (
      <span
        key={err.message}
        className="body-s-regular"
        style={{ color: "var(--red-500)" }}
      >
        {err.message}
      </span>
    ));
  }

  return (
    error && (
      <span
        key={error.message}
        className="body-s-regular"
        style={{ color: "var(--red-500)" }}
      >
        {error.message}
      </span>
    )
  );
};

export default BaseError;
