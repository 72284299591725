import { DownOutlined, UpOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import SectionVerticalBar from "./SectionVerticalBar.jsx";
import { Question } from "./Question.jsx";
import BaseAccordion from "@/components_v2/Accordion/BaseAccordion.js";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip.js";
import { useTranslation } from "react-i18next";

const SurveyViewDetails = ({
  sections,
  surveyStatisticsResponse,
  surveyViewResponse,
  filteredQuestions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <BaseAccordion
        defaultActiveKey={[]}
        expandIconPosition="start"
        expandIcon={({ isActive }) => (
          <>
            <BaseTooltip title={t("survey.labels.expandSurveySectionView")}>
              {isActive ? <DownOutlined /> : <UpOutlined />}
            </BaseTooltip>
          </>
        )}
        className="survey-view-details-accordion-container"
        items={sections
          .slice()
          .sort((a, b) => a.index - b.index)
          .map((section) => {
            const questions = section.tabs.length
              ? section.tabs.reduce((acc, tab) => acc.concat(tab.questions), [])
              : section.questions;

            return {
              key: section.id,
              label: surveyStatisticsResponse.isSuccess && (
                <SectionVerticalBar
                  section={{
                    ...section,
                    statistics: surveyStatisticsResponse.data.find(
                      (i) => i.id === section.id,
                    ),
                  }}
                />
              ),
              children: (
                <div key={section.id} className="survey-view-details-accordion">
                  {questions
                    .filter(
                      (i) =>
                        filteredQuestions === null ||
                        filteredQuestions.includes(i),
                    )
                    .map((question, index) => (
                      <Question
                        key={question}
                        questionIndex={index}
                        questionId={question}
                        surveyView={surveyViewResponse.data}
                      />
                    ))}
                </div>
              ),
            };
          })}
      />
    </>
  );
};

SurveyViewDetails.propTypes = {
  sections: PropTypes.array.isRequired,
  surveyStatisticsResponse: PropTypes.object.isRequired,
  surveyViewResponse: PropTypes.object.isRequired,
  filteredQuestions: PropTypes.array,
};

SurveyViewDetails.defaultProps = {
  filteredQuestions: null,
};

export default SurveyViewDetails;
