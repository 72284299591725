import { useEffect, useState } from "react";

import { Input } from "antd";
import PropTypes from "prop-types";
import FieldError from "./FieldError.jsx";

const DebouncedInput = ({
  onChange,
  placeholder = "",
  initialValue = "",
  errors = null,
  rows = 7,
  isTextArea = true,
  type = "text",
  ...props
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (value !== initialValue) {
      const delayInputTimeoutId = setTimeout(() => onChange(value), 700);
      return () => clearTimeout(delayInputTimeoutId);
    }
  }, [value, initialValue, onChange]);

  const handleKeyDown = (e) => {
    if (type === "number") {
      const char = e.key;
      const currentValue = e.target.value;

      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        ".",
        ",",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
      ];

      if (!allowedKeys.includes(char)) {
        e.preventDefault();
        return;
      }

      if ((char === "." || char === ",") && currentValue.length === 0) {
        e.preventDefault();
      }
    }
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;

    if (type === "number") {
      const reg = /^-?\d*([.,]\d*)?$/;

      if (reg.test(inputValue) || inputValue === "") {
        setValue(inputValue);
      } else {
        e.preventDefault();
      }
    } else {
      setValue(inputValue);
    }
  };

  const handlePaste = (e) => {
    if (type === "number") {
      const pastedText = e.clipboardData.getData("text");
      const reg = /^-?\d*([.,]\d*)?$/;

      if (!reg.test(pastedText)) {
        e.preventDefault();
      }
    }
  };

  const Component = isTextArea ? Input.TextArea : Input;

  return (
    <div>
      <Component
        style={{ borderRadius: 0 }}
        rows={rows}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        placeholder={placeholder}
        {...props}
      />
      {errors && <FieldError errors={errors} />}
    </div>
  );
};

DebouncedInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isTextArea: PropTypes.bool,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  type: PropTypes.string,
  rows: PropTypes.number,
};

export default DebouncedInput;
