import { useEffect, useMemo, useState } from "react";
import { List } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

import UserAvatar from "../../../../components_v2/Avatar/UserAvatar";
import BaseAvatar from "../../../../components_v2/Avatar/BaseAvatar";
import BaseAccordion from "../../../../components_v2/Accordion/BaseAccordion";
import {
  useDeleteDataBlockRequestMutation,
  useLazyGetDataBlockRequestsQuery,
} from "@/redux/service";
import { DataBlockRequest } from "../../types/requests";
import BaseList from "@/components_v2/List/BaseList";
import { RequestStatuses } from "../../core/constants";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import {
  DataBlockRequestEditModalsState,
  DataBlockRequestFiltersState,
  Recipient,
  RecipientType,
  UserInviteStatus,
} from "../../types/types";
import EditRequestModal from "./EditRequestModal";
import ResendRequestModal from "./ResendRequest";
import { RequestStatus } from "../constants";

import BaseButton from "@/components_v2/Button/BaseButton";
import { RedoOutlined, DeleteOutlined, FormOutlined } from "@ant-design/icons";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";
import RequestFilters from "./RequestFilters";
import EmptyIcon from "@/assets/icons/empty.svg?react";

import "./styles.css";

const RequestsAccordion = () => {
  const { t } = useTranslation();
  const [getRequests, { data, isLoading, isFetching }] =
    useLazyGetDataBlockRequestsQuery();
  const [deleteDataBlockRequest] = useDeleteDataBlockRequestMutation();
  const [editRequestModal, setEditRequestModal] =
    useState<DataBlockRequestEditModalsState>({
      open: false,
      request: undefined,
    });
  const [resendModal, setResendModal] =
    useState<DataBlockRequestEditModalsState>({
      open: false,
      request: undefined,
    });

  const [filters, setFilters] = useState<DataBlockRequestFiltersState>({
    pagination: "off" as const,
    user: undefined,
  });

  useEffect(() => {
    getRequests(filters);
  }, [filters]);

  const groupDataBlockRequests = (requests: DataBlockRequest[]) => {
    const groupedMap = new Map();

    requests.forEach((request) => {
      let recipientKey;
      let recipientObject;

      if (request.user) {
        recipientKey = `user_${request.user.id}`;
        recipientObject = {
          type: RecipientType.User,
          key: recipientKey,
          ...request.user,
        };
      } else if (request.userInvite) {
        recipientKey = `invite_${request.userInvite.id}`;
        recipientObject = {
          type: RecipientType.UserInvite,
          key: recipientKey,
          ...request.userInvite,
        };
      } else {
        return;
      }

      if (!groupedMap.has(recipientKey)) {
        groupedMap.set(recipientKey, {
          recipient: recipientObject,
          requests: [],
        });
      }

      groupedMap.get(recipientKey).requests.push(request);
    });
    return Array.from(groupedMap.values());
  };

  const getRecipient = (recipient: Recipient) => {
    let avatar;
    let email;
    if (recipient.type === RecipientType.User) {
      avatar = <UserAvatar user={recipient} size="default" />;
      email = recipient.email;
    } else {
      avatar = (
        <BaseAvatar
          label={recipient.email.slice(0, 2).toUpperCase()}
          size="default"
        />
      );
      email = recipient.email;
    }

    return (
      <div className="request-accordion-user">
        {avatar}
        <span className="body-m-medium">{email}</span>
      </div>
    );
  };

  const NoRequestsSent = (
    <div className="data-block-requests-empty-container">
      <BaseSvgRenderer component={EmptyIcon} />
      <h5 className="data-block-requests-empty-message body-m-regular">
        {t("datablocks.labels.noRequestsSent")}
      </h5>
    </div>
  );

  const items = useMemo(() => {
    if (!data) return [];
    const results = data as DataBlockRequest[];
    const groupedRequests = groupDataBlockRequests(results);

    return groupedRequests.map((request) => ({
      key: request.recipient.email,
      label: (
        <div>
          <div className="request-accordion-label-container">
            {getRecipient(request.recipient)}

            <p className="request-accordion-label-status body-s-regular">
              {request.recipient.type === UserInviteStatus.USER_INVITE
                ? "Not registered"
                : "Registered"}
            </p>
          </div>

          <span className="request-accordion-requests-count body-s-regular">
            {`${request.requests.length} ${t(`datablocks.labels.${request.requests.length === 1 ? "invite" : "invites"}`)}`}
          </span>
        </div>
      ),
      children: (
        <BaseList<DataBlockRequest>
          bordered
          dataSource={request.requests}
          renderItem={(item) => (
            <List.Item>
              <div className="request-accordion-list-item-content">
                <span className="request-accordion-list-item body-s-regular">
                  {item.dataTemplate.title}
                </span>

                <span className="request-accordion-list-item body-s-regular">
                  {RequestStatuses[item.status]}
                </span>

                <span className="request-accordion-list-item body-s-regular">
                  {DateTime.fromISO(item.expiresAt).toFormat("dd MMM yyyy")}
                </span>

                <span>
                  {item.dataBlock ? (
                    <Link
                      to={`/evidence/${item.dataBlock}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("datablocks.labels.seeDataBlock")}
                    </Link>
                  ) : (
                    "-"
                  )}
                </span>
              </div>

              <div className="request-accordion-list-item-actions">
                {item.status === RequestStatus.EXPIRED.value && (
                  <BaseTooltip
                    title={t("datablocks.labels.resend")}
                    className="request-accordion-tooltip-button"
                  >
                    <BaseButton
                      variant="link"
                      onClick={() =>
                        setResendModal({ open: true, request: item.id })
                      }
                    >
                      <RedoOutlined />
                    </BaseButton>
                  </BaseTooltip>
                )}

                {item.status === RequestStatus.SUBMITTED.value && (
                  <BaseTooltip
                    title={t("datablocks.labels.editRequest")}
                    className="request-accordion-tooltip-button"
                  >
                    <BaseButton
                      variant="link"
                      onClick={() =>
                        setEditRequestModal({ open: true, request: item.id })
                      }
                    >
                      <FormOutlined />
                    </BaseButton>
                  </BaseTooltip>
                )}

                <BaseTooltip
                  title={t("datablocks.labels.delete")}
                  className="request-accordion-tooltip-button"
                >
                  <BaseButton
                    variant="link"
                    onClick={() => deleteDataBlockRequest(item.id)}
                  >
                    <DeleteOutlined />
                  </BaseButton>
                </BaseTooltip>
              </div>
            </List.Item>
          )}
        />
      ),
    }));
  }, [data]);

  return (
    <div className="requests-accordion">
      <div className="requests-accordion-filters">
        <RequestFilters
          filterNames={["type", "search"]}
          setFilters={(filters) =>
            setFilters((prevValue) => ({ ...prevValue, ...filters }))
          }
        />
      </div>

      {isLoading || isFetching ? (
        <div className="requests-accordion-spinner-container">
          <BaseSpinner />
        </div>
      ) : items.length === 0 ? (
        NoRequestsSent
      ) : (
        <BaseAccordion items={items} />
      )}
      <ResendRequestModal
        open={resendModal.open}
        setOpen={(value) => setResendModal({ open: value, request: undefined })}
        request={resendModal.request}
      />
      <EditRequestModal
        open={editRequestModal.open}
        setOpen={(value) =>
          setEditRequestModal({ open: value, request: undefined })
        }
        request={editRequestModal.request}
      />
    </div>
  );
};

export default RequestsAccordion;
