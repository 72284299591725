import { useTranslation } from "react-i18next";
import { SharingRights } from "@/utilities/constants";
import { getContentItemForField } from "../fields/renderers";

import {
  DataBlockDetailsProps,
  DisplayFieldValue,
  DataBlockContentProps,
} from "../../types/types";
import { FieldValue } from "../../types/blocks";

import ContentItem from "@/components_v2/ContentElements/ContentItem";
import { DateElement } from "@/components_v2/ContentElements/DateElement";
import BaseTag from "@/components_v2/Tags/BaseTag";
import BaseButton from "@/components_v2/Button/BaseButton";
import { useNavigate } from "react-router-dom";

export const DataBlockDetails = ({
  dataBlock,
  dataTemplate,
}: DataBlockDetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fieldValues: DisplayFieldValue[] = [
    {
      field: dataTemplate.templateFields.find(
        (tf) => tf.id === dataBlock.fieldsMapping.publishDate,
      ),
      value: dataBlock.fieldValues.find(
        (fv) => fv.field === dataBlock.fieldsMapping.publishDate,
      )?.value,
    },
    {
      field: dataTemplate.templateFields.find(
        (tf) => tf.id === dataBlock.fieldsMapping.languages,
      ),
      value: dataBlock.fieldValues.find(
        (fv) => fv.field === dataBlock.fieldsMapping.languages,
      )?.value,
    },
  ].filter((fv): fv is DisplayFieldValue => !!fv.field);

  return (
    <div className="data-block-details">
      <h2 className="header-m-medium">
        {t("datablocks.labels.datablocksDetails")}
      </h2>

      <div className="data-block-details-content">
        <ContentItem
          label={t("datablocks.labels.owner")}
          content={
            <a onClick={() => navigate(`/profile/${dataBlock.createdBy.id}`)}>
              {dataBlock.createdBy.fullName
                ? dataBlock.createdBy.fullName
                : dataBlock.createdBy.email}
            </a>
          }
          showDivider={false}
          layout="horizontal"
        />

        <ContentItem
          label={t("datablocks.labels.organization")}
          content={
            <a
              onClick={() =>
                navigate(`/organizations/${dataBlock.organization.id}`)
              }
            >
              {dataBlock.organization.name}
            </a>
          }
          showDivider={false}
          layout="horizontal"
        />

        <ContentItem
          label={t("sharingRights.label")}
          content={
            <BaseTag label={SharingRights.getLabel(dataBlock.sharingRights)} />
          }
          showDivider={false}
          layout="horizontal"
        />

        {fieldValues.map((fieldValue) => getContentItemForField(fieldValue))}
        <ContentItem
          label={t("datablocks.labels.lastModified")}
          content={
            <DateElement value={dataBlock.updatedAt || dataBlock.createdAt} />
          }
          showDivider={false}
          layout="horizontal"
        />
      </div>
    </div>
  );
};

export const DataBlockContent = ({
  dataBlock,
  dataTemplate,
}: DataBlockContentProps) => {
  const { t } = useTranslation();

  const excludeFields = [
    dataBlock.fieldsMapping.languages,
    dataBlock.fieldsMapping.contributors,
    dataBlock.fieldsMapping.publishDate,
    dataBlock.fieldsMapping.title,
  ];

  const fieldValuesMap = dataBlock.fieldValues.reduce<
    Record<number, FieldValue>
  >((acc, field) => {
    acc[field?.field] = field;
    return acc;
  }, {});

  const allFields: DisplayFieldValue[] = dataTemplate.templateFields
    .filter((field) => !excludeFields.includes(field.id))
    .sort((a, b) => a.order - b.order)
    .map((templateField) => {
      const fieldValue = fieldValuesMap[templateField.id];
      return {
        field: templateField,
        value: fieldValue?.value,
      };
    });

  return (
    <div className="data-block-content">
      <h2 className="header-m-medium">
        {t("datablocks.labels.datablocksContent")}
      </h2>

      <div className="data-block-content-items">
        {allFields.map((fieldValue) => getContentItemForField(fieldValue))}
      </div>
    </div>
  );
};
