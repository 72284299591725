import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PrimaryButton from "../../../components/PrimaryButton.jsx";

const OrganizationUserPermissionsModal = ({ open, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={t("organizationUserPermission")}
      onCancel={onCancel}
      width={1000}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel}>
          {t("buttons.close")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <div>
        <table className="prme-permissions">
          <thead>
            <tr>
              <th className="prme-perms-header" style={{ width: "150px" }}>
                {t("typeOfTerm")}
              </th>
              <th className="prme-perms-header" style={{ width: "200px " }}>
                {t("term")}
              </th>
              <th className="prme-perms-header">{t("definition")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="row-term">{t("institutionalAccountTerm")}</td>
              <td className="row-definition">
                {t("institutionalAccountDefinition")}
              </td>
            </tr>
            <tr>
              <td className="row-term">{t("supervisorTerm")}</td>
              <td className="row-definition">{t("supervisorDefinition")}</td>
            </tr>
            <tr>
              <td className="row-term">{t("memberTerm")}</td>
              <td className="row-definition">{t("memberDefinition")}</td>
            </tr>
            <tr>
              <td className="row-type" rowSpan="3">
                {t("sharingRights.label")}*
              </td>
              <td className="row-term">{t("publicTerm")}</td>
              <td className="row-definition">{t("publicDescription")}</td>
            </tr>
            <tr>
              <td className="row-term">{t("privateTerm")}</td>
              <td className="row-definition">{t("privateDescription")}</td>
            </tr>
            <tr>
              <td className="row-term">{t("prmeCommunityTerm")}</td>
              <td className="row-definition">
                {t("prmeCommunityDescription")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

OrganizationUserPermissionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrganizationUserPermissionsModal;
