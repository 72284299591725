import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { OrganizationRoles, Roles } from "@/utilities/constants";
import { useGetCurrentUserQuery } from "@/redux/service";
import { ColumnKey, FilterKey, Tabs } from "../types/types";
import BaseTabs from "@/components_v2/Tabs/BaseTabs";
import BaseButton from "@/components_v2/Button/BaseButton";
import CreateDataBlock from "../core/components/CreateDatablock";
import DataBlocksTable from "../core/components/DataBlocksTable";
import { useDataBlockPermissions } from "../core/hooks/useDataBlockPermissions";

import "./styles.css";

const { Title } = Typography;

const DataBlocksList = () => {
  const { t } = useTranslation();
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const { canCreate } = useDataBlockPermissions();
  const [isModalOpen, setModalOpen] = useState(false);

  const tabs: Tabs[] = useMemo(() => {
    if (!isCurrentUserSuccess) return [];

    const isAdmin = currentUser.role === Roles.ADMIN.value;
    const isGuest =
      currentUser.organizationRole === OrganizationRoles.GUEST.value;

    const institutionalTab = {
      key: "institutional",
      title: t("table.institutionalEvidence"),
      defaultFilters: {
        organizations: currentUser.organization?.id,
      },
      filters: [
        "type",
        "sharingRights",
        "contributors",
        "languages",
        "search",
      ] as FilterKey[],
      columns: [
        "title",
        "type",
        "contributors",
        "sharingRights",
        "updatedAt",
      ] as ColumnKey[],
    };

    const communityTab = {
      key: "community",
      title: t("table.communityEvidence"),
      defaultFilters: isAdmin
        ? {}
        : { excludeOrganizations: currentUser.organization.id },
      filters: [
        "type",
        "sharingRights",
        "organizations",
        "languages",
        "search",
      ] as FilterKey[],
      columns: [
        "title",
        "type",
        "organization",
        "sharingRights",
        "languages",
        "updatedAt",
      ] as ColumnKey[],
    };

    const personalTab = {
      key: "personal",
      title: t("table.personalEvidence"),
      defaultFilters: {
        user: currentUser.id,
        contributors: [currentUser.id],
        orFilters: ["user", "contributors"],
      },
      filters: [
        "type",
        "sharingRights",
        "contributors",
        "languages",
        "search",
      ] as FilterKey[],
      columns: [
        "title",
        "type",
        "contributors",
        "sharingRights",
        "updatedAt",
      ] as ColumnKey[],
    };

    const tabsList: Tabs[] = [];

    if (!isAdmin && !isGuest) {
      tabsList.push(institutionalTab);
    }

    if (!isGuest) {
      tabsList.push(communityTab);
    }

    tabsList.push(personalTab);

    return tabsList;
  }, [isCurrentUserSuccess, currentUser, t]);

  const tabItems = useMemo(() => {
    if (!tabs) return [];
    return tabs.map((tab) => ({
      key: tab.key,
      label: tab.title,
      children: (
        <DataBlocksTable
          defaultFilters={tab.defaultFilters}
          filterNames={tab.filters}
          columnNames={tab.columns}
        />
      ),
    }));
  }, [tabs]);

  return (
    isCurrentUserSuccess && (
      <Flex vertical gap={24} className="datablocks-table">
        <Flex wrap gap={"small"} justify="space-between" align="center">
          <Title className="header-l-medium">
            {t("labels.evidenceRepository")}
          </Title>

          {canCreate && (
            <BaseButton
              variant="primary"
              size="small"
              leadingIcon={<PlusOutlined />}
              onClick={() => setModalOpen(true)}
            >
              {t("datablocks.labels.createDataBlock")}
            </BaseButton>
          )}
        </Flex>

        <BaseTabs items={tabItems} />

        <CreateDataBlock open={isModalOpen} setOpen={setModalOpen} />
      </Flex>
    )
  );
};

export default DataBlocksList;
