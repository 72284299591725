import { useEffect, useState } from "react";

import { App, Select, Switch, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";

import {
  EmailTypes,
  Frequency,
  NotificationTypes,
} from "@/utilities/constants.jsx";
import {
  useGetEmailSettingsForUserQuery,
  useGetNotificationSettingsForUserQuery,
  usePatchEmailSettingsMutation,
  usePatchNotificationSettingsMutation,
} from "@/redux/service.js";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner.js";

const { Title, Text } = Typography;

const NotificationSettings = () => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { notification } = App.useApp();

  const {
    data: notificationSettingsData,
    isSuccess: isNotificationSettingsSuccess,
  } = useGetNotificationSettingsForUserQuery();
  const { data: emailSettingsData, isSuccess: isEmailSettingsSuccess } =
    useGetEmailSettingsForUserQuery();
  const [patchNotificationSettings] = usePatchNotificationSettingsMutation();
  const [patchEmailSettings] = usePatchEmailSettingsMutation();

  const [notificationSettings, setNotificationSettings] = useState(null);
  const [emailSettings, setEmailSettings] = useState(null);
  const [frequency, setFrequency] = useState(null);

  useEffect(() => {
    if (isNotificationSettingsSuccess) {
      const settings = notificationSettingsData.reduce((acc, i) => {
        acc[i.type] = {
          id: i.id,
          enabled: i.enabled,
        };
        return acc;
      }, {});
      setNotificationSettings(settings);
    }
  }, [notificationSettingsData, isNotificationSettingsSuccess]);

  useEffect(() => {
    if (isEmailSettingsSuccess) {
      const digestFrequency = emailSettingsData.find(
        (i) => i.type === EmailTypes.DIGEST.value,
      )?.frequency;
      setFrequency(digestFrequency);
      const settings = emailSettingsData.reduce((acc, i) => {
        acc[i.type] = {
          id: i.id,
          enabled: i.enabled,
        };
        return acc;
      }, {});
      setEmailSettings(settings);
    }
  }, [emailSettingsData, isEmailSettingsSuccess]);

  useEffect(() => {
    if (emailSettings) {
      const digestFrequency = emailSettingsData.find(
        (i) => i.type === EmailTypes.DIGEST.value,
      )?.frequency;
      if (digestFrequency !== frequency) {
        onChangeEmailSetting(
          EmailTypes.DIGEST.value,
          emailSettings[EmailTypes.DIGEST.value]?.enabled,
        );
      }
    }
  }, [frequency]);

  const onChange = (notificationType, value) => {
    if (notificationType) {
      const id = notificationSettings[notificationType].id;
      patchNotificationSettings({ id, data: { enabled: value } })
        .unwrap()
        .then(() => {
          notification.success({ message: t("notificationSettingsUpdated") });
        });
    } else {
      const promises = [];
      Object.values(notificationSettings).forEach((settings) => {
        promises.push(
          patchNotificationSettings({
            id: settings.id,
            data: { enabled: false },
          }),
        );
      });
      Promise.all(promises).then(() => {
        notification.success({ message: t("notificationSettingsUpdated") });
      });
    }
  };

  const onChangeEmailSetting = (notificationType, value) => {
    const id = emailSettings[notificationType].id;
    patchEmailSettings({ id, data: { enabled: value, frequency } })
      .unwrap()
      .then(() => {
        notification.success({ message: t("emailSettingsUpdated") });
      });
  };

  if (!emailSettings || !notificationSettings) return <BaseSpinner />;

  return (
    <div>
      <div>
        <div className="mb-2">
          <Title
            level={4}
            style={{ display: "inline", color: token.colorTextThird }}
            className="mb-2 me-3"
          >
            {t("communityAlerts")}
          </Title>
          <Text
            style={{
              fontSize: "15px",
              fontWeight: 500,
              color: "rgba(91, 146, 229, 1)",
              cursor: "pointer",
            }}
            onClick={() => onChange(null, false)}
          >
            {t("disableAll")}
          </Text>
        </div>
        {[
          {
            type: NotificationTypes.MENTION.value,
            label: t("mentionsOfYou"),
          },
          {
            type: NotificationTypes.POST_COMMENT_CREATE.value,
            label: t("commentsOnYourPosts"),
          },
          {
            type: NotificationTypes.POST_LIKE.value,
            label: t("likesOnYourPosts"),
          },
          {
            type: NotificationTypes.COMMENT_REPLY_CREATE.value,
            label: t("repliesToYourComments"),
          },
        ].map((i) => (
          <div key={i.type} className="mb-3">
            <Switch
              checked={notificationSettings[i.type].enabled}
              onChange={(e) => onChange(i.type, e)}
            />
            <Text
              className="ms-2"
              style={{
                fontWeight: "bold",
                color: token.colorTextThird,
                fontSize: "15px",
              }}
            >
              {i.label}
            </Text>
          </div>
        ))}
        <div className="mb-3">
          <Switch
            checked={emailSettings[EmailTypes.DIGEST.value].enabled}
            onChange={(e) => onChangeEmailSetting(EmailTypes.DIGEST.value, e)}
          />
          <Text
            className="ms-2"
            style={{
              fontWeight: "bold",
              color: token.colorTextThird,
              fontSize: "15px",
            }}
          >
            {t("emailNotificationSetting")}
          </Text>
          <Select
            style={{ width: "150px" }}
            defaultValue={Frequency.DAILY.value}
            className="ms-3"
            value={frequency}
            onChange={setFrequency}
            disabled={!emailSettings[EmailTypes.DIGEST.value].enabled}
            options={Frequency.asList()}
          />
        </div>
      </div>
      <div className="mt-4">
        <Title
          className="mb-2"
          style={{ color: token.colorTextThird }}
          level={4}
        >
          {t("recommendationAlerts")}
        </Title>
        <div>
          <Switch
            checked={
              notificationSettings[NotificationTypes.FEATURED_CONTENT.value]
                .enabled
            }
            onChange={(e) =>
              onChange(NotificationTypes.FEATURED_CONTENT.value, e)
            }
          />
          <Text
            className="ms-2"
            style={{
              fontWeight: "bold",
              color: token.colorTextThird,
              fontSize: "15px",
            }}
          >
            {t("featuredContent")}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
