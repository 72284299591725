import { Flex } from "antd";
import { useTranslation } from "react-i18next";

import { CancelConfirmationModalProps } from "./types";
import BaseButton from "../Button/BaseButton";
import BaseModal from "./BaseModal";

const CancelConfirmationModal = ({
  open,
  onCancel,
  onConfirm,
  title,
  confirmButtonText,
  content,
}: CancelConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      classNames={{
        wrapper: "cancelation-modal",
      }}
      width={500}
      open={open}
      onCancel={onCancel}
      title={title}
      footer={
        <Flex wrap justify="end" align="center" gap={"small"}>
          <BaseButton variant="tertiary" onClick={onCancel}>
            {t("buttons.cancel")}
          </BaseButton>

          <BaseButton onClick={onConfirm}>{confirmButtonText}</BaseButton>
        </Flex>
      }
    >
      {content}
    </BaseModal>
  );
};

export default CancelConfirmationModal;
