import { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { App, Layout, theme, Typography } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Roles, UNPRME_FAQ_URL } from "../../utilities/constants.jsx";
import {
  useGetCountriesQuery,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
  useLazyGetUserByIdQuery,
  usePaginateFilterDataTemplatesQuery,
} from "@/redux/service.js";
import { PrivateRoutes } from "@/router/Routes.js";
import Header from "../header/Header.jsx";
import MobileRedirect from "../../views/MobileRedirect.jsx";
import { useResponsive } from "@/hooks/useBreakpoints.js";
import SidebarMenu from "../../components_v2/Menu/SidebarMenu.js";
import PrmeLogo from "@/assets/icons/prme-logo.png";

import "./styles.css";

const { Title } = Typography;
const { Footer, Content, Sider } = Layout;

const AuthenticatedLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { notification } = App.useApp();

  const { data: currentUser, error } = useGetCurrentUserQuery();
  const [getUserById, userResponse] = useLazyGetUserByIdQuery();
  const { t } = useTranslation();
  const showGreyBackground = useSelector(
    (state) => state.utils.showGreyBackground,
  );
  const [isUserAllowedToView, setIsUserAllowedToView] = useState(false);
  const { isMobile } = useResponsive();

  // Get static data
  usePaginateFilterDataTemplatesQuery(
    { pagination: "off" },
    {
      pollingInterval: 0,
      refetchOnMountOrArgChange: false,
      refetchOnFocus: false,
      refetchOnReconnect: false,
    },
  );
  useGetLanguagesQuery(undefined, {
    pollingInterval: 0,
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  });
  useGetCountriesQuery(undefined, {
    pollingInterval: 0,
    refetchOnMountOrArgChange: false,
    refetchOnFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (error) {
      if (
        error.data?.errors &&
        "permission_denied" === error.data?.errors[0].code
      ) {
        notification.error({
          message: t("errors.permission_denied"),
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (currentUser) {
      getUserById(currentUser.id);
      checkIsCurrentUserAllowedToView();
    }
  }, [currentUser]);

  const checkIsCurrentUserAllowedToView = () => {
    if (!currentUser) false;

    const currentRoute = PrivateRoutes.find((i) => {
      const pattern = i.path.replace(/:\w+/g, "[^/]+");
      const regex = new RegExp(`^${pattern}$`);
      return regex.test(location.pathname);
    });

    if (currentRoute) {
      if (
        currentRoute?.roles &&
        !currentRoute.roles.includes(currentUser.role) &&
        !currentRoute?.organizationRoles
      ) {
        navigate("/");
      }

      if (
        currentRoute?.organizationRoles &&
        !currentRoute.organizationRoles.includes(currentUser.organizationRole)
      ) {
        navigate("/");
      }
    }
    setIsUserAllowedToView(true);
  };

  if (isMobile) {
    return <MobileRedirect />;
  }

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        width={isMobile ? "85vw" : 250}
        className="side-menu"
      >
        <div className="side-menu-logo-container">
          <img className="side-menu-logo" src={PrmeLogo} alt={t("alts.logo")} />
        </div>

        {currentUser ? (
          <div>
            {currentUser.role === Roles.ADMIN.value && (
              <div
                className="mt-4 py-2 text-center"
                style={{ backgroundColor: token.colorTextThird }}
              >
                <Title
                  level={5}
                  style={{
                    fontSize: "17px",
                    color: "white",
                    fontWeight: "300",
                  }}
                >
                  {t("prmeSecretariat")}
                </Title>
              </div>
            )}
            <SidebarMenu />
          </div>
        ) : null}
      </Sider>

      <Layout>
        {userResponse.isSuccess && <Header user={userResponse.data} />}

        <Content
          className={location.pathname === "/" ? "" : ""}
          style={
            showGreyBackground ? { backgroundColor: "rgb(243, 243, 247)" } : {}
          }
        >
          <div
            className={`${location.pathname === "/" ? "h-100" : "main-content-container"}`}
          >
            {isUserAllowedToView && <Outlet />}
          </div>
        </Content>

        <Footer
          id="footer"
          style={{
            padding: "7px 0",
            textAlign: "center",
            borderTop: "1px solid rgba(198, 206, 220, 1)",
          }}
        >
          <a
            onClick={() => navigate("/privacy-policy")}
            style={{ color: "black", textDecoration: "underline" }}
          >
            {t("privacyPolicy")}
          </a>
          <span className="mx-3">|</span>
          <a
            onClick={() => window.open(UNPRME_FAQ_URL, "_blank")}
            style={{ color: "black", textDecoration: "underline" }}
          >
            {t("faqs")}
          </a>
          <span className="mx-3">|</span>
          <a
            href="https://prme.storylane.io/share/iuag9sbcz72f"
            target="_blank"
            rel="noreferrer"
            style={{ color: "black", textDecoration: "underline" }}
          >
            {t("demoTutorial")}
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;
