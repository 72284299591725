import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { DeleteOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/es/upload/interface";

import BaseTabs from "../../Tabs/BaseTabs";
import BaseUpload from "./BaseUpload";
import BaseButton from "../../Button/BaseButton";
import BaseSpinner from "../../Spiner/BaseSpinner";
import InfiniteListSelect from "../Select/InfiniteListSelect";
import { FileUploadError } from "@/components_v2/FormFields/Upload/errors";
import BaseError from "@/components_v2/FormFields/BaseError";
import {
  BaseFile,
  FileRepositoryUploadProps,
  InProgressUpload,
  UploadWithBaseListProps,
} from "./types";

const FileRepositoryUpload = <T extends BaseFile>({
  value,
  onChange,
  fetchFn,
  onUploadFinish,
  multiple = false,
  onUploadStart,
  ...props
}: FileRepositoryUploadProps<T>) => {
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState<
    UploadWithBaseListProps<BaseFile | InProgressUpload>[]
  >([]);
  const [initialValueFetched, setInitialValueFetched] = useState(false);
  const [errors, setErrors] = useState<FileUploadError[]>([]);

  useEffect(() => {
    if (!value || initialValueFetched) return;

    const fetchInitialOptions = async () => {
      const ids = Array.isArray(value) ? value : [value];
      if (ids.length === 0) return;

      const response = await fetchFn({ ids });
      const initialOptions = response.data.results.map((item: T) => ({
        ...item,
        isUpload: true,
        value: item.id,
        label: item.name,
      }));
      setSelectedOptions(initialOptions);
      setInitialValueFetched(true);
    };

    fetchInitialOptions();
  }, [value, initialValueFetched]);

  const handleOnUploadStart = (files: UploadFile[]) => {
    setErrors([]);
    setSelectedOptions((prevValue) => [
      ...prevValue,
      ...files.map((file) => ({
        isUpload: true,
        isInProgress: true,
        label: file.name,
        id: file.uid,
        value: file.uid,
      })),
    ]);
    if (onUploadStart) {
      onUploadStart(files);
    }
  };

  const handleOnUploadFinish = (files: BaseFile[]) => {
    setInitialValueFetched(true);
    const newFiles: UploadWithBaseListProps<BaseFile>[] = files.map((file) => ({
      isUpload: true,
      label: file.name,
      value: file.id,
      ...file,
    }));
    if (multiple) {
      const newFileIds = newFiles.map((file) => file.oldId);
      setSelectedOptions((prev) => [
        ...prev.filter((file) => !newFileIds.includes(file.value.toString())),
        ...newFiles,
      ]);
      onChange([...(value || []), ...newFiles.map((f) => f.value)]);
    } else {
      const file = newFiles[0];
      if (file) {
        setSelectedOptions([file]);
        onChange(file.value);
      } else {
        setSelectedOptions([]);
        onChange(null);
      }
    }
    if (onUploadFinish) {
      onUploadFinish();
    }
  };

  const onUploadError = (errors: FileUploadError[]) => {
    setInitialValueFetched(true);
    const failedErrorIds = errors.map((error) => error.file.uid);
    setSelectedOptions((prevOptions) =>
      prevOptions.filter(
        (option) => !failedErrorIds.includes(option.id as string),
      ),
    );
    setErrors(errors);
  };

  const handleOnSelect = (
    option: UploadWithBaseListProps<BaseFile>,
    isSelected: boolean,
  ) => {
    setInitialValueFetched(true);

    if (multiple) {
      setSelectedOptions((prev) => {
        const filteredOptions = prev.filter(
          (item) => item.value !== option.value,
        );
        return isSelected ? [...filteredOptions, option] : filteredOptions;
      });

      onChange(
        isSelected
          ? [...(value || []), option.value]
          : (value || []).filter((v: number) => v !== option.value),
      );
    } else {
      setSelectedOptions(isSelected ? [option] : []);
      onChange(isSelected ? option.value : undefined);
    }
  };

  const uploadedSelectedFiles = useMemo(() => {
    const uploadedFiles = selectedOptions.filter(
      (i) => i?.isUpload && !i?.isInProgress,
    );
    return uploadedFiles as UploadWithBaseListProps<BaseFile>[];
  }, [selectedOptions]);

  const tabs = useMemo(
    () => [
      {
        key: "upload",
        label: t("labels.uploadNewFile"),
        children: (
          <BaseUpload
            value={value}
            onChange={onChange}
            multiple={multiple}
            shouldShowValues={false}
            onUploadFinish={handleOnUploadFinish}
            onUploadStart={handleOnUploadStart}
            onUploadError={onUploadError}
            {...props}
          />
        ),
      },
      {
        key: "select",
        label: t("labels.selectExistingFile"),
        children: (
          <InfiniteListSelect<BaseFile>
            value={value}
            onChange={onChange}
            additionalOptions={uploadedSelectedFiles.map((i) => ({
              ...i,
              value: i.id,
              label: i.name,
            }))}
            onSelect={handleOnSelect}
            fetchFn={fetchFn}
            multiple={multiple}
            labelFieldKey="name"
            renderItem={(option) => {
              const formatted = DateTime.fromISO(
                option.createdAt,
              ).toLocaleString(DateTime.DATE_FULL);
              return (
                <>
                  <span className="text-gray-900">{option.name}</span>
                  <span className="text-gray-500">{formatted}</span>
                </>
              );
            }}
          />
        ),
      },
    ],
    [value, selectedOptions, onChange, t, props, multiple],
  );

  const removeItem = (
    file: UploadWithBaseListProps<BaseFile> | InProgressUpload,
  ) => {
    const newSelectedOptions = selectedOptions.filter(
      (i) => i.value !== file.value,
    );
    setSelectedOptions(newSelectedOptions);

    if (multiple) {
      onChange(value.filter((fileId: number) => fileId !== file.value));
    } else {
      onChange(undefined);
    }
  };

  return (
    <div className="file-repository-upload">
      <BaseTabs
        className="file-repository-upload-tabs"
        defaultActiveKey="upload"
        items={tabs}
        urlSync={false}
      />

      <div className="mt-4">
        {selectedOptions.length > 0 && (
          <div>
            <h6 className="body-s-medium">{t("labels.selectedFiles")}</h6>
            <div className="space-y-2">
              {selectedOptions.map((file, index) => (
                <div
                  key={`selected-${index}`}
                  className="file-repository-upload-list"
                >
                  <div className="file-repository-upload-list-file-name">
                    {file?.isInProgress && (
                      <BaseSpinner size="extra-small" isFullWidth={false} />
                    )}
                    <span className="text-sm">{file.label}</span>
                  </div>
                  <BaseButton
                    variant="link"
                    leadingIcon={<DeleteOutlined />}
                    onClick={() => removeItem(file)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {errors.map((error, index) => (
          <BaseError
            error={{ message: error.message, type: "test" }}
            key={`error-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default FileRepositoryUpload;
