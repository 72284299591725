import { useTranslation } from "react-i18next";

import BaseTooltip from "./BaseTooltip";
import { SharingRights } from "../../utilities/constants";
import BaseTag from "../Tags/BaseTag";
import { SharingRightsTooltipProps } from "./types";
import "./styles.css";

const SharingRightsTooltip = ({ children }: SharingRightsTooltipProps) => {
  const { t } = useTranslation();

  const TooltipContent = (
    <div className="sharing-rights-tooltip p-2">
      <h5>{t("sharingRights.label")}</h5>
      <div className="sharing-rights-item">
        <BaseTag
          icon={SharingRights.PUBLIC.icon}
          label={SharingRights.PUBLIC.label}
          className={SharingRights.PUBLIC.className}
        />
        <p className="body-s-regular">{t("sharingRights.publicHelpText")}</p>
      </div>
      <div className="sharing-rights-item">
        <BaseTag
          icon={SharingRights.PRIVATE.icon}
          label={SharingRights.PRIVATE.label}
          className={SharingRights.PRIVATE.className}
        />
        <p className="body-s-regular">{t("sharingRights.privateHelpText")}</p>
      </div>
      <div className="sharing-rights-item">
        <BaseTag
          icon={SharingRights.PRME_COMMUNITY.icon}
          label={SharingRights.PRME_COMMUNITY.label}
          className={SharingRights.PRME_COMMUNITY.className}
        />
        <p className="body-s-regular">
          {t("sharingRights.prmeCommunityHelpText")}
        </p>
      </div>
    </div>
  );

  return <BaseTooltip title={TooltipContent}>{children}</BaseTooltip>;
};

export default SharingRightsTooltip;
