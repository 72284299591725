import React from "react";

import { ContextMapper } from "../../types/types";
import { useDataBlockContext } from "./useDataBlockContext";

export function useDataBlockContextHandlers<T extends ContextMapper>(
  contextMapper: T,
) {
  const dataBlockContext = useDataBlockContext();

  return React.useMemo(() => {
    if (!dataBlockContext) return {} as { [K in keyof T]: () => void };

    return Object.entries(contextMapper).reduce(
      (acc, [propName, values]) => {
        acc[propName as keyof T] = () => {
          dataBlockContext.setDataBlockContextState((prevValue) => ({
            ...prevValue,
            [values.field]: values.value,
          }));
        };
        return acc;
      },
      {} as { [K in keyof T]: () => void },
    );
  }, [dataBlockContext, contextMapper]);
}

export const UPLOAD_CONTEXT_MAPPER = {
  onUploadStart: {
    field: "isUploading" as const,
    value: true,
  },
  onUploadFinish: {
    field: "isUploading" as const,
    value: false,
  },
} as const;
