import { Col, Row, Typography } from "antd";
import PropTypes from "prop-types";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer.js";

const { Title } = Typography;

const StatisticsBox = ({ label, statistic, image, className }) => (
  <div
    className={`py-3 px-4 ${className}`}
    style={{
      minHeight: "130px",
      width: "300px",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <Row>
      <Col sm={24} md={15} lg={15}>
        <Title level={5} style={{ fontWeight: 400, color: "#7E808A" }}>
          {label}
        </Title>
      </Col>
      <Col sm={24} md={9} lg={9} style={{ textAlign: "right" }}>
        <BaseSvgRenderer component={image} width="40" height="40" />
      </Col>
    </Row>
    <div>
      <Title
        level={2}
        style={{ fontWeight: "bold", color: "#1C3150" }}
        className="mt-3"
      >
        {statistic}
      </Title>
    </div>
  </div>
);

StatisticsBox.propTypes = {
  label: PropTypes.string.isRequired,
  statistic: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  className: PropTypes.object,
};

StatisticsBox.defaultProps = {
  className: {},
};

export default StatisticsBox;
