import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App, Checkbox, Modal, Spin, theme, Typography } from "antd";
import PropTypes from "prop-types";

import {
  useGetCountriesQuery,
  useLazyGetOrganizationsQuery,
  useGetSurveyByIdQuery,
  useSendSurveyInvitesMutation,
  useLazyGetUsersQuery,
} from "../../redux/service.js";
import Table from "../../components/Table.jsx";
import Tag from "../../components/Tag.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import { SurveyAudience } from "../../utilities/constants.jsx";
import MailIcon from "@/assets/icons/mail.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer.js";

const { Title, Text } = Typography;

const InviteConfirmationModal = ({
  open,
  onCancel,
  onConfirm,
  selectedEntitiesCount,
}) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const [isSubmissionRequired, setIsSubmissionRequired] = useState(false);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={[
        <PrimaryButton key="cancel" onClick={onCancel} type="secondary">
          {t("buttons.cancel")}
        </PrimaryButton>,
        <PrimaryButton
          key="confirm"
          onClick={() => onConfirm(isSubmissionRequired)}
        >
          {t("buttons.confirm")}
        </PrimaryButton>,
      ]}
      modalRender={(element) => (
        <div className="modal-main-container">{element}</div>
      )}
    >
      <Title level={3} style={{ color: token.colorTextThird }} className="mb-3">
        {t("confirmInvite")}
      </Title>
      <Text>
        {t("chosenEntitiesCountConfirmation", {
          selectedEntitiesCount,
          plural: selectedEntitiesCount === 1 ? "y" : "ies",
        })}
      </Text>
      <br />
      <Text>{t("proceedEntitiesInviteConfirmation")}</Text>
      <div className="mt-3">
        <Checkbox
          checked={isSubmissionRequired}
          onChange={() => setIsSubmissionRequired((oldVal) => !oldVal)}
        >
          {t("isSubmissionRequiredInvites")}
        </Checkbox>
      </div>
    </Modal>
  );
};

InviteConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedEntitiesCount: PropTypes.number.isRequired,
};

const SurveyInvites = () => {
  const { surveyId } = useParams();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const {
    data: survey,
    isLoading,
    isSuccess,
  } = useGetSurveyByIdQuery({ surveyId });
  const [getOrganizations, organizationsResponse] =
    useLazyGetOrganizationsQuery();
  const [getUsers, usersResponse] = useLazyGetUsersQuery();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [sendSurveyInvites] = useSendSurveyInvitesMutation();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      if (survey.audience === SurveyAudience.ORGANIZATION.value) {
        getOrganizations({ page: 1, pageSize: 10 });
      } else {
        getUsers({ page: 1, pageSize: 10 });
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (organizationsResponse.isSuccess) {
      setResponse(organizationsResponse.data);
    } else if (usersResponse.isSuccess) {
      setResponse(usersResponse.data);
    }
  }, [organizationsResponse, usersResponse]);

  const columns = [
    {
      title: t("table.name"),
      dataIndex: "name",
      key: "name",
      addSearch: true,
      width: 400,
      ellipsis: true,
      render: (_, record) =>
        isSuccess &&
        (survey.audience === SurveyAudience.ORGANIZATION.value
          ? record.name
          : record.fullName),
    },
    {
      title: t("table.country"),
      dataIndex: "country",
      key: "country",
      addSearch: true,
      width: 400,
      ellipsis: true,
      render: (_, record) => isCountriesSuccess && countries[record.country],
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      width: 400,
      ellipsis: true,
      render: (_, record) =>
        record.surveys.includes(Number.parseInt(surveyId)) ? (
          <Tag
            label={t("invited")}
            color="rgba(2, 186, 60, 0.087)"
            style={{ fontWeight: "bold", color: "rgba(0, 107, 59, 0.906)" }}
            className="py-1 px-3"
          />
        ) : (
          <Tag
            label={t("notInvited")}
            color="rgba(255, 1, 1, 0.063)"
            style={{ fontWeight: "bold", color: "rgba(187, 0, 7, 0.836)" }}
            className="py-1 px-3"
          />
        ),
    },
  ];

  const sendInvites = (isSubmissionRequired) => {
    sendSurveyInvites(
      selectedEntities.map((i) => ({
        surveyId,
        ...(survey.audience === SurveyAudience.ORGANIZATION.value
          ? { organization: i }
          : { user: i }),
        isSubmissionRequired,
      })),
    )
      .unwrap()
      .then(() => {
        notification.success({
          message: t(
            selectedEntities.length > 1
              ? "entityInvitedSuccessPlural"
              : "entityInvitedSuccessSingle",
          ),
        });
        setSelectedEntities([]);
        setOpenConfirmationModal(false);
      });
  };

  const handleTableChange = (pagination, filterObj) => {
    const filters = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      name: filterObj?.name,
    };
    let promise;
    if (survey.audience === SurveyAudience.ORGANIZATION.value) {
      promise = getOrganizations(filters);
    } else {
      promise = getUsers(filters);
    }
    promise.unwrap().then((data) => setResponse(data));
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return (
    isSuccess && (
      <div>
        <Title level={2}>
          {survey.audience === SurveyAudience.ORGANIZATION.value
            ? t("labels.inviteOrganizations")
            : t("labels.inviteUsers")}{" "}
          - {survey.title}
        </Title>
        {response && (
          <div className="mt-4">
            <div style={{ display: "flex" }} className="mb-3">
              <div>
                <Text style={{ textTransform: "uppercase" }}>
                  {t("selected", { count: selectedEntities.length })}
                </Text>
              </div>
              <div className="ms-3">
                <PrimaryButton
                  disabled={selectedEntities.length === 0}
                  onClick={() => setOpenConfirmationModal(true)}
                >
                  <span className="pe-2">
                    <BaseSvgRenderer component={MailIcon} />
                  </span>
                  {t("sendInvite")}
                </PrimaryButton>
              </div>
              <div style={{ display: "flex", marginLeft: "auto" }}>
                <div>
                  <Title level={5}>{t("labels.invited")}</Title>
                  {survey.invitesCount}
                </div>
                <div className="ms-3">
                  <Title level={5}>
                    {t(
                      survey.audience === SurveyAudience.ORGANIZATION.value
                        ? "labels.totalOrganizations"
                        : "labels.totalUsers",
                    )}
                  </Title>
                  {response.count}
                </div>
              </div>
            </div>
            <Table
              data={response.results}
              totalCount={response.count}
              columns={columns}
              handleTableChange={handleTableChange}
              rowSelection={{
                type: "checkbox",
                getCheckboxProps: (record) => ({
                  disabled: record.surveys.includes(Number.parseInt(surveyId)),
                }),
                selectedRowKeys: selectedEntities,
                onChange: (selectedKeys) => {
                  const rows = selectedEntities.filter(
                    (i) => !response.results.map((i) => i.id).includes(i),
                  );
                  setSelectedEntities([...rows, ...selectedKeys]);
                },
              }}
            />
          </div>
        )}
        <InviteConfirmationModal
          open={openConfirmationModal}
          onCancel={() => setOpenConfirmationModal(false)}
          onConfirm={sendInvites}
          selectedEntitiesCount={selectedEntities.length}
        />
      </div>
    )
  );
};

export default SurveyInvites;
