import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import createDOMPurify from "dompurify";

import {
  usePaginateFilterDataBlocksQuery,
  usePaginateFilterDataTemplatesQuery,
} from "@/redux/service";
import { DataTemplate } from "../types/templates";
import { DataBlock } from "../types/blocks";
import {
  DataBlockContent,
  DataBlockDetails,
} from "../core/components/DataBlockContent";
import BaseTabs from "@/components_v2/Tabs/BaseTabs";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import { getDataBlockTitle } from "../core/hooks/useDataBlockField";

interface DataTemplateWithExamples extends DataTemplate {
  examples: DataBlock[] | undefined;
}

const DataTemplateExample = ({
  template,
}: {
  template: DataTemplateWithExamples;
}) => {
  const { t } = useTranslation();
  const DOMPurify = createDOMPurify(window);

  return (
    <div className="data-template-example">
      <h2 className="header-m-medium">{template.title}</h2>

      <div>
        <h3 className="header-s-medium">
          {t("datablocks.labels.description")}
        </h3>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(template.fullDescription || "", {
                USE_PROFILES: { html: true },
              }),
            }}
          />
        </div>
      </div>

      {template.examples && template.examples.length > 0 ? (
        <div className="data-template-example-items">
          <div className="data-template-example-container">
            {template.examples.map((example) => (
              <div key={example.id} className="data-template-example-item">
                <h3 className="header-s-medium">
                  {getDataBlockTitle(example)}
                </h3>

                <DataBlockDetails dataBlock={example} dataTemplate={template} />

                <DataBlockContent dataBlock={example} dataTemplate={template} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const DataBlocksDocumentation = () => {
  const { t } = useTranslation();
  const { data: dataTemplates } = usePaginateFilterDataTemplatesQuery({
    pagination: "off",
    disabled: false,
  }) as { data: DataTemplate[] };
  const { data: exampleDataBlocks } = usePaginateFilterDataBlocksQuery({
    pagination: "off",
    is_example: true,
  }) as { data: DataBlock[] };

  const dataTemplatesWithExamples = useMemo(() => {
    if (!dataTemplates || !exampleDataBlocks) return;

    return dataTemplates.map((template) => ({
      ...template,
      examples: exampleDataBlocks.filter(
        (block) => block.template.id === template.id,
      ),
    }));
  }, [dataTemplates, exampleDataBlocks]);

  const tabs = useMemo(() => {
    if (!dataTemplatesWithExamples) return [];

    return dataTemplatesWithExamples.map((template) => ({
      label: template.title,
      icon: <img src={template.icon} alt={template.title} />,
      key: template.title.toLowerCase(),
      children: <DataTemplateExample template={template} />,
    }));
  }, [dataTemplatesWithExamples]);

  if (!dataTemplatesWithExamples) {
    return (
      <div className="data-block-loading-state">
        <BaseSpinner />
      </div>
    );
  }

  return (
    <div className="data-blocks-documentation">
      <div>
        <h1 className="header-l-medium">
          {t("datablocks.labels.documentationTitle")}
        </h1>
        <p className="data-blocks-documentation-help-text body-m-regular">
          {t("datablocks.labels.documentationHelpText")}
        </p>
      </div>

      <BaseTabs
        className="data-blocks-documentation-tabs"
        items={tabs}
        tabPosition="left"
      />
    </div>
  );
};

export default DataBlocksDocumentation;
