import { FormFieldWrapperProps } from "./types";

import BaseError from "../FormFields/BaseError";

import "./styles.css";

const FormFieldWrapper = ({
  label,
  description,
  helpText,
  error,
  children,
  required,
}: FormFieldWrapperProps) => {
  return (
    <div className="form-field-wrapper">
      <div>
        <div className="form-field-wrapper-header">
          <h6 className="body-s-medium">
            {label}
            {required && " *"}
          </h6>
          <p
            className="form-field-wrapper-help-text body-s-regular"
            dangerouslySetInnerHTML={{
              __html: helpText || "",
            }}
          ></p>
        </div>
        <i className="body-s-regular">{description}</i>
      </div>

      {children}
      <BaseError error={error} />
    </div>
  );
};

export default FormFieldWrapper;
