import React, { useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";
import * as Sentry from "@sentry/react";

import BaseListSelect from "./BaseListSelect";
import { InfiniteListSelectProps, SelectWithBaseListProps } from "./types";
import { BaseData } from "../../../types/base";

const InfiniteListSelect = <T extends BaseData>({
  fetchFn,
  labelFieldKey,
  additionalOptions,
  ...props
}: InfiniteListSelectProps<T>) => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState<SelectWithBaseListProps<T>[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const fetchRef = useRef(0);
  const currentPage = useRef(0);

  useEffect(() => {
    if (additionalOptions) {
      const additionalOptionsIds = additionalOptions.map((i) => i.value);
      setOptions((prevValue) => [
        ...additionalOptions,
        ...prevValue.filter((i) => !additionalOptionsIds.includes(i.value)),
      ]);
    }
  }, [additionalOptions]);

  const fetchData = useCallback(async () => {
    if (!hasMore || page !== currentPage.current + 1) return;

    setIsFetching(true);
    fetchRef.current += 1;
    const fetchId = fetchRef.current;

    try {
      const response = await fetchFn({
        page,
        search: searchText || undefined,
      });

      if (fetchId !== fetchRef.current) return;

      setOptions((prev) => {
        const data = response.data.results.map((i: T) => ({
          ...i,
          value: i.id,
          label: i[labelFieldKey as keyof T],
        }));
        if (page === 1) return data;
        return [...prev, ...data];
      });

      setHasMore(!!response.data.next);
      currentPage.current = page;
    } catch (error) {
      setHasMore(false);
      Sentry.captureException(error);
    } finally {
      setIsFetching(false);
      setIsLoading(false);
    }
  }, [fetchFn, page, searchText]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (
      !isLoading &&
      !isFetching &&
      hasMore &&
      scrollHeight - scrollTop <= clientHeight + 50 &&
      page === currentPage.current
    ) {
      setPage((prev) => prev + 1);
    }
  };

  const debouncedSearch = debounce((value: string) => {
    setSearchText(value);
    setPage(1);
    currentPage.current = 0;
    setOptions([]);
    setHasMore(true);
  }, 300);

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [page, searchText]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  return (
    <BaseListSelect<SelectWithBaseListProps<T>>
      options={options}
      isLoading={isLoading}
      isFetching={isFetching}
      onSearch={debouncedSearch}
      onScroll={handleScroll}
      {...props}
    />
  );
};

export default InfiniteListSelect;
