import { OrganizationRoles, Roles } from "@/utilities/constants";

export const DEFAULT_ACCESS = {
  allowedRoles: [Roles.ADMIN.value, Roles.USER.value],
  allowedOrgRoles: OrganizationRoles.getValues().map(
    (role: { value: string }) => role,
  ),
};

export const APP_ADMIN_ONLY = {
  allowedRoles: [Roles.ADMIN.value],
  allowedOrgRoles: [],
};

export const SUPERVISOR_AND_HIGHER = {
  allowedRoles: [Roles.ADMIN.value, Roles.USER.value],
  allowedOrgRoles: [
    OrganizationRoles.ADMIN.value,
    OrganizationRoles.SUPERVISOR.value,
  ],
};

export const CONTRIBUTOR_AND_HIGHER = {
  allowedRoles: [Roles.ADMIN.value, Roles.USER.value],
  allowedOrgRoles: [
    OrganizationRoles.ADMIN.value,
    OrganizationRoles.SUPERVISOR.value,
    OrganizationRoles.CONTRIBUTOR.value,
  ],
};

export const MEMBER_AND_HIGHER = {
  allowedRoles: [Roles.ADMIN.value, Roles.USER.value],
  allowedOrgRoles: [
    OrganizationRoles.ADMIN.value,
    OrganizationRoles.SUPERVISOR.value,
    OrganizationRoles.CONTRIBUTOR.value,
    OrganizationRoles.MEMBER.value,
  ],
};
