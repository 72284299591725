import { useState, useEffect, useMemo } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { InfiniteScrollCustomChoiceDropdownProps, Option } from "./types";
import useInfiniteScrollOptions from "./hooks/useInfiniteScrollOptions";
import useCustomChoiceOptions from "./hooks/useCustomChoiceOptions";
import BaseSpinner from "../../Spiner/BaseSpinner";
import BaseDropdown from "./BaseDropdown";
import { withCustomChoiceInput } from "./hocs/withCustomChoice";

const InfiniteScrollCustomChoiceDropdown = <T extends Option>({
  value,
  onChange,
  fetchFn,
  labelFieldName,
  valueFieldName,
  searchFieldName,
  debounceMs = 300,
  defaultFilters,
  multiple,
  customChoiceSchema,
  className,
  onChangeCallback,
  ...props
}: InfiniteScrollCustomChoiceDropdownProps<T>) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const {
    options: infiniteOptions,
    isLoading,
    search,
    setSearch,
    handleScroll,
    resetSearch,
    fetchOptions,
    initialValueFetched,
  } = useInfiniteScrollOptions<T>({
    fetchFn,
    defaultFilters,
    labelFieldName,
    valueFieldName,
    searchFieldName,
    debounceMs,
    initialValue: value,
  });

  const { customOptions, addCustomOption } = useCustomChoiceOptions({
    initialValue: value,
    onChange: onChange,
    multiple,
  });

  const options = useMemo(() => {
    const combinedOptions = [...infiniteOptions];
    customOptions.forEach((customOpt) => {
      if (!combinedOptions.some((opt) => opt.value === customOpt.value)) {
        combinedOptions.push(customOpt as T);
      }
    });
    return combinedOptions;
  }, [infiniteOptions, customOptions]);

  useEffect(() => {
    if (isOpen) {
      fetchOptions();
    } else {
      resetSearch();
    }
  }, [isOpen, fetchOptions, resetSearch]);

  const handleChange = (value: string | number) => {
    onChange(value);
    setSearch("");
    if (onChangeCallback) {
      const selectedOption = options.find((option) => option.value === value);
      if (selectedOption) {
        onChangeCallback(selectedOption);
      }
    }
  };

  const handleCustomOption = (value: string) => {
    const option = addCustomOption(value);
    if (onChangeCallback && option) {
      onChangeCallback(option);
    }
  };

  const currentValue = useMemo(() => {
    if (!initialValueFetched && value) return;

    if (value) {
      return value;
    }
    return !initialValueFetched && value;
  }, [initialValueFetched, value]);

  return (
    <BaseDropdown
      className={`base-dropdown ${className && className}`}
      {...props}
      options={options}
      loading={isLoading}
      onSearch={setSearch}
      searchValue={search}
      filterOption={false}
      onPopupScroll={handleScroll}
      onChange={handleChange}
      value={currentValue}
      onDropdownVisibleChange={setIsOpen}
      open={isOpen}
      multiple={multiple}
      notFoundContent={
        isLoading ? (
          <BaseSpinner size="small" />
        ) : (
          <div className="text-center p-4">
            <InboxOutlined className="text-2xl mb-2" />
            <p>{t("noData")}</p>
          </div>
        )
      }
      dropdownRender={(menu) =>
        withCustomChoiceInput(menu, {
          onAddOption: handleCustomOption,
          schema: customChoiceSchema,
        })
      }
    />
  );
};

export default InfiniteScrollCustomChoiceDropdown;
