import { useEffect, useMemo } from "react";

import { App, Col, Row, Spin, Switch, theme, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import PrimaryButton from "../../components/PrimaryButton.jsx";
import Select from "../../components/form/Select.jsx";
import DiscussionBox from "./components/DiscussionBox.jsx";
import Input from "../../components/form/Input.jsx";
import CommunitySearchForm from "./components/CommunitySearchForm.jsx";
import SearchFields from "./components/SearchFields.jsx";
import {
  useCreateTopicNotificationSettingsMutation,
  useGetTopicNotificationSettingsForUserQuery,
  useGetTopicsQuery,
  usePatchTopicNotificationSettingsMutation,
} from "../../redux/service.js";

const { Title, Text } = Typography;

const CommunityTopicSearch = ({
  form,
  handleEnterPress,
  handleSearch,
  discussionsData,
  discussionsSuccess,
  discussionsLoading,
}) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { topic } = useParams();
  const { notification } = App.useApp();

  const { data: topicSettings, isSuccess: isTopicSettingsSuccess } =
    useGetTopicNotificationSettingsForUserQuery({ pagination: "off" });
  const [createTopicNotificationSetting] =
    useCreateTopicNotificationSettingsMutation();
  const [patchTopicNotificationSetting] =
    usePatchTopicNotificationSettingsMutation();
  const { data: topicsData, isSuccess: topicsIsSuccess } = useGetTopicsQuery({
    title: topic,
    pagination: topic ? null : "off",
  });

  useEffect(() => {
    if (topicsIsSuccess && topicsData.count === 0) {
      navigate("/community");
      notification.error({ message: `The topic ${topic} could not be found.` });
    }
  }, [topicsIsSuccess]);

  const topicSubscription = useMemo(() => {
    if (isTopicSettingsSuccess && topicsIsSuccess) {
      return topicSettings.find((i) => i.topic === topicsData.results[0].id);
    }
    return null;
  }, [topicSettings, topicsData]);

  const handleTopicSubscription = () => {
    if (topicSubscription) {
      patchTopicNotificationSetting({
        id: topicSubscription.id,
        data: {
          topic: topicsData.results[0].id,
          enabled: !topicSubscription.enabled,
        },
      });
    } else {
      createTopicNotificationSetting({
        topic: topicsData.results[0].id,
        enabled: true,
      });
    }
  };

  return (
    <div>
      <Row>
        <Col span={19}>
          <div className="pe-5">
            <Input
              placeholder={t("form.placeholders.discussionTitle")}
              style={{ borderRadius: 0 }}
              className="w-75"
              name="title"
              control={form.control}
              onKeyPress={handleEnterPress}
              prefix={<SearchOutlined />}
              suffix={
                <PrimaryButton onClick={form.handleSubmit(handleSearch)}>
                  {t("buttons.search")}
                </PrimaryButton>
              }
            />
            <div className="mt-4 mb-4">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ paddingRight: "0px", width: "83%" }}>
                  <Title
                    level={2}
                    className="mb-1"
                    style={{ color: token.colorTextThird }}
                  >
                    {topic}
                  </Title>
                </div>
                <div style={{ marginLeft: "auto", textAlign: "center" }}>
                  <div>
                    <Text
                      className="ms-2"
                      style={{
                        fontWeight: "bold",
                        color: token.colorTextThird,
                        fontSize: "15px",
                      }}
                    >
                      {t(
                        topicSubscription?.enabled
                          ? "unsubscribe"
                          : "subscribeToTopic",
                      )}
                    </Text>
                  </div>
                  <Switch
                    checked={topicSubscription?.enabled}
                    onChange={handleTopicSubscription}
                  />
                </div>
              </div>
              {topicsIsSuccess && topicsData.count === 1 ? (
                <Text>{topicsData.results[0].description}</Text>
              ) : null}
            </div>
          </div>
          <div>
            {discussionsSuccess && (
              <Title
                className="mb-3"
                level={4}
                style={{ color: token.colorTextThird }}
              >
                {t("results", { count: discussionsData.count })}
              </Title>
            )}
            <Row>
              <Col span={2} style={{ display: "flex", alignItems: "center" }}>
                <Title level={5}>{t("sortBy")}:</Title>
              </Col>
              <Col span={3}>
                <Select
                  style={{ borderRadius: 0 }}
                  name="ordering"
                  control={form.control}
                  options={[
                    { label: "Most recent", value: "-published" },
                    { label: "Oldest", value: "published" },
                  ]}
                />
              </Col>
              <Col span={18} style={{ textAlign: "right" }}>
                <PrimaryButton
                  style={{
                    backgroundColor: "rgba(74, 105, 156, 1)",
                    color: "white",
                    fontWeight: "500",
                  }}
                  onClick={() => navigate("/discussions/form")}
                >
                  {t("startADiscussion")}
                </PrimaryButton>
              </Col>
            </Row>
          </div>
          <div className="pe-4">
            {discussionsLoading && (
              <div className="mt-3 d-flex justify-content-center align-items-center h-100">
                <Spin size="large" />
              </div>
            )}
            {discussionsSuccess &&
              discussionsData.results.map((i) => (
                <DiscussionBox discussion={i} key={i.id} />
              ))}
          </div>
        </Col>
        <Col
          span={5}
          className="py-3 pe-3 ps-4"
          style={{ borderLeft: "1px solid rgba(1, 1, 46, 0.134)" }}
        >
          <SearchFields form={form} />
        </Col>
      </Row>
    </div>
  );
};

CommunityTopicSearch.propTypes = {
  form: PropTypes.object.isRequired,
  handleEnterPress: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  discussionsSuccess: PropTypes.bool.isRequired,
  discussionsLoading: PropTypes.bool.isRequired,
  discussionsData: PropTypes.object,
};

CommunityTopicSearch.defaultProps = {
  discussionsData: null,
};

const CommunityTopicSearchWithForm = CommunitySearchForm(CommunityTopicSearch);

export default CommunityTopicSearchWithForm;
