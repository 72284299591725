import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { BaseTabsProps } from "./types";

import "./styles.css";

const BaseTabs = ({
  items,
  onChange,
  defaultActiveKey,
  urlSync = true,
  className,
  ...props
}: BaseTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState("");

  useEffect(() => {
    const nonSkippableItems = items.filter((i) => !i?.skip);
    let newActiveKey = "";

    const tabParam = searchParams.get("tab");
    if (tabParam && items.map((i) => i.key).includes(tabParam)) {
      newActiveKey = tabParam;
    }

    if (!newActiveKey) {
      newActiveKey = defaultActiveKey || nonSkippableItems[0]?.key || "";
    }

    if (urlSync) {
      setActiveKey(newActiveKey);
    } else {
      if (activeKey === "") {
        setActiveKey(newActiveKey);
      }
    }
  }, [items, urlSync && searchParams.get("tab"), defaultActiveKey, activeKey]);

  const handleChange = (val: string) => {
    setActiveKey(val);
    if (urlSync) {
      const oldTab = searchParams.get("tab");
      if (oldTab) {
        const paramsToStore: Record<string, string> = {};
        searchParams.forEach((value, key) => {
          if (key !== "tab") {
            paramsToStore[key] = value;
          }
        });
        sessionStorage.setItem(
          `tab_params_${oldTab}`,
          JSON.stringify(paramsToStore),
        );
      }

      const newParams = new URLSearchParams();
      newParams.set("tab", val);
      const storedParams = sessionStorage.getItem(`tab_params_${val}`);
      if (storedParams) {
        Object.entries(JSON.parse(storedParams)).forEach(([key, value]) => {
          newParams.set(key, value as string);
        });
      }
      setSearchParams(newParams, { replace: true });
    }
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <Tabs
      className={`base-tabs ${className}`}
      items={items}
      {...props}
      activeKey={activeKey}
      onChange={handleChange}
    />
  );
};

export default BaseTabs;
