import { Progress } from "antd";

import { BaseProgressBarProps } from "./types";

import "./styles.css";

const BaseProgressBar = ({
  value,
  total,
  label,
  helpText,
  description,
  ...props
}: BaseProgressBarProps) => {
  const percent = Math.floor((value / total) * 100);
  return (
    <div className="base-progress-bar-container">
      <div className="base-progress-bar-header-container">
        <h6 className="base-progress-bar-label body-m-regular">{label}</h6>

        {helpText && (
          <p className="base-progress-bar-help-text body-m-regular">
            {helpText}
          </p>
        )}
      </div>

      <Progress
        percent={percent}
        showInfo={false}
        className="base-progress-bar"
        strokeColor={"var(--primary-1000)"}
        trailColor={"var(--neutral-200)"}
        strokeWidth={8}
        {...props}
      />

      {description && (
        <p className="base-progress-bar-description body-s-regular">
          {description}
        </p>
      )}
    </div>
  );
};

export default BaseProgressBar;
