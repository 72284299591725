import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  useGetCurrentUserQuery,
  useLazyGetSurveyInvitesQuery,
} from "../../redux/service.js";
import { Roles, SipPreviews } from "../../utilities/constants.jsx";
import SurveyTimeline from "./components/SurveyTimeline.jsx";
import OverdueSurveysReminder from "./components/OverdueSurveysReminder.jsx";
import { setIsFirstLogin } from "../../redux/users/reducer.js";
import BaseButton from "../../components_v2/Button/BaseButton.js";
import PrmePrinciples from "@/assets/icons/prme-commons-principles.png";

import "./styles.css";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isFirstLogin = useSelector((state) => state.auth.isFirstLogin);
  const [getOverdueSurveyInvites, overdueSurveyInvitesResponse] =
    useLazyGetSurveyInvitesQuery({ pagination: "off", overdue: "true" });
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const [isReminderVisible, setIsReminderVisible] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    if (isCurrentUserSuccess && currentUser.role !== Roles.ADMIN.value) {
      getOverdueSurveyInvites({ pagination: "off", overdue: "true" });
    }
  }, [isCurrentUserSuccess]);

  useEffect(() => {
    if (isFirstLogin) {
      window.Storylane.Play({
        type: "popup",
        demo_type: "image",
        width: 2560,
        height: 1299.2,
        scale: "0.95",
        demo_url: "https://prme.storylane.io/demo/tuxjeaqartam?embed=popup",
        padding_bottom: "calc(50.75% + 25px)",
      });
      dispatch(setIsFirstLogin(false));
    }
  }, [isFirstLogin]);

  return (
    isCurrentUserSuccess && (
      <div className="onboarding-container">
        <div className="onboarding-main-content">
          {overdueSurveyInvitesResponse.isSuccess &&
          overdueSurveyInvitesResponse.data.length > 0 &&
          isReminderVisible ? (
            <OverdueSurveysReminder
              setIsVisible={setIsReminderVisible}
              surveys={overdueSurveyInvitesResponse.data.map((i) => i.survey)}
            />
          ) : null}

          <div className="onboarding-content-wrapper">
            <div className="onboarding-text-section">
              <h1 className="onboarding-title header-l-medium">
                {t("titles.welcomeToCommons")}
              </h1>

              <div className="onboarding-description">
                <p className="onboarding-text">
                  {t("onboardingPagePart1")}
                  <strong>{t("sharingInformationProgress")}</strong>
                  {t("onboardingPagePart2")}
                  <a
                    href="https://www.unprme.org/search-sips/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="onboarding-link"
                  >
                    {t("prmeWebsite")}
                  </a>

                  {t("onboardingPagePart3")}
                </p>
              </div>

              <div className="onboarding-actions">
                <div className="language-select">
                  <Select
                    className="language-dropdown"
                    placeholder={t("form.placeholders.selectLanguage")}
                    options={SipPreviews.languages}
                    value={selectedLanguage}
                    onChange={setSelectedLanguage}
                  />
                </div>

                <BaseButton
                  onClick={() =>
                    window.open(SipPreviews.getLink(selectedLanguage), "_blank")
                  }
                >
                  {t("downloadSurvey")}
                </BaseButton>
              </div>
            </div>

            <div className="onboarding-image-section">
              <img
                src={PrmePrinciples}
                alt="prme commons principles"
                className="onboarding-image"
              />
            </div>
          </div>
        </div>

        <div className="onboarding-timeline-section">
          <div className="timeline-container">
            <SurveyTimeline />
          </div>
        </div>
      </div>
    )
  );
};

export default Home;
