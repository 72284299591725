import { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CheckCircleTwoTone, CheckCircleOutlined } from "@ant-design/icons";

import { OptionType, PRME_ORGANIZATION_ID } from "@/utilities/constants.jsx";
import {
  commonsApi,
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useGetCurrentUserQuery,
  useGetQuestionByIdQuery,
  useLazyGetAnswersQuery,
  usePatchAnswerMutation,
  useUpdateAnswerMutation,
} from "@/redux/service.js";
import "./styles.css";
import BaseDropdown from "@/components_v2/FormFields/Dropdown/BaseDropdown.js";
import CustomChoiceDropdown from "@/components_v2/FormFields/Dropdown/CustomChoiceDropdown.js";
import { OptionEntities } from "./OptionEntities.jsx";
import BaseAccordion from "@/components_v2/Accordion/BaseAccordion.js";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner.js";
import SliderDropdown from "@/components_v2/FormFields/Dropdown/SliderDropdown.js";
import { useLocation } from "react-router-dom";
import DebouncedInput from "@/components/form/DebouncedInput.jsx";
import { useDispatch } from "react-redux";

const Question = ({ questionId }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const isSample = location.pathname.includes("sample");
  const { data: question } = useGetQuestionByIdQuery({
    questionId,
    filters: { organization: isSample ? PRME_ORGANIZATION_ID : null },
  });
  const [
    createAnswer,
    { isLoading: isCreateAnswerLoading, isSuccess: isCreateAnswerSuccess },
  ] = useCreateAnswerMutation();
  const [
    updateAnswer,
    { isLoading: isUpdateAnswerLoading, isSuccess: isUpdateAnswerSuccess },
  ] = useUpdateAnswerMutation();
  const [
    patchAnswer,
    { isLoading: isPatchAnswerLoading, isSuccess: isPatchAnswerSuccess },
  ] = usePatchAnswerMutation();
  const [
    deleteAnswer,
    { isLoading: isDeleteAnswerLoading, isSuccess: isDeleteAnswerSuccess },
  ] = useDeleteAnswerMutation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [getAnswers, { data: answersData }] = useLazyGetAnswersQuery();
  const isAnswerSubmitting =
    isCreateAnswerLoading ||
    isUpdateAnswerLoading ||
    isPatchAnswerLoading ||
    isDeleteAnswerLoading;
  const isAnswerSuccess =
    isCreateAnswerSuccess ||
    isUpdateAnswerSuccess ||
    isPatchAnswerSuccess ||
    isDeleteAnswerSuccess;

  const otherOption = question?.options.find(
    (opt) => opt.type === OptionType.CHOICE_TEXT.value,
  );
  const hasSlider = question?.options.find(
    (opt) => opt.relatedOptions.length > 0,
  );
  const questionType =
    question?.options.length > 0 ? question.options[0].type : null;
  const [answerMap, setAnswerMap] = useState({});
  const [customOptions, setCustomOptions] = useState([]);

  useEffect(() => {
    if (answersData) {
      answersData.forEach((answer) => {
        const textAnswer = answer.textAnswer || [];
        if (textAnswer) {
          setCustomOptions((prev) => [...new Set([...prev, ...textAnswer])]);
        }
        setAnswerMap((prev) => ({
          ...prev,
          [answer.option]: {
            ...answer,
            textAnswer,
          },
        }));
      });
    }
  }, [answersData]);

  useEffect(() => {
    if (question) {
      const answerIds = question.options
        .map((option) => option.answer)
        .filter((answer) => answer != null);
      if (answerIds.length) {
        getAnswers({
          ids: answerIds,
          pagination: "off",
          organization: isSample ? PRME_ORGANIZATION_ID : null,
        });
      }
    }
  }, [question]);

  const handleSingleChoiceSelect = async (selectedOptionId) => {
    if (!question || !currentUser) return;

    const selectedOption = question.options.find(
      (opt) => opt.id === selectedOptionId,
    );
    if (!selectedOption) return;

    const existingAnswerOptionId = Object.keys(answerMap).map(Number)[0];
    const existingAnswerId = existingAnswerOptionId
      ? answerMap[existingAnswerOptionId]?.id
      : null;

    const existingAnswer = answerMap?.selectedOptionId;
    setAnswerMap({ [selectedOptionId]: null });

    try {
      if (existingAnswerId) {
        const response = await updateAnswer({
          id: existingAnswerId,
          data: {
            option: selectedOption.id,
            question: selectedOption.question,
            organization: currentUser.organization.id,
          },
        });

        setAnswerMap({ [selectedOption.id]: response.data });
      } else {
        const response = await createAnswer({
          option: selectedOption.id,
          question: selectedOption.question,
          organization: currentUser.organization.id,
        });

        setAnswerMap({ [selectedOption.id]: response.data });
      }
    } catch (error) {
      if (existingAnswerOptionId) {
        setAnswerMap({ [existingAnswerOptionId]: existingAnswer });
      } else {
        setAnswerMap({});
      }
    }
  };

  const handleMultipleChoiceSelect = async (selectedValues) => {
    if (!question || !currentUser) return;

    const currentSelectedOptions = Object.keys(answerMap).map(Number);

    const optionsToAdd = selectedValues.filter(
      (v) => !currentSelectedOptions.includes(v),
    );
    const optionsToRemove = currentSelectedOptions.filter(
      (v) =>
        !selectedValues.includes(v) && (!otherOption || v !== otherOption.id),
    );

    for (const optionId of optionsToAdd) {
      const option = question.options.find((opt) => opt.id === optionId);
      if (option) {
        setAnswerMap((prev) => ({
          ...prev,
          [option.id]: null,
        }));

        try {
          const data = {
            option: option.id,
            question: option.question,
            organization: currentUser.organization.id,
          };
          const response = await createAnswer(data);
          setAnswerMap((prev) => ({
            ...prev,
            [option.id]: response.data,
          }));
        } catch (error) {
          setAnswerMap((prev) => {
            const updated = { ...prev };
            delete updated[option.id];
            return updated;
          });
        }
      }
    }

    for (const optionId of optionsToRemove) {
      const option = question.options.find((opt) => opt.id === optionId);
      const answer = answerMap[option.id];

      if (option && answer) {
        setAnswerMap((prev) => {
          const updated = { ...prev };
          delete updated[option.id];
          return updated;
        });
        try {
          await deleteAnswer({ id: answer.id, questionId: question.id });
          dispatch(
            commonsApi.util.invalidateTags(
              answer.attachedBlocks.map((block) => ({
                type: "DataBlockReference",
                id: block.id,
              })),
            ),
          );
        } catch (error) {
          setAnswerMap((prev) => ({
            ...prev,
            [option.id]: answer,
          }));
        }
      }
    }
  };

  const handleTextAnswer = async (textValues, option) => {
    const existingAnswerId = answerMap[option.id]?.id;
    if (
      !question ||
      !currentUser ||
      (textValues.length === 0 && !existingAnswerId)
    )
      return;

    setCustomOptions((prev) => [...new Set([...prev, ...textValues])]);

    setAnswerMap((prev) => ({
      ...prev,
      [option.id]: {
        ...prev[option.id],
        textAnswer: textValues,
      },
    }));

    try {
      if (existingAnswerId) {
        if (existingAnswerId && textValues.length === 0) {
          setAnswerMap((prev) => {
            const updated = { ...prev };
            delete updated[option.id];
            return updated;
          });
          await deleteAnswer({ id: existingAnswerId, questionId: question.id });
        } else {
          await patchAnswer({
            id: answerMap[option.id].id,
            data: { textAnswer: textValues },
          });
        }
      } else {
        const response = await createAnswer({
          option: option.id,
          question: option.question,
          organization: currentUser.organization.id,
          textAnswer: textValues,
        });

        setAnswerMap((prev) => ({
          ...prev,
          [option.id]: {
            ...response.data,
            textAnswer: textValues,
          },
        }));
      }
    } catch (error) {
      setAnswerMap((prev) => ({
        ...prev,
        [option.id]: {
          ...prev[option.id],
          textAnswer: prev[option.id]?.textAnswer || [],
        },
      }));
    }
  };

  const handleRegularOptionsInSliderDropdown = async (
    currentAnswerMap,
    numericValues,
  ) => {
    const newNumericValues = numericValues.reduce((acc, choice) => {
      acc[choice.choice] = {
        ...(currentAnswerMap[choice.choice] || {}),
        textAnswer: choice.scale != null ? [choice.scale] : [],
      };
      return acc;
    }, {});
    setAnswerMap((prev) => {
      const result = { ...newNumericValues };

      if (otherOption && prev[otherOption.id]) {
        result[otherOption.id] = prev[otherOption.id];
      }

      return result;
    });

    for (const optionId of Object.keys(currentAnswerMap).filter(
      (key) =>
        key !== otherOption?.id?.toString() &&
        !Object.keys(newNumericValues).includes(key),
    )) {
      const answer = currentAnswerMap[optionId];
      if (answer) {
        await deleteAnswer({ id: answer.id, questionId: question.id });
        dispatch(
          commonsApi.util.invalidateTags(
            answer.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ),
        );
      }
    }

    for (const choice of numericValues) {
      const currentScaleValue =
        currentAnswerMap[choice.choice]?.textAnswer?.[0];
      const scaleHasChanged =
        choice.scale != null && choice.scale !== currentScaleValue;
      const hasNoRelatedOptions = !question.options.find(
        (opt) => opt.id === choice.choice,
      )?.relatedOptions?.length;

      if (scaleHasChanged || hasNoRelatedOptions.length === 0) {
        let response;
        if (currentAnswerMap[choice.choice]?.id) {
          response = await patchAnswer({
            id: currentAnswerMap[choice.choice].id,
            data: { textAnswer: [choice.scale] },
          });
        } else {
          response = await createAnswer({
            option: choice.choice,
            question: question.id,
            organization: currentUser.organization.id,
            textAnswer: [choice.scale],
          });
        }
        setAnswerMap((prev) => ({
          ...prev,
          [choice.choice]: {
            ...response.data,
            textAnswer: [choice.scale],
          },
        }));
      }
    }
  };

  const handleCustomOptionsInSliderDropdown = async (otherValues) => {
    const existingChoices = [...(answerMap[otherOption?.id]?.textAnswer || [])];

    const newCustomChoices = otherValues.map((v) => {
      const existingIndex = existingChoices.findIndex(
        (c) => c.choice === v.choice,
      );
      if (existingIndex >= 0) {
        return {
          ...existingChoices[existingIndex],
          choice: v.choice,
          scale: v.scale || null,
        };
      } else {
        return {
          choice: v.choice,
          scale: v.scale || null,
        };
      }
    });
    setAnswerMap((prev) => ({
      ...prev,
      [otherOption.id]: {
        ...prev[otherOption.id],
        textAnswer: newCustomChoices,
      },
    }));

    const hasNewScaledCustomChoices = newCustomChoices.every(
      (c) => c.scale != null,
    );

    if (hasNewScaledCustomChoices) {
      if (answerMap[otherOption.id]?.id) {
        const textAnswer = newCustomChoices.filter((c) => c.scale != null);
        if (textAnswer.length === 0) {
          await deleteAnswer({
            id: answerMap[otherOption.id].id,
            questionId: question.id,
          });
          return;
        }
        await patchAnswer({
          id: answerMap[otherOption.id].id,
          data: {
            textAnswer,
          },
        });
      } else {
        const response = await createAnswer({
          option: otherOption.id,
          question: question.id,
          organization: currentUser.organization.id,
          textAnswer: newCustomChoices.filter((c) => c.scale != null),
        });

        setAnswerMap((prev) => ({
          ...prev,
          [otherOption.id]: {
            ...response.data,
            textAnswer: existingChoices,
          },
        }));
      }
    }
  };

  const handleSliderDropdownChanges = async (values) => {
    const currentAnswerMap = { ...answerMap };
    const regularValues = values.filter((v) => typeof v.choice === "number");
    const otherValues = values.filter((v) => typeof v.choice === "string");
    const hasChangedRegularValues = regularValues.some((choice) => {
      const currentScaleValue =
        currentAnswerMap[choice.choice]?.textAnswer?.[0];
      return choice.scale !== currentScaleValue;
    });

    const currentNumericKeys = Object.keys(currentAnswerMap)
      .filter((key) => key !== otherOption?.id?.toString())
      .map(Number);

    const selectedNumericKeys = regularValues.map((v) => v.choice).map(Number);
    const hasAddedOrRemovedRegularOptions =
      selectedNumericKeys.some((key) => !currentNumericKeys.includes(key)) ||
      currentNumericKeys.some((key) => !selectedNumericKeys.includes(key));
    if (hasChangedRegularValues || hasAddedOrRemovedRegularOptions) {
      await handleRegularOptionsInSliderDropdown(
        currentAnswerMap,
        regularValues,
      );
      return;
    }

    await handleCustomOptionsInSliderDropdown(otherValues);
  };

  const handleCustomChoiceChange = async (selectedValues) => {
    const values = Array.isArray(selectedValues)
      ? selectedValues
      : [selectedValues];
    if (hasSlider) {
      await handleSliderDropdownChanges(values);
    } else {
      // Non-slider dropdown behavior
      const textValues = values.filter((i) => typeof i === "string");
      const selectValues = values.filter((i) => typeof i === "number");

      if (otherOption && textValues.length > 0) {
        await handleTextAnswer(textValues, otherOption);
      }

      if (questionType === OptionType.SINGLE_CHOICE.value) {
        if (selectValues.length > 0) {
          await handleSingleChoiceSelect(selectValues[0]);
        }
      } else {
        await handleMultipleChoiceSelect(selectValues);
      }
    }
  };

  const handleAttachBlocks = async (option, answer, blocks) => {
    if (answer) {
      const response = await updateAnswer({
        id: answer.id,
        data: {
          ...answer,
          attachedBlockIds: blocks.map((i) => i.id),
        },
      });
      setAnswerMap({ [option.id]: response.data });
    } else {
      const response = await createAnswer({
        option: option.id,
        question: option.question,
        organization: currentUser.organization.id,
        attachedBlockIds: blocks.map((i) => i.id),
      });
      setAnswerMap({ [option.id]: response.data });
    }
  };

  const renderOptionList = () => {
    return question.options
      .slice()
      .sort((a, b) => a.id - b.id)
      .map((option) => {
        if (
          [OptionType.TEXT.value, OptionType.NUMBER.value].includes(option.type)
        ) {
          const textRows = option.textFieldRows
            ? option.textFieldRows
            : option.type === OptionType.NUMBER.value
              ? 3
              : 1;
          return (
            <DebouncedInput
              key={option.id}
              placeholder={question.placeholder}
              initialValue={answerMap[option.id]?.textAnswer || ""}
              onChange={(value) => handleTextAnswer(value, option)}
              disabled={isSample}
              rows={textRows}
              className="text-option"
              type={option.type === OptionType.NUMBER.value ? "number" : "text"}
              isTextArea={
                option.type === OptionType.TEXT.value &&
                option.textFieldRows != null
              }
            />
          );
        } else if (option.type === OptionType.PROMPT.value) {
          return (
            <OptionEntities
              key={option.id}
              option={option}
              answer={answerMap[option.id]}
              handleSubmit={(blocks) =>
                handleAttachBlocks(option, answerMap[option.id], blocks)
              }
            />
          );
        }
        return null;
      });
  };

  const questionContent = useMemo(() => {
    if (!question) return null;
    const isMultiple = questionType === OptionType.MULTIPLE_CHOICE.value;
    const selectedValues = Object.keys(answerMap).map(Number);

    if (
      [
        OptionType.SINGLE_CHOICE.value,
        OptionType.MULTIPLE_CHOICE.value,
      ].includes(questionType) ||
      hasSlider
    ) {
      const options = question.options
        .filter((option) => option.type !== OptionType.CHOICE_TEXT.value)
        .map((option) => ({
          value: option.id,
          label: option.label,
          relatedOptions: option.relatedOptions.map((relatedOption) => ({
            value: relatedOption.id,
            label: relatedOption.label,
          })),
        }));

      if (hasSlider) {
        const relatedOptions = options[0]?.relatedOptions;
        const dropdownOptions = [...options];
        let sliderValues = [];

        // Handle standard options
        Object.keys(answerMap)
          .filter((id) => id !== otherOption?.id?.toString())
          .forEach((optionId) => {
            const numericId = Number(optionId);
            const option = question.options.find((opt) => opt.id === numericId);

            if (option) {
              sliderValues.push({
                choice: numericId,
                scale: answerMap[optionId]?.textAnswer?.[0],
                label: option.label,
              });
            }
          });

        // Handle custom choices from otherOption
        if (otherOption && answerMap[otherOption.id]) {
          const customChoices = Array.isArray(
            answerMap[otherOption.id].textAnswer,
          )
            ? answerMap[otherOption.id].textAnswer
            : [];

          customChoices.forEach((item) => {
            if (item && typeof item === "object" && item.choice) {
              dropdownOptions.push({
                value: item.choice,
                label: item.choice,
                relatedOptions,
              });
              sliderValues.push({
                choice: item.choice,
                scale: item.scale,
                label: item.choice,
              });
            }
          });
        }

        return (
          <SliderDropdown
            placeholder={question.placeholder}
            multiple={isMultiple}
            value={sliderValues}
            options={dropdownOptions}
            helpText={question.relatedOptionsHelpText}
            onChange={handleCustomChoiceChange}
            customInputOptions={{ show: true, relatedOptions }}
            disabled={isSample}
          />
        );
      }

      if (otherOption) {
        const customValues = isMultiple
          ? [
              ...selectedValues.filter((i) => i !== otherOption.id),
              ...(answerMap[otherOption.id]?.textAnswer || []),
            ]
          : selectedValues[0];
        return (
          <CustomChoiceDropdown
            placeholder={question.placeholder}
            options={[
              ...options,
              ...customOptions.map((text) => ({
                value: text,
                label: text,
              })),
            ]}
            onChange={handleCustomChoiceChange}
            multiple={isMultiple}
            value={customValues}
            disabled={isSample}
          />
        );
      } else {
        return (
          <BaseDropdown
            options={options}
            placeholder={question.placeholder}
            onChange={
              isMultiple ? handleMultipleChoiceSelect : handleSingleChoiceSelect
            }
            multiple={isMultiple}
            value={isMultiple ? selectedValues : selectedValues[0]}
            disabled={isSample}
          />
        );
      }
    }

    return renderOptionList();
  }, [
    question,
    otherOption,
    questionType,
    hasSlider,
    question,
    answerMap,
    customOptions,
  ]);

  return (
    question && (
      <BaseAccordion
        className="question-container"
        expandIconPosition="end"
        defaultActiveKey={[questionId]}
        items={[
          {
            key: question.id,
            label: (
              <div className="question-header-container">
                <div className="question-header">
                  <h4 className="header-m-medium">
                    {question.index + 1 + "."}
                  </h4>
                  <h4 className="header-m-medium">
                    {question.label}
                    {question.isRequired && " *"}
                  </h4>
                  {question.isAnswered && (
                    <CheckCircleTwoTone twoToneColor="#7a9e54" />
                  )}
                </div>
                <p className="question-description body-m-regular">
                  {question.description}
                </p>
                <p className="body-s-regular question-help-text">
                  {question.helpText}
                </p>
              </div>
            ),
            isActive: true,
            children: (
              <div className="question-status-container">
                {questionContent}
                {isAnswerSubmitting && (
                  <div className="question-status-label">
                    <BaseSpinner size="extra-small" isFullWidth={false} />
                    <p>{t("labels.syncingInProgress")}</p>
                  </div>
                )}
                {isAnswerSuccess && !isAnswerSubmitting && (
                  <div className="question-status-label">
                    <CheckCircleOutlined style={{ color: "green" }} />
                    <p>{t("labels.syncingDone")}</p>
                  </div>
                )}
              </div>
            ),
          },
        ]}
      />
    )
  );
};

Question.propTypes = {
  questionId: PropTypes.number.isRequired,
};

export default Question;
