import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { App } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DateTime } from "luxon";
import { InferType } from "yup";

import BaseModal from "@/components_v2/Modal/BaseModal";
import { usePatchDataBlockRequestMutation } from "@/redux/service";
import { EditRequestModalProps } from "../../types/types";
import BaseButton from "@/components_v2/Button/BaseButton";
import FormFieldWrapper from "@/components_v2/Wrapper/FormFieldWrapper";
import {
  HookFormDatePicker,
  HookFormTextArea,
} from "@/components_v2/FormFields/ReactHookForm/fields";
import { BaseDatePickerHandle } from "@/components_v2/FormFields/DatePicker/types";

const ResendRequestModal = ({
  open,
  setOpen,
  request,
}: EditRequestModalProps) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();
  const datePickerRef = useRef<BaseDatePickerHandle>(null);

  const [resendRequest] = usePatchDataBlockRequestMutation();

  const resendRequestSchema = yup.object().shape({
    message: yup.string().typeError(t("datablocks.errors.notValid")),
    expiresAt: yup
      .mixed()
      .test("is-date-or-datetime", t("datablocks.errors.notValid"), (value) => {
        return value instanceof Date || (value && DateTime.isDateTime(value));
      })
      .typeError(t("datablocks.errors.notValid"))
      .required(t("datablocks.errors.required")),
  });

  const form = useForm({
    resolver: yupResolver(resendRequestSchema),
    mode: "onChange",
    defaultValues: {
      message: "",
      expiresAt: undefined,
    },
  });

  const handleSubmit = (data: InferType<typeof resendRequestSchema>) => {
    if (!request) return;

    resendRequest({
      id: request,
      data: {
        ...data,
        expiresAt: (data.expiresAt as DateTime)
          .plus({ days: 1 })
          .startOf("day")
          .toUTC(),
      },
    })
      .unwrap()
      .then(() => {
        notification.success({
          message: t("datablocks.labels.resendRequestForDataBlock"),
        });
        setOpen(false);
      });
  };

  const handleEndOfDayClick = () => {
    // Work around to set the current date manually since otherwise the previous value will overwrite it
    if (datePickerRef.current) {
      datePickerRef.current.close();
    }
    setTimeout(() => {
      form.setValue("expiresAt", DateTime.now().endOf("day"));
    }, 50);
  };

  return (
    <BaseModal
      title={t("datablocks.labels.resendRequest")}
      open={open}
      width={700}
      onCancel={() => setOpen(false)}
      footer={
        <div className="resend-request-modal-footer">
          <BaseButton onClick={form.handleSubmit(handleSubmit)}>
            {t("datablocks.labels.resend")}
          </BaseButton>
        </div>
      }
    >
      <div className="resend-request-modal-content">
        <FormFieldWrapper label={t("datablocks.labels.expiryDate")}>
          <HookFormDatePicker
            ref={datePickerRef}
            name="expiresAt"
            control={form.control}
            disabledDate={(current: DateTime) =>
              current && current < DateTime.now().startOf("day")
            }
            showNow={false}
            renderExtraFooter={() => (
              <BaseButton
                variant="link"
                isFullWidth
                onClick={handleEndOfDayClick}
              >
                {t("datablocks.labels.endOfDay")}
              </BaseButton>
            )}
          />
        </FormFieldWrapper>

        <FormFieldWrapper
          label={t("datablocks.labels.message")}
          helpText={t("datablocks.labels.requestEditHelpText")}
        >
          <HookFormTextArea
            name="message"
            placeholder={t("datablocks.placeholders.message")}
            control={form.control}
          />
        </FormFieldWrapper>
      </div>
    </BaseModal>
  );
};

export default ResendRequestModal;
