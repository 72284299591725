import { useTranslation } from "react-i18next";

import FilterPopover from "./FilterPopover";
import { FilterRendererProps } from "./types";
import BaseButton from "../Button/BaseButton";

const FilterRenderer = ({
  fields,
  activeFilterCount,
  popoverTitle,
  handleClear,
  handleSearch,
  areFiltersDirty,
  className,
}: FilterRendererProps) => {
  const { t } = useTranslation();

  const popoverFields = fields.filter((i) => i.isAdvancedFilter);
  const regularFields = fields.filter((i) => !i.isAdvancedFilter);

  const ClearAllButton = () => (
    <BaseButton variant="link" onClick={handleClear}>
      {t("buttons.clearFilters")}
    </BaseButton>
  );

  const showClearButton = (index: number) => {
    const noAdvancedFilters = popoverFields.length === 0;
    return areFiltersDirty && noAdvancedFilters && index === 0;
  };

  return (
    <div className={`${className || ""}`}>
      {regularFields.map((field, index) => (
        <span
          key={`${field.label}-${index}`}
          className="filter-renderer-container"
        >
          {field.component}
          {showClearButton(index) && <ClearAllButton />}
        </span>
      ))}

      {popoverFields.length > 0 && (
        <FilterPopover
          title={popoverTitle || t("buttons.filters")}
          fields={popoverFields}
          activeFilterCount={activeFilterCount}
          handleClear={handleClear}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
};

export default FilterRenderer;
