import { ListItemsCardProps } from "./types";

import BaseButton from "../Button/BaseButton";
import { InfoCircleOutlined } from "@ant-design/icons";
import BaseTooltip from "../Tooltip/BaseTooltip";
import { ReactNode } from "react";

import "./styles.css";

const ListItemsCard = ({
  headerContent,
  title,
  helpText,
  description,
  items,
  buttonText,
  className,
  onButtonClick,
}: ListItemsCardProps) => {
  return (
    <div className={`list-items-card ${className}`}>
      <div className="list-items-card-content">
        {headerContent}

        <div className="list-items-card-title-container">
          <h4 className="list-items-card-title header-s-medium">{title}</h4>

          {helpText && (
            <BaseTooltip title={helpText}>
              <InfoCircleOutlined />
            </BaseTooltip>
          )}
        </div>

        <span className="list-items-card-description body-m-regular">
          {description}
        </span>

        <div className="list-items-card-items body-m-regular">
          {items &&
            items.map((item: { icon: ReactNode; content: string }, index) => (
              <div key={index} className="list-items-card-item">
                <span>{item.icon}</span>
                <span className="list-items-card-item-content">
                  {item.content}
                </span>
              </div>
            ))}
        </div>
      </div>

      {buttonText && (
        <BaseButton isFullWidth size="small" onClick={onButtonClick}>
          {buttonText}
        </BaseButton>
      )}
    </div>
  );
};

export default ListItemsCard;
