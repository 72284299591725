import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TFunction } from "i18next/typescript/t";
import * as yup from "yup";
import _ from "lodash";

import {
  DataBlockRequestFiltersProps,
  DataBlockRequestFiltersState,
  FilterKey,
} from "../../types/types";
import { FilterField } from "@/components_v2/Filters/types";
import FilterRenderer from "@/components_v2/Filters/FilterRenderer";
import { useDataTemplates } from "../../core/hooks/useDataTemplates";
import {
  HookFormInput,
  HookFormBaseDropdown,
} from "@/components_v2/FormFields/ReactHookForm/fields";

const dataBlockRequestsFiltersSchema = (t: TFunction) =>
  yup
    .object()
    .shape({
      dataTemplates: yup
        .array()
        .of(
          yup
            .number()
            .required()
            .typeError(t("datablocks.errors.datablockTypeNotValid")),
        )
        .default([]),
      search: yup
        .string()
        .transform((value) => (value === "" ? undefined : value))
        .matches(/^[^<>*]+$/, t("datablocks.errors.specialCharsNotAllowed"))
        .max(100, t("datablocks.errors.searchTooLong")),
    })
    .required();

const RequestFilters = ({
  filterNames,
  setFilters,
  defaultFilters,
}: DataBlockRequestFiltersProps) => {
  const { t } = useTranslation();
  const dataTemplates = useDataTemplates();

  const form = useForm<DataBlockRequestFiltersState>({
    // @ts-expect-error filters state is same as schema
    resolver: yupResolver(dataBlockRequestsFiltersSchema(t)),
    mode: "onChange",
    defaultValues: {
      search: "",
      dataTemplates: [],
      ...(defaultFilters || {}),
    },
  });

  const debouncedFiltersChange = useMemo(
    () => _.debounce((filters) => setFilters(filters), 500),
    [setFilters],
  );

  const dataTemplateTypeOptions = useMemo(() => {
    if (dataTemplates == null) return [];

    return dataTemplates.map((template) => ({
      label: template.title,
      value: template.id,
    }));
  }, [dataTemplates]);

  const filterFields: Record<string, FilterField> = useMemo(() => {
    return {
      type: {
        name: "dataTemplates",
        isAdvancedFilter: false,
        component: (
          <HookFormBaseDropdown
            name="dataTemplates"
            control={form.control}
            options={dataTemplateTypeOptions}
            multiple
            placeholder={t("datablocks.placeholders.dataTemplateType")}
          />
        ),
      },
      search: {
        name: "search",
        isAdvancedFilter: false,
        isDebounced: true,
        component: (
          <HookFormInput
            name="search"
            control={form.control}
            className="data-blocks-filters-search"
            suffix={<SearchOutlined />}
            placeholder={t("datablocks.placeholders.searchInvites")}
          />
        ),
      },
    };
  }, [dataTemplateTypeOptions]);

  const activeFields = useMemo(() => {
    return filterNames.reduce<FilterField[]>((acc, filterName) => {
      const field = filterFields[filterName as FilterKey];
      if (field) {
        acc.push(field);
      }
      return acc;
    }, []);
  }, [filterFields, filterNames]);

  useEffect(() => {
    const subscription = form.watch((formValues, { name }) => {
      if (!name) return;
      const typedFormValues = formValues as DataBlockRequestFiltersState;

      const debouncedFields = activeFields
        .filter((field) => field?.isDebounced)
        .map((field) => field.name);
      const nonAdvancedFilters = activeFields
        .filter((field) => !field?.isAdvancedFilter)
        .map((field) => field.name);
      if (debouncedFields.includes(name)) {
        debouncedFiltersChange(typedFormValues);
      } else if (nonAdvancedFilters.includes(name)) {
        setFilters(typedFormValues);
      }
    });

    return () => {
      subscription.unsubscribe();
      debouncedFiltersChange.cancel();
    };
  }, [form, activeFields, setFilters]);

  return (
    <FilterRenderer
      fields={activeFields}
      popoverTitle={t("datablocks.labels.filters")}
      handleClear={() => {
        form.reset(defaultFilters);
        setFilters(form.getValues());
      }}
      areFiltersDirty={form.formState.isDirty}
      handleSearch={() => setFilters(form.getValues())}
    />
  );
};

export default RequestFilters;
