import { DateTime } from "luxon";

import { DateElementProps, DateRangeElementProps } from "./types";

export const DateElement = ({
  value,
  format = "dd-MM-yyyy",
}: DateElementProps) => {
  if (!value) {
    return null;
  }
  if (!DateTime.isDateTime(value)) {
    value = DateTime.fromISO(value);
  }

  return <div>{value.toFormat(format)}</div>;
};

export const DateRangeElement = ({
  value,
  format = "dd-MM-yyyy",
}: DateRangeElementProps) => {
  if (!value) return null;
  const start = value[0];
  const end = value[1];
  if (!start || !end) return;

  const startDate = DateTime.fromISO(start);
  const endDate = DateTime.fromISO(end);
  if (!startDate.isValid || !endDate.isValid) {
    return null;
  }

  return (
    <div>
      {startDate.toFormat(format)} - {endDate.toFormat(format)}
    </div>
  );
};
