import Login from "../views/auth/Login.jsx";
import ResetPassword from "../views/auth/ResetPassword.jsx";
import ResetPasswordConfirm from "../views/auth/ResetPasswordConfirm.jsx";
import EmailVerificationResend from "../views/auth/EmailVerificationResend.jsx";
import EmailVerification from "../views/auth/EmailVerification.jsx";
import Home from "../views/home/index.jsx";
import Register from "../views/auth/Register.jsx";
import Settings from "../views/users/Settings.jsx";
import ListOrganizations from "../views/organizations/ListOrganizations.jsx";
import SingleNarrativeView from "../views/narratives/SingleNarrativeView.jsx";
import SurveySectionForm from "../views/survey/components/active-survey-form/SurveySectionForm.jsx";
import SurveyList from "../views/survey/SurveyList.jsx";
import SingleSurvey from "../views/survey/SingleSurvey.jsx";
import SurveyInvites from "../views/survey/SurveyInvites.jsx";
import PrivacyPolicy from "../views/PrivacyPolicy.jsx";
import Community from "../views/community/Community.jsx";
import DiscussionForm from "../views/community/DiscussionForm.jsx";
import Profile from "../views/users/profile/index.jsx";
import SingleDiscussion from "../views/community/SingleDiscussion.jsx";
import CommunityTopicSearch from "../views/community/CommunityTopicSearch.jsx";
import CommunitySearch from "../views/community/CommunitySearch.jsx";
import SingleOrganization from "../views/organizations/SingleOrganization.jsx";
import SurveyViewForm from "../views/survey/components/survey-views/SurveyViewForm.jsx";
import SurveyView from "../views/survey/components/survey-views/SurveyView.jsx";
import Dashboard from "../views/dashboard/index.jsx";
import DataBlocksDocumentation from "../views/datablocks/pages/DataBlocksDocumentation.tsx";
import DataBlockContainer from "../views/datablocks/pages/DataBlockContainer.tsx";
import UpdateDataBlock from "../views/datablocks/pages/UpdateDataBlock.tsx";
import DataBlocksList from "../views/datablocks/pages/DataBlocksList.tsx";
import DataBlockRequests from "../views/datablocks/pages/DataBlockRequests.tsx";
import {
  APP_ADMIN_ONLY,
  DEFAULT_ACCESS,
  MEMBER_AND_HIGHER,
  SUPERVISOR_AND_HIGHER,
} from "@/router/roles.js";

export const PublicRoutes = [
  {
    path: "/login",
    view: Login,
  },
  {
    path: "/register",
    view: Register,
  },
  {
    path: "/reset-password/confirm",
    view: ResetPasswordConfirm,
  },
  {
    path: "/reset-password",
    view: ResetPassword,
  },
  {
    path: "/email-verification",
    view: EmailVerification,
  },
  {
    path: "/email-verification/resend",
    view: EmailVerificationResend,
  },
  {
    path: "/public/reports/:surveyId/report",
    view: SurveyView,
  },
];

export const PrivateRoutes = [
  {
    path: "/",
    view: Home,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/privacy-policy",
    view: PrivacyPolicy,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/dashboard",
    view: Dashboard,
    ...APP_ADMIN_ONLY,
  },
  {
    path: "/profile/:userId",
    view: Profile,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/settings",
    view: Settings,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/organizations",
    view: ListOrganizations,
    ...APP_ADMIN_ONLY,
  },
  {
    path: "/organizations/:organizationId",
    view: SingleOrganization,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/evidence",
    view: DataBlocksList,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/evidence/:dataBlockId",
    view: DataBlockContainer,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/evidence/:dataBlockId/edit",
    view: UpdateDataBlock,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/evidence/documentation",
    view: DataBlocksDocumentation,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/evidence/invites",
    view: DataBlockRequests,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/narratives/:narrativeId",
    view: SingleNarrativeView,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/reports",
    view: SurveyList,
    ...SUPERVISOR_AND_HIGHER,
  },
  {
    path: "/reports/:surveyId",
    view: SingleSurvey,
    ...SUPERVISOR_AND_HIGHER,
  },
  {
    path: "/reports/:surveyId/sample",
    view: SingleSurvey,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/reports/:surveyId/section/:sectionId",
    view: SurveySectionForm,
    ...SUPERVISOR_AND_HIGHER,
  },
  {
    path: "/reports/:surveyId/section/:sectionId/sample",
    view: SurveySectionForm,
    ...SUPERVISOR_AND_HIGHER,
  },
  {
    path: "/reports/:surveyId/invites",
    view: SurveyInvites,
    ...APP_ADMIN_ONLY,
  },
  {
    path: "/reports/:surveyId/report",
    view: SurveyView,
    ...DEFAULT_ACCESS,
  },
  {
    path: "/community",
    view: Community,
    ...MEMBER_AND_HIGHER,
  },
  {
    path: "/community/search",
    view: CommunitySearch,
    ...MEMBER_AND_HIGHER,
  },
  {
    path: "/community/topics/:topic",
    view: CommunityTopicSearch,
    ...MEMBER_AND_HIGHER,
  },
  {
    path: "/discussions/:discussionId",
    view: SingleDiscussion,
    ...MEMBER_AND_HIGHER,
  },
  {
    path: "/discussions/form",
    view: DiscussionForm,
    ...MEMBER_AND_HIGHER,
  },
  {
    path: "/discussions/form/:discussionId",
    view: DiscussionForm,
    ...MEMBER_AND_HIGHER,
  },
  {
    path: "/reports/:surveyId/views/form",
    view: SurveyViewForm,
    ...MEMBER_AND_HIGHER,
  },
  {
    path: "/reports/:surveyId/views/:surveyViewId/form",
    view: SurveyViewForm,
    ...MEMBER_AND_HIGHER,
  },
];
