import { Breadcrumb, theme, Typography } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import PropTypes from "prop-types";

import Notifications from "./Notifications.jsx";
import Profile from "./Profile.jsx";

import "./styles.css";

const { Title } = Typography;

const CustomHeader = ({ user }) => {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.utils.breadcrumbs);
  const { token } = theme.useToken();

  return (
    <header className="base-header">
      <div className="base-header-content">
        <div className="base-header-content-notifications">
          <Notifications />
        </div>

        {user && <Profile currentUser={user} />}
      </div>

      {/* {breadcrumbs && (
        <Breadcrumb
          style={{
            padding: "11px 50px",
            color: token.colorTextThird,
            backgroundColor: "rgba(244, 244, 244, 1)",
          }}
          separator=">"
          items={breadcrumbs.map((i) => {
            const props = {
              href: i?.to ? "#" : null,
              onClick: i?.to ? () => navigate(i.to) : null,
            };

            return i?.to
              ? {
                  title: (
                    <Title level={5} style={{ color: token.colorTextThird }}>
                      {_.truncate(i.title, { length: 60 })}
                    </Title>
                  ),
                  ...props,
                }
              : {
                  ...i,
                  title: _.truncate(i.title, { length: 60 }),
                };
          })}
        />
      )} */}
    </header>
  );
};

CustomHeader.propTypes = {
  user: PropTypes.object.isRequired,
};

export default CustomHeader;
