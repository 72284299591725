import { createUrl } from "../../utilities/index.js";

const narrativesService = (builder) => ({
  paginateFilterNarratives: builder.query({
    query: (queryParams) => ({
      url: createUrl("/narratives/", queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.results.map((i) => ({ type: "Narratives", id: i.id })),
            { type: "Narratives", id: "PARTIAL-LIST" },
            { type: "Narratives" },
          ]
        : [{ type: "Narratives" }],
  }),
  getNarrativeById: builder.query({
    query: (id) => ({
      url: `/narratives/${id}/`,
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [{ type: "Narratives", id: result.id }]
        : [{ type: "Narratives" }],
  }),
});

export default narrativesService;
