import { useEffect } from "react";
import { Modal } from "antd";

import { BaseModalProps } from "./types";
import BaseModalHeader from "./Elements/BaseModalHeader";

import "./styles.css";

const BaseModal = ({
  children,
  title,
  subtitle,
  className,
  containerRef,
  ...props
}: BaseModalProps) => {
  useEffect(() => {
    if (props.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.open]);

  return (
    <Modal
      className={`base-modal ${className}`}
      centered
      title={<BaseModalHeader title={title} subtitle={subtitle} />}
      footer={null}
      getContainer={() => {
        if (!containerRef) return document.body;
        if (!containerRef.current) {
          containerRef.current = document.createElement("div");
          document.body.appendChild(containerRef.current);
        }
        return containerRef.current;
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default BaseModal;
