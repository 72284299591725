import { ReactNode, useState } from "react";

import { DataBlockContext } from "../hooks/useDataBlockContext";
import { DataBlockContextType } from "../../types/types";

interface DataBlockProviderProps {
  children: ReactNode;
}

export const DataBlockProvider = ({ children }: DataBlockProviderProps) => {
  const [dataBlockContextState, setDataBlockContextState] = useState({
    isUploading: false,
  });

  const contextValue: DataBlockContextType = {
    dataBlockContextState,
    setDataBlockContextState,
  };

  return (
    <DataBlockContext.Provider value={contextValue}>
      {children}
    </DataBlockContext.Provider>
  );
};
