import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Progress, Typography } from "antd";
import PropTypes from "prop-types";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";
import ThinCircleIcon from "@/assets/icons/thin-circle.svg?react";
import ThickCircleIcon from "@/assets/icons/thick-circle.svg?react";
import CircleCheckIcon from "@/assets/icons/circle-check.svg?react";

const { Text } = Typography;

const SectionProgressCircle = ({
  requiredQuestionsCount,
  answeredRequiredQuestionsCount,
  color,
}) => {
  if (answeredRequiredQuestionsCount === 0) {
    return (
      <BaseSvgRenderer
        width="16"
        fill={"var(--neutral-300)"}
        component={ThinCircleIcon}
      />
    );
  } else if (answeredRequiredQuestionsCount >= requiredQuestionsCount) {
    return (
      <BaseSvgRenderer width="16" fill={color} component={CircleCheckIcon} />
    );
  } else
    return (
      <BaseSvgRenderer width="16" fill={color} component={ThickCircleIcon} />
    );
};

SectionProgressCircle.propTypes = {
  requiredQuestionsCount: PropTypes.number.isRequired,
  answeredRequiredQuestionsCount: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

const SectionProgressBar = ({ sections }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSample = location.pathname.includes("sample");
  const { sectionId } = useParams();

  const calculatePercentage = (section) => {
    return section.requiredQuestionsCount === 0
      ? 100
      : Math.min(
          section.answeredRequiredQuestionsCount /
            section.requiredQuestionsCount,
          1,
        ) * 100;
  };

  return (
    <div style={{ display: "flex" }}>
      {sections
        .slice()
        .sort((a, b) => a.index - b.index)
        .map((section) => {
          const percentage = calculatePercentage(section);

          return (
            <div
              key={section.id}
              style={{ cursor: "pointer", flex: 1 }}
              className="ms-2"
              onClick={() =>
                navigate(
                  `/reports/${section.survey}/section/${section.id}${isSample ? "/sample" : ""}`,
                )
              }
            >
              <Progress
                percent={percentage}
                className={`section-progress ${sectionId == section.id && "section-progress-active"}`}
                size="small"
                strokeColor={
                  sectionId == section.id ? section.color : `${section.color}60`
                }
              />

              <div className="section-progress-bar-label-container">
                <SectionProgressCircle
                  requiredQuestionsCount={section.requiredQuestionsCount}
                  answeredRequiredQuestionsCount={
                    section.answeredRequiredQuestionsCount
                  }
                  color={
                    sectionId == section.id
                      ? section.color
                      : `${section.color}60`
                  }
                />
                <Text
                  className={`section-progress-bar-label ${sectionId == section.id ? "body-s-medium" : "body-s-regular"}`}
                >
                  {section.title}
                </Text>
              </div>
            </div>
          );
        })}
    </div>
  );
};

SectionProgressBar.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default SectionProgressBar;
