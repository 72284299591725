import { useEffect, useMemo } from "react";
import { DatePicker } from "antd";
import luxonGenerateConfig from "rc-picker/lib/generate/luxon";
import { DateTime } from "luxon";

import BaseError from "../BaseError";
import { BaseDateRangePickerProps } from "./types";

import "./styles.css";
import { BaseFormError } from "../types";

const LuxonDatePicker =
  DatePicker.generatePicker<DateTime>(luxonGenerateConfig);

const { RangePicker } = LuxonDatePicker;

const BaseDateRangePicker = ({
  value,
  onChange,
  error,
  ...props
}: BaseDateRangePickerProps) => {
  useEffect(() => {
    if (!value) return;

    if (Array.isArray(value)) {
      const shouldUpdate = value.every((value) => DateTime.isDateTime(value));
      if (!shouldUpdate) {
        onChange(
          (value as DateTime<boolean>[]).map((val) => {
            if (!DateTime.isDateTime(val)) {
              return DateTime.fromISO(val);
            }
            return val;
          }),
        );
      }
    }
  }, [value]);

  const currentValue = useMemo(() => {
    if (!value) return;

    if (Array.isArray(value)) {
      if (value.every((value) => DateTime.isDateTime(value))) {
        return value;
      }
    }
  }, [value]);

  return (
    <>
      <RangePicker
        className={`base-date-range-picker ${error && "base-date-range-picker-error"}`}
        onChange={onChange}
        value={currentValue}
        {...props}
      />
      <BaseError error={error as BaseFormError} />
    </>
  );
};

export default BaseDateRangePicker;
