import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { App } from "antd";
import * as yup from "yup";
import { TFunction } from "i18next/typescript/t";

import {
  useCreateBulkDataBlockRequestsMutation,
  useGetCurrentUserQuery,
} from "@/redux/service";
import { DataBlockRequestFormProps } from "../../types/types";
import { DateTime } from "luxon";

const requestFormSchema = (t: TFunction) =>
  yup.object().shape({
    dataTemplate: yup
      .number()
      .typeError(t("datablocks.errors.notValid"))
      .required(t("datablocks.errors.required")),
    expiresAt: yup
      .mixed()
      .test("is-date-or-datetime", t("datablocks.errors.notValid"), (value) => {
        return value instanceof Date || (value && DateTime.isDateTime(value));
      })
      .typeError(t("datablocks.errors.notValid"))
      .required(t("datablocks.errors.required")),
    requests: yup
      .array()
      .of(
        yup
          .object()
          .shape({
            email: yup
              .mixed()
              .test(
                "is-email-or-int",
                t("datablocks.errors.notValid"),
                function (value) {
                  if (
                    Number.isInteger(Number(value)) &&
                    String(Number(value)) === String(value)
                  ) {
                    return true;
                  }

                  return yup.string().email().isValidSync(value);
                },
              )
              .typeError(t("datablocks.errors.notValid"))
              .required(t("datablocks.errors.required")),
            role: yup
              .string()
              .typeError(t("datablocks.errors.notValid"))
              .required(t("datablocks.errors.required")),
            message: yup
              .string()
              .typeError(t("datablocks.errors.notValid"))
              .optional(),
          })
          .typeError(t("datablocks.errors.invitesNotValid")),
      )
      .typeError(t("datablocks.errors.notValid"))
      .required(t("datablocks.errors.required"))
      .min(1, t("datablocks.errors.required")),
    message: yup.string().typeError(t("datablocks.errors.notValid")),
  });

export const useDataBlockRequestForm = ({
  onSuccess,
}: {
  onSuccess: () => void;
}): DataBlockRequestFormProps => {
  const { t } = useTranslation();
  const { notification } = App.useApp();
  const [currentStep, setCurrentStep] = useState(0);
  const [createDataBlockRequests, { isLoading }] =
    useCreateBulkDataBlockRequestsMutation();
  const [showIndividualMessage, setShowIndividualMessage] = useState(false);
  const { data: currentUser } = useGetCurrentUserQuery();

  const form = useForm({
    resolver: yupResolver(requestFormSchema(t)),
    mode: "onChange",
    defaultValues: {
      dataTemplate: undefined,
      requests: [],
      message: "",
      expiresAt: undefined,
    },
  });

  const resetForm = () => {
    form.reset({
      dataTemplate: undefined,
      requests: [],
      message: "",
      expiresAt: undefined,
    });
    setCurrentStep(0);
    setShowIndividualMessage(false);
  };

  const validateCurrentStep = async () => {
    return await form.trigger(
      currentStep === 0 ? ["dataTemplate", "expiresAt"] : "requests",
    );
  };

  const handleNextStep = async () => {
    const isValid = await validateCurrentStep();
    if (!isValid || !currentUser) return false;

    if (currentStep === 0) {
      setCurrentStep(1);
    } else {
      handleSubmit();
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      return true;
    }
    return false;
  };

  const handleSubmit = () => {
    if (!currentUser) return;

    const requests = form.getValues("requests");
    const formData = form.getValues();
    const data = requests.map((invite) => ({
      ...invite,
      dataTemplate: formData.dataTemplate,
      message: invite?.message || formData.message,
      expiresAt: (formData.expiresAt as DateTime)
        .plus({ days: 1 })
        .startOf("day")
        .toUTC(),
      organization: currentUser.organization.id,
    }));

    createDataBlockRequests(data)
      .unwrap()
      .then(() => {
        notification.success({
          message: t("datablocks.labels.requestsHaveBeenSent"),
        });
        resetForm();
        if (onSuccess) {
          onSuccess();
        }
      });
  };

  const handleShowIndividualMessageChange = (value: boolean) => {
    const currentRequests = form.getValues("requests");
    const currentMessage = form.getValues("message");
    let updatedRequests;

    if (value) {
      updatedRequests = currentRequests.map((request) => ({
        ...request,
        message: currentMessage,
      }));
    } else {
      updatedRequests = currentRequests.map((request) => ({
        ...request,
        message: undefined,
      }));
    }
    form.setValue("requests", updatedRequests);
    setShowIndividualMessage(value);
  };

  return {
    form,
    currentStep,
    handleNextStep,
    handlePreviousStep,
    isSubmitting: isLoading,
    showIndividualMessage,
    resetForm,
    handleShowIndividualMessageChange,
  };
};
