import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Divider, Flex } from "antd";

import {
  DataBlockDetailsStepProps,
  FormFieldsWithSharingRights,
} from "../../types/types";
import { SharingRights } from "@/utilities/constants";
import {
  ContentType,
  DataField,
  FieldType,
  ValidationRuleTypes,
} from "../../types/templates";
import {
  useCreateDataFileMutation,
  useGetCurrentUserQuery,
  useLazyGetDataFilesQuery,
  useLazyGetSubjectsQuery,
  useLazyGetUsersQuery,
} from "@/redux/service";
import { getFieldTypeComponent } from "../fields/editors";

import "./styles.css";
import FormFieldWrapper from "../../../../components_v2/Wrapper/FormFieldWrapper";

const getBorderStyles = (value: number | undefined) => {
  if (value === undefined) {
    return "";
  }

  const baseStyles = "either-group-questions";

  switch (value) {
    case -1:
      return baseStyles + " either-group-questions-top";
    case 0:
      return baseStyles;
    case 1:
      return baseStyles + " either-group-questions-bottom";
    default:
      return "";
  }
};

const DataBlockDetailsForm = ({
  dataTemplate,
  excludeFields = [],
}: DataBlockDetailsStepProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [getUsers] = useLazyGetUsersQuery();
  const [getSubjects] = useLazyGetSubjectsQuery();
  const [getDataFiles] = useLazyGetDataFilesQuery();
  const [createDataFile] = useCreateDataFileMutation();
  const { data: currentUser } = useGetCurrentUserQuery();

  const fields: FormFieldsWithSharingRights[] = useMemo(() => {
    const newFields: FormFieldsWithSharingRights[] = [
      ...dataTemplate.templateFields.map((i: DataField, index) => ({
        ...i,
        name: `fieldValues.${index}.value`, // must use index because RHF uses it to set it in the array
      })),
    ];
    newFields.splice(4, 0, {
      id: "sharingRights",
      name: "sharingRights",
      disabled: false,
      hasOther: false,
      placeholder: t("sharingRights.selectPlaceholder"),
      contentType: ContentType.Options,
      options: SharingRights.asList().map((option: Record<string, string>) => ({
        id: option.value,
        label: option.label,
      })),
      order: 4,
      rules: [
        {
          type: ValidationRuleTypes.REQUIRED,
          dependentOn: null,
          errorMessage: "ruleRequired",
        },
      ],
      description: "",
      title: "Sharing Rights",
      type: FieldType.SINGLE_CHOICE,
    });

    return newFields.filter((i) => !excludeFields.includes(i.id));
  }, [dataTemplate, excludeFields]);

  const eitherRequiredRules: Record<number, -1 | 0 | 1> = useMemo(() => {
    const rules = dataTemplate.validationRules
      .filter((rule) => rule.type === ValidationRuleTypes.EITHER_REQUIRED)
      .map((rule) => rule.ruleFields);

    const orderFieldIds = fields.map((field) => field.id);

    const orderedRules = rules.map((rule) =>
      rule
        .slice()
        .sort((a, b) => orderFieldIds.indexOf(a) - orderFieldIds.indexOf(b)),
    );

    return orderedRules.reduce(
      (acc, fieldIds) => {
        fieldIds.forEach((fieldId: number) => {
          const fieldIndex = fieldIds.indexOf(fieldId);
          acc[fieldId] =
            fieldIndex === 0 ? -1 : fieldIndex === fieldIds.length - 1 ? 1 : 0;
        });
        return acc;
      },
      {} as Record<number, -1 | 0 | 1>,
    );
  }, [dataTemplate, fields]);

  const dependencies = useMemo(
    () => ({
      getUsers,
      getSubjects,
      getDataFiles,
      createDataFile,
      currentUser,
      t,
      dataTemplate,
    }),
    [
      getUsers,
      getSubjects,
      getDataFiles,
      createDataFile,
      currentUser,
      t,
      dataTemplate,
    ],
  );

  return (
    currentUser && (
      <Flex vertical gap={16} className="evidence-details-content">
        {fields.map((field) => {
          const { component: FieldComponent, props } = getFieldTypeComponent(
            field as DataField,
            dependencies,
          );
          const isRequired = Boolean(
            (field?.rules || []).find(
              (i) => i.type === ValidationRuleTypes.REQUIRED,
            ),
          );
          const showLabel = [FieldType.CHECKBOX].includes(field.type);
          let borderStyles;
          let showOr;
          if (typeof field.id === "number") {
            const ruleForField = eitherRequiredRules[field.id];
            borderStyles = getBorderStyles(ruleForField);
            showOr = field.id in eitherRequiredRules && ruleForField !== 1;
          }

          const helpTextContent = () => {
            if (field.guide) {
              return (
                t("datablocks.labels.getStartedTemplate", {
                  guide: field.guide,
                }) +
                " " +
                field.helpText
              );
            }

            return field.helpText;
          };

          return (
            <div key={`${field.id}-${dataTemplate.id}`}>
              <Flex vertical gap={4} className={borderStyles}>
                <FormFieldWrapper
                  label={showLabel ? "" : field.title}
                  required={isRequired}
                  description={field.description ?? undefined}
                  helpText={helpTextContent()}
                >
                  <FieldComponent
                    // @ts-expect-error control is required for any component
                    control={control}
                    name={field.name}
                    placeholder={field.placeholder || field.title}
                    {...props}
                  />
                </FormFieldWrapper>
              </Flex>

              {showOr && <Divider>{t("datablocks.labels.or")}</Divider>}
            </div>
          );
        })}
      </Flex>
    )
  );
};

export default DataBlockDetailsForm;
