import { createTimeFilters, createUrl } from "@/utilities/index.js";

const communityService = (builder) => ({
  paginateFilterDiscussions: builder.query({
    query: (queryParams) => {
      if (
        Object.keys(queryParams).includes("time") &&
        queryParams.time != null
      ) {
        const timeFilters = createTimeFilters(queryParams.time);
        queryParams = { ...queryParams, ...timeFilters };
      }

      return {
        url: createUrl("/discussions/", queryParams),
        method: "GET",
      };
    },
    providesTags: (result) =>
      result
        ? [
            ...result.results.map((i) => ({ type: "Discussion", id: i.id })),
            { type: "Discussions", id: "PARTIAL-LIST" },
            { type: "Discussions" },
          ]
        : [{ type: "Discussions" }],
  }),
  getDiscussionsStatistics: builder.query({
    query: (queryParams) => ({
      url: createUrl("/discussions/statistics/", queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result ? [{ type: "DiscussionsStatistics" }] : [],
  }),
  getDiscussionById: builder.query({
    query: (id) => ({
      url: `/discussions/${id}/`,
      method: "GET",
    }),
    providesTags: (result) =>
      result ? [{ type: "Discussion", id: result.id }] : [],
  }),
  createDiscussion: builder.mutation({
    query: (data) => ({
      url: "/discussions/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "Discussions" },
            ...result.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ]
        : [],
  }),
  updateDiscussion: builder.mutation({
    query: ({ id, data }) => ({
      url: `/discussions/${id}/`,
      method: "PUT",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "Discussion", id: result.id },
            ...result.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ]
        : [],
  }),
  patchDiscussion: builder.mutation({
    query: ({ id, data }) => ({
      url: `/discussions/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "Discussion", id: result.id },
            ...result.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ]
        : [],
  }),
  reportDiscussion: builder.query({
    query: (id) => ({
      url: `/discussions/${id}/report/`,
      method: "GET",
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Discussion", id: result.id }] : [],
  }),
  incrementDiscussionViews: builder.query({
    query: (id) => ({
      url: `/discussions/${id}/views/`,
      method: "GET",
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Discussion", id: result.id }] : [],
  }),
  deleteDiscussion: builder.mutation({
    query: (id) => ({
      url: `/discussions/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error) =>
      error
        ? []
        : [
            { type: "Discussions", id: "PARTIAL-LIST" },
            { type: "Discussions" },
            { type: "DiscussionsStatistics" },
            { type: "Topics" },
          ],
  }),
  getTopics: builder.query({
    query: (queryParams) => ({
      url: createUrl("/discussions/topics/", queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [
            ...(result?.results || result).map((i) => ({
              type: "Topics",
              id: i.id,
            })),
            { type: "Topics" },
          ]
        : [],
  }),
  createTopic: builder.mutation({
    query: (data) => ({
      url: "/discussions/topics/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) => (result ? [{ type: "Topics" }] : []),
  }),
  updateTopic: builder.mutation({
    query: ({ id, data }) => ({
      url: `/discussions/topics/${id}/`,
      method: "PUT",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Topics", id: result.id }] : [],
  }),
  deleteTopic: builder.mutation({
    query: (id) => ({
      url: `/discussions/topics/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, args) =>
      error ? [] : [{ type: "Topics", id: args }],
  }),
  likeDiscussion: builder.mutation({
    query: ({ id, isLiked }) => ({
      url: `/discussions/${id}/likes/`,
      method: "POST",
      data: { isLiked },
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Discussion", id: result.discussion }] : [],
  }),
  getComments: builder.query({
    query: (queryParams) => ({
      url: createUrl("/comments/", queryParams, [undefined]),
      method: "GET",
    }),
    providesTags: (result) =>
      result ? result.results.map((i) => ({ type: "Comment", id: i.id })) : [],
  }),
  createComment: builder.mutation({
    query: (data) => ({
      url: "/comments/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? result.attachedBlocks.map((block) => ({
            type: "DataBlockReference",
            id: block.id,
          }))
        : [],
  }),
  getComment: builder.query({
    query: (id) => ({
      url: `/comments/${id}/`,
      method: "GET",
    }),
    providesTags: (result) =>
      result ? [{ type: "Comment", id: result.id }] : [],
  }),
  updateComment: builder.mutation({
    query: ({ id, data }) => ({
      url: `/comments/${id}/`,
      method: "PUT",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "Comment", id: result.id },
            ...result.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ]
        : [],
  }),
  patchComment: builder.mutation({
    query: ({ id, data }) => ({
      url: `/comments/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? result.attachedBlocks.map((block) => ({
            type: "DataBlockReference",
            id: block.id,
          }))
        : [],
  }),
  deleteComment: builder.mutation({
    query: (id) => ({
      url: `/comments/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, errors, args) =>
      errors ? [] : [{ type: "Comment", id: args }],
  }),
  likeComment: builder.mutation({
    query: ({ id, isLiked }) => ({
      url: `/comments/${id}/likes/`,
      method: "POST",
      data: { isLiked },
    }),
  }),
  reportComment: builder.query({
    query: (id) => ({
      url: `/comments/${id}/report/`,
      method: "GET",
    }),
  }),
});

export default communityService;
