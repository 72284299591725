import { useState, useEffect } from "react";

import { CustomChoiceHookProps, CustomChoiceOption } from "../types";

const useCustomChoiceOptions = ({
  initialOptions,
  initialValue,
  onChange,
  multiple,
}: CustomChoiceHookProps) => {
  const [areInitialValuesSet, setAreInitialValuesSet] = useState(false);
  const [customOptions, setCustomOptions] = useState<CustomChoiceOption[]>([]);

  useEffect(() => {
    if (initialOptions) {
      setCustomOptions([
        ...customOptions.filter((option) => option.custom),
        ...initialOptions,
      ]);
    }
  }, [initialOptions]);

  useEffect(() => {
    if (!areInitialValuesSet && initialValue) {
      setAreInitialValuesSet(true);

      const values = Array.isArray(initialValue)
        ? initialValue
        : [initialValue];
      const newOptions = [...customOptions];

      values.forEach((value) => {
        if (typeof value !== "string") return;

        const exists = customOptions.some((option) => option.value === value);

        if (!exists) {
          newOptions.push({ value, label: value, custom: true });
        }
      });

      if (newOptions.length !== customOptions.length) {
        setCustomOptions(newOptions);
      }
    }
  }, [initialOptions, areInitialValuesSet, initialValue]);

  const addCustomOption = (label: string) => {
    if (!label) return;

    const trimmedLabel = label.trim();
    const newOption = {
      value: trimmedLabel,
      label: trimmedLabel,
      custom: true,
    };
    setCustomOptions([...customOptions, newOption]);

    if (Array.isArray(initialValue)) {
      onChange([...initialValue, trimmedLabel]);
    } else {
      onChange(multiple ? [trimmedLabel] : trimmedLabel);
    }

    return newOption;
  };

  return {
    customOptions,
    setCustomOptions,
    addCustomOption,
  };
};

export default useCustomChoiceOptions;
