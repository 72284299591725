import { useEffect } from "react";

import { Spin } from "antd";
import { useParams } from "react-router-dom";

import {
  useGetCurrentUserQuery,
  useLazyGetOrganizationByIdQuery,
} from "../../redux/service.js";
import PrivateOrganizationProfile from "./profile/PrivateOrganizationProfile.jsx";
import { OrganizationRoles, Roles } from "../../utilities/constants.jsx";
import PublicOrganizationProfile from "./profile/PublicOrganizationProfile.jsx";

const Organization = () => {
  const { organizationId } = useParams();

  const {
    data: currentUser,
    isSuccess: isCurrentUserSuccess,
    isLoading: isCurrentUserLoading,
  } = useGetCurrentUserQuery();
  const [getOrganizationById, organizationResponse] =
    useLazyGetOrganizationByIdQuery();

  useEffect(() => {
    if (isCurrentUserSuccess) {
      getOrganizationById(organizationId);
    }
  }, [isCurrentUserSuccess]);

  if (isCurrentUserLoading || organizationResponse.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  if (isCurrentUserSuccess && organizationResponse.isSuccess) {
    if (
      currentUser.role === Roles.ADMIN.value ||
      (currentUser.organization.id === organizationResponse.data.id &&
        [
          OrganizationRoles.ADMIN.value,
          OrganizationRoles.SUPERVISOR.value,
        ].includes(currentUser.organizationRole))
    ) {
      return (
        <PrivateOrganizationProfile organization={organizationResponse.data} />
      );
    } else {
      return (
        <PublicOrganizationProfile organization={organizationResponse.data} />
      );
    }
  }
};

export default Organization;
