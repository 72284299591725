import { Divider } from "antd";

import { ContentItemProps } from "./types";

import "./styles.css";

const ContentItem = ({
  label,
  content,
  showDivider = false,
  layout = "horizontal",
}: ContentItemProps) => {
  return (
    <div className="content-item">
      <div
        className="content-item-container"
        style={{ flexDirection: layout === "horizontal" ? "row" : "column" }}
      >
        <span className="content-item-container-label body-m-medium">
          {label}
        </span>

        <span className="content-item-container-value body-m-regular">
          {content}
        </span>
      </div>

      {showDivider && <Divider className="base-divider" />}
    </div>
  );
};

export default ContentItem;
