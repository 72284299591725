import { createUrl } from "../../utilities/index.js";

const organizationService = (builder) => ({
  verifyInviteEmail: builder.query({
    query: (token) => createUrl("/invites/email-verification/", { token }),
  }),
  getOrganizations: builder.query({
    query: (queryParams = {}) => ({
      url: createUrl("/organizations/", queryParams),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? [
            ...(result?.results || result).map((i) => ({
              type: "Organization",
              id: i.id,
            })),
            { type: "Organizations", id: "PARTIAL-LIST" },
          ]
        : [{ type: "Organizations" }],
  }),
  getOrganizationById: builder.query({
    query: (organizationId) => ({
      url: `/organizations/${organizationId}/`,
      method: "GET",
    }),
    providesTags: (result) => {
      const organizationTags = result
        ? [{ type: "Organization", id: result.id }, { type: "Organizations" }]
        : [{ type: "Organizations" }];
      const inviteTags = result
        ? [
            ...(result?.invites || []).map((i) => ({
              type: "Invite",
              id: i.id,
            })),
            { type: "Invites" },
          ]
        : [{ type: "Invites" }];
      const memberTags = result
        ? [
            ...(result?.members || []).map((i) => ({
              type: "Membership",
              id: i.id,
            })),
            { type: "Memberships" },
          ]
        : [{ type: "Memberships" }];
      return [...organizationTags, ...inviteTags, ...memberTags];
    },
  }),
  createOrganization: builder.mutation({
    query: (data) => ({
      url: "/organizations/",
      method: "POST",
      data,
    }),
    invalidatesTags: [{ type: "Organizations" }],
  }),
  patchOrganizationById: builder.mutation({
    query: ({ id, data }) => ({
      url: `/organizations/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "Organization", id: result.id },
            { type: "Organizations", id: "PARTIAL-LIST" },
          ]
        : [],
  }),
  deleteOrganizationById: builder.mutation({
    query: ({ id, data = null }) => ({
      url: `/organizations/${id}/`,
      method: "DELETE",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "Organization", id: result.id },
            { type: "Organization", id: "PARTIAL-LIST" },
          ]
        : [],
  }),
  exportOrganizations: builder.query({
    query: (params) => {
      const queryString = Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => {
          const val = Array.isArray(value) ? value.join(",") : value;
          return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        })
        .join("&");

      return {
        url: `/organizations/export/?${queryString}`,
        method: "GET",
      };
    },
  }),
  getMemberships: builder.query({
    query: (filters) => ({
      url: createUrl("/memberships/", filters),
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? (result?.results || result).map((i) => ({
            type: "Memberships",
            id: i.id,
          }))
        : [],
  }),
  patchMembershipById: builder.mutation({
    query: ({ membership, data }) => ({
      url: `/memberships/${membership}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result, error, args) =>
      result ? [{ type: "Organization", id: args.organizationId }] : [],
  }),
  deleteMembershipById: builder.mutation({
    query: (membershipId) => ({
      url: `/memberships/${membershipId}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, args) =>
      error ? [] : [{ type: "Organization", id: args.organizationId }],
  }),
  getInvites: builder.query({
    query: () => ({
      url: `/invites/`,
      method: "GET",
    }),
    providesTags: (result) =>
      result
        ? (result?.results || result).map((i) => ({
            type: "Invite",
            id: i.id,
          }))
        : [],
  }),
  inviteUser: builder.mutation({
    query: (data) => ({
      url: `/invites/`,
      method: "POST",
      data,
    }),
    invalidatesTags: (result) => (result ? [{ type: "Invites" }] : []),
  }),
  patchInviteById: builder.mutation({
    query: ({ id, data }) => ({
      url: `/invites/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Invite", id: result.id }] : [],
  }),
  deleteInviteById: builder.mutation({
    query: (id) => ({
      url: `/invites/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, args) =>
      error ? [] : [{ type: "Invite", id: args }],
  }),
  importOrganizationsFromCsv: builder.mutation({
    query: (data) => ({
      url: `/organizations/import/`,
      method: "POST",
      data,
    }),
    invalidatesTags: (result) => (result ? [{ type: "Organization" }] : []),
  }),
  exportOrganizationData: builder.mutation({
    query: (id) => ({
      url: `/organizations/${id}/export/`,
      method: "POST",
    }),
  }),
  getExportedOrganizationData: builder.query({
    query: (id) => `/organizations/${id}/export/`,
  }),
  getSubjects: builder.query({
    query: (filters) => createUrl("/subjects/", filters),
  }),
});

export default organizationService;
