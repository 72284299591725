import { Flex } from "antd";

import { BaseCardProps } from "./types";

import "./styles.css";

const BaseCard = ({
  icon,
  color,
  title,
  description,
  additionalData,
  additionalDataIcon,
  isActive,
  onClick,
}: BaseCardProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`base-card ${isActive ? "base-card-active" : ""}`}
      style={{
        border: isActive ? `0.125rem solid ${color}` : undefined,
        borderLeft: isActive
          ? `0.125rem solid ${color}`
          : `0.25rem solid ${color}`,
        backgroundColor: isActive ? `${color}1a` : "transparent",
      }}
      onClick={handleClick}
    >
      <Flex gap={8} align="center">
        {icon && (
          <span style={{ backgroundColor: color }} className="base-card-icon">
            <img src={icon} alt="card icon" />
          </span>
        )}

        <span className="base-card-title header-s-medium">{title}</span>
      </Flex>

      <p className="base-card-description body-s-regular">{description}</p>

      {additionalData && (
        <Flex gap={8} align="center">
          {additionalDataIcon}
          <span className="base-card-duration body-s-regular">
            {additionalData}
          </span>
        </Flex>
      )}
    </div>
  );
};

export default BaseCard;
