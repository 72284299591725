import { ReactElement } from "react";
import { DataFile } from "../../views/datablocks/types/blocks";
import { DateTime } from "luxon";

export interface ContentItemProps {
  label?: string;
  content: ReactElement | undefined;
  showDivider?: boolean;
  layout?: "horizontal" | "vertical";
}

export interface FileElementProps {
  value: DataFile;
}

export interface FileGroupElementProps {
  value: DataFile[];
}

export const RegularText = "text";
export const RichText = "richtext";

export type TextElementTypes = typeof RegularText | typeof RichText;

export interface TextElementProps {
  value?: string | null;
  type: TextElementTypes;
  link?: string;
}

export interface DateElementProps {
  value?: string | DateTime | null;
  format?: string;
}

export interface DateRangeElementProps {
  value?: string[] | null;
  format?: string;
}

export interface Tag {
  label: string;
  link?: string;
}

export interface TagsElementProps {
  value?: Tag[] | null;
}

export interface CheckboxElementProps {
  label: string;
  value: boolean;
}

export interface SliderDropdownValue {
  label: string;
  scaleLabel: string;
}

export interface SliderDropdownElementProps {
  value: SliderDropdownValue[];
}

export interface Option {
  label: string;
  value: number;
}

export interface SliderElementProps {
  value: number;
  options: Option[];
}
