import React, { useState, useEffect, useRef, useCallback } from "react";
import { debounce } from "lodash";
import * as Sentry from "@sentry/react";

import { InfiniteScrollOptionsProps, Option } from "../types";

const useInfiniteScrollOptions = <T>({
  fetchFn,
  defaultFilters,
  labelFieldName,
  valueFieldName,
  searchFieldName,
  debounceMs = 300,
  initialValue,
}: InfiniteScrollOptionsProps) => {
  const [options, setOptions] = useState<(T & Option)[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number | null>(1);
  const [initialValueFetched, setInitialValueFetched] = useState(false);

  const fetchRef = useRef(0);
  const debouncedSearchRef = useRef<any>(null);

  /**
   * Fetch data from the API
   */
  const fetchData = useCallback(
    async (params: Record<string, any>, isSearching = false) => {
      setIsLoading(true);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      try {
        const response = await fetchFn(params);

        // Ignore if not the latest request
        if (fetchId !== fetchRef.current) return;

        const results = response.data?.results || [];
        const newOptions = results
          .filter(
            (item: T) =>
              item &&
              item[labelFieldName as keyof T] !== undefined &&
              item[valueFieldName as keyof T] !== undefined,
          )
          .map((item: T) => ({
            ...item,
            label: String(item[labelFieldName as keyof T]),
            value: item[valueFieldName as keyof T],
          }));

        setOptions((prev) => {
          if (isSearching) {
            return newOptions;
          } else {
            return [
              ...prev,
              ...newOptions.filter(
                (newOpt: Option) =>
                  !prev.some(
                    (existingOpt) => existingOpt.value === newOpt.value,
                  ),
              ),
            ];
          }
        });

        setPage(response.data?.next || null);
      } catch (error) {
        setPage(null);
        Sentry.captureException(error, {
          extra: {
            params,
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [fetchFn, labelFieldName, valueFieldName],
  );

  const doSearch = debounce((searchValue: string) => {
    const params = {
      ...(defaultFilters || {}),
      [searchFieldName]: searchValue
        ? encodeURIComponent(searchValue)
        : undefined,
      page: 1,
    };

    setOptions([]);
    fetchData(params, true);
  }, debounceMs);

  /**
   * Handle search input changes
   */
  const handleSearch = useCallback((value: string) => {
    setSearch(value);

    if (debouncedSearchRef.current) {
      debouncedSearchRef.current.cancel();
    }
    debouncedSearchRef.current = doSearch;
    debouncedSearchRef.current(value);
  }, []);

  /**
   * Fetch more options for pagination
   */
  const fetchMoreOptions = useCallback(() => {
    if (!page) return;

    const params = {
      ...(defaultFilters || {}),
      [searchFieldName]: search ? encodeURIComponent(search) : undefined,
      page,
    };

    fetchData(params, false);
  }, [fetchData, defaultFilters, page, search, searchFieldName]);

  /**
   * Fetch initial values
   */
  const fetchInitialValues = useCallback(async () => {
    if (
      !initialValue ||
      (Array.isArray(initialValue) && initialValue.length === 0) ||
      initialValueFetched
    )
      return;

    setIsLoading(true);
    try {
      const values = Array.isArray(initialValue)
        ? initialValue
        : [initialValue];

      const response = await fetchFn({
        ...(defaultFilters || {}),
        [valueFieldName]: values,
        pagination: "off",
      });

      const results = Array.isArray(response.data)
        ? response.data
        : response.data?.results || [];

      const initialOptions = results
        .filter(
          (item: T) =>
            item &&
            item[labelFieldName as keyof T] !== undefined &&
            item[valueFieldName as keyof T] !== undefined,
        )
        .map((item: T) => ({
          ...item,
          label: String(item[labelFieldName as keyof T]),
          value: item[valueFieldName as keyof T],
        }));

      setOptions(initialOptions);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          initialValue,
          defaultFilters,
        },
      });
    } finally {
      setIsLoading(false);
      setInitialValueFetched(true);
    }
  }, [
    fetchFn,
    defaultFilters,
    initialValue,
    initialValueFetched,
    labelFieldName,
    valueFieldName,
  ]);

  /**
   * Reset search state
   */
  const resetSearch = useCallback(() => {
    setSearch("");
    setPage(1);
  }, []);

  /**
   * Handle scroll event for infinite scrolling
   */
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const target = e.currentTarget;
      if (
        !isLoading &&
        page &&
        target.scrollTop + target.offsetHeight >= target.scrollHeight - 200
      ) {
        fetchMoreOptions();
      }
    },
    [isLoading, page, fetchMoreOptions],
  );

  useEffect(() => {
    fetchInitialValues();
  }, [fetchInitialValues]);

  return {
    options,
    isLoading,
    search,
    setSearch: handleSearch,
    handleScroll,
    resetSearch,
    fetchOptions: fetchMoreOptions,
    initialValueFetched,
  };
};

export default useInfiniteScrollOptions;
