import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Flex } from "antd";
import { ColumnType } from "antd/es/table/interface";
import { Link } from "react-router-dom";
import { DeleteOutlined, RedoOutlined } from "@ant-design/icons";

import {
  ColumnKey,
  DataBlockRequestFiltersState,
  DataBlockRequestsTableProps,
} from "../../types/types";
import { useResponsive } from "@/hooks/useBreakpoints";
import {
  useDeleteDataBlockRequestMutation,
  useLazyGetDataBlockRequestsQuery,
} from "@/redux/service";
import { MinDataTemplate } from "../../types/templates";
import UserAvatar from "@/components_v2/Avatar/UserAvatar";
import QueryTable from "@/components_v2/Tables/QueryTable";
import BaseAvatar from "@/components_v2/Avatar/BaseAvatar";
import { DataBlockRequest } from "../../types/requests";
import { RequestStatuses } from "../../core/constants";
import RequestFilters from "./RequestFilters";
import EditRequestModal from "./EditRequestModal";
import BaseButton from "@/components_v2/Button/BaseButton";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip";

const RequestsTable = ({
  columnNames,
  filterNames,
  defaultFilters = {},
  additionalColumns,
  ...props
}: DataBlockRequestsTableProps<DataBlockRequest>) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  const [filters, setFilters] = useState<DataBlockRequestFiltersState>(
    defaultFilters as DataBlockRequestFiltersState,
  );
  const [deleteDataBlockRequest] = useDeleteDataBlockRequestMutation();
  const [editRequestModal, setEditRequestModal] = useState<{
    open: boolean;
    request: DataBlockRequest | undefined;
  }>({
    open: false,
    request: undefined,
  });

  const columns: Record<string, ColumnType<DataBlockRequest>> = useMemo(() => {
    const predefinedColumns: Record<string, ColumnType<DataBlockRequest>> = {
      user: {
        title: t("datablocks.labels.user"),
        fixed: !isMobile ? "left" : undefined,
        width: 200,
        ellipsis: true,
        render: (value: DataBlockRequest) => {
          let avatar;
          let email;
          if (value.user) {
            avatar = <UserAvatar user={value.user} />;
            email = value.user.email;
          } else if (value.userInvite) {
            avatar = (
              <BaseAvatar
                label={value.userInvite.email.slice(0, 2).toUpperCase()}
              />
            );
            email = value.userInvite.email;
          }

          return (
            <p>
              {avatar} {email}
            </p>
          );
        },
      },
      type: {
        title: t("table.dataTemplateType"),
        dataIndex: "dataTemplate",
        render: (value: MinDataTemplate) => {
          return value.title;
        },
      },
      status: {
        title: t("datablocks.labels.status"),
        dataIndex: "status",
        render: (value: string) => {
          return RequestStatuses[value as keyof typeof RequestStatuses];
        },
      },
      dataBlock: {
        title: t("datablocks.labels.dataBlock"),
        dataIndex: "dataBlock",
        render: (value) => {
          return value ? (
            <Link
              to={`/evidence/${value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("datablocks.labels.seeDataBlock")}
            </Link>
          ) : (
            "-"
          );
        },
      },
      actions: {
        title: t("datablocks.labels.actions"),
        key: "actions",
        render: (value: DataBlockRequest) => (
          <div className="requests-table-actions-container">
            <BaseTooltip
              title={t("datablocks.labels.delete")}
              className="requests-table-actions-tooltip-button"
            >
              <BaseButton
                size="small"
                variant="link"
                onClick={() => deleteDataBlockRequest(value.id)}
              >
                <DeleteOutlined />
              </BaseButton>
            </BaseTooltip>

            {value.dataBlock && (
              <BaseTooltip
                title={t("datablocks.labels.resend")}
                className="requests-table-actions-tooltip-button"
              >
                <BaseButton
                  size="small"
                  variant="link"
                  onClick={() =>
                    setEditRequestModal({ open: true, request: value })
                  }
                >
                  <RedoOutlined />
                </BaseButton>
              </BaseTooltip>
            )}
          </div>
        ),
      },
    };
    return { ...predefinedColumns, ...(additionalColumns || {}) };
  }, [isMobile]);

  const mappedColumns = useMemo(
    () =>
      columnNames.map(
        (columnKey) =>
          columns[columnKey as ColumnKey] as ColumnType<DataBlockRequest>,
      ),
    [columns, columnNames],
  );

  return (
    <Flex vertical gap={"small"}>
      <RequestFilters
        filterNames={filterNames}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
      />
      <QueryTable<DataBlockRequest>
        query={useLazyGetDataBlockRequestsQuery}
        filters={filters}
        initialSorting={{
          field: "created_at",
          order: "descend",
        }}
        columns={mappedColumns}
        locale={{
          emptyText: (
            <div>
              {t("datablocks.labels.noActiveRequests")}
              {t("datablocks.labels.sendRequestsToColleagues")}
            </div>
          ),
        }}
        {...props}
      />
      <EditRequestModal
        open={editRequestModal.open}
        setOpen={(value) =>
          setEditRequestModal({ open: value, request: undefined })
        }
        request={editRequestModal.request?.id}
      />
    </Flex>
  );
};

export default RequestsTable;
