import { SliderDropdownElementProps } from "./types";

const SliderDropdownElement = ({ value }: SliderDropdownElementProps) => {
  return (
    <div>
      {value.map((i) => (
        <p key={`${i.label}-${i.scaleLabel}`}>
          {i.label} - {i.scaleLabel}
        </p>
      ))}
    </div>
  );
};

export default SliderDropdownElement;
