import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { BaseSpinnerProps } from "./types";

import "./styles.css";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";

const BaseSpinner = ({
  size,
  isFullWidth = true,
  color = "black",
}: BaseSpinnerProps) => {
  let fontSize;
  switch (size) {
    case "extra-small":
      fontSize = 16;
      break;
    case "small":
      fontSize = 24;
      break;
    case "medium":
      fontSize = 32;
      break;
    case "large":
    default:
      fontSize = 48;
      break;
  }

  return (
    <div className={`${isFullWidth ? "base-spinner" : ""}`}>
      <Spin
        indicator={
          <BaseSvgRenderer
            component={LoadingOutlined}
            style={{ fontSize }}
            fill={color}
          />
        }
      />
    </div>
  );
};

export default BaseSpinner;
