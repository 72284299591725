import React, { useEffect, useMemo } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { TFunction } from "i18next/typescript/t";
import { SearchOutlined } from "@ant-design/icons";

import { Roles, SharingRights } from "@/utilities/constants";
import { SharingRight } from "@/types/base";
import {
  useGetCurrentUserQuery,
  useLazyGetOrganizationsQuery,
  useLazyGetUsersQuery,
} from "@/redux/service";
import {
  DataBlocksFiltersProps,
  DataBlocksFiltersState,
  FilterKey,
} from "../../types/types";
import { User } from "@/views/users/types/types";
import { useDataTemplates } from "../hooks/useDataTemplates";
import { ChoiceOption } from "../../types/templates";
import { FilterField } from "@/components_v2/Filters/types";
import FilterRenderer from "@/components_v2/Filters/FilterRenderer";
import { useFilterCount } from "@/hooks/useFilterCount";
import { Organization } from "@/views/organizations/types/types";
import {
  HookFormInput,
  HookFormBaseDropdown,
  HookFormInfiniteScrollDropdown,
} from "@/components_v2/FormFields/ReactHookForm/fields";

const dataBlockFiltersSchema = (t: TFunction) =>
  yup
    .object()
    .shape({
      dataTemplates: yup
        .array()
        .of(
          yup
            .number()
            .required()
            .typeError(t("datablocks.errors.datablockTypeNotValid")),
        )
        .default([]),
      sharingRights: yup
        .array()
        .of(
          yup
            .string()
            .required()
            .typeError(t("form.validation.sharingRightsNotValid")),
        )
        .default([]),
      contributors: yup
        .array()
        .of(
          yup
            .number()
            .required()
            .typeError(t("form.validation.contributorsNotValid")),
        )
        .default([]),
      organizations: yup
        .array()
        .of(
          yup
            .number()
            .required()
            .typeError(t("form.validation.organizationsNotValid")),
        )
        .default([]),
      languages: yup
        .array()
        .of(
          yup
            .number()
            .required()
            .typeError(t("form.validation.languagesNotValid")),
        )
        .default([]),
      search: yup
        .string()
        .transform((value) => (value === "" ? undefined : value))
        .matches(/^[^<>*]+$/, t("datablocks.errors.specialCharsNotAllowed"))
        .max(100, t("datablocks.errors.searchTooLong")),
    })
    .required();

const DataBlocksFilters = ({
  onFiltersChange,
  filterNames,
  defaultFilters,
}: DataBlocksFiltersProps) => {
  const { t } = useTranslation();
  const [getUsers] = useLazyGetUsersQuery();
  const [getOrganizations] = useLazyGetOrganizationsQuery();
  const { data: currentUser } = useGetCurrentUserQuery<{ data: User }>();
  const dataTemplates = useDataTemplates();
  const form = useForm<DataBlocksFiltersState>({
    // @ts-expect-error filters state is same as schema
    resolver: yupResolver(dataBlockFiltersSchema(t)),
    mode: "onChange",
    defaultValues: {
      dataTemplates: [],
      sharingRights: [],
      contributors: [],
      organizations: [],
      languages: [],
      search: undefined,
      ...defaultFilters,
    },
  });
  const activeFiltersCount = useFilterCount(form, defaultFilters, ["search"]);

  const debouncedFiltersChange = useMemo(
    () => _.debounce((filters) => onFiltersChange(filters), 500),
    [onFiltersChange],
  );

  const dataTemplatesOptions = useMemo(() => {
    if (dataTemplates == null) return [];

    return dataTemplates.map((template) => ({
      label: template.title,
      value: template.id,
    }));
  }, [dataTemplates]);

  const languageOptions = useMemo(() => {
    if (dataTemplates == null) return [];

    const template = dataTemplates.find((template) =>
      template.templateFields.some(
        (field) => field.id === template.fieldsMapping.languages,
      ),
    );
    const templateField = template?.templateFields.find(
      (field) => field.id === template?.fieldsMapping.languages,
    );
    return templateField ? templateField.options : [];
  }, [dataTemplates]);

  const filterFields: Record<string, FilterField> = useMemo(() => {
    return {
      type: {
        name: "dataTemplates",
        isAdvancedFilter: true,
        label: t("form.labels.dataTemplateType"),
        component: (
          <HookFormBaseDropdown
            name="dataTemplates"
            control={form.control}
            options={dataTemplatesOptions}
            multiple
            placeholder={t("datablocks.placeholders.dataTemplateType")}
          />
        ),
      },
      sharingRights: {
        name: "sharingRights",
        isAdvancedFilter: true,
        label: t("form.labels.sharingRights"),
        component: (
          <HookFormBaseDropdown
            name="sharingRights"
            control={form.control}
            options={[
              SharingRights.PRIVATE,
              SharingRights.PUBLIC,
              SharingRights.PRME_COMMUNITY,
            ].map((right: SharingRight) => ({
              label: right.label,
              value: right.value,
              className: right.className,
            }))}
            multiple
            placeholder={t("sharingRights.selectPlaceholder")}
          />
        ),
      },
      ...(currentUser && {
        createdBy: {
          name: "user",
          isAdvancedFilter: false,
          component: (
            <HookFormInfiniteScrollDropdown<User>
              name="user"
              control={form.control}
              fetchFn={getUsers}
              labelFieldName="fullName"
              valueFieldName="id"
              searchFieldName="full_name"
              placeholder={t("datablocks.placeholders.createdBy")}
              defaultFilters={
                currentUser.role === Roles.ADMIN.value
                  ? {}
                  : {
                      organization: currentUser.organization.id,
                    }
              }
            />
          ),
        },
        contributors: {
          name: "contributors",
          isAdvancedFilter: true,
          label: t("form.labels.contributors"),
          component: (
            <HookFormInfiniteScrollDropdown<User>
              name="contributors"
              control={form.control}
              fetchFn={getUsers}
              labelFieldName="fullName"
              valueFieldName="id"
              searchFieldName="full_name"
              multiple
              placeholder={t("form.placeholders.contributors")}
              defaultFilters={
                currentUser.role === Roles.ADMIN.value
                  ? {}
                  : {
                      organization: currentUser.organization.id,
                    }
              }
            />
          ),
        },
      }),
      organizations: {
        name: "organizations",
        isAdvancedFilter: true,
        label: t("form.labels.signatories"),
        component: (
          <HookFormInfiniteScrollDropdown<Organization>
            name="organizations"
            control={form.control}
            fetchFn={getOrganizations}
            labelFieldName="name"
            valueFieldName="id"
            searchFieldName="name"
            multiple
            placeholder={t("datablocks.placeholders.signatories")}
          />
        ),
      },
      ...(languageOptions.length > 0 && {
        languages: {
          name: "languages",
          isAdvancedFilter: true,
          label: t("form.labels.languages"),
          component: (
            <HookFormBaseDropdown
              name="languages"
              control={form.control}
              options={[...languageOptions]
                .sort((a, b) => {
                  if (a.order && b.order) return a.order - b.order;
                  return a.id - b.id;
                })
                .map((language: ChoiceOption) => ({
                  label: language.label,
                  value: language.id,
                }))}
              multiple
              placeholder={t("form.placeholders.languages")}
            />
          ),
        },
      }),
      search: {
        name: "search",
        isAdvancedFilter: false,
        isDebounced: true,
        label: t("form.labels.searchEvidence"),
        component: (
          <HookFormInput
            name="search"
            className="data-blocks-filters-search"
            suffix={<SearchOutlined />}
            placeholder={t("datablocks.placeholders.searchEvidence")}
            control={form.control}
          />
        ),
      },
    };
  }, [dataTemplatesOptions, languageOptions, currentUser]);

  const activeFields = useMemo(() => {
    return filterNames.reduce<FilterField[]>((acc, filterName) => {
      const field = filterFields[filterName as FilterKey];
      if (field) {
        acc.push(field);
      }
      return acc;
    }, []);
  }, [filterNames]);

  useEffect(() => {
    const subscription = form.watch((formValues, { name }) => {
      if (!name) return;
      const typedFormValues = formValues as DataBlocksFiltersState;

      const debouncedFields = activeFields
        .filter((field) => field?.isDebounced)
        .map((field) => field.name);
      const nonAdvancedFilters = activeFields
        .filter((field) => !field?.isAdvancedFilter)
        .map((field) => field.name);
      if (debouncedFields.includes(name)) {
        debouncedFiltersChange(typedFormValues);
      } else if (nonAdvancedFilters.includes(name)) {
        onFiltersChange(typedFormValues);
      }
    });

    return () => {
      subscription.unsubscribe();
      debouncedFiltersChange.cancel();
    };
  }, [form, activeFields, onFiltersChange]);

  return (
    <FilterRenderer
      fields={activeFields}
      activeFilterCount={activeFiltersCount}
      popoverTitle={t("datablocks.labels.filters")}
      handleClear={() => {
        form.reset(defaultFilters);
        onFiltersChange(form.getValues());
      }}
      handleSearch={() => onFiltersChange(form.getValues())}
      className="data-blocks-filters"
    />
  );
};

export default DataBlocksFilters;
