import * as yup from "yup";
import { DateTime } from "luxon";

declare module "yup" {
  interface Schema {
    datetime(): yup.Schema<DateTime>;
  }
}

yup.addMethod<yup.AnySchema>(yup.mixed, "datetime", function () {
  return this.test("datetime", "Invalid DateTime", function (value) {
    if (value === null || value === undefined) return true;
    return value?.isLuxonDateTime;
  });
});

export default yup;
