import { FieldType } from "../types/templates";
import i18n from "../../../libraries/i18n";
import { OrganizationRoles } from "@/utilities/constants";
import { RequestStatus } from "../invites/constants";

const { t } = i18n;

export const CHOICE_FIELDS = [
  FieldType.SINGLE_CHOICE,
  FieldType.SLIDER,
  FieldType.MULTIPLE_CHOICE,
  FieldType.MULTIPLE_CHOICE_WITH_SCALE,
];

export const MULTIPLE_CHOICE_FIELDS = [
  FieldType.MULTIPLE_CHOICE,
  FieldType.MULTIPLE_CHOICE_WITH_SCALE,
];

export const RequestStatuses = {
  [RequestStatus.EXPIRED.value]: t("datablocks.requestStatus.expired"),
  [RequestStatus.PENDING.value]: t("datablocks.requestStatus.pending"),
  [RequestStatus.REVISION_NEEDED.value]: t(
    "datablocks.requestStatus.revision_needed",
  ),
  [RequestStatus.SUBMITTED.value]: t("datablocks.requestStatus.submitted"),
};

export const CAN_CREATE_REQUESTS = [
  OrganizationRoles.ADMIN.value,
  OrganizationRoles.SUPERVISOR.value,
];
