import PropTypes from "prop-types";

import { SurveyStatus } from "@/utilities/constants.jsx";
import SurveyView from "../survey-views/SurveyView.jsx";
import SurveySectionsOverview from "../active-survey-form/SurveySectionsOverview.jsx";

const SingleSurveyUser = ({ survey }) => {
  return survey.status === SurveyStatus.ACTIVE.value &&
    !survey.details?.isSubmitted ? (
    <SurveySectionsOverview survey={survey} />
  ) : (
    <SurveyView />
  );
};

SingleSurveyUser.propTypes = {
  survey: PropTypes.object.isRequired,
};

export default SingleSurveyUser;
