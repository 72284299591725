import React, { useRef, useEffect } from "react";
import { BaseSvgRendererProps } from "./types";

const BaseSvgRenderer = ({
  component: SvgComponent,
  stroke,
  fill,
  strokeWidth,
  width = "24",
  height = "24",
  ...props
}: BaseSvgRendererProps) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const paths = svgRef.current.querySelectorAll("path");

    paths.forEach((path) => {
      if (stroke) path.setAttribute("stroke", stroke);
      if (fill) path.setAttribute("fill", fill);
      if (strokeWidth)
        path.setAttribute("stroke-width", strokeWidth.toString());
    });
  }, [stroke, fill, strokeWidth, svgRef]);

  return <SvgComponent ref={svgRef} width={width} height={height} {...props} />;
};

export default BaseSvgRenderer;
