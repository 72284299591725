import { LocalStorageName } from "./constants.jsx";
import dayjs from "dayjs";
import { DateTime } from "luxon";

export const handleErrors = (errors, setError, notificationsApi) => {
  if (errors?.data && errors.data?.errors) {
    errors.data.errors.forEach((i) => {
      if (i.attr && setError) {
        setError(i.attr, { type: "custom", message: i.detail });
      } else {
        notificationsApi.error({
          message: i.detail,
        });
      }
    });
  }
};

export const isUserAuthenticated = () => {
  const tokenStorage = JSON.parse(
    window.localStorage.getItem(LocalStorageName),
  );
  if (tokenStorage?.accessExpiration && tokenStorage?.refreshExpiration) {
    return (
      new Date(tokenStorage.accessExpiration * 1000) >
        new Date().getUTCDate() ||
      new Date(tokenStorage.refreshExpiration * 1000) > new Date().getUTCDate()
    );
  }
  return false;
};

export const createUrl = (
  baseUrl,
  queryParams = {},
  excludeValues = [null, undefined],
) => {
  const params = convertToQueryParamsString(queryParams, excludeValues);

  return params === "" ? baseUrl : baseUrl + "?" + params;
};

export const convertToQueryParamsString = (queryParams, excludeValues) => {
  const newQueryParams = {};
  Object.keys(queryParams)
    .filter((key) => !excludeValues.includes(queryParams[key]))
    .map(
      (key) =>
        (newQueryParams[
          key.replace(/[A-Z]/g, (match) => "_" + match.toLowerCase())
        ] = queryParams[key]),
    );
  return new URLSearchParams(newQueryParams).toString();
};

export const convertToFormData = (
  obj,
  formData = new FormData(),
  parentKey = "",
) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const newKey = parentKey ? `${parentKey}${key}` : key;

    if (dayjs.isDayjs(value)) {
      formData.append(newKey, value.toISOString());
    } else if (value instanceof File) {
      formData.append(newKey, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        const nestedKey = `${newKey}[${index}]`;
        if (typeof item === "object" && !Array.isArray(item)) {
          convertToFormData(item, formData, nestedKey);
        } else {
          formData.append(nestedKey, item);
        }
      });
    } else if (typeof value === "object" && value != null) {
      convertToFormData(value, formData, `${newKey}.`);
    } else if (![undefined, null].includes(value)) {
      formData.append(newKey, value);
    }
  });
  return formData;
};

export const createTimeFilters = (time) => {
  const today = DateTime.now();
  let timeOffset = null;

  if (time === "day") {
    timeOffset = 1;
  } else if (time === "week") {
    timeOffset = 7;
  } else if (time === "month") {
    timeOffset = 30;
  } else if (time === "year") {
    timeOffset = 365;
  }
  if (timeOffset) {
    return {
      created_at_after: today.minus({ days: timeOffset }),
      created_at_before: today,
    };
  }
  return {};
};
