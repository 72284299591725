import { useNavigate, useParams } from "react-router-dom";
import { Spin, theme, Typography } from "antd";

import PrivateProfile from "./PrivateProfile.jsx";
import PublicProfile from "./PublicProfile.jsx";
import {
  useGetCommentQuery,
  useGetCurrentUserQuery,
  useGetTopicsQuery,
} from "../../../redux/service.js";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import createDOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import AddDocumentIcon from "@/assets/icons/reply-icon.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer.js";
const { Title, Text } = Typography;

export const ProfileCommentDetails = ({ entity }) => {
  const DOMPurify = createDOMPurify(window);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const { data: parentComment, isSuccess: isParentCommentSuccess } =
    useGetCommentQuery(entity.parent, { skip: !entity.parent });
  const { data: discussionTopics, isSuccess: isTopicsSuccess } =
    useGetTopicsQuery(
      { pagination: "off", ids: entity.topics },
      { skip: entity.topics.length === 0 },
    );

  const openUserProfile = () => {
    navigate(`/profile/${parentComment.createdBy.id}`);
  };

  return (
    <div
      style={{ borderBottom: "2px solid rgba(228, 228, 233, 1)" }}
      className="pb-2 mt-4"
    >
      <Title level={4} className="mb-2" style={{ color: token.colorTextThird }}>
        {entity.discussionTitle}
      </Title>
      {isParentCommentSuccess && (
        <div className="mb-2">
          <Text style={{ color: "rgba(139, 141, 152, 1)" }}>
            <span className="me-2">
              <BaseSvgRenderer component={AddDocumentIcon} />
            </span>
            {t("inResponseTo") + " "}
            <a onClick={openUserProfile}>{parentComment.createdBy.fullName}</a>
          </Text>
        </div>
      )}
      <div>
        <Text className="mt-5">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(entity.content, {
                USE_PROFILES: { html: true },
              }),
            }}
          />
        </Text>
        <Text
          style={{ display: "block", color: "rgba(139, 141, 152, 1)" }}
          className="mt-2"
        >
          {t("commented") + " "}
          {DateTime.fromISO(entity.createdAt).toRelative()} {t("by") + " "}
          <span style={{ color: token.colorBackgroundSecondary }}>
            {entity.createdBy.fullName}
          </span>{" "}
          {isTopicsSuccess ? (
            <>
              {t("in") + " "}
              {discussionTopics.map((i, index) => (
                <span key={i.title}>
                  <a
                    key={i.title}
                    style={{ color: "rgba(74, 105, 156, 1)" }}
                    onClick={() => navigate(`/community/topics/${i.title}`)}
                  >
                    {i.title}
                  </a>
                  {index + 1 !== discussionTopics.length && ", "}
                </span>
              ))}
            </>
          ) : null}
        </Text>
      </div>
    </div>
  );
};

ProfileCommentDetails.propTypes = {
  entity: PropTypes.object.isRequired,
};

const Profile = () => {
  const { userId } = useParams();
  const {
    data: currentUser,
    isSuccess: isCurrentUserSuccess,
    isLoading,
  } = useGetCurrentUserQuery();

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  if (isCurrentUserSuccess) {
    if (currentUser.id == userId) {
      return <PrivateProfile userId={userId} />;
    } else {
      return <PublicProfile userId={userId} />;
    }
  }
};

export default Profile;
