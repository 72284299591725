import React, { forwardRef } from "react";

import BaseCheckbox from "../Checkbox/BaseCheckbox";
import BaseInput from "../Input/BaseInput";
import BaseRichText from "../Input/BaseRichText";
import BaseTextArea from "../Input/BaseTextArea";
import BaseDropdown from "../Dropdown/BaseDropdown";
import CustomChoiceDropdown from "@/components_v2/FormFields/Dropdown/CustomChoiceDropdown";
import InfiniteScrollDropdown from "../Dropdown/InfiniteScrollDropdown";
import InfiniteScrollCustomChoiceDropdown from "../Dropdown/InfiniteScrollCustomChoiceDropdown";
import SliderDropdown from "../Dropdown/SliderDropdown";
import { createHookFormConstraintsComponent } from "../Dropdown/hocs/withConstraints";
import BaseDatePicker from "../DatePicker/BaseDatePicker";
import BaseDateRangePicker from "../DatePicker/BaseDateRangePicker";
import BaseSlider from "../Slider/BaseSlider";
import FileRepositoryUpload from "../Upload/FileRepositoryUpload";
import { createHookFormComponent } from "./hocs/withHookForm";
import { HookFormComponentProps } from "./types";
import {
  BaseInputProps,
  BaseRichTextProps,
  BaseTextAreaProps,
} from "../Input/types";
import {
  BaseDropdownProps,
  CustomChoiceDropdownProps,
  InfiniteScrollCustomChoiceDropdownProps,
  InfiniteScrollDropdownProps,
  Option,
  SliderDropdownProps,
} from "../Dropdown/types";
import { BaseCheckboxProps } from "../Checkbox/types";
import {
  BaseDatePickerHandle,
  BaseDatePickerProps,
  BaseDateRangePickerProps,
} from "../DatePicker/types";
import { BaseSliderProps } from "../Slider/types";
import { FileRepositoryUploadProps } from "../Upload/types";
import { BaseData } from "@/types/base";

export const HookFormInput = createHookFormComponent<BaseInputProps>(BaseInput);

export const HookFormRichTextInput =
  createHookFormComponent<BaseRichTextProps>(BaseRichText);

export const HookFormTextArea =
  createHookFormComponent<BaseTextAreaProps>(BaseTextArea);

export const HookFormDatePicker = forwardRef<
  BaseDatePickerHandle,
  HookFormComponentProps<BaseDatePickerProps>
>((props, ref) => {
  const Component =
    createHookFormComponent<BaseDatePickerProps>(BaseDatePicker);
  return <Component {...props} ref={ref} />;
});

export const HookFormDateRangePicker =
  createHookFormComponent<BaseDateRangePickerProps>(BaseDateRangePicker);

export const HookFormSlider =
  createHookFormComponent<BaseSliderProps>(BaseSlider);

export const HookFormCheckbox =
  createHookFormComponent<BaseCheckboxProps>(BaseCheckbox);

export const HookFormBaseDropdown =
  createHookFormConstraintsComponent<BaseDropdownProps>(BaseDropdown);

export const HookFormCustomChoiceDropdown =
  createHookFormConstraintsComponent<CustomChoiceDropdownProps>(
    CustomChoiceDropdown,
  );

export const HookFormInfiniteScrollDropdown = createHookFormComponent<
  InfiniteScrollDropdownProps<any>
>(InfiniteScrollDropdown) as <T>(
  props: HookFormComponentProps<InfiniteScrollDropdownProps<T>> &
    React.RefAttributes<any>,
) => React.ReactElement;

export const HookFormInfiniteScrollCustomChoiceDropdown =
  createHookFormComponent<InfiniteScrollCustomChoiceDropdownProps<any>>(
    InfiniteScrollCustomChoiceDropdown,
  ) as <T extends Option>(
    props: HookFormComponentProps<InfiniteScrollCustomChoiceDropdownProps<T>> &
      React.RefAttributes<any>,
  ) => React.ReactElement;

export const HookFormSliderDropdown =
  createHookFormConstraintsComponent<SliderDropdownProps>(SliderDropdown, true);

export const HookFormFileRepositoryUpload = createHookFormComponent<
  FileRepositoryUploadProps<any>
>(FileRepositoryUpload) as <T extends BaseData>(
  props: HookFormComponentProps<FileRepositoryUploadProps<T>> &
    React.RefAttributes<any>,
) => React.ReactElement;
