import { useState } from "react";
import * as yup from "yup";

import { DataTemplate } from "../../types/templates";
import { DataBlockFormFields } from "../../types/types";
import { generateValidationSchema } from "../fields/validators";
import i18n from "@/libraries/i18n";

const { t } = i18n;

export const createDataBlockSchema = () => {
  return yup
    .object()
    .shape({
      dataTemplate: yup
        .number()
        .typeError(t("datablocks.errors.dataTemplateNotValid"))
        .required(t("datablocks.errors.dataTemplateRequired")),
      organization: yup
        .number()
        .typeError(t("datablocks.errors.required"))
        .required(t("datablocks.errors.required")),
      sharingRights: yup
        .string()
        .typeError(t("datablocks.errors.required"))
        .required(t("datablocks.errors.required")),
      request: yup.number().typeError(t("datablocks.errors.required")),
      fieldValues: yup
        .array()
        .of(
          yup.object().shape({
            field: yup.number(),
            value: yup.mixed(),
          }),
        )
        .optional(),
    })
    .required();
};

export const useDataBlockValidation = () => {
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<DataBlockFormFields>
  >(
    // @ts-expect-error schema
    createDataBlockSchema(),
  );

  const updateSchemaForTemplate = (template: DataTemplate) => {
    const templateSchema = generateValidationSchema(
      template.templateFields,
      template.validationRules,
      t,
    );
    const newSchema = createDataBlockSchema().concat(templateSchema);
    setValidationSchema(newSchema);
    return newSchema;
  };

  return {
    validationSchema,
    updateSchemaForTemplate,
  };
};
