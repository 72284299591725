import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Col, Divider, Row, Typography } from "antd";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import PrimaryButton from "../../../components/PrimaryButton.jsx";
import { SurveyType } from "../../../utilities/constants.jsx";

const { Title, Text } = Typography;

const OverdueSurveysReminder = ({ surveys, setIsVisible }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row
      className="mb-5 py-3 px-4"
      style={{ backgroundColor: "rgba(255, 191, 1, 0.19)" }}
    >
      <Col sm={1} md={1} xl={1}>
        <InfoCircleOutlined
          className="ms-1 me-3"
          style={{ fontSize: "18px", color: "rgba(120, 50, 0, 0.81)" }}
        />
      </Col>
      <Col sm={22} md={22} xl={22}>
        <div className="mb-3">
          <Title
            level={5}
            style={{ color: "rgba(120, 50, 0, 0.81)", fontWeight: "bold" }}
          >
            {t("reportSubmissionOverdue")}
          </Title>
        </div>
        {surveys.map((survey, index) => (
          <div key={survey.id}>
            <div>
              <Title
                style={{ display: "inline", color: "rgba(120, 50, 0, 0.812)" }}
                level={5}
              >
                {survey.title}
              </Title>
              <Text style={{ color: "rgba(120, 50, 0, 0.812)" }}>
                {" "}
                -{" "}
                {t(
                  survey.type === SurveyType.I5.value
                    ? "i5SurveyOverdueReminder"
                    : "sipSurveyOverdueReminder",
                )}
              </Text>
            </div>
            <div className="mt-2">
              <PrimaryButton onClick={() => navigate(`/reports/${survey.id}`)}>
                {t("buttons.continueReport")}
              </PrimaryButton>
            </div>
            {surveys.length !== index + 1 && (
              <Divider
                style={{
                  borderBlockStart: "1px solid rgba(120, 50, 0, 0.812)",
                }}
              />
            )}
          </div>
        ))}
      </Col>
      <Col sm={1} md={1} xl={1}>
        <CloseOutlined
          onClick={() => setIsVisible(false)}
          style={{ cursor: "pointer", color: "rgba(120, 50, 0, 0.812)" }}
        />
      </Col>
    </Row>
  );
};

OverdueSurveysReminder.propTypes = {
  setIsVisible: PropTypes.func.isRequired,
  surveys: PropTypes.array.isRequired,
};

export default OverdueSurveysReminder;
