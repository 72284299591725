import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { App, Flex } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

import {
  useCreateDataBlockMutation,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
} from "@/redux/service";
import BaseButton from "../../../../components_v2/Button/BaseButton";
import BaseModal from "../../../../components_v2/Modal/BaseModal";
import "./styles.css";
import DataBlockTypeStep from "./DataBlockTypeStep";
import DataBlockDetailsForm from "./DataBlockDetailsForm";
import {
  CreateDataBlockModalFooterProps,
  CreateDataBlockProps,
} from "../../types/types";
import CancelConfirmationModal from "../../../../components_v2/Modal/CancelConfirmationModal";
import { DataTemplate } from "../../types/templates";
import { useDataBlockSubmission } from "../hooks/useDataBlockSubmission";
import { useDataBlockValidation } from "../hooks/useDataBlockValidation";
import { useDataBlockForm } from "../hooks/useDataBlockForm";
import { DataBlockProvider } from "../providers/DataBlockProvider";
import { DynamicValue } from "../../types/blocks";
import { useEnabledDataTemplates } from "../hooks/useDataTemplates";
import { handleErrors } from "@/utilities";
import { BaseUser } from "@/views/users/types/types";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";

const ModalFooter = ({
  currentStep,
  handleBack,
  handleNext,
  isNextEnabled,
  isSubmissionStarted,
}: CreateDataBlockModalFooterProps) => {
  const { t } = useTranslation();

  return (
    <Flex wrap justify="space-between" align="center" gap={"small"}>
      <div>
        {currentStep > 0 && (
          <BaseButton variant="tertiary" size="large" onClick={handleBack}>
            {t("buttons.back")}
          </BaseButton>
        )}

        {currentStep === 0 && (
          <BaseButton
            variant="tertiary"
            size="large"
            leadingIcon={<InfoCircleOutlined />}
            onClick={() => window.open("/evidence/documentation", "_blank")}
          >
            {t("datablocks.labels.learnMore")}
          </BaseButton>
        )}
      </div>

      <div className="create-datablock-footer-buttons-group">
        <BaseButton
          onClick={() => handleNext(true)}
          loading={isSubmissionStarted}
          leadingIcon={
            isSubmissionStarted ? (
              <BaseSpinner size="extra-small" color="white" />
            ) : undefined
          }
          disabled={
            (currentStep === 0 && !isNextEnabled) || isSubmissionStarted
          }
        >
          {t(
            currentStep === 0
              ? "buttons.nextStep"
              : "datablocks.labels.saveDataBlock",
          )}
        </BaseButton>
      </div>
    </Flex>
  );
};

const GuidedFirstStep = ({
  templateTitle,
  message,
  createdBy,
  date,
  error,
}: {
  templateTitle: string;
  message?: string;
  error?: { message: string };
  createdBy?: BaseUser;
  date?: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className="guided-step-container">
      <div className="guided-step-container-info-container">
        <div className="guided-step-container-info">
          <p className="body-m-regular">{t("datablocks.labels.from")}</p>
          <p className="body-m-medium">{createdBy?.fullName}</p>
        </div>
        <div className="guided-step-container-info">
          <p className="body-m-regular">{t("datablocks.labels.expiresOn")}</p>
          <p className="body-m-medium">{date?.slice(0, 10)}</p>
        </div>
        <div className="guided-step-container-info">
          <p className="body-m-regular">
            {t("datablocks.labels.dataTemplateType")}
          </p>
          <p className="body-m-medium">{templateTitle}</p>
        </div>
      </div>

      <h6 className="body-m-medium">{t("datablocks.labels.description")}</h6>
      <p className="body-s-regular">{message}</p>
      {error && <div className="error-message">{error.message}</div>}
    </div>
  );
};

const CreateDataBlockContent = ({
  open,
  setOpen,
  onSubmit,
  filters,
  defaultValues,
}: CreateDataBlockProps) => {
  const { t } = useTranslation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: languages } = useGetLanguagesQuery({});
  const [createDataBlock] = useCreateDataBlockMutation();
  const { isSubmissionStarted, handleSubmit } =
    useDataBlockSubmission(currentUser);
  const { validationSchema, updateSchemaForTemplate } =
    useDataBlockValidation();
  const { form, initializeFormFieldValues } =
    useDataBlockForm(validationSchema);
  const enabledTemplates = useEnabledDataTemplates(filters?.dataTemplates);
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { notification } = App.useApp();

  const selectedTemplateId = form.watch("dataTemplate");

  const setCurrentStepDependingOnDefaultValues = () => {
    if (defaultValues?.dataTemplate && !defaultValues.request) {
      setCurrentStep(1);
    }
  };

  useEffect(() => {
    if (open) {
      setCurrentStepDependingOnDefaultValues();
    }
  }, [open]);

  useEffect(() => {
    if (defaultValues) {
      setCurrentStepDependingOnDefaultValues();
      form.reset({
        dataTemplate: defaultValues?.dataTemplate,
        request: defaultValues?.request?.id,
      });
    }
  }, [defaultValues]);

  const activeTemplate = useMemo(() => {
    if (enabledTemplates && selectedTemplateId) {
      return enabledTemplates.find(
        (i: DataTemplate) => i.id === selectedTemplateId,
      );
    }
  }, [enabledTemplates, selectedTemplateId]);

  const setDefaultValues = () => {
    const setValueToField = (
      fieldId: number,
      value: DynamicValue | DateTime,
    ) => {
      const fieldValues = form.getValues("fieldValues") || [];

      const existingFieldIndex = fieldValues.findIndex(
        (field) => field.field === fieldId,
      );
      const existingField = fieldValues[existingFieldIndex];
      if (existingField) {
        // @ts-expect-error datetime is an option for the value
        existingField.value = value;
      }
      return fieldValues;
    };

    if (currentUser && activeTemplate && languages) {
      const contributorsField = activeTemplate.templateFields.find(
        (field) => field.id === activeTemplate.fieldsMapping.contributors,
      );
      const languageField = activeTemplate.templateFields.find(
        (field) => field.id === activeTemplate.fieldsMapping.languages,
      );
      const publishDateField = activeTemplate.templateFields.find(
        (field) => field.id === activeTemplate.fieldsMapping.publishDate,
      );
      let newFieldValues;
      if (contributorsField) {
        newFieldValues = setValueToField(contributorsField.id, [
          currentUser.id,
        ]);
      }
      const languageCode = currentUser.organization.language || undefined;
      if (languageField && languageCode) {
        const languageName = languages[languageCode];
        const choiceId = languageField.options.find(
          (option) => option.label === languageName,
        );
        if (choiceId) {
          newFieldValues = setValueToField(languageField.id, [choiceId.id]);
        }
      }
      if (publishDateField) {
        newFieldValues = setValueToField(publishDateField.id, DateTime.now());
      }
      if (newFieldValues) form.setValue("fieldValues", newFieldValues);
    }
  };

  useEffect(() => {
    if (
      currentStep === 1 &&
      activeTemplate &&
      enabledTemplates &&
      currentUser
    ) {
      updateSchemaForTemplate(activeTemplate);
      initializeFormFieldValues(activeTemplate.templateFields);
      setDefaultValues();
    }
  }, [currentStep, activeTemplate, currentUser, enabledTemplates]);

  const renderSteps = useMemo(() => {
    if (!enabledTemplates) return;
    if (currentStep === 0) {
      if (defaultValues?.dataTemplate && defaultValues.request) {
        const template = enabledTemplates.find(
          (template) => template.id === defaultValues.dataTemplate,
        );
        if (template) {
          return (
            <GuidedFirstStep
              templateTitle={template.title}
              message={defaultValues.request?.message}
              createdBy={defaultValues.request?.createdBy}
              date={defaultValues?.request?.expiresAt}
            />
          );
        }
      }

      return (
        <DataBlockTypeStep
          templates={enabledTemplates}
          activeTemplateId={activeTemplate?.id}
          error={
            form.formState.errors?.dataTemplate?.message
              ? { message: form.formState.errors?.dataTemplate.message }
              : undefined
          }
          handleTemplateChange={(selectedTemplate: number) =>
            form.setValue("dataTemplate", selectedTemplate)
          }
        />
      );
    }

    if (currentStep === 1 && activeTemplate) {
      return <DataBlockDetailsForm dataTemplate={activeTemplate} />;
    }
  }, [
    enabledTemplates,
    form.formState.errors,
    activeTemplate,
    currentStep,
    defaultValues,
  ]);

  const modalTitle = useMemo(() => {
    if (currentStep === 0) {
      return t("datablocks.labels.createDataBlock");
    }
    return activeTemplate
      ? `${t("datablocks.labels.createDataBlock")} - ${activeTemplate.title}`
      : "";
  }, [currentStep, activeTemplate, t]);

  const resetModalHeight = () => {
    if (containerRef.current) {
      const modalBody = containerRef.current.querySelector(".ant-modal-body");
      if (modalBody) {
        modalBody.scrollTop = 0;
      }
    }
  };

  const handleNext = async () => {
    const isValid = await form.trigger(
      currentStep === 0 ? ["dataTemplate"] : ["sharingRights", "fieldValues"],
    );
    if (!isValid || !currentUser || isSubmissionStarted) return;

    if (currentStep === 0) {
      setCurrentStep((prev) => prev + 1);
      resetModalHeight();
      return;
    }
    await handleSubmit(
      form.getValues(),
      (data) => createDataBlock(data).unwrap(),
      (result) => {
        notification.success({
          message: t("datablocks.labels.dataBlockSaved"),
        });
        setOpen(false);
        form.reset({
          dataTemplate: undefined,
        });
        if (onSubmit) {
          onSubmit(result);
        }
        setCurrentStep(0);
      },
      (errors) => {
        handleErrors(errors, form.setError, notification);
      },
    );
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      resetModalHeight();
      form.reset({
        dataTemplate: selectedTemplateId,
        sharingRights: undefined,
        fieldValues: [],
      });
    }
  };

  return (
    <>
      <BaseModal
        classNames={{
          wrapper: "create-datablocks-modal",
        }}
        width={1000}
        open={open}
        maskClosable={false}
        onCancel={() => setConfirmCloseModal(true)}
        title={modalTitle}
        subtitle={
          currentStep === 0 && !defaultValues?.dataTemplate
            ? t("datablocks.labels.createBlockSubtitle")
            : undefined
        }
        footer={
          <ModalFooter
            currentStep={currentStep}
            handleBack={handleBack}
            handleNext={handleNext}
            isNextEnabled={selectedTemplateId != null}
            isSubmissionStarted={isSubmissionStarted}
          />
        }
        containerRef={containerRef}
      >
        <FormProvider {...form}>
          <div className="steps-container">{renderSteps}</div>
        </FormProvider>
      </BaseModal>

      <CancelConfirmationModal
        title={t("discardChanges")}
        content={t("discardChangesContent")}
        confirmButtonText={t("buttons.discard")}
        open={confirmCloseModal}
        onCancel={() => setConfirmCloseModal(false)}
        onConfirm={() => {
          setConfirmCloseModal(false);
          setOpen(false);
          setCurrentStep(0);
          form.reset({});
        }}
      />
    </>
  );
};

const CreateDataBlock = (props: CreateDataBlockProps) => {
  return (
    <DataBlockProvider>
      <CreateDataBlockContent {...props} />
    </DataBlockProvider>
  );
};

export default CreateDataBlock;
