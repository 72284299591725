import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";

import { useEnabledDataTemplates } from "../../core/hooks/useDataTemplates";
import FormFieldWrapper from "@/components_v2/Wrapper/FormFieldWrapper";
import BaseSwitch from "@/components_v2/FormFields/Switch/BaseSwitch";
import BaseFormFieldsList from "@/components_v2/FormFieldsList/BaseFormFieldsList";
import BaseStepper from "@/components_v2/Stepper/BaseStepper";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import { useGetCurrentUserQuery, useLazyGetUsersQuery } from "@/redux/service";
import {
  DataBlockRequestFormItem,
  DataBlockRequestFormProps,
} from "../../types/types";
import { Option } from "@/components_v2/FormFields/Dropdown/types";
import { User } from "@/views/users/types/types";
import {
  HookFormTextArea,
  HookFormBaseDropdown,
  HookFormInfiniteScrollCustomChoiceDropdown,
  HookFormDatePicker,
} from "@/components_v2/FormFields/ReactHookForm/fields";
import { getRolesByPermissionLevel } from "@/utilities/roles";
import { BaseDatePickerHandle } from "@/components_v2/FormFields/DatePicker/types";
import { OrganizationRoles } from "@/utilities/constants";
import BaseButton from "@/components_v2/Button/BaseButton";

const RequestForm = ({ formHook }: { formHook: DataBlockRequestFormProps }) => {
  const { t } = useTranslation();
  const activeTemplates = useEnabledDataTemplates();
  const [selectedUsers, setSelectedUsers] = useState<
    Record<string, string | undefined>
  >({});
  const [getUsers] = useLazyGetUsersQuery();
  const selectedUsersRef = useRef({});
  const datePickerRef = useRef<BaseDatePickerHandle>(null);
  const { data: currentUser } = useGetCurrentUserQuery();

  const {
    form,
    currentStep,
    showIndividualMessage,
    handleShowIndividualMessageChange,
  } = formHook;

  useEffect(() => {
    selectedUsersRef.current = selectedUsers;
    const values = form.getValues();
    (values?.requests || []).forEach(
      (request: DataBlockRequestFormItem, index: number) => {
        const email = request.email as string;
        const role = selectedUsers[email];
        if (email && role) {
          form.setValue(`requests.${index}.role`, role);
          form.clearErrors(`requests.${index}.role`);
        }
      },
    );
  }, [selectedUsers]);

  const getIsRoleDisabled = useCallback(
    (index: number) => {
      const values = form.getValues();
      const selectedItem = (values?.requests || [])[index];
      return selectedItem && selectedItem.email in selectedUsersRef.current;
    },
    [form],
  );

  const handleChangeSelectedUsers = (option: Option | (User & Option)) => {
    const role =
      "organizationRole" in option ? option.organizationRole : undefined;
    if (role) {
      setSelectedUsers((prevValue) => ({
        ...prevValue,
        [option.value]: OrganizationRoles.getLabel(role),
      }));
    }
  };

  const handleEndOfDayClick = () => {
    // Work around to set the current date manually since otherwise the previous value will overwrite it
    if (datePickerRef.current) {
      datePickerRef.current.close();
    }
    setTimeout(() => {
      form.setValue("expiresAt", DateTime.now().endOf("day"));
    }, 50);
  };

  const fields = useMemo(() => {
    return [
      ...(currentUser
        ? [
            {
              name: "email",
              component: (
                <HookFormInfiniteScrollCustomChoiceDropdown<User & Option>
                  name="email"
                  className="data-block-request-form-email-dropdown"
                  control={form.control}
                  fetchFn={getUsers}
                  labelFieldName={"fullName"}
                  valueFieldName={"email"}
                  searchFieldName={"fullName"}
                  defaultFilters={{
                    organization: currentUser.organization.id,
                    exclude: currentUser.id,
                  }}
                  placeholder={t("datablocks.placeholders.email")}
                  onChangeCallback={handleChangeSelectedUsers}
                  customChoiceSchema={yup
                    .string()
                    .email(t("datablocks.errors.emailNotValid"))
                    .required(t("form.validation.itemRequired"))
                    .typeError(t("form.validation.itemNotValid"))}
                  aria-autocomplete="none"
                />
              ),
            },
            {
              name: "role",
              component: (
                <HookFormBaseDropdown
                  name="role"
                  control={form.control}
                  options={getRolesByPermissionLevel(
                    currentUser?.organizationRole,
                  )}
                  placeholder={t("datablocks.placeholders.role")}
                  aria-autocomplete="none"
                />
              ),
              disabled: getIsRoleDisabled,
            },
          ]
        : []),
      ...(showIndividualMessage
        ? [
            {
              name: "message",
              component: (
                <HookFormTextArea
                  name="email"
                  control={form.control}
                  placeholder={t("datablocks.placeholders.message")}
                />
              ),
            },
          ]
        : []),
    ];
  }, [currentUser, form, showIndividualMessage]);

  const defaultRequestMessage = useMemo(() => {
    if (!currentUser) return null;
    return t("datablocks.labels.dataBlockRequestDefaultMessage", {
      organizationName:
        currentUser.organization.name || t("datablocks.labels.ourInstitution"),
    });
  }, [currentUser]);

  useEffect(() => {
    if (defaultRequestMessage && !form.getValues().message) {
      form.setValue("message", defaultRequestMessage);
    }
  }, [currentStep, defaultRequestMessage]);

  const RequestSteps = useMemo(() => {
    if (!activeTemplates) return [];

    return [
      <div className="data-block-request-form-details-step">
        <FormFieldWrapper
          label={t("datablocks.labels.dataTemplateType")}
          description={t("datablocks.labels.evidenceTypeHelpText")}
          error={form.formState.errors.dataTemplate}
          required
        >
          <HookFormBaseDropdown
            name="dataTemplate"
            control={form.control}
            options={activeTemplates.map((template) => ({
              label: template.title,
              value: template.id,
            }))}
            placeholder={t("datablocks.placeholders.selectTemplate")}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          label={t("datablocks.labels.expiryDate")}
          error={form.formState.errors.expiresAt}
          required
        >
          <HookFormDatePicker
            ref={datePickerRef}
            name="expiresAt"
            control={form.control}
            disabledDate={(current) =>
              current && current < DateTime.now().startOf("day")
            }
            showNow={false}
            renderExtraFooter={() => (
              <BaseButton
                variant="link"
                isFullWidth
                onClick={handleEndOfDayClick}
              >
                {t("datablocks.labels.endOfDay")}
              </BaseButton>
            )}
          />
        </FormFieldWrapper>
      </div>,
      <div className="data-block-request-form-emails-step">
        <div className="data-block-request-form-emails-step-switch">
          <BaseSwitch
            value={showIndividualMessage}
            onChange={handleShowIndividualMessageChange}
          />
          <h6 className="body-s-regular">
            {t("datablocks.labels.customizeInviteMessages")}
          </h6>
        </div>

        <BaseFormFieldsList
          title={t("datablocks.labels.emails")}
          description={t("datablocks.labels.invitesDescription")}
          name="requests"
          form={form}
          fields={fields}
          addButtonText={t("datablocks.labels.addNewUser")}
          defaultValues={{ message: defaultRequestMessage }}
        />
        {!showIndividualMessage && (
          <HookFormTextArea
            name="message"
            placeholder={t("datablocks.placeholders.message")}
            control={form.control}
          />
        )}
      </div>,
    ];
  }, [activeTemplates, defaultRequestMessage, showIndividualMessage]);

  return (
    <FormProvider {...form}>
      <div className="data-block-request-form">
        <BaseStepper
          current={currentStep}
          items={[
            {
              title: t("datablocks.labels.inviteDetails"),
            },
            {
              title: t("datablocks.labels.inviteEmails"),
            },
          ]}
        />
        {!activeTemplates && <BaseSpinner />}
        {RequestSteps[currentStep]}
      </div>
    </FormProvider>
  );
};

export default RequestForm;
