import { useMemo } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import createDOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";

import { DataBlockReferencesProps, ReferencesTypes } from "../../types/types";
import { DataBlockReference } from "../../types/blocks";
import { useGetDataBlockReferencesQuery } from "@/redux/service";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip";
import ChaptersIcon from "@/assets/icons/chapters.svg?react";
import ChatIcon from "@/assets/icons/chat.svg?react";
import ReportsIcon from "@/assets/icons/reports-icon.svg?react";
import AnalysisIcon from "@/assets/icons/analysis-icon.svg?react";

const DataBlockReferences = ({ dataBlock }: DataBlockReferencesProps) => {
  const { t } = useTranslation();
  const DOMPurify = createDOMPurify(window);
  const navigate = useNavigate();

  const {
    data: references,
    isFetching,
    isSuccess,
  } = useGetDataBlockReferencesQuery(dataBlock.id);

  const referencesTypes: ReferencesTypes = {
    surveys: {
      title: t("datablocks.labels.surveys"),
      icon: ReportsIcon,
      getLink: (value: DataBlockReference) => `/reports/${value.id}`,
    },
    surveyViews: {
      title: t("datablocks.labels.surveyViews"),
      icon: AnalysisIcon,
      getLink: (value: DataBlockReference) =>
        `/reports/${value.surveyId}/report?view=${value.uuid}`,
    },
    discussions: {
      title: t("datablocks.labels.discussions"),
      icon: ChatIcon,
      getLink: (value: DataBlockReference) => `/discussons/${value.id}`,
    },
    comments: {
      title: t("datablocks.labels.comments"),
      icon: ChaptersIcon,
      getLink: (value: DataBlockReference) =>
        `/discussons/${value.discussionId}`,
    },
  };

  const noReferencesExist = useMemo(() => {
    if (!references) return true;

    return Object.values(references)
      .map((entry) => entry.length === 0)
      .every(Boolean);
  }, [references]);

  const referencesData = useMemo(() => {
    if (isSuccess) {
      if (noReferencesExist) {
        return (
          <div className="body-s-regular">
            {t("datablocks.labels.noReferencesExist")}
          </div>
        );
      } else {
        return Object.entries(references).map(
          ([referenceType, referenceDetails]) => {
            const referenceTypeDetails =
              referencesTypes[referenceType as keyof ReferencesTypes];
            if (!referenceDetails) return;

            return (
              referenceDetails.length > 0 && (
                <div key={referenceType}>
                  <div className="data-block-reference-label-container">
                    <referenceTypeDetails.icon />
                    <h6 className="body-s-medium">
                      {referenceTypeDetails.title}
                    </h6>
                  </div>

                  <ul className="data-block-reference-list">
                    {referenceDetails.map((value: DataBlockReference) => (
                      <li key={value.label}>
                        <a
                          className="data-block-reference-list-item"
                          onClick={() =>
                            navigate(referenceTypeDetails.getLink(value))
                          }
                        >
                          {referenceType === "comments" ? (
                            <div
                              style={{ wordWrap: "break-word" }}
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  _.truncate(value.label, { length: 80 }),
                                  {
                                    USE_PROFILES: { html: true },
                                  },
                                ),
                              }}
                            />
                          ) : (
                            value.label
                          )}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            );
          },
        );
      }
    }
  }, [references, noReferencesExist]);

  return (
    <div className="data-block-sidebar-references">
      <div className="data-block-sidebar-references-header">
        <h2 className="header-m-medium">{t("datablocks.labels.references")}</h2>
        <BaseTooltip title={t("datablocks.labels.referencesInfo")}>
          <InfoCircleOutlined />
        </BaseTooltip>
      </div>
      <div className="data-block-sidebar-references-items">
        {isFetching && <BaseSpinner size="small" />}
        {isSuccess && referencesData}
      </div>
    </div>
  );
};

export default DataBlockReferences;
