import { To, useNavigate } from "react-router-dom";

import { TagsElementProps } from "./types";
import BaseTag from "../Tags/BaseTag";

export const TagsElement = ({ value }: TagsElementProps) => {
  const navigate = useNavigate();

  if (!value) {
    return null;
  }

  const handleClick = (link: string | undefined) => {
    if (link) {
      navigate(link as To);
    }
  };

  return (
    <div>
      {value.map((i) => (
        <BaseTag
          key={i.label}
          label={i.label}
          onClick={i.link ? () => handleClick(i.link) : undefined}
        />
      ))}
    </div>
  );
};
