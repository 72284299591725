import React from "react";

import { Tag } from "antd";
import { BaseTagProps } from "./types";

import "./styles.css";

const BaseTag = ({
  label,
  className,
  size = "large",
  icon,
  color,
  ...props
}: BaseTagProps) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      onMouseDown={onPreventMouseDown}
      className={`body-s-regular base-tag base-tag-${size} ${className ? `${className}-tag` : ""}`}
      style={{ backgroundColor: `${color}33`, borderColor: color }}
      icon={icon}
      {...props}
    >
      {label}
    </Tag>
  );
};

export default BaseTag;
