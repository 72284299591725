import { Space } from "antd";

import { BaseAvatarGroupProps, CollapsedAvatarGroupLabel } from "./types";

import BaseAvatar from "./BaseAvatar";

import "./styles.css";
import BaseTooltip from "../Tooltip/BaseTooltip";

const CollapsedAvatars = <T extends CollapsedAvatarGroupLabel>({
  AvatarComponent,
  avatars,
}: BaseAvatarGroupProps<T>) => {
  const tooltipContent = (
    <Space>
      {avatars.map((avatar) => {
        return (
          <div key={avatar.label}>
            <AvatarComponent {...avatar} tooltip={undefined} />
            <span>{avatar.label}</span>
          </div>
        );
      })}
    </Space>
  );

  return (
    <BaseTooltip title={tooltipContent}>
      <BaseAvatar label={`+${avatars.length}`} />
    </BaseTooltip>
  );
};

const BaseAvatarGroup = <T extends CollapsedAvatarGroupLabel>({
  AvatarComponent,
  avatars,
  maxVisible = 2,
}: BaseAvatarGroupProps<T>) => {
  const visibleAvatars = avatars.slice(0, maxVisible);
  const hiddenAvatars = avatars.slice(maxVisible);
  const showCollapsed = hiddenAvatars.length > 0;

  return (
    <div>
      <div className="base-avatar-group">
        {visibleAvatars.map((avatar, index) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { label, ...newAvatarProps } = avatar;

          return <AvatarComponent key={index} {...newAvatarProps} />;
        })}
        {showCollapsed && (
          <CollapsedAvatars
            AvatarComponent={AvatarComponent}
            avatars={hiddenAvatars}
          />
        )}
      </div>
    </div>
  );
};

export default BaseAvatarGroup;
