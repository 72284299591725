import { BasePaginationQueryParams } from "@/types/pagination";
import { DynamicValue } from "./blocks";

export enum FieldType {
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  RICH_TEXT = "RICH_TEXT",
  NUMBER = "NUMBER",
  CHECKBOX = "CHECKBOX",
  DATE = "DATE",
  DATE_RANGE = "DATE_RANGE",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  MULTIPLE_CHOICE_WITH_SCALE = "MULTIPLE_CHOICE_WITH_SCALE",
  SLIDER = "SLIDER",
}

export enum ValidationRuleTypes {
  REQUIRED = "REQUIRED",
  EITHER_REQUIRED = "EITHER_REQUIRED",
  DEPENDENT_REQUIRED = "DEPENDENT_REQUIRED",
  MIN_LENGTH = "MIN_LENGTH",
  MAX_LENGTH = "MAX_LENGTH",
  ALLOWED_FILE_TYPES = "ALLOWED_FILE_TYPES",
  MAX_FILE_SIZE = "MAX_FILE_SIZE",
  REGEX = "REGEX",
}

export enum ContentType {
  Options = "options",
  Users = "users",
  Files = "files",
  Subjects = "subjects",
}

export type FieldMappingKeys =
  | "title"
  | "contributors"
  | "languages"
  | "publishDate";

export interface ChoiceOption {
  id: number;
  label: string;
  order?: number;
  disabled: boolean;
  category: string;
  relatedOptions: ChoiceOption[];
}

export interface OptionConstraint {
  id: number;
  affectsMainOptions: boolean;
  parentField: number;
  parentOption: number;
  allowedChildOptions: number[];
}

export interface DataField {
  id: number;
  title: string;
  type: FieldType;
  hasOther: boolean;
  disabled: boolean;
  placeholder?: string;
  description: string | null;
  helpText?: string;
  sliderHelpText?: string; // Temp field
  order: number;
  guide?: string;
  options: ChoiceOption[];
  constraints?: OptionConstraint[];
  contentType?: ContentType;
  rules?: ValidationRule[];
}

export interface ValidationRule {
  id: number;
  type: ValidationRuleTypes;
  ruleFields: number[];
  value?: DynamicValue;
  errorMessage: string;
  dependentOn: number | null;
}

export interface DataTemplate {
  id: number;
  title: string;
  shortDescription: string;
  fullDescription: string;
  disabled: boolean;
  estimatedCompletionTime: number | null;
  templateFields: DataField[];
  fieldsMapping: Record<FieldMappingKeys, number>;
  validationRules: ValidationRule[];
  icon?: string;
  color?: string;
}

export interface MinDataTemplate {
  id: number;
  title: string;
}

export interface DataTemplatesQueryParams extends BasePaginationQueryParams {
  disabled?: boolean;
}

export interface ChoiceOptionsQueryParams extends BasePaginationQueryParams {
  ids?: number[];
}
