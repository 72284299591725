import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import { App as AntdApp, ConfigProvider } from "antd";
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Provider } from "react-redux";
import {
  captureConsoleIntegration,
  extraErrorDataIntegration,
} from "@sentry/integrations";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ErrorBoundary } from "@sentry/react";
import { isAxiosError } from "axios";
import { ResponsiveProvider } from "./hooks/useBreakpoints";

import router from "./router/Router.jsx";
import store from "./redux/store";

import "./assets/styles/tokens/colors.css";
import "./assets/styles/tokens/spacing.css";
import "./assets/styles/tokens/typography.css";
import "./assets/styles/tokens/radius.css";
import "./assets/styles/tokens/shadows.css";
import "./assets/styles/tokens/layout.css";
import "@/assets/styles/styles/typography.css";

Sentry.init({
  dsn: "https://a1cb7d2ad932086c2e131af8f8b43189@o4506655293767680.ingest.sentry.io/4506690611380224",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    captureConsoleIntegration({ levels: ["error"] }),
    extraErrorDataIntegration({ depth: 20 }),
  ],
  enabled: import.meta.env.VITE_ENV !== "local",
  environment: import.meta.env.VITE_ENV,
  tracesSampleRate: 0.2,
  tracePropagationTargets: [import.meta.env.VITE_DOMAIN],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: handleSentryBeforeSend,
});

function handleSentryBeforeSend(event, hint) {
  if (hint?.originalException) {
    if (isAxiosError(hint.originalException)) {
      if (hint.originalException?.response?.status === 401) return null;

      addAxiosContext(event, hint.originalException);
    }
  }
  return event;
}

function addAxiosContext(event, error) {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
}

const App = () => (
  <ErrorBoundary>
    <HelmetProvider>
      <Provider store={store}>
        <Helmet>
          <title>PRME Commons</title>
          <meta name="title" content="PRME Commons" />
          <meta
            name="description"
            content="A digital platform enabling business schools to track, share, and enhance their sustainability practices through collaborative reporting aligned with UN PRME principles."
          />
          <meta
            name="keywords"
            content="PRME Commons, responsible management education, sustainability reporting, business schools, UN initiative, UNGC, Unite Nations, Global Compact, SIP reports, collaborative platform, SDGs, sustainable development"
          />
          <meta property="og:title" content="PRME Commons" />
          <meta
            property="og:description"
            content="Join over 800 business schools worldwide in sharing best practices, curricula innovations, and sustainability initiatives through dynamic reporting and knowledge exchange."
          />
          <meta
            property="og:image"
            content="https://prmecommons.org/assets/prme-logo.png"
          />
          <meta name="application-name" content="PRME Commons" />
          <meta name="apple-mobile-web-app-title" content="PRME Commons" />
          <meta
            name="author"
            content="Principles for Responsible Management Education (PRME)"
          />
          <meta name="copyright" content="© PRME" />
        </Helmet>
        <AntdApp>
          <ConfigProvider
            theme={{
              token: {
                colorPrimaryText: "#1E3250",
                colorAsterisk: "rgba(30, 50, 80, 0.39)",
                colorTextThird: "rgba(30, 50, 80, 1)",
                backgroundColor: "#1E3250",
                colorTextSecondary: "#8B8D98",
                colorBackgroundSecondary: "#4A699C",
                linkColor: "#5B92E5",
              },
              components: {
                Layout: {
                  bodyBg: "#fff",
                  footerBg: "#fff",
                  headerBg: "#fff",
                  siderBg: "#fff",
                  triggerBg: "#1E3250",
                },
                Tag: {
                  borderRadius: 0,
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                },
                Menu: {
                  itemColor: "#1E3250",
                  fontSize: 16,
                },
                Popover: {
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                },
                Select: {
                  borderRadius: 0,
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                },
                Typography: {
                  titleMarginBottom: 0,
                  titleMarginTop: 0,
                  fontFamily: "Roboto",
                },
                Avatar: {
                  borderRadius: 0,
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                },
                Spin: {
                  colorPrimary: "#1E3250",
                  dotSize: 800,
                },
                Modal: {
                  borderRadius: 0,
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                },
                Tabs: {
                  cardBg: "white",
                  fontSize: 16,
                  colorBorder: "white",
                  borderRadius: 0,
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                  itemColor: "rgba(0, 7, 19, 0.624)",
                  itemSelectedColor: "white",
                  itemHoverColor: "rgba(91, 146, 229, 1)",
                },
                Notification: {
                  borderRadius: 0,
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                },
                Collapse: {
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                  lineWidth: 0,
                },
                List: {
                  colorBorder: 0,
                },
                Tooltip: {
                  borderRadius: 0,
                  borderRadiusLG: 0,
                  borderRadiusXS: 0,
                  borderRadiusSM: 0,
                },
              },
            }}
          >
            <ResponsiveProvider>
              <RouterProvider router={router} />
            </ResponsiveProvider>
          </ConfigProvider>
        </AntdApp>
      </Provider>
    </HelmetProvider>
  </ErrorBoundary>
);

export default App;
