import React, { useMemo } from "react";
import { matchPath } from "react-router-dom";

import { Roles } from "@/utilities/constants";
import { User } from "@/views/users/types/types";
import { MenuItem } from "../types";
import { PrivateRoutes } from "@/router/Routes";
import HomeIcon from "@/assets/icons/home.svg?react";
import DashboardIcon from "@/assets/icons/home.svg?react";
import EvidenceRepositoryIcon from "@/assets/icons/evidence-repository.svg?react";
import OrganizationIcon from "@/assets/icons/organization-icon.svg?react";
import SurveyIcon from "@/assets/icons/survey-icon.svg?react";
import CommunityIcon from "@/assets/icons/community.svg?react";
import DataBlockInvitesIcon from "@/assets/icons/datablock-invites.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";

const useMenuItems = (currentUser: User | undefined) => {
  return useMemo(() => {
    if (!currentUser) return [];

    const items: MenuItem[] = [
      {
        icon: <BaseSvgRenderer component={HomeIcon} width="20" height="20" />,
        key: "/",
        label: "Home",
      },
      {
        icon: (
          <BaseSvgRenderer component={DashboardIcon} width="20" height="20" />
        ),
        key: "/dashboard",
        label: "Dashboard",
      },
      {
        icon: (
          <BaseSvgRenderer
            component={EvidenceRepositoryIcon}
            width="20"
            height="20"
          />
        ),
        key: "/evidence",
        label: "Evidence",
        children: [
          {
            key: "/evidence/invites",
            icon: (
              <BaseSvgRenderer
                component={DataBlockInvitesIcon}
                width="20"
                height="20"
              />
            ),
            label: "Invites",
          },
        ],
      },
      ...(currentUser.organization
        ? [
            {
              icon: (
                <BaseSvgRenderer
                  component={OrganizationIcon}
                  width="20"
                  height="20"
                />
              ),
              key: `/organizations/${currentUser.organization.id}`,
              label: "Organization",
            },
          ]
        : []),
      {
        icon: (
          <BaseSvgRenderer
            component={OrganizationIcon}
            width="20"
            height="20"
          />
        ),
        key: "/organizations",
        label: "Organizations",
      },
      {
        icon: <BaseSvgRenderer component={SurveyIcon} width="20" height="20" />,
        key: "/reports",
        label: "Reports",
      },
      {
        icon: (
          <BaseSvgRenderer component={CommunityIcon} width="20" height="20" />
        ),
        key: "/community",
        label: "Community",
      },
    ];

    return items.filter((item) => {
      if (!currentUser) return false;

      const route = PrivateRoutes.find((route) => {
        return matchPath({ path: route.path }, item.key) !== null;
      });
      if (!route || !route.allowedRoles) return false;

      if (currentUser.role === Roles.ADMIN.value) {
        return !(
          !route.allowedRoles || !route.allowedRoles.includes(Roles.ADMIN.value)
        );
      }

      if (currentUser.role === Roles.USER.value) {
        const hasAllowedUserRole =
          route.allowedRoles && route.allowedRoles.includes(Roles.USER.value);

        if (!hasAllowedUserRole) {
          return false;
        }
        return (
          currentUser.organizationRole &&
          route.allowedOrgRoles.includes(currentUser.organizationRole)
        );
      }
    });
  }, [currentUser]);
};

export default useMenuItems;
