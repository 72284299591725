import { createUrl } from "../../utilities";
import {
  CountriesResponse,
  LanguagesQueryParams,
  LanguagesResponse,
  Tags,
} from "./types";

import { BuilderType } from "../../types/base";

const utilsService = (builder: BuilderType<Tags>) => ({
  getLanguages: builder.query<
    LanguagesResponse,
    LanguagesQueryParams | undefined
  >({
    query: (filters = {}) => createUrl("/languages/", filters),
    providesTags: (result) => (result ? [{ type: "Languages" }] : []),
  }),
  getCountries: builder.query<CountriesResponse, undefined>({
    query: () => "/countries/",
    providesTags: (result) => (result ? [{ type: "Countries" }] : []),
  }),
  getFrequentlyAskedQuestions: builder.query({
    query: () => "/faqs/",
    providesTags: (result) => (result ? [{ type: "FAQS" }] : []),
  }),
});

export default utilsService;
