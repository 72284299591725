import React, { Suspense } from "react";
import { useForm } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Divider } from "antd";
import { TFunction } from "i18next/typescript/t";

import "./styles.css";

import BaseButton from "../../../Button/BaseButton";
import { CustomChoiceInputProps } from "../types";
import { InferType } from "yup";
const HookFormInput = React.lazy(() =>
  import("@/components_v2/FormFields/ReactHookForm/fields").then((module) => ({
    default: module.HookFormInput,
  })),
);

const customInputSchema = (t: TFunction) =>
  yup
    .string()
    .required(t("form.validation.itemRequired"))
    .typeError(t("form.validation.itemNotValid"));

const CustomChoiceInput = ({ onAddOption, schema }: CustomChoiceInputProps) => {
  const { t } = useTranslation();

  const formSchema = yup.object().shape({
    label: schema || customInputSchema(t),
  });
  const form = useForm({
    resolver: yupResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      label: "",
    },
  });
  type CustomInputSchema = InferType<typeof formSchema>;

  const handleAddItem = (values: CustomInputSchema) => {
    const value = values.label?.trim();
    if (!value) return;

    onAddOption(value);
    form.reset({});
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      form.handleSubmit(handleAddItem)();
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    form.handleSubmit(handleAddItem)();
  };

  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <div className="custom-choice-item">
        <Suspense fallback={<div>{t("labels.loading")}</div>}>
          <HookFormInput
            name="label"
            control={form.control}
            onKeyDown={handleKeyDown}
            placeholder={t("form.placeholders.addCustomOption")}
          />
          <BaseButton
            size="small"
            icon={<PlusOutlined />}
            onClick={handleClick}
          >
            {t("buttons.addItem")}
          </BaseButton>
        </Suspense>
      </div>
    </>
  );
};

export const withCustomChoiceInput = (
  menu: React.ReactElement<unknown, string | React.JSXElementConstructor<any>>,
  { ...props }: CustomChoiceInputProps,
) => {
  return (
    <>
      {menu}
      <CustomChoiceInput {...props} />
    </>
  );
};
