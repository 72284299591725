import { UploadFile } from "antd/es/upload/interface";

export class FileUploadError extends Error {
  status: number;
  file: UploadFile;

  constructor(message: string, status: number, file: UploadFile) {
    super(message);
    this.status = status;
    this.file = file;

    Object.setPrototypeOf(this, FileUploadError.prototype);
  }
}
