import { useEffect, useState } from "react";

import { Spin } from "antd";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import { PRME_ORGANIZATION_ID, Roles } from "../../utilities/constants.jsx";
import {
  useGetCurrentUserQuery,
  useGetSurveyByIdQuery,
} from "@/redux/service.js";
import SingleSurveyUser from "./components/single-survey/SingleSurveyUser.jsx";
import SingleSurveyAdmin from "./components/single-survey/SingleSurveyAdmin.jsx";
import { setShowGreyBackground } from "@/redux/utils/reducer.js";
import { canUserPerformActionOnSurvey } from "@/utilities/permissions.js";

const SingleSurvey = () => {
  const { surveyId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isSample = location.pathname.includes("sample");

  const { data: currentUser, isLoading: isLoadingUser } =
    useGetCurrentUserQuery();
  const {
    data: survey,
    isLoading: isLoadingSurvey,
    isError,
  } = useGetSurveyByIdQuery({
    surveyId,
    filters: { organization: isSample ? PRME_ORGANIZATION_ID : null },
  });
  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    dispatch(setShowGreyBackground(true));

    return () => dispatch(setShowGreyBackground(false));
  }, []);

  useEffect(() => {
    if (survey && currentUser) {
      const hasPermissions =
        canUserPerformActionOnSurvey(
          survey.permissions,
          currentUser.organizationRole,
        ) || currentUser.role === Roles.ADMIN.value;
      if (!hasPermissions) {
        navigate("/");
      }
      setHasPermissions(hasPermissions);
    }
  }, [survey, currentUser]);

  if (isLoadingUser || isLoadingSurvey || !hasPermissions) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  if (isError) {
    return <Navigate to="/" />;
  }

  if (currentUser.role === Roles.ADMIN.value) {
    return <SingleSurveyAdmin survey={survey} />;
  } else {
    return <SingleSurveyUser survey={survey} />;
  }
};

export default SingleSurvey;
