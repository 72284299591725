import { useEffect, useState } from "react";

import { Upload } from "antd";
import { Col, Row } from "antd";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";
import {
  DeleteOutlined,
  FileOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import FieldError from "./FieldError.jsx";
import PrimaryButton from "../PrimaryButton.jsx";
import PlaceholderImage from "@/assets/icons/placeholder.png";

const UploadField = ({
  name,
  control,
  listType,
  imageUrl,
  isLoading,
  supportedFormats,
  type,
  multiple,
  isEdit,
}) => {
  const [loading, setLoading] = useState(isLoading);
  const [imageLink, setImageLink] = useState(imageUrl);
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();

  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  useEffect(() => {
    if (imageUrl) setImageLink(imageUrl);
  }, [imageUrl]);

  const handleChange = (info) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setLoading(false);
      setImageLink(reader.result);
    });
    reader.readAsDataURL(info.file);
    field.onChange(info.file);
    if (type !== "dropzone") {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setLoading(false);
        setImageLink(reader.result);
      });
      reader.readAsDataURL(info.file);
    }

    if (multiple) {
      field.onChange(info.fileList);
    } else {
      field.onChange(info.file);
    }
  };

  const props = {
    name,
    listType,
    beforeUpload: () => false, // Needed in order to stop automatically posting file
    accept: supportedFormats ? supportedFormats.join(",") : null,
    onChange: handleChange,
    showUploadList: false,
    disabled: !isEdit,
  };

  const handleDelete = (index) => {
    if (multiple) {
      field.onChange(field.value.filter((i, fileIndex) => fileIndex !== index));
    } else {
      field.onChange(null);
    }
  };

  if (type === "dropzone") {
    return (
      <div>
        {field.value && (
          <div className="mb-2">
            {(multiple ? field.value : [field.value]).map((i, index) => (
              <Row key={i.name}>
                <Col span={2}>
                  <FileOutlined />
                </Col>
                <Col span={20} className="filename">
                  {i.name}
                </Col>
                <Col span={2}>
                  <DeleteOutlined onClick={() => handleDelete(index)} />
                </Col>
              </Row>
            ))}
          </div>
        )}
        <Upload.Dragger
          {...props}
          multiple={multiple}
          style={{ borderRadius: "0px", borderColor: error ? "red" : "silver" }}
        >
          <p style={{ fontWeight: "bold", color: "rgba(128, 128, 128, 1)" }}>
            {t("form.labels.dragFile")}
          </p>
          <PrimaryButton>{t("buttons.selectFile")}</PrimaryButton>
          <p className="mt-2" style={{ color: "rgba(128, 128, 128, 1)" }}>
            {supportedFormats &&
              supportedFormats
                .map((i) => i.replace(".", "").toUpperCase())
                .join(", ")}
            , {t("form.validation.supportedFormatSize")}
          </p>
        </Upload.Dragger>
        {error && <FieldError errors={error} />}
      </div>
    );
  }

  return (
    <div>
      <Upload className="antd-profile-image-uploader" {...props}>
        {isEdit && !imageLink ? null : (
          <img
            style={{ width: "180px", height: "180px", borderRadius: "50%" }}
            onMouseEnter={(e) => {
              setIsHovered(true);
              e.target.style.opacity = "50%";
            }}
            onMouseLeave={(e) => {
              setIsHovered(false);
              e.target.style.opacity = "100%";
            }}
            src={isEdit ? imageLink : imageLink || PlaceholderImage}
            alt="avatar"
          />
        )}
        {isEdit && (
          <div
            style={{
              marginLeft: !imageLink ? "0px" : "-170px",
              display: isHovered || !imageLink ? "block" : "none",
            }}
          >
            <button style={{ border: 0, background: "none" }} type="button">
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>
                {t("buttons.uploadProfilePicture")}
              </div>
            </button>
          </div>
        )}
      </Upload>
      {error && <FieldError errors={error} />}
    </div>
  );
};

UploadField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  listType: PropTypes.string,
  imageUrl: PropTypes.string,
  isLoading: PropTypes.bool,
  supportedFormats: PropTypes.array,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  isEdit: PropTypes.bool,
};

UploadField.defaultProps = {
  listType: "text",
  imageUrl: null,
  isLoading: false,
  supportedFormats: null,
  type: "normal",
  multiple: false,
  isEdit: true,
};

export default UploadField;
