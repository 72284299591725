import React, { createContext, useContext, ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

export interface BreakpointValues {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export const breakpointValues: BreakpointValues = {
  xs: 320,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

export const breakpoints = {
  xs: `${breakpointValues.xs}px`,
  sm: `${breakpointValues.sm}px`,
  md: `${breakpointValues.md}px`,
  lg: `${breakpointValues.lg}px`,
  xl: `${breakpointValues.xl}px`,
  xxl: `${breakpointValues.xxl}px`,
};

interface ResponsiveContextType {
  isMobileS: boolean; // < 480px (smaller than sm)
  isMobileM: boolean; // >= 480px and < 768px (sm to md)
  isTablet: boolean; // >= 768px and < 1024px (md to lg)
  isLaptop: boolean; // >= 1024px and < 1280px (lg to xl)
  isDesktop: boolean; // >= 1280px (xl and above)

  isMobile: boolean; // < 768px (smaller than md)
  isDesktopOrLaptop: boolean; // >= 1024px (lg and above)
}

export const ResponsiveContext = createContext<ResponsiveContextType>({
  isMobileS: false,
  isMobileM: false,
  isTablet: false,
  isLaptop: false,
  isDesktop: false,
  isMobile: false,
  isDesktopOrLaptop: false,
});

interface ResponsiveProviderProps {
  children: ReactNode;
}

export const ResponsiveProvider: React.FC<ResponsiveProviderProps> = ({
  children,
}) => {
  const isMobileS = useMediaQuery({ maxWidth: breakpointValues.sm - 1 });
  const isMobileM = useMediaQuery({
    minWidth: breakpointValues.sm,
    maxWidth: breakpointValues.md - 1,
  });
  const isTablet = useMediaQuery({
    minWidth: breakpointValues.md,
    maxWidth: breakpointValues.lg - 1,
  });
  const isLaptop = useMediaQuery({
    minWidth: breakpointValues.lg,
    maxWidth: breakpointValues.xl - 1,
  });
  const isDesktop = useMediaQuery({ minWidth: breakpointValues.xl });

  const isMobile = useMediaQuery({ maxWidth: breakpointValues.md - 1 });
  const isDesktopOrLaptop = useMediaQuery({ minWidth: breakpointValues.lg });

  const value = {
    isMobileS,
    isMobileM,
    isTablet,
    isLaptop,
    isDesktop,
    isMobile,
    isDesktopOrLaptop,
  };

  return (
    <ResponsiveContext.Provider value={value}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export const useResponsive = (): ResponsiveContextType => {
  return useContext(ResponsiveContext);
};

export const getBreakpointValue = (
  breakpoint: keyof BreakpointValues,
): number => {
  return breakpointValues[breakpoint];
};

export default breakpoints;
