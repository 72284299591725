import { Collapse } from "antd";

import { BaseAccordionProps } from "./types";

import "./styles.css";

const BaseAccordion = ({ className, ...props }: BaseAccordionProps) => {
  return <Collapse className={`base-accordion ${className}`} {...props} />;
};

export default BaseAccordion;
