import React from "react";

import {
  ChoiceOption,
  ContentType,
  DataField,
  FieldType,
} from "../../types/templates";
import TextElement from "../../../../components_v2/ContentElements/TextElement";
import CheckboxElement from "../../../../components_v2/ContentElements/CheckboxElement";
import {
  DateElement,
  DateRangeElement,
} from "@/components_v2/ContentElements/DateElement";
import {
  ChoiceOptionWithScaleLabel,
  DisplayFieldValue,
} from "../../types/types";
import {
  ContentTypeRelations,
  DynamicValue,
  SelectedOption,
} from "../../types/blocks";
import ContentItem from "../../../../components_v2/ContentElements/ContentItem";
import { useDataBlockRelatedData } from "../hooks/useDataBlockRelatedData";
import BaseSpinner from "../../../../components_v2/Spiner/BaseSpinner";
import { RichText } from "@/components_v2/ContentElements/types";
import {
  getRendererComponentForContentType,
  getRendererPropsForContentType,
} from "./contentTypeConfig";

const RelatedDataDisplayComponent = ({
  dataField,
  values,
  component: Component,
  transformValue,
}: {
  dataField: DataField;
  values: number | string | number[] | (number | string)[];
  component: React.ComponentType;
  transformValue?: (data: ContentTypeRelations) => ChoiceOptionWithScaleLabel[];
}) => {
  const choices: { ids: number[]; other: string[] } = { ids: [], other: [] };
  if (Array.isArray(values)) {
    values.forEach((val) => {
      if (typeof val === "string") {
        choices.other.push(val);
      } else choices.ids.push(val);
    });
  } else {
    if (typeof values === "string") {
      choices.other.push(values);
    } else choices.ids.push(values);
  }

  const { data, isLoading } = useDataBlockRelatedData(
    choices.ids,
    dataField.contentType,
  );

  if (isLoading) return <BaseSpinner size="small" />;

  const transformedData = transformValue ? transformValue(data) : data;
  const transformedProps = getRendererPropsForContentType(dataField, [
    ...(transformedData || []),
    ...choices.other.map((i) => ({ label: i, value: i })),
  ] as ContentTypeRelations);

  if (!transformedProps) return null;
  return <Component {...transformedProps} />;
};

export const getFieldDisplay = (dataField: DataField, value: DynamicValue) => {
  if (value == null) {
    return {
      component: TextElement,
      props: { value: undefined },
    };
  }

  switch (dataField.type) {
    case FieldType.TEXT:
    case FieldType.TEXT_AREA:
    case FieldType.NUMBER:
      return {
        component: TextElement,
        props: {
          value: value as string,
        },
      };

    case FieldType.RICH_TEXT:
      return {
        component: TextElement,
        props: {
          type: RichText,
          value: value as string,
        },
      };

    case FieldType.CHECKBOX:
      return {
        component: CheckboxElement,
        props: {
          label: dataField.title,
          value: value as boolean,
        },
      };

    case FieldType.DATE:
      return {
        component: DateElement,
        props: {
          value: value as string,
        },
      };

    case FieldType.DATE_RANGE:
      return {
        component: DateRangeElement,
        props: {
          value: value as number[],
        },
      };

    case FieldType.SINGLE_CHOICE: {
      const id = value as number;

      return {
        component: RelatedDataDisplayComponent,
        props: {
          dataField,
          values: id,
          component: getRendererComponentForContentType(dataField),
        },
      };
    }

    case FieldType.MULTIPLE_CHOICE: {
      const values = value as number[];

      return {
        component: RelatedDataDisplayComponent,
        props: {
          dataField,
          values,
          component: getRendererComponentForContentType(dataField),
        },
      };
    }

    case FieldType.MULTIPLE_CHOICE_WITH_SCALE: {
      const selectedOptions = value as SelectedOption[];
      const { data: scaleData } = useDataBlockRelatedData(
        selectedOptions.map((opt) => opt.scale),
        ContentType.Options,
      ) as { data: ChoiceOption[] };

      const scaleDataMappings = scaleData
        ? selectedOptions.reduce<Record<number, string | undefined>>(
            (acc, option) => {
              const scale = scaleData.find(
                (scaleOption) => scaleOption.id === option.scale,
              );
              acc[option.choice] = scale?.label;
              return acc;
            },
            {},
          )
        : {};
      return {
        component: RelatedDataDisplayComponent,
        props: {
          dataField,
          values: selectedOptions.map((opt) => opt.choice),
          component: getRendererComponentForContentType(dataField),
          transformValue: (options: ChoiceOption[]) =>
            (options || []).map((option) => ({
              ...option,
              scaleLabel: scaleDataMappings[option.id] ?? "",
            })),
        },
      };
    }

    case FieldType.SLIDER:
      return {
        component: RelatedDataDisplayComponent,
        props: {
          dataField,
          values: value,
          component: getRendererComponentForContentType(dataField),
          baseProps: {},
        },
      };

    default:
      return {
        component: TextElement,
        props: {
          value: String(value),
        },
      };
  }
};

export function getDisplayComponentForField(
  dataField: DataField,
  value: DynamicValue,
) {
  if (value == null) {
    return {
      component: TextElement,
      props: { value: undefined },
    };
  }

  const display = getFieldDisplay(dataField, value);

  if (!display) {
    throw new Error(`Can't find component for field of type ${dataField.type}`);
  }

  return display;
}

/**
 * Retrieves the display component according to the field type and returns it inside a Content Item component with a label and properties.
 */
export const getContentItemForField = (
  fieldValue: DisplayFieldValue,
  additionalProps: Record<string, string | undefined> = {},
) => {
  const { component: FieldComponent, props: fieldProps } =
    getDisplayComponentForField(fieldValue.field, fieldValue.value);
  const content =
    fieldValue.value !== undefined ? (
      // @ts-expect-error props for the component
      <FieldComponent {...fieldProps} />
    ) : (
      <p>N/A</p>
    );

  return (
    <ContentItem
      key={fieldValue.field.id}
      label={
        fieldValue.field.type === FieldType.CHECKBOX &&
        fieldValue.value !== undefined
          ? undefined
          : fieldValue.field.title
      }
      content={content}
      {...additionalProps}
    />
  );
};
