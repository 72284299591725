import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import BaseButton from "../../../../components_v2/Button/BaseButton.js";
import SurveyDataBlockModal from "../../../datablocks/invites/components/SurveyDataBlockModal.js";
import { EntitiesCondition, OptionEntityType } from "@/utilities/constants.jsx";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer.js";
import { getDataBlockTitle } from "../../../datablocks/core/hooks/useDataBlockField.js";
import AddDocumentIcon from "@/assets/icons/add-document.svg?react";
import SurveyIcon from "@/assets/icons/survey-icon.svg?react";
import { CloseOutlined } from "@ant-design/icons";

export const OptionEntities = ({ option, answer, handleSubmit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isSample = location.pathname.includes("sample");

  const [dataBlocksModelOpen, setDataBlocksModelOpen] = useState(false);

  const handleRemoveBlock = (blockId) => {
    const newBlocks = answer.attachedBlocks.filter((i) => i.id !== blockId);
    handleSubmit(newBlocks);
  };

  const handleAttachBlocks = async (blocks) => {
    await handleSubmit(blocks);
    setDataBlocksModelOpen(false);
  };

  const requiredDataBlockTypes = useMemo(() => {
    const prompts = (option?.optionEntities || []).filter(
      (entity) => entity.type === OptionEntityType.DATA_BLOCK.value,
    );
    if (prompts.length) {
      return prompts.map((i) => i.dataTemplate.title);
    }
    return [t("labels.all")];
  }, [option]);

  const requiredDataTemplateIds = (option?.optionEntities || [])
    .filter((entity) => entity.type === OptionEntityType.DATA_BLOCK.value)
    .map((i) => i.dataTemplate.id);

  return (
    <div className="option-entities">
      {requiredDataBlockTypes && (
        <p className="option-entities-label body-m-regular">
          <BaseSvgRenderer component={SurveyIcon} width="16" height="16" />
          {t(
            option.entitiesCondition === EntitiesCondition.AND.value
              ? "survey.labels.dataBlockTypesRequired"
              : "survey.labels.dataBlockTypesAccepted",
            {
              plural: requiredDataBlockTypes.length === 1 ? "" : "s",
            },
          )}{" "}
          {requiredDataBlockTypes.join(", ")}
        </p>
      )}
      {!isSample && (
        <BaseButton
          size="small"
          leadingIcon={
            <BaseSvgRenderer component={AddDocumentIcon} stroke="white" />
          }
          onClick={() => setDataBlocksModelOpen(true)}
        >
          {t("survey.labels.addEvidence")}
        </BaseButton>
      )}

      {answer && answer.attachedBlocks.length > 0 ? (
        <div className="option-entity-attached-container">
          <h4 className="option-entity-attached-header-label body-m-regular">
            {t("datablocks.labels.attachedEvidence")} (
            {answer.attachedBlocks.length})
          </h4>
          {answer.attachedBlocks.map((block) => {
            const title = getDataBlockTitle(block);
            return (
              <div
                key={block.id}
                className="option-entity-attached-content-container"
              >
                <h4
                  className="option-entity-attached-item body-m-medium"
                  onClick={() => {
                    navigate(`/evidence/${block.id}`);
                  }}
                >
                  {title}
                </h4>
                {!isSample && (
                  <BaseButton
                    size="small"
                    variant="link"
                    onClick={() => handleRemoveBlock(block.id)}
                  >
                    <CloseOutlined />
                  </BaseButton>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
      <SurveyDataBlockModal
        open={dataBlocksModelOpen}
        setOpen={setDataBlocksModelOpen}
        filters={{
          dataTemplates: requiredDataTemplateIds,
        }}
        selectedBlocks={answer?.attachedBlocks || []}
        setSelectedBlocks={handleAttachBlocks}
      />
    </div>
  );
};
