import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { ProgressCardProps } from "./types";

import BaseProgressBar from "../ProgressBars/BaseProgressBar";
import BaseButton from "../Button/BaseButton";
import BaseTooltip from "../Tooltip/BaseTooltip";
import { useTranslation } from "react-i18next";

const ProgressCard = ({
  title,
  status,
  color,
  iconSrc,
  buttonLabel,
  onButtonClick,
  onCardClick,
  ...props
}: ProgressCardProps) => {
  const { t } = useTranslation();

  return (
    <div className="progress-card" onClick={onCardClick}>
      <div className="progress-card-header">
        <div
          style={{ backgroundColor: color }}
          className="progress-card-colored-background"
        />

        <div className="progress-card-icon">
          <img src={iconSrc} />
        </div>
      </div>

      <div className="progress-card-content">
        <div className="progress-card-content-header">
          <h4 className="header-s-medium">{title}</h4>

          {status === "required-missing" ? (
            <BaseTooltip
              title={t("survey.labels.requiredQuestionsMissing")}
              placement="topRight"
            >
              <ExclamationCircleOutlined
                style={{ color: "var(--red-500)" }}
                className="progress-card-status-icon"
              />
            </BaseTooltip>
          ) : status === "optional-missing" ? (
            <BaseTooltip
              title={t("survey.labels.optionalQuestionsMissing")}
              placement="topRight"
            >
              <ExclamationCircleOutlined
                style={{ color: "var(--neutral-500)" }}
                className="progress-card-status-icon"
              />
            </BaseTooltip>
          ) : status === "completed" ? (
            <BaseTooltip
              title={t("survey.labels.complete")}
              placement="topRight"
            >
              <CheckCircleOutlined
                style={{ color: "var(--green-700)" }}
                className="progress-card-status-icon"
              />
            </BaseTooltip>
          ) : null}
        </div>

        <BaseProgressBar
          value={props.value}
          total={props.total}
          label={props.label}
        />

        {buttonLabel && (
          <BaseButton
            variant="tertiary"
            size="small"
            isFullWidth={true}
            onClick={onButtonClick}
          >
            {buttonLabel}
          </BaseButton>
        )}
      </div>
    </div>
  );
};

export default ProgressCard;
