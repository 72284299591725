import { useEffect, useState } from "react";

import { Col, Pagination, Row, theme, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useLazyGetTopicsQuery } from "../../../redux/service.js";
import PrimaryButton from "../../../components/PrimaryButton.jsx";

const itemRender = (_, type, originalElement, hasNext, hasPrevious) => {
  if (type === "prev") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasPrevious ? "black" : "silver",
        }}
      >
        <LeftOutlined /> Previous
      </a>
    );
  }
  if (type === "next") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasNext ? "black" : "silver",
        }}
      >
        Next <RightOutlined />
      </a>
    );
  }
  return originalElement;
};

const { Title, Text } = Typography;

const TopicsList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const [paginateTopics, { data, isSuccess }] = useLazyGetTopicsQuery();
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 20,
  });

  useEffect(() => {
    paginateTopics(pagination);
  }, [pagination]);

  return (
    isSuccess && (
      <div>
        <Row className="mb-2">
          <Col span={12}>
            <Title level={3} style={{ color: token.colorTextThird }}>
              {t("topics")}
            </Title>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <PrimaryButton
              style={{
                backgroundColor: "rgba(74, 105, 156, 1)",
                color: "white",
                fontWeight: "500",
              }}
              onClick={() => navigate("/discussions/form")}
            >
              {t("startADiscussion")}
            </PrimaryButton>
          </Col>
        </Row>
        {data.results.map((topic) => (
          <Row
            key={topic.id}
            style={{ backgroundColor: "rgba(242, 242, 245, 1)" }}
            className="mb-4 py-4 px-4"
          >
            <Col span={19}>
              <Title
                level={5}
                className="mb-2"
                style={{ color: token.colorTextThird, cursor: "pointer" }}
                onClick={() => navigate(`/community/topics/${topic.title}`)}
              >
                {topic.title}
              </Title>
              <Text style={{ color: "rgba(96, 100, 108, 1)" }}>
                {topic.description}
              </Text>
            </Col>
            <Col
              span={5}
              style={{ textAlign: "center", alignContent: "center" }}
            >
              <div>
                <Title level={1} style={{ color: "rgba(126, 128, 138, 1)" }}>
                  {topic.discussionsCount}
                </Title>
                <Title
                  level={5}
                  style={{ fontWeight: 400, color: "rgba(126, 128, 138, 1)" }}
                >
                  {t("discussions")}
                </Title>
              </div>
            </Col>
          </Row>
        ))}
        {data.next || data.previous ? (
          <Pagination
            rootClassName="mt-4 text-center"
            total={data.count}
            itemRender={(...props) =>
              itemRender(...props, data.next != null, data.previous != null)
            }
            showSizeChanger
            pageSize={pagination.pageSize}
            onChange={(page, pageSize) => setPagination({ page, pageSize })}
          />
        ) : null}
      </div>
    )
  );
};

export default TopicsList;
