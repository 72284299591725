import { Slider } from "antd";
import React, { forwardRef, useEffect, useState } from "react";
import { SliderRef } from "rc-slider/lib/Slider";

import { BaseSliderProps } from "./types";
import "./styles.css";
import BaseError from "@/components_v2/FormFields/BaseError";

const BaseSlider = forwardRef<SliderRef, BaseSliderProps>(
  ({ options, value, error, ...props }: BaseSliderProps, ref) => {
    const [selectedValue, setSelectedValue] = useState<number | undefined>(
      value,
    );

    useEffect(() => {
      if (value !== undefined) {
        setSelectedValue(value);
      }
    }, [value]);

    const firstOrderedOption = options[0];
    const lastOrderedOption = options[options.length - 1];
    if (!firstOrderedOption || !lastOrderedOption) return null;

    const optionsSortedByValue = options.sort((a, b) => a.value - b.value);
    const firstOptionByValue = optionsSortedByValue[0];
    const lastOptionByValue =
      optionsSortedByValue[optionsSortedByValue.length - 1];
    if (!firstOptionByValue || !lastOptionByValue) return null;

    const generateMarks = () => {
      if (options.length < 5) {
        return options.reduce<Record<number, string>>((acc, option) => {
          acc[option.value] = option.label;
          return acc;
        }, {});
      } else {
        const marks: Record<number, string> = {
          [firstOrderedOption.value]: firstOrderedOption.label,
          [lastOrderedOption.value]: lastOrderedOption.label,
        };

        if (
          selectedValue !== undefined &&
          selectedValue !== firstOrderedOption.value &&
          selectedValue !== lastOrderedOption.value
        ) {
          const selectedOption = options.find(
            (opt) => opt.value === selectedValue,
          );
          if (selectedOption) {
            marks[selectedValue] = selectedOption.label;
          }
        }

        return marks;
      }
    };

    if (!options || options.length < 2) {
      return null;
    }

    return (
      <div className="base-slider-container">
        <Slider
          className="base-slider"
          min={firstOptionByValue.value}
          max={lastOptionByValue.value}
          marks={generateMarks()}
          tooltip={{
            formatter: (value: number | undefined) => {
              const selectedOption = options.find((i) => i.value === value);
              return selectedOption?.label || "";
            },
          }}
          value={value}
          {...props}
          ref={ref}
        />
        {error && <BaseError error={error} />}
      </div>
    );
  },
);

export default BaseSlider;
