import { useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import {
  useGetSectionByIdQuery,
  useGetSurveyStatisticsQuery,
} from "@/redux/service.js";
import Question from "./Question.jsx";
import BaseTabs from "../../../../components_v2/Tabs/BaseTabs.js";
import BaseProgressBar from "../../../../components_v2/ProgressBars/BaseProgressBar.js";
import BaseButton from "../../../../components_v2/Button/BaseButton.js";
import { PRME_ORGANIZATION_ID } from "@/utilities/constants.jsx";

const SectionNavigationButton = ({ section, isPrevious }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSample = location.pathname.includes("sample");

  const navigateToSection = useMemo(() => {
    if (isPrevious) {
      return section.previousSection;
    }
    return section.nextSection;
  }, [section, isPrevious]);

  return (
    <BaseButton
      variant="link"
      onClick={() =>
        navigate(
          `/reports/${section.survey}/section/${navigateToSection.id}${isSample ? "/sample" : ""}`,
        )
      }
    >
      {isPrevious ? (
        <>
          {" "}
          <ArrowLeftOutlined /> {navigateToSection.title}{" "}
        </>
      ) : (
        <>
          {" "}
          {section.nextSection.title} <ArrowRightOutlined />{" "}
        </>
      )}
    </BaseButton>
  );
};

SectionNavigationButton.propTypes = {
  section: PropTypes.object.isRequired,
  isPrevious: PropTypes.bool.isRequired,
};

const SectionHeader = ({ activeSurveyId, section }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isSample = location.pathname.includes("sample");

  const {
    data: surveyStatisticsData,
    isSuccess: surveyStatisticsResponseSuccess,
  } = useGetSurveyStatisticsQuery({
    survey: activeSurveyId,
    organization: isSample ? PRME_ORGANIZATION_ID : null,
  });

  const currentSectionStatistics = surveyStatisticsResponseSuccess
    ? surveyStatisticsData.find((i) => i.id == section.id)
    : null;

  if (!currentSectionStatistics) return;

  const answeredQuestions =
    currentSectionStatistics.answeredRequiredQuestionsCount +
    currentSectionStatistics.answeredOptionalQuestionsCount;
  const totalQuestions =
    currentSectionStatistics.requiredQuestionsCount +
    currentSectionStatistics.optionalQuestionsCount;
  const progressBarLabel = () => {
    return (
      <>
        {t("survey.labels.required")}:{" "}
        {currentSectionStatistics.answeredRequiredQuestionsCount}/
        {currentSectionStatistics.requiredQuestionsCount} |{" "}
        {t("survey.labels.optional")}:{" "}
        {currentSectionStatistics.answeredOptionalQuestionsCount}/
        {currentSectionStatistics.optionalQuestionsCount}
      </>
    );
  };

  return (
    <div className="section-header">
      <div
        className="section-header-image-container"
        style={{ background: section.color }}
      >
        {section.icon && <img src={section.icon} alt="icon for section" />}
      </div>

      <div className="section-header-content">
        <h1 className="section-header-content-heading header-l-medium">
          {section.title}
        </h1>
        <p className="section-header-content-description">
          {section.description}
        </p>
        <BaseProgressBar
          value={answeredQuestions}
          total={totalQuestions}
          label={progressBarLabel()}
        />
      </div>
    </div>
  );
};

const SectionContent = ({ activeSurveyId }) => {
  const { sectionId } = useParams();

  const { data: section, isSuccess } = useGetSectionByIdQuery(sectionId);

  const tabs = useMemo(() => {
    if (!section) return [];

    if (section.tabs && section.tabs.length > 0) {
      return section.tabs.map((tab) => ({
        key: tab.title.toLowerCase(),
        label: tab.title,
        children: (
          <div className="section-content-container">
            {tab.questions
              .slice()
              .sort((a, b) => a.index - b.index)
              .map((i) => (
                <Question key={i} questionId={i} />
              ))}
          </div>
        ),
      }));
    }

    return [];
  }, [section]);

  return (
    isSuccess && (
      <div className="section-content">
        <SectionHeader activeSurveyId={activeSurveyId} section={section} />
        <h6 className="body-m-regular">{section.helpText}</h6>
        {section.tabs && section.tabs.length > 0 ? (
          <BaseTabs items={tabs} />
        ) : (
          <>
            {section.questions
              .slice()
              .sort((a, b) => a.index - b.index)
              .map((i) => (
                <Question key={i} questionId={i} />
              ))}
          </>
        )}
        <div
          className={`section-content-navigation ${!section.previousSection && section.nextSection ? "next-only" : ""}`}
        >
          {section.previousSection && (
            <SectionNavigationButton section={section} isPrevious />
          )}
          {section.nextSection && (
            <SectionNavigationButton section={section} isPrevious={false} />
          )}
        </div>
      </div>
    )
  );
};

SectionContent.propTypes = {
  activeSurveyId: PropTypes.number.isRequired,
};

export default SectionContent;
