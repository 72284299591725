import { TFunction } from "i18next";
import {
  ContentType,
  DataField,
  FieldType,
  ValidationRule as ValidationRuleType,
  ValidationRuleTypes,
} from "../../types/templates";
import {
  DataBlockFormFields,
  FormFieldValue,
  SchemaGenerator,
  ValidationContext,
  ValidationResult,
  ValidationRulesFormSchema,
  YupSchema,
} from "../../types/types";
import yup from "../../../../libraries/yup";
import i18n from "../../../../libraries/i18n";
import { DynamicValue } from "../../types/blocks";
import { CHOICE_FIELDS, MULTIPLE_CHOICE_FIELDS } from "../constants";

const { t } = i18n;

abstract class ValidationRule {
  constructor(protected context: ValidationContext) {}

  abstract validate(): ValidationResult;

  protected getErrorMessage(fallbackKey: string): string {
    const { rule } = this.context;
    return rule.errorMessage || t(fallbackKey);
  }
}

class RequiredValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { schema } = this.context;
    if (schema instanceof yup.ArraySchema) {
      return {
        schema: schema
          .required(this.getErrorMessage("form.validation.required"))
          .min(1, this.getErrorMessage("form.validation.required")),
      };
    }

    return {
      schema: schema.required(this.getErrorMessage("form.validation.required")),
    };
  }
}

class FileTypeValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { field, schema, rule } = this.context;

    if (field?.contentType !== ContentType.Files) {
      return { schema };
    }

    return {
      schema: schema.test(
        "fileType",
        this.getErrorMessage("form.validation.fileType"),
        (value) => {
          if (!(value instanceof File)) return true;
          const extension = this.getFileExtension(value.name);
          const allowedExtensions = (rule.value || []) as string[];
          return allowedExtensions.includes(extension);
        },
      ),
    };
  }

  private getFileExtension(filename: string): string {
    const lastDot = filename.lastIndexOf(".");
    return lastDot === -1 ? "" : filename.substring(lastDot);
  }
}

class MinLengthValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { schema, rule } = this.context;
    const minLength = rule.value as number;
    return {
      schema: (schema as yup.ArraySchema<never, never>).min(
        minLength,
        this.getErrorMessage("form.validation.minLength"),
      ),
    };
  }
}

class MaxLengthValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { schema, rule } = this.context;
    const maxLength = rule.value as number;
    return {
      schema: (schema as yup.ArraySchema<never, never>).max(
        maxLength,
        this.getErrorMessage("form.validation.maxLength"),
      ),
    };
  }
}

class MaxFileSizeValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { field, schema, rule } = this.context;

    if (field?.contentType !== ContentType.Files) {
      return { schema };
    }
    const maxFileSize = rule.value as number;
    return {
      schema: schema.test(
        "fileSize",
        this.getErrorMessage("form.validation.fileSize"),
        (value) => {
          if (!(value instanceof File)) return true;
          return value.size <= maxFileSize;
        },
      ),
    };
  }
}

class RegexValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { schema, rule } = this.context;

    if (typeof (schema as yup.StringSchema).matches !== "function") {
      return { schema };
    }

    const regex = rule.value as string;
    return {
      schema: (schema as yup.StringSchema).test({
        name: "matches",
        test: function (value) {
          if (!value || value.trim() === "") {
            return true;
          }

          const fixedRegexStr = regex
            .replace(/\\\\/g, "\\")
            .replace(/\\\//g, "/");

          return new RegExp(fixedRegexStr).test(value);
        },
        message: this.getErrorMessage("form.validation.regex"),
      }),
    };
  }
}

class EitherRequiredValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { schema } = this.context;

    return {
      schema: schema.test(
        "either-required",
        this.getErrorMessage("datablocks.errors.eitherRequired"),
        this.validateFields.bind(this),
      ),
    };
  }

  private validateFields(
    _values: DataBlockFormFields,
    ctx: yup.TestContext,
  ): boolean | yup.ValidationError {
    if (!_values) return true;

    const { rule } = this.context;
    const fieldMappings = _values.fieldValues.reduce<
      Record<number, DynamicValue>
    >((acc, i) => {
      acc[i.field] = i?.value;
      return acc;
    }, {});

    const fieldValues =
      rule.ruleFields?.map((fieldId) => fieldMappings[fieldId]) || [];
    const isValid = fieldValues.some((value) => !!value);

    if (!isValid) {
      const errorMessage = this.getErrorMessage(
        "datablocks.errors.eitherRequired",
      );
      const templateFieldIdsOrdering = _values.fieldValues.map(
        (i: FormFieldValue) => i.field,
      );

      const errors = rule.ruleFields.map((ruleFieldId) => {
        return ctx.createError({
          path: `fieldValues.${templateFieldIdsOrdering.indexOf(ruleFieldId)}.value`,
          message: errorMessage,
          params: {
            otherFields: rule.ruleFields?.slice(1),
          },
        });
      });

      return new yup.ValidationError(errors);
    }

    return true;
  }
}

class DependentRequiredValidationRule extends ValidationRule {
  validate(): ValidationResult {
    const { schema } = this.context;

    return {
      schema: schema.test(
        "dependent-required",
        this.getErrorMessage("datablocks.errors.dependentRequired"),
        this.validateFields.bind(this),
      ),
    };
  }

  private validateFields(
    _values: DataBlockFormFields,
    ctx: yup.TestContext,
  ): boolean | yup.ValidationError {
    if (!_values) return true;

    const { rule } = this.context;
    const fieldMappings = _values.fieldValues.reduce<
      Record<number, DynamicValue>
    >((acc, i) => {
      acc[i.field] = i?.value;
      return acc;
    }, {});

    const fieldValues =
      rule.ruleFields?.map((fieldId) => fieldMappings[fieldId]) || [];
    const isValid = fieldValues.every((value) => !!value);

    if (!isValid) {
      const errorMessage = this.getErrorMessage(
        "datablocks.errors.dependentRequired",
      );
      const templateFieldIdsOrdering = _values.fieldValues.map(
        (i: FormFieldValue) => i.field,
      );

      const errors = rule.ruleFields.map((ruleFieldId) => {
        return ctx.createError({
          path: `fieldValues.${templateFieldIdsOrdering.indexOf(ruleFieldId)}.value`,
          message: errorMessage,
          params: {
            otherFields: rule.ruleFields?.slice(1),
          },
        });
      });

      return new yup.ValidationError(errors);
    }

    return true;
  }
}

class ValidationService {
  private ruleMap: Map<
    ValidationRuleTypes,
    new (context: ValidationContext) => ValidationRule
  >;

  constructor(private t: TFunction) {
    this.ruleMap = new Map([
      [ValidationRuleTypes.REQUIRED, RequiredValidationRule],
      [ValidationRuleTypes.ALLOWED_FILE_TYPES, FileTypeValidationRule],
      [ValidationRuleTypes.MIN_LENGTH, MinLengthValidationRule],
      [ValidationRuleTypes.MAX_LENGTH, MaxLengthValidationRule],
      [ValidationRuleTypes.MAX_FILE_SIZE, MaxFileSizeValidationRule],
      [ValidationRuleTypes.REGEX, RegexValidationRule],
      [ValidationRuleTypes.EITHER_REQUIRED, EitherRequiredValidationRule],
      [ValidationRuleTypes.DEPENDENT_REQUIRED, DependentRequiredValidationRule],
    ]);
  }

  createRule(
    type: ValidationRuleTypes,
    field: DataField | null,
    schema: YupSchema,
    rule: ValidationRuleType,
  ): ValidationRule | null {
    const RuleClass = this.ruleMap.get(type);
    if (!RuleClass) {
      return null;
    }

    return new RuleClass({
      field,
      schema,
      rule,
      t: this.t,
    });
  }

  setValidationRules(field: DataField, schema: YupSchema): YupSchema {
    return (field.rules || []).reduce((currentSchema, rule) => {
      const validationRule = this.createRule(
        rule.type,
        field,
        currentSchema,
        rule,
      );
      if (!validationRule) {
        return currentSchema;
      }

      const { schema: newSchema } = validationRule.validate();
      return newSchema;
    }, schema);
  }
}

const FIELD_TYPE_SCHEMA_MAP: Partial<Record<FieldType, SchemaGenerator>> = {
  [FieldType.TEXT]: () => yup.string(),
  [FieldType.TEXT_AREA]: () => yup.string(),
  [FieldType.RICH_TEXT]: () => yup.string(),
  [FieldType.NUMBER]: () => yup.number(),
  [FieldType.SLIDER]: () => yup.number(),
  [FieldType.CHECKBOX]: () => yup.boolean(),
  [FieldType.DATE]: () => yup.mixed().datetime(),
  [FieldType.DATE_RANGE]: () => yup.array().min(2),
} as const;

const getSchemaForContentType = (field: DataField): YupSchema => {
  switch (field.contentType) {
    case ContentType.Options: {
      return yup
        .mixed()
        .test(
          "isValidOptionValue",
          field.type === FieldType.MULTIPLE_CHOICE_WITH_SCALE
            ? t("datablocks.errors.choiceAndScaleRequired")
            : t("datablocks.errors.oneOptionRequired"),
          (value) => {
            if (!value) return true;

            if (field.type === FieldType.MULTIPLE_CHOICE_WITH_SCALE) {
              if (typeof value !== "object") return false;
              return (
                "choice" in value &&
                value.choice != null &&
                "scale" in value &&
                value.scale != null
              );
            }
            return field.hasOther
              ? typeof value === "number" || typeof value === "string"
              : typeof value === "number";
          },
        );
    }
    case ContentType.Files: {
      return yup
        .mixed()
        .test(
          "isValidFileValue",
          t("datablocks.errors.fileOrNumberRequired"),
          (value) =>
            value === null ||
            value === undefined ||
            value instanceof File ||
            typeof value === "number",
        );
    }
    case ContentType.Users: {
      return yup.number();
    }
    default:
      return yup.number();
  }
};

const getSchemaForField = (field: DataField): YupSchema => {
  const schemaGenerator = FIELD_TYPE_SCHEMA_MAP[field.type];
  if (schemaGenerator) {
    return schemaGenerator();
  }

  if (CHOICE_FIELDS.includes(field.type)) {
    const schema = getSchemaForContentType(field);
    if (MULTIPLE_CHOICE_FIELDS.includes(field.type)) {
      return yup.array().of(schema);
    }
    return schema;
  }

  return yup.string();
};

export const generateValidationSchema = (
  fields: DataField[],
  templateRules: ValidationRuleType[],
  t: TFunction,
): ValidationRulesFormSchema => {
  const validationService = new ValidationService(t);

  const fieldValuesSchema = yup.array().of(
    yup.object().shape({
      field: yup.number(),
      value: yup.lazy((_, { parent }) => {
        const fieldDef = fields.find((f) => f.id === parent.field);
        if (!fieldDef) return yup.mixed();

        const baseSchema = getSchemaForField(fieldDef);
        return validationService.setValidationRules(fieldDef, baseSchema);
      }),
    }),
  );

  let formSchema = yup.object().shape({
    fieldValues: fieldValuesSchema,
  });

  // Apply template rules
  templateRules.forEach((rule) => {
    const validationRule = validationService.createRule(
      rule.type,
      null,
      formSchema,
      rule,
    );

    if (validationRule) {
      const { schema: newSchema } = validationRule.validate();
      formSchema = newSchema as ValidationRulesFormSchema;
    }
  });

  return formSchema as ValidationRulesFormSchema;
};
