import { createUrl } from "../../utilities/index.js";
import { DateTime } from "luxon";

const transformSurveyRequest = (data) => {
  if (data?.startDate) {
    data.startDate = (
      data.startDate instanceof Date
        ? DateTime.fromJSDate(data.startDate)
        : DateTime.fromISO(data.startDate)
    ).toFormat("yyyy-MM-dd");
  }
  if (data?.hardDeadline) {
    data.hardDeadline = (
      data.hardDeadline instanceof Date
        ? DateTime.fromJSDate(data.hardDeadline)
        : DateTime.fromISO(data.hardDeadline)
    ).toFormat("yyyy-MM-dd");
  }
  if (data?.softDeadline) {
    data.softDeadline = (
      data.softDeadline instanceof Date
        ? DateTime.fromJSDate(data.softDeadline)
        : DateTime.fromISO(data.softDeadline)
    ).toFormat("yyyy-MM-dd");
  }
  return data;
};

const surveyService = (builder) => ({
  paginateFilterSurveys: builder.query({
    query: (filters = {}) => createUrl("/surveys/", filters),
    providesTags: (result) =>
      result
        ? [
            ...(result?.results || result).map((i) => ({
              type: "Survey",
              id: i.id,
            })),
            { type: "Surveys" },
          ]
        : [],
  }),
  getSurveyInvites: builder.query({
    query: (filters = {}) => createUrl("/surveys/details/", filters),
    providesTags: (result) =>
      result
        ? [
            ...(result?.results || result).map((i) => ({
              type: "SurveyInvite",
              id: i.id,
            })),
            { type: "SurveyInvites" },
          ]
        : [],
  }),
  getSurveyById: builder.query({
    query: ({ surveyId, filters }) =>
      createUrl(`/surveys/${surveyId}/`, filters),
    providesTags: (result) =>
      result ? [{ type: "Survey", id: result.id }] : [],
  }),
  getSurveyAdminStatistics: builder.query({
    query: (surveyId) => `/surveys/${surveyId}/statistics/`,
    providesTags: (result) =>
      result ? [{ type: "SurveyAdminStatistics", id: result.id }] : [],
  }),
  createSurvey: builder.mutation({
    query: (data) => ({
      url: "/surveys/",
      method: "POST",
      data: transformSurveyRequest(data),
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Surveys", id: result.id }] : [],
  }),
  patchSurvey: builder.mutation({
    query: ({ id, data }) => ({
      url: `/surveys/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Survey", id: result.id }] : [],
  }),
  deleteSurvey: builder.mutation({
    query: (id) => ({
      url: `/surveys/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error) => (error ? [] : [{ type: "Surveys" }]),
  }),
  getAdminSurveyStatistics: builder.query({
    query: (filters = {}) =>
      createUrl("/surveys/details/", { ...filters, type: "statistics" }),
    providesTags: (result) =>
      result
        ? result.results.map((i) => ({ type: "SurveyDetails", id: i.id }))
        : [],
  }),
  getSurveyStatistics: builder.query({
    query: ({ survey, organization = null }) =>
      createUrl(`/surveys/${survey}/statistics/organization/`, {
        organization,
      }),
    providesTags: (result) =>
      result ? [{ type: "SurveyOrganizationStatistics" }] : [],
  }),
  getSections: builder.query({
    query: (filters = {}) => createUrl("/sections/", filters),
    providesTags: (result) => {
      if (result) {
        if (result?.results) {
          return [
            ...result.results.map((i) => ({ type: "Section", id: i.id })),
            { type: "Sections" },
          ];
        } else {
          return [
            ...result.map((i) => ({ type: "Section", id: i.id })),
            { type: "Sections" },
          ];
        }
      }
      return [{ type: "Sections" }];
    },
  }),
  getSectionById: builder.query({
    query: (id) => `/sections/${id}/`,
    providesTags: (result) =>
      result ? [{ type: "Section", id: result.id }, { type: "Sections" }] : [],
  }),
  createSection: builder.mutation({
    query: (data) => ({
      url: "/sections/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Section", id: result.id }, { type: "Sections" }] : [],
  }),
  updateSection: builder.mutation({
    query: ({ id, data }) => ({
      url: `/sections/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Section", id: result.id }, { type: "Sections" }] : [],
  }),
  deleteSection: builder.mutation({
    query: (id) => ({
      url: `/sections/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error) => (error ? [] : [{ type: "Sections" }]),
  }),
  getQuestions: builder.query({
    query: (filters = {}) => createUrl("/questions/", filters),
    providesTags: (result) => {
      if (result) {
        if (result?.results) {
          return [
            ...result.results.map((i) => ({ type: "Question", id: i.id })),
            { type: "Questions" },
          ];
        } else {
          return [
            ...result.map((i) => ({ type: "Question", id: i.id })),
            { type: "Questions" },
          ];
        }
      }
      return [{ type: "Questions" }];
    },
  }),
  getQuestionById: builder.query({
    query: ({ questionId, filters = {} }) =>
      createUrl(`/questions/${questionId}/`, filters),
    providesTags: (result) =>
      result
        ? [{ type: "Question", id: result.id }, { type: "Questions" }]
        : [],
  }),
  createQuestion: builder.mutation({
    query: (data) => ({
      url: "/questions/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) => (result ? [{ type: "Questions" }] : []),
  }),
  updateQuestion: builder.mutation({
    query: ({ id, data }) => ({
      url: `/questions/${id}/`,
      method: "PUT",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Question", id: result.id }] : [],
  }),
  patchOption: builder.mutation({
    query: ({ id, data }) => ({
      url: `/options/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "Question", id: result.question }] : [],
  }),
  deleteQuestion: builder.mutation({
    query: (id) => ({
      url: `/questions/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, args) =>
      error ? [] : [{ type: "Question", id: args }],
  }),
  getAnswers: builder.query({
    query: (filters) => createUrl("/answers/", filters),
    providesTags: (result) =>
      result
        ? [
            ...(result?.results || result).map((i) => ({
              type: "Answer",
              id: i.id,
            })),
            { type: "Answers" },
          ]
        : [],
  }),
  getAnswerById: builder.query({
    query: ({ id, filters = {} }) => ({
      url: createUrl(`/answers/${id}/`, filters),
      method: "GET",
    }),
    providesTags: (result) =>
      result ? [{ type: "Answer", id: result.id }] : [],
  }),
  createAnswer: builder.mutation({
    query: (data) => ({
      url: "/answers/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "SurveyOrganizationStatistics" },
            { type: "Question", id: result.question },
            ...result.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ]
        : [],
  }),
  updateAnswer: builder.mutation({
    query: ({ id, data }) => ({
      url: `/answers/${id}/`,
      method: "PUT",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "SurveyOrganizationStatistics" },
            { type: "Answer", id: result.id },
            { type: "Question", id: result.question },
            ...result.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ]
        : [],
  }),
  patchAnswer: builder.mutation({
    query: ({ id, data }) => ({
      url: `/answers/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "SurveyOrganizationStatistics" },
            { type: "Answer", id: result.id },
            ...result.attachedBlocks.map((block) => ({
              type: "DataBlockReference",
              id: block.id,
            })),
          ]
        : [],
  }),
  deleteAnswer: builder.mutation({
    query: ({ id, questionId }) => ({
      url: `/answers/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, args) => {
      return error
        ? []
        : [
            { type: "SurveyOrganizationStatistics" },
            { type: "Question", id: args.questionId },
          ];
    },
  }),
  updateSurveyDetails: builder.mutation({
    query: ({ id, data }) => ({
      url: `/surveys/details/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [{ type: "SurveyDetails" }, { type: "Survey", id: result.survey.id }]
        : [],
  }),
  sendSurveyInvites: builder.mutation({
    query: (data) => ({
      url: "/surveys/details/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            { type: "SurveyDetails" },
            { type: "Organization" },
            { type: "Organizations" },
            { type: "Users" },
            ...result.map((i) => ({ type: "Survey", id: i.survey })),
          ]
        : [],
  }),
  paginateFilterSurveyViews: builder.query({
    query: (filters = {}) => createUrl("/surveys/views/", filters),
    providesTags: (result) =>
      result
        ? [
            ...(result?.results || result).map((i) => ({
              type: "SurveyView",
              id: i.id,
            })),
            { type: "SurveyViews" },
          ]
        : [],
  }),
  getSurveyViewById: builder.query({
    query: (id) => `/surveys/views/${id}/`,
    providesTags: (result) =>
      result
        ? [{ type: "SurveyView", id: result.id }, { type: "SurveyViews" }]
        : [],
  }),
  createSurveyView: builder.mutation({
    query: (data) => ({
      url: "/surveys/views/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) => (result ? [{ type: "SurveyViews" }] : []),
  }),
  updateSurveyView: builder.mutation({
    query: ({ id, data }) => ({
      url: `/surveys/views/${id}/`,
      method: "PUT",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "SurveyView", id: result.id }] : [],
  }),
  deleteSurveyView: builder.mutation({
    query: (id) => ({
      url: `/surveys/views/${id}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, args) =>
      error ? [] : [{ type: "SurveyView", id: args }, { type: "SurveyViews" }],
  }),
  likeSurveyView: builder.mutation({
    query: ({ id, isLiked }) => ({
      url: `/surveys/views/${id}/likes/`,
      method: "POST",
      data: { isLiked },
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "SurveyView", id: result.id }] : [],
  }),
  incrementSurveyViewViews: builder.query({
    query: (id) => ({
      url: `/surveys/views/${id}/views/`,
      method: "GET",
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "SurveyView", id: result.id }] : [],
  }),
});

export default surveyService;
