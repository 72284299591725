import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { App, Col, Row, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";

import { setShowGreyBackground } from "../../redux/utils/reducer.js";
import ReportingStatistics from "./components/ReportingStatistics.jsx";
import OrganizationStatistics from "./components/OrganizationStatistics.jsx";
import CommunityStatistics from "./components/CommunityStatistics.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import { createUrl } from "../../utilities/index.js";
import { clientApi } from "../../utilities/api.js";
import BaseTabs from "../../components_v2/Tabs/BaseTabs.tsx";

const { Title } = Typography;

const Dashboard = () => {
  const dispatch = useDispatch();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    dispatch(setShowGreyBackground(true));

    return () => dispatch(setShowGreyBackground(false));
  }, []);

  const handleDownloadSurveyStatistics = () => {
    if (!selectedSurvey) {
      notification.error({
        message: t("selectSurveyToDownload"),
        key: "survey-statistics-download",
      });
      return;
    }

    setIsDownloading(true);
    const url = createUrl(`/surveys/${selectedSurvey}/statistics/download/`);
    clientApi({
      url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.headers["x-filename"]);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => setIsDownloading(false));
  };

  return (
    <div>
      <Row>
        <Col sm={24} md={18} lg={18}>
          <Title
            className="mb-4"
            level={2}
            style={{ color: token.colorTextThird }}
          >
            {t("labels.dashboard")}
          </Title>
        </Col>
        <Col sm={24} md={6} lg={6}>
          <PrimaryButton
            disabled={isDownloading}
            onClick={handleDownloadSurveyStatistics}
          >
            {isDownloading ? (
              <>
                <Spin size="small" className="me-2" />
                {t("downloading")}
              </>
            ) : (
              t("buttons.downloadStatistics")
            )}
          </PrimaryButton>
        </Col>
      </Row>
      <BaseTabs
        type="card"
        defaultActiveKey="reporting"
        items={[
          {
            label: t("labels.users"),
            key: "users",
            children: <OrganizationStatistics />,
          },
          {
            label: t("labels.reporting"),
            key: "reporting",
            children: (
              <ReportingStatistics
                setSelectedSurveyCallback={setSelectedSurvey}
              />
            ),
          },
          {
            label: t("labels.community"),
            key: "community",
            children: <CommunityStatistics />,
          },
        ]}
      />
    </div>
  );
};

export default Dashboard;
