import { useRef, useMemo, useCallback, useEffect, useState } from "react";

import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import L from "leaflet";
import { theme } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./index.css";
import { SurveyAudience } from "../../../utilities/constants.jsx";

const CountriesMap = ({ survey, countrySurveyStatisticsData }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const [countriesGeoData, setCountriesGeoData] = useState(null);
  const geoMap = useRef(null);

  useEffect(() => {
    fetch(
      "https://commons-static.s3.eu-central-1.amazonaws.com/assets/countries-geodata.json",
    ).then(async (response) => {
      const data = await response.json();
      setCountriesGeoData(data);
    });
  }, []);

  const getColor = (surveyDetails) => {
    const defaultColor = "#CCCCCC";

    if (!surveyDetails || surveyDetails.submittedSurveysCount === 0) {
      return defaultColor;
    }
    const normalizedValue =
      surveyDetails.submittedSurveysCount /
      countrySurveyStatisticsData["maxTotalSubmitted"];

    return `rgba(30, 50, 80, ${normalizedValue})`;
  };

  const highlightFeature = (e) => {
    let layer = e.target;
    layer.setStyle({
      color: "white",
      weight: 2,
    });
    layer.bringToFront();
  };

  const resetHighlight = (e) => {
    e.target.setStyle({
      color: "white",
      weight: 1,
    });
  };

  const onEachFeature = useCallback(
    (feature, layer) => {
      if (feature.properties.surveyData) {
        layer.bindTooltip(
          `
            <div>
                <h5 class="tooltip-country" style="color: ${token.colorTextThird};">${feature.properties.surveyData.name}</h5>
                <div style="color: ${token.colorTextThird}">
                    <div >
                        <h5 class="map-popup-bold" >${feature.properties.surveyData.entitiesCount}</h5><p class="map-popup-text"> ${t(survey.audience === SurveyAudience.ORGANIZATION.value ? "schools" : "users", { plural: feature.properties.surveyData.entitiesCount === 1 ? "" : "s" })}</p>
                    </div>
                    <div><h5 class="map-popup-bold">${feature.properties.surveyData.submittedSurveysCount}</h5><p class="map-popup-text"> ${t("reports", { plural: feature.properties.surveyData.submittedSurveysCount === 1 ? "" : "s" })}</p></div>
                    <div><h5 class="map-popup-bold">${feature.properties.surveyData.narrativesCount}</h5><p class="map-popup-text"> ${t("labels.narratives", { plural: feature.properties.surveyData.narrativesCount === 1 ? "" : "s" })}</p></div>
                    <div><h5 class="map-popup-bold">${feature.properties.surveyData.blocksCount}</h5><p class="map-popup-text"> ${t("labels.dataBlock", { plural: feature.properties.surveyData.blocksCount === 1 ? "s" : "s" })}</p></div>
                </div>
            </div>
        `,
          { sticky: true },
        );

        layer.on({
          mouseover: highlightFeature,
          mouseout: resetHighlight,
        });
      }
    },
    [countrySurveyStatisticsData],
  );

  const style = useCallback(
    (feature) => {
      let mapStyle = {
        fillColor: getColor(feature.properties.surveyData),
        weight: 1,
        opacity: 1,
        color: "white",
        fillOpacity: 1,
      };

      return mapStyle;
    },
    [getColor],
  );

  const geoJsonComponent = useMemo(() => {
    if (!countriesGeoData) {
      return null;
    }

    const countriesData = {
      ...countriesGeoData,
      features: countriesGeoData["features"].map((i) => {
        return {
          ...i,
          properties: {
            ...i["properties"],
            surveyData:
              countrySurveyStatisticsData["data"][i["properties"]["iso_a2"]],
          },
        };
      }),
    };

    return (
      <GeoJSON
        data={countriesData}
        style={style}
        onEachFeature={onEachFeature}
        ref={geoMap}
      />
    );
  }, [style, onEachFeature, countrySurveyStatisticsData]);

  return (
    <div className="dashboard-map">
      <MapContainer
        center={[40, 0]}
        zoomControl={false}
        zoom={0}
        maxZoom={3.5}
        minZoom={2}
        zoomSnap={0.5}
        zoomDelta={0.5}
        wheelPxPerZoomLevel={120}
        maxBoundsViscosity={0.5}
        style={{ backgroundColor: "white" }}
        maxBounds={L.latLngBounds(
          new L.LatLng(85, -210),
          new L.LatLng(-85, 210),
        )}
      >
        <TileLayer
          style={style}
          onEachFeature={onEachFeature}
          ref={geoMap}
          url="https://api.mapbox.com/styles/v1/unprmetech/cm0y21ptp011c01pi1s6eduxu/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidW5wcm1ldGVjaCIsImEiOiJjbTB3aDQwOWMwMXJvMmxzNTk0cDZjZG5hIn0.uEXPhzPzjk-Cl4VJ4AjcAw"
        />
        {geoJsonComponent}
      </MapContainer>
    </div>
  );
};

CountriesMap.propTypes = {
  survey: PropTypes.object.isRequired,
  countrySurveyStatisticsData: PropTypes.object.isRequired,
};

export default CountriesMap;
