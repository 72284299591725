import { Checkbox } from "antd";

import { CheckboxElementProps } from "./types";

import "./styles.css";

const CheckboxElement = ({ label, value }: CheckboxElementProps) => {
  return (
    <div>
      <Checkbox
        className="checkbox-element body-s-regular"
        checked={value}
        disabled={true}
      >
        {label}
      </Checkbox>
    </div>
  );
};

export default CheckboxElement;
