import { App, Divider } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import {
  useDeleteDataBlockMutation,
  useGetCurrentUserQuery,
  useLazyGetDataBlockByIdQuery,
  useLazyGetDataTemplateByIdQuery,
  useLazyIncrementDataBlockViewsQuery,
  usePatchDataBlockMutation,
} from "@/redux/service";
import UserAvatar from "@/components_v2/Avatar/UserAvatar";
import BaseButton from "@/components_v2/Button/BaseButton";
import BaseTag from "@/components_v2/Tags/BaseTag";
import AddContributorsModal from "../core/components/AddContributorsModal";
import { getContentItemForField } from "../core/fields/renderers";
import { useDataBlockPermissions } from "../core/hooks/useDataBlockPermissions";
import { useDataBlockContributors } from "../core/hooks/useDataBlockField";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import DataBlockReferences from "../core/components/DataBlockReferences";
import CancelConfirmationModal from "@/components_v2/Modal/CancelConfirmationModal";
import {
  DataBlockActionButtonsProps,
  DataBlockRequestEditModalsState,
  DataBlockSidebarProps,
} from "../types/types";
import {
  DataBlockContent,
  DataBlockDetails,
} from "../core/components/DataBlockContent";
import { BaseUser } from "../../users/types/types";
import { DataBlock, FieldValue } from "../types/blocks";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip";
import { OrganizationRoles } from "@/utilities/constants";
import EditRequestModal from "../invites/components/EditRequestModal";
import { RequestStatus } from "../invites/constants";

const DataBlockSidebar = ({
  dataBlock,
  dataTemplate,
  showCtas,
}: DataBlockSidebarProps) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();
  const [patchDataBlock] = usePatchDataBlockMutation();
  const [contributorModalOpen, setContributorModalOpen] = useState(false);
  const contributors = useDataBlockContributors(dataBlock);
  const { canEdit } = useDataBlockPermissions({ dataBlock, dataTemplate });

  const deleteContributor = (deletedContributorId: number) => {
    const data = {
      fieldValues: [
        {
          field: dataBlock.fieldsMapping.contributors,
          value: (contributors.data as BaseUser[])
            .filter((i) => i.id !== deletedContributorId)
            .map((i) => i.id),
        },
      ],
    };
    patchDataBlock({ id: dataBlock.id, data })
      .unwrap()
      .then(() =>
        notification.success({
          message: t("datablocks.labels.contributorsDeleted"),
        }),
      );
  };

  return (
    <div className="data-block-sidebar">
      <div className="data-block-sidebar-contributors">
        <div className="data-block-sidebar-contributors-header">
          <h2 className="header-m-medium">
            {t("datablocks.labels.contributors")}
          </h2>

          {canEdit && showCtas && (
            <BaseButton
              variant="link"
              size="small"
              leadingIcon={<PlusOutlined />}
              onClick={() => setContributorModalOpen(true)}
            >
              {t("datablocks.labels.addContributors")}
            </BaseButton>
          )}
        </div>

        <div className="data-block-sidebar-contributors-items-container">
          {contributors.isLoading ? (
            <div className="data-block-sidebar-contributors-items-container">
              <BaseSpinner size="small" />
            </div>
          ) : (
            (contributors.data as BaseUser[]).map((contributor) => (
              <div
                key={contributor.id}
                className="data-block-sidebar-contributors-items"
              >
                <div className="data-block-sidebar-contributors-items-details">
                  <UserAvatar user={contributor} />
                  <span className="data-block-sidebar-contributors-items-names">
                    {contributor.fullName}
                  </span>
                </div>

                {canEdit && showCtas && (
                  <DeleteOutlined
                    onClick={() => deleteContributor(contributor.id)}
                  />
                )}
              </div>
            ))
          )}
        </div>
      </div>
      <Divider />
      <DataBlockReferences dataBlock={dataBlock} />
      <AddContributorsModal
        open={contributorModalOpen}
        setOpen={setContributorModalOpen}
        dataBlock={dataBlock}
        currentContributorIds={
          contributors.isLoading
            ? []
            : (contributors.data as BaseUser[]).map((i) => i.id)
        }
      />
    </div>
  );
};

const DataBlockActionButtons = ({
  dataBlock,
  dataTemplate,
  setOpenDeleteModal,
  setEditRequestModal,
}: DataBlockActionButtonsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dataBlockId } = useParams();

  const { canEdit, canDelete, isOwner, isContributor } =
    useDataBlockPermissions({ dataBlock, dataTemplate });
  const { data: currentUser } = useGetCurrentUserQuery();

  const deleteTooltipErrorMessage = useMemo(() => {
    if (!dataBlock || canDelete) return;

    if (dataBlock.isUsedInActiveSurvey) {
      return t("datablocks.labels.notDeletableUsedInSurvey");
    }

    if (dataBlock.request) {
      return t("datablocks.labels.notDeletableByInvitee");
    }
  }, [canDelete, dataBlock]);

  const editTooltipErrorMessage = useMemo(() => {
    if (canEdit) return;

    return t("datablocks.labels.notEditableByInvitee");
  }, [canEdit]);

  const editDeleteButtons = useMemo(() => {
    if (!dataBlock || (!isOwner && !isContributor)) return;

    return (
      <>
        <BaseTooltip title={deleteTooltipErrorMessage} placement="topLeft">
          <BaseButton
            variant="tertiary"
            leadingIcon={<DeleteOutlined />}
            disabled={!canDelete}
            size="small"
            onClick={() => setOpenDeleteModal(true)}
          >
            {t("datablocks.labels.deleteDatablock")}
          </BaseButton>
        </BaseTooltip>
        <BaseTooltip title={editTooltipErrorMessage} placement="topLeft">
          <BaseButton
            variant="secondary"
            leadingIcon={<EditOutlined />}
            disabled={!canEdit}
            size="small"
            onClick={() => navigate(`/evidence/${dataBlockId}/edit`)}
          >
            {t("datablocks.labels.editDatablock")}
          </BaseButton>
        </BaseTooltip>
      </>
    );
  }, [dataBlock, isOwner, isContributor, canEdit, canDelete, dataBlockId]);

  const requestEditButton = useMemo(() => {
    if (
      !currentUser ||
      !currentUser.organization ||
      !dataBlock ||
      dataBlock.request == null
    )
      return null;

    const isCurrentUserAdminOrSupervisor =
      currentUser.organizationRole &&
      [
        OrganizationRoles.ADMIN.value,
        OrganizationRoles.SUPERVISOR.value,
      ].includes(currentUser.organizationRole);
    const dataBlockBelongsToCurrentOrg =
      currentUser.organization.id === dataBlock.organization.id;

    if (!isCurrentUserAdminOrSupervisor || !dataBlockBelongsToCurrentOrg)
      return;
    const isEditAlreadyRequested =
      dataBlock.request!.status === RequestStatus.REVISION_NEEDED.value;

    return (
      <BaseTooltip
        title={
          isEditAlreadyRequested
            ? t("datablocks.labels.editIsAlreadyRequested")
            : undefined
        }
        placement="topLeft"
      >
        <BaseButton
          size="small"
          variant="secondary"
          leadingIcon={<FormOutlined />}
          disabled={isEditAlreadyRequested}
          onClick={() =>
            setEditRequestModal({
              open: true,
              request: dataBlock.request!.id,
            })
          }
        >
          {t("datablocks.labels.requestEdit")}
        </BaseButton>
      </BaseTooltip>
    );
  }, [currentUser, dataBlock]);

  return dataBlock.editable ? (
    <>
      {editDeleteButtons}
      {requestEditButton}
    </>
  ) : null;
};

const DataBlockContainer = ({
  initialDataBlock,
  showCtas = true,
}: {
  initialDataBlock: DataBlock;
  showCtas: boolean;
}) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();
  const { dataBlockId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [
    getDataBlockById,
    { data: fetchedDataBlock, isLoading: isDataBlockLoading },
  ] = useLazyGetDataBlockByIdQuery();
  const [
    getDataTemplateById,
    { data: dataTemplate, isLoading: isDataTemplateLoading },
  ] = useLazyGetDataTemplateByIdQuery();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [deleteDataBlock] = useDeleteDataBlockMutation();
  const [incrementDataBlockViews] = useLazyIncrementDataBlockViewsQuery();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dataBlock = useMemo(
    () => initialDataBlock || fetchedDataBlock,
    [initialDataBlock, fetchedDataBlock],
  );

  const [editRequestModal, setEditRequestModal] =
    useState<DataBlockRequestEditModalsState>({
      open: false,
      request: undefined,
    });

  const titleFieldValue = useMemo(() => {
    if (!dataTemplate || !dataBlock) return;
    const field = dataTemplate.templateFields.find(
      (tf) => tf.id === dataBlock.fieldsMapping.title,
    );
    const value = dataBlock.fieldValues.find(
      (fv: FieldValue) => fv.field === dataBlock.fieldsMapping.title,
    )?.value;
    if (!field || !value) return;

    return {
      field,
      value,
    };
  }, [dataTemplate, dataBlock]);

  useEffect(() => {
    if (dataBlockId) {
      getDataBlockById(dataBlockId);
    }
  }, [dataBlockId]);

  useEffect(() => {
    if (dataBlock) {
      getDataTemplateById(dataBlock.template.id);
      setTimeout(() => {
        incrementDataBlockViews(dataBlock.id);
      }, 10000);
    }
  }, [dataBlock]);

  const handleDelete = () => {
    deleteDataBlock(dataBlockId)
      .unwrap()
      .then(() => {
        notification.success({
          message: t("datablocks.labels.datablockIsDeleted"),
        });
        navigate("/evidence");
      });
  };

  const startDiscussion = () => {
    navigate("/discussions/form", {
      state: { dataBlock },
    });
  };

  const calculateTotalHeight = () => {
    const headerButton = document.querySelector(
      ".data-block-header-button",
    ) as HTMLElement;
    const headerBlock = document.querySelector(
      ".data-block-header",
    ) as HTMLElement;
    const baseHeader = document.querySelector(".base-header") as HTMLElement;

    const height =
      (headerButton?.offsetHeight || 0) +
      (headerBlock?.offsetHeight || 0) +
      (baseHeader?.offsetHeight || 0);

    return height + 1000 + "px";
  };

  if (isDataBlockLoading || isDataTemplateLoading) {
    return <BaseSpinner />;
  }

  if (!dataBlock || !dataTemplate) return;

  return (
    <div className="data-block">
      {showCtas &&
      currentUser &&
      currentUser.organizationRole !== OrganizationRoles.GUEST.value ? (
        <div className="data-block-header-button">
          {location.state?.showBackButton !== false ? (
            <BaseButton
              size="small"
              variant="tertiary"
              onClick={() => navigate(-1)}
            >
              {t("datablocks.labels.back")}
            </BaseButton>
          ) : (
            <div />
          )}
          <BaseButton size="small" onClick={startDiscussion}>
            {t("datablocks.labels.startDiscussion")}
          </BaseButton>
        </div>
      ) : null}

      <div className="data-block-header">
        <div className="data-block-header-content">
          <BaseTag color={dataTemplate.color} label={dataTemplate.title} />
          {titleFieldValue &&
            getContentItemForField(titleFieldValue, { label: undefined })}
        </div>

        <div className="data-block-header-content">
          {showCtas ? (
            <DataBlockActionButtons
              dataBlock={dataBlock}
              dataTemplate={dataTemplate}
              setOpenDeleteModal={setOpenDeleteModal}
              setEditRequestModal={setEditRequestModal}
            />
          ) : null}
        </div>
      </div>

      <div
        className="data-block-details-main-content-container"
        style={{
          height: `calc(100vh - ${calculateTotalHeight})`,
        }}
      >
        <div className="data-block-details-main-content-left-container">
          <DataBlockDetails dataBlock={dataBlock} dataTemplate={dataTemplate} />
          <DataBlockContent dataBlock={dataBlock} dataTemplate={dataTemplate} />
        </div>

        <div className="data-block-details-main-content-right-container">
          <DataBlockSidebar
            dataBlock={dataBlock}
            dataTemplate={dataTemplate}
            showCtas={showCtas}
          />
        </div>
      </div>

      <CancelConfirmationModal
        title={t("confirm.confirmDelete")}
        content={
          <span className="body-m-regular">
            {t("datablocks.labels.confirmDeleteContent")}{" "}
            <span className="body-m-medium">
              "{titleFieldValue?.value as string | undefined}"
            </span>
            ?
          </span>
        }
        confirmButtonText={t("buttons.delete")}
        open={openDeleteModal}
        onConfirm={handleDelete}
        onCancel={() => setOpenDeleteModal(false)}
      />

      <EditRequestModal
        open={editRequestModal.open}
        setOpen={(value) =>
          setEditRequestModal({ open: value, request: undefined })
        }
        request={editRequestModal.request}
      />
    </div>
  );
};

export default DataBlockContainer;
