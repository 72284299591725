import { Col, Row, Upload } from "antd";
import React, { useMemo, useRef } from "react";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { UploadChangeParam } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";
import { useTranslation } from "react-i18next";

import BaseError from "../BaseError";
import { BaseUploadProps } from "./types";
import { BaseFormError } from "../types";

import "./styles.css";

const { Dragger } = Upload;

const BaseUpload = ({
  value,
  onChange,
  uploadFn,
  onUploadStart,
  onUploadFinish,
  onUploadError,
  maxFileSize,
  supportedExtensions,
  multiple,
  shouldShowValues = true,
  error,
  ...props
}: BaseUploadProps) => {
  const { t } = useTranslation();
  const uploadingRef = useRef<Set<string>>(new Set());

  const handleUploadFunc = async (info: UploadChangeParam) => {
    if (!uploadFn) return;

    const newFiles = multiple
      ? info.fileList
          .map((file) => file.originFileObj)
          .filter(
            (file): file is RcFile =>
              file != null && !uploadingRef.current.has(file.uid),
          )
      : !uploadingRef.current.has(info.file.uid)
        ? [info.file]
        : [];

    if (newFiles.length === 0) return;
    newFiles.forEach((file) => uploadingRef.current.add(file.uid));

    try {
      if (onUploadStart) {
        onUploadStart(newFiles);
      }

      const { successful, failed } = await uploadFn(newFiles);

      if (onUploadFinish) {
        onUploadFinish(successful);
      }

      if (onUploadError) {
        onUploadError(failed);
      }

      if (multiple) {
        onChange([...(value || []), ...successful.map((i) => i.id)]);
      } else {
        const firstResponse = successful[0];
        onChange(firstResponse?.id);
      }
    } finally {
      newFiles.forEach((file) => uploadingRef.current.delete(file.uid));
    }
  };

  // If upload function is provided, upload the files and send the response to the callback, otherwise just set them to the form
  const handleChange = async (info: UploadChangeParam) => {
    if (uploadFn) {
      await handleUploadFunc(info);
    } else {
      if (multiple) {
        onChange(info.fileList);
      } else {
        onChange(info.file);
      }
    }
  };

  const handleDelete = (index: number) => {
    if (multiple) {
      onChange(
        value.filter((_: never, fileIndex: number) => fileIndex !== index),
      );
    } else {
      onChange(null);
    }
  };

  const label = useMemo(() => {
    let label = "";
    if (supportedExtensions) {
      const extensions = supportedExtensions
        .map((i) => i.replace(".", "").toUpperCase())
        .join(", ");
      label += t("form.validation.supportedExtensions", { extensions });
    }
    if (maxFileSize) {
      label += `, ${t("form.validation.maxFileSize", { fileSize: Math.floor(maxFileSize / 1000000) })}`;
    }

    return label;
  }, [supportedExtensions, maxFileSize]);

  return (
    <div className="base-upload">
      {value && !uploadFn && shouldShowValues && (
        <div className="mb-2">
          {(multiple ? value : [value]).map((i: File, index: number) => (
            <Row key={i.name}>
              <Col span={2}>
                <FileOutlined />
              </Col>
              <Col span={20} className="filename">
                {i.name}
              </Col>
              <Col span={2}>
                <DeleteOutlined onClick={() => handleDelete(index)} />
              </Col>
            </Row>
          ))}
        </div>
      )}

      <Dragger
        className={error && "base-uploader-error"}
        multiple={multiple}
        beforeUpload={() => false} // Needed in order to stop automatically posting file
        accept={supportedExtensions ? supportedExtensions.join(",") : undefined}
        showUploadList={false}
        {...props}
        fileList={[]}
        onChange={handleChange}
      >
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>

        <p className="ant-upload-text">{t("labels.dragAndDropFiles")}</p>

        <p className="mt-2" style={{ color: "rgba(128, 128, 128, 1)" }}>
          {label}
        </p>
      </Dragger>
      <BaseError error={error as BaseFormError} />
    </div>
  );
};

export default BaseUpload;
