import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { Pagination } from "antd";

const itemRender = (_, type, originalElement, hasNext, hasPrevious) => {
  if (type === "prev") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasPrevious ? "black" : "silver",
        }}
      >
        <LeftOutlined /> Previous
      </a>
    );
  }
  if (type === "next") {
    return (
      <a
        className="py-2 px-3"
        style={{
          border: "1px solid rgba(198, 206, 220, 1)",
          color: hasNext ? "black" : "silver",
        }}
      >
        Next <RightOutlined />
      </a>
    );
  }
  return originalElement;
};

const CustomPagination = ({ data, pagination, onChange }) => {
  return (
    <Pagination
      rootClassName="mt-4 text-center"
      style={{ justifyContent: "center" }}
      total={data.count}
      itemRender={(...props) =>
        itemRender(...props, data.next != null, data.previous != null)
      }
      showSizeChanger
      pageSize={pagination.pageSize}
      onChange={onChange}
    />
  );
};

CustomPagination.propTypes = {
  data: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomPagination;
