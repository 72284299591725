import { Checkbox, CheckboxChangeEvent } from "antd";

import { BaseCheckboxProps } from "./types";
import BaseError from "../BaseError";

import "./styles.css";

const BaseCheckbox = ({
  label,
  checked,
  value,
  onChange,
  error,
  ...props
}: BaseCheckboxProps) => {
  const isChecked = checked !== undefined ? checked : value;

  const handleChange = (e: CheckboxChangeEvent) => {
    onChange(e.target.checked);
  };

  return (
    <>
      <Checkbox
        className="base-checkbox"
        checked={isChecked}
        onChange={handleChange}
        {...props}
      >
        {label}
      </Checkbox>
      <BaseError error={error} />
    </>
  );
};

export default BaseCheckbox;
