import PropTypes from "prop-types";

import BaseError from "../../components_v2/FormFields/BaseError";

const FieldError = ({ errors }) => {
  let renderErrors = [];

  if (Array.isArray(errors)) {
    renderErrors = errors.map((i) => i.message);
  } else {
    renderErrors = [errors.message];
  }

  return [...new Set(renderErrors)].map(() => <BaseError error={errors} />);
};

FieldError.propTypes = {
  errors: PropTypes.any.isRequired,
};

export default FieldError;
