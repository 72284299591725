import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { App, Flex, theme } from "antd";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  useCreateDiscussionMutation,
  useGetTopicsQuery,
  useLazyGetDiscussionByIdQuery,
  useUpdateDiscussionMutation,
  useLazyGetSubjectsQuery,
  useGetCurrentUserQuery,
} from "../../redux/service.js";
import { handleErrors } from "../../utilities/index.js";

import ContentField from "./components/ContentField.jsx";
import BaseButton from "../../components_v2/Button/BaseButton.js";

import "./styles.css";
import { HookFormBaseDropdown } from "../../components_v2/FormFields/ReactHookForm/fields";
import {
  HookFormCheckbox,
  HookFormInput,
} from "../../components_v2/FormFields/ReactHookForm/fields.js";

const discussionSchema = (t) =>
  yup.object().shape({
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
    content: yup
      .string()
      .typeError(t("form.validation.bodyNotValid"))
      .required(t("form.validation.bodyRequired")),
    topicIds: yup
      .array()
      .typeError(t("form.validation.topicsNotValid"))
      .required(t("form.validation.topicsRequired"))
      .min(1, t("form.validation.topicsMinimum")),
    subjects: yup
      .array()
      .typeError(t("form.validation.tagsNotValid"))
      .required(t("form.validation.tagsRequired"))
      .min(1, t("form.validation.tagsMinimum")),
    notificationsEnabled: yup
      .bool()
      .typeError(t("form.validation.notificationsEnabledNotValid")),
    narratives: yup.array(),
    blocks: yup.array(),
    surveyViews: yup.array(),
  });

const DiscussionForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { discussionId } = useParams();
  const location = useLocation();

  const [getDiscussionById, { data: existingDiscussionData }] =
    useLazyGetDiscussionByIdQuery();
  const [createDiscussion, createDiscussionResponse] =
    useCreateDiscussionMutation();
  const [updateDiscussion] = useUpdateDiscussionMutation();
  const [
    getSubjects,
    { data: subjectsResponse, isSuccess: subjectsIsSuccess },
  ] = useLazyGetSubjectsQuery();
  const { data: topicsResponse, isSuccess: topicsIsSuccess } =
    useGetTopicsQuery({ pagination: "off" });
  const { data: currentUser } = useGetCurrentUserQuery();

  const form = useForm({
    resolver: yupResolver(discussionSchema(t)),
    mode: "onChange",
    defaultValues: {
      title: "",
      topicIds: null,
      subjects: null,
      content: "",
      notificationsEnabled: false,
      narratives: [],
      blocks: [],
      surveyViews: [],
    },
  });

  useEffect(() => {
    if (location.state?.dataBlock) {
      form.setValue("blocks", [location.state.dataBlock]);
    }
    if (location.state?.surveyView) {
      form.setValue("surveyViews", [location.state.surveyView]);
    }
  }, [location]);

  useEffect(() => {
    if (discussionId) {
      getDiscussionById(discussionId);
    } else if (currentUser) {
      getSubjects({
        pagination: "off",
        organization: currentUser.organization?.id,
      });
    }
  }, [currentUser, discussionId]);

  useEffect(() => {
    if (existingDiscussionData) {
      form.reset({
        title: existingDiscussionData.title,
        topicIds: existingDiscussionData.topics,
        subjects: existingDiscussionData.subjects.map((i) => i.name),
        content: existingDiscussionData.content,
        notificationsEnabled: existingDiscussionData.notificationsEnabled,
        narratives: existingDiscussionData.attachedNarratives,
        blocks: existingDiscussionData.attachedBlocks,
        surveyViews: existingDiscussionData.surveyViews,
      });
      getSubjects({
        pagination: "off",
      });
    }
  }, [existingDiscussionData]);

  const handleSubmit = (values) => {
    let mutationFunction;
    const data = {
      ...values,
      attachedBlockIds: values.blocks.map((i) => i.id),
      attachedNarrativeIds: values.narratives.map((i) => i.id),
      attachedSurveyViewUuids: values.surveyViews.map((i) => i.uuid),
      subjects: values.subjects.map((i) => ({ name: i })),
    };
    if (discussionId) {
      mutationFunction = updateDiscussion({ id: discussionId, data });
    } else {
      mutationFunction = createDiscussion(data);
    }

    mutationFunction
      .unwrap()
      .then((data) => {
        navigate(`/discussions/${data.id}`);
        notification.success({
          message: discussionId
            ? t("discussionUpdated")
            : t("newDiscussionCreated"),
        });
      })
      .catch((errors) => handleErrors(errors, form.setError, notification));
  };

  return (
    <Flex vertical gap={24} className="discussion-form">
      <h1 className="header-l-medium">
        {discussionId ? t("updateDiscussion") : t("newDiscussion")}
      </h1>

      <Flex vertical gap={16}>
        <Flex vertical gap={4}>
          <div className="body-s-medium">{t("form.labels.title")}</div>

          <HookFormInput
            name="title"
            placeholder={t("form.placeholders.title")}
            control={form.control}
          />
        </Flex>

        <Flex vertical gap={4}>
          <div className="body-s-medium">{t("form.labels.topics")}</div>

          <HookFormBaseDropdown
            mode="multiple"
            name="topicIds"
            options={
              topicsIsSuccess
                ? topicsResponse.map((i) => ({
                    value: i.id,
                    label: i.title,
                  }))
                : []
            }
            placeholder={t("form.placeholders.topics")}
            control={form.control}
          />
        </Flex>

        <Flex vertical gap={4}>
          <div className="body-s-medium">{t("form.labels.subjects")}</div>

          <HookFormBaseDropdown
            mode="tags"
            name="subjects"
            options={
              subjectsIsSuccess
                ? subjectsResponse.map((i) => ({
                    value: i.name,
                    label: i.name,
                  }))
                : []
            }
            placeholder={t("form.placeholders.subjectsSelect")}
            control={form.control}
          />
        </Flex>

        <ContentField form={form} label={t("form.labels.body")}>
          <Flex vertical gap={"small"}>
            <HookFormCheckbox
              name="notificationsEnabled"
              label={t("form.labels.emailNotificationsComments")}
              control={form.control}
            />

            <Flex wrap justify="space-between">
              <BaseButton
                variant="tertiary"
                onClick={() => navigate("/community")}
              >
                {t("buttons.cancel")}
              </BaseButton>

              <BaseButton
                variant="primary"
                disabled={createDiscussionResponse.isLoading}
                onClick={form.handleSubmit(handleSubmit)}
              >
                {t("buttons.post")}
              </BaseButton>
            </Flex>
          </Flex>
        </ContentField>
      </Flex>
    </Flex>
  );
};

export default DiscussionForm;
