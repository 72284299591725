import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useDataBlockRequestForm } from "../hooks/useDataBlockRequestsForm";
import BaseButton from "@/components_v2/Button/BaseButton";
import BaseModal from "@/components_v2/Modal/BaseModal";
import RequestForm from "./RequestForm";
import CancelConfirmationModal from "@/components_v2/Modal/CancelConfirmationModal";
import { SimpleModalProps } from "@/components_v2/Modal/types";

const RequestFormModal = ({ open, setOpen }: SimpleModalProps) => {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const formHook = useDataBlockRequestForm({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const renderFooter = () => {
    const { currentStep, handleNextStep, handlePreviousStep, isSubmitting } =
      formHook;

    return (
      <div className="data-block-requests-modal-footer-buttons">
        {currentStep > 0 && (
          <BaseButton
            variant="tertiary"
            size="large"
            onClick={handlePreviousStep}
          >
            {t("buttons.back")}
          </BaseButton>
        )}
        <BaseButton onClick={handleNextStep} loading={isSubmitting}>
          {t(
            currentStep === 0
              ? "datablocks.labels.next"
              : "datablocks.labels.send",
          )}
        </BaseButton>
      </div>
    );
  };

  const handleClose = () => {
    formHook.resetForm();
    setOpen(false);
  };

  return (
    <>
      <BaseModal
        title={t("datablocks.labels.sendRequests")}
        open={open}
        width={800}
        onCancel={() => setOpenDeleteModal(true)}
        footer={renderFooter()}
      >
        <RequestForm formHook={formHook} />
      </BaseModal>

      <CancelConfirmationModal
        title={t("discardChanges")}
        content={t("discardChangesContent")}
        confirmButtonText={t("buttons.discard")}
        open={openDeleteModal}
        onConfirm={() => {
          handleClose();
          setOpenDeleteModal(false);
        }}
        onCancel={() => {
          setOpenDeleteModal(false);
        }}
      />
    </>
  );
};

export default RequestFormModal;
