import { useMemo } from "react";

import { DataBlock, DynamicValue } from "../../types/blocks";
import {
  ContentType,
  DataField,
  DataTemplate,
  FieldType,
} from "../../types/templates";
import { getDisplayComponentForField } from "../fields/renderers";
import { useDataBlockRelatedData } from "./useDataBlockRelatedData";
import { BaseUser } from "@/views/users/types/types";

export function formatValueByType(
  value: DynamicValue,
  field: DataField,
): string[] | string | undefined {
  if (value === undefined) {
    return undefined;
  }

  switch (field.type) {
    case FieldType.TEXT:
    case FieldType.TEXT_AREA:
    case FieldType.RICH_TEXT:
    case FieldType.DATE:
      return value as string;
    case FieldType.SINGLE_CHOICE: {
      const selectedOption = field.options?.find((opt) => opt.id === value);
      return selectedOption?.label;
    }
    case FieldType.MULTIPLE_CHOICE: {
      const values = (value as number[]) || [];
      const selectedOptions = field.options.filter((opt) =>
        values.includes(opt.id),
      );
      return selectedOptions.map((opt) => opt.label) as string[];
    }
    default:
      return undefined;
  }
}

export function getDataBlockFieldValue<T = DynamicValue>(
  dataBlock: DataBlock | undefined,
  fieldMapping: keyof DataBlock["fieldsMapping"],
  template?: DataTemplate,
  formatter?: (value: DynamicValue, field: DataField) => T,
): T | undefined {
  if (!dataBlock) return undefined;
  const field = dataBlock.fieldValues.find(
    (value) => value.field === dataBlock.fieldsMapping[fieldMapping],
  );

  if (!field) return undefined;

  if (formatter && template) {
    const templateField = template.templateFields.find(
      (tf) => tf.id === field.field,
    );

    if (!templateField) return undefined;

    return formatter(field.value as DynamicValue, templateField);
  }

  return field.value as T;
}

export function getDataBlockTitle(
  dataBlock: DataBlock | undefined,
): string | undefined {
  return getDataBlockFieldValue<string>(dataBlock, "title");
}

export function getDataBlockContributors(
  dataBlock: DataBlock | undefined,
): number[] {
  return getDataBlockFieldValue<number[]>(dataBlock, "contributors") || [];
}

export function getDataBlockLanguages(
  dataBlock: DataBlock | undefined,
  template: DataTemplate | undefined,
): string[] | undefined {
  const rawLanguages = getDataBlockFieldValue(dataBlock, "languages");
  const languagesField = template?.templateFields.find(
    (tf) => tf.id === dataBlock?.fieldsMapping.languages,
  );

  if (!languagesField) return undefined;
  return formatValueByType(
    rawLanguages as DynamicValue,
    languagesField,
  ) as string[];
}

export function useDataBlockField(
  dataBlock: DataBlock | undefined,
  fieldName: keyof DataBlock["fieldsMapping"],
  template?: DataTemplate,
  options: {
    rawValue?: boolean;
    componentOnly?: boolean;
  } = {},
) {
  const rawValue = useMemo(
    () => getDataBlockFieldValue(dataBlock, fieldName),
    [dataBlock, fieldName],
  );

  const field = template?.templateFields.find(
    (tf) => tf.id === dataBlock?.fieldsMapping[fieldName],
  );

  const formattedValue = useMemo(() => {
    if (!field || options.rawValue) return rawValue;
    return formatValueByType(rawValue as DynamicValue, field);
  }, [rawValue, field, options.rawValue]);

  if (options.componentOnly && field) {
    return getDisplayComponentForField(field, rawValue);
  }

  return formattedValue;
}

export function useDataBlockContributors(
  dataBlock: DataBlock | undefined,
  options?: { rawValue?: boolean },
) {
  const contributorIds = useDataBlockField(
    dataBlock,
    "contributors",
    undefined,
    { rawValue: true },
  ) as number[] | undefined;

  const { data: contributors, isLoading } = useDataBlockRelatedData(
    contributorIds || [],
    ContentType.Users,
  ) as { data: BaseUser[] | undefined; isLoading: boolean };

  return {
    data: (options?.rawValue ? contributorIds : contributors) || [],
    isLoading,
  };
}
