import { useEffect, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { App } from "antd";
import { DateTime } from "luxon";

import {
  useGetCurrentUserQuery,
  useGetDataBlockByIdQuery,
  useGetDataBlockRequestByIdQuery,
  useLazyGetDataTemplateByIdQuery,
  useUpdateDataBlockMutation,
} from "@/redux/service";
import BaseTag from "@/components_v2/Tags/BaseTag";
import BaseButton from "@/components_v2/Button/BaseButton";
import DataBlockDetailsForm from "../core/components/DataBlockDetailsForm";
import { getContentItemForField } from "../core/fields/renderers";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import { DataBlockProvider } from "../core/providers/DataBlockProvider";
import { useDataBlockForm } from "../core/hooks/useDataBlockForm";
import { useDataBlockValidation } from "../core/hooks/useDataBlockValidation";
import { useDataBlockSubmission } from "../core/hooks/useDataBlockSubmission";
import { DataField } from "../types/templates";
import { useDataBlockPermissions } from "../core/hooks/useDataBlockPermissions";
import { handleErrors } from "@/utilities";
import ListItemsCard from "../../../components_v2/Card/ListItemsCard";
import CalendarIcon from "@/assets/icons/calendar.svg?react";
import SurveyIcon from "@/assets/icons/survey-icon.svg?react";
import BaseSvgRenderer from "@/components_v2/Svg/BaseSvgRenderer";

import "./styles.css";

const UpdateDataBlockContent = () => {
  const { dataBlockId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const { data: dataBlock, isSuccess: isDataBlockSuccess } =
    useGetDataBlockByIdQuery(dataBlockId);
  const { data: blockRequest, isSuccess: isBlockRequestSuccess } =
    useGetDataBlockRequestByIdQuery(dataBlock?.request?.id, {
      skip: dataBlock?.request == null,
    });

  const [
    getDataTemplateById,
    { data: dataTemplate, isSuccess: isTemplateSuccess },
  ] = useLazyGetDataTemplateByIdQuery();
  const { data: currentUser } = useGetCurrentUserQuery();
  const [updateDataBlock] = useUpdateDataBlockMutation();

  const [permissionChecked, setPermissionChecked] = useState(false);

  const { isSubmissionStarted, handleSubmit } =
    useDataBlockSubmission(currentUser);
  const { validationSchema, updateSchemaForTemplate } =
    useDataBlockValidation();
  const { form, orderFieldValuesAccordingToTemplateFields } =
    useDataBlockForm(validationSchema);
  const { canEdit } = useDataBlockPermissions({ dataBlock, dataTemplate });

  const titleFieldValue = useMemo(() => {
    if (!dataTemplate || !dataBlock) return;
    const field = dataTemplate.templateFields.find(
      (tf) => tf.id === dataBlock.fieldsMapping.title,
    );
    const value = dataBlock.fieldValues.find(
      (fv) => fv.field === dataBlock.fieldsMapping.title,
    )?.value;
    if (!field || !value) return;

    return {
      field,
      value,
    };
  }, [dataTemplate, dataBlock]);

  const templateFieldsMapping = useMemo(() => {
    if (!dataTemplate) return null;

    return dataTemplate.templateFields.reduce<Record<number, DataField>>(
      (acc, item) => {
        acc[item.id] = item;
        return acc;
      },
      {},
    );
  }, [dataTemplate]);

  useEffect(() => {
    if (isDataBlockSuccess && dataBlock) {
      if (!dataTemplate) {
        getDataTemplateById(dataBlock.template.id);
      } else if (templateFieldsMapping) {
        setInitialDataToForm();
      }
    }
  }, [dataBlock, templateFieldsMapping, dataTemplate, isDataBlockSuccess]);

  useEffect(() => {
    if (isTemplateSuccess && dataTemplate) {
      updateSchemaForTemplate(dataTemplate);
    }
  }, [dataTemplate, isTemplateSuccess]);

  useEffect(() => {
    if (dataTemplate && currentUser && dataBlock) {
      if (!canEdit) {
        notification.error({
          message: t("datablocks.errors.unauthorized"),
        });
        navigate(`/evidence/${dataBlockId}`, {
          state: { showBackButton: false },
        });
      }
      setPermissionChecked(true);
    }
  }, [dataTemplate, currentUser, dataBlock]);

  const setInitialDataToForm = () => {
    if (templateFieldsMapping && dataBlock && dataTemplate) {
      const values = dataBlock.fieldValues.map((fieldValue) => ({
        field: fieldValue.field,
        value: fieldValue.value,
      }));
      const fieldOrder = dataTemplate.templateFields.map((field) => field.id);
      const orderedValues = orderFieldValuesAccordingToTemplateFields(
        fieldOrder,
        values,
      );
      const existingValues = orderedValues.map((fieldValue) => ({
        field: fieldValue.field,
        value: fieldValue.value,
      }));
      const allFieldValues = dataTemplate.templateFields.map((field) => {
        const existingValue = existingValues.find((v) => v.field === field.id);
        return existingValue || { field: field.id, value: undefined };
      });

      form.setValue("dataTemplate", dataBlock.template.id);
      form.setValue("sharingRights", dataBlock.sharingRights);
      form.setValue("fieldValues", allFieldValues);
    }
  };

  const handleFormSubmit = async () => {
    const isValid = await form.trigger(["sharingRights", "fieldValues"]);
    if (!isValid || !currentUser) return;

    handleSubmit(
      form.getValues(),
      (data) => updateDataBlock({ id: dataBlockId, data }).unwrap(),
      () => {
        notification.success({
          message: t("datablocks.labels.dataBlockSaved"),
        });
        navigate(`/evidence/${dataBlockId}`, {
          state: { showBackButton: false },
        });
        form.reset({});
      },
      (errors) => {
        handleErrors(errors, form.setError, notification);
      },
    );
  };

  const dataBlockContentFields = useMemo(() => {
    if (!dataBlock) return [];

    return [
      dataBlock.fieldsMapping.title,
      "sharingRights",
      dataBlock.fieldsMapping.contributors,
      dataBlock.fieldsMapping.languages,
      dataBlock.fieldsMapping.publishDate,
    ];
  }, [dataBlock]);

  const dataBlockDetailsFields = useMemo(() => {
    if (!dataBlock || !dataTemplate) return [];

    const fieldsToShow = dataBlockContentFields;
    return dataTemplate.templateFields
      .filter((field) => !fieldsToShow.includes(field.id))
      .map((i) => i.id);
  }, [dataBlock, dataBlockContentFields, dataTemplate]);

  if (!dataBlock || !dataTemplate || !currentUser || !permissionChecked) {
    return (
      <div className="data-block-loading-state">
        <BaseSpinner size="large" />
      </div>
    );
  }

  return (
    <div className="update-datablock">
      <div className="update-datablock-header">
        <BaseButton
          variant="tertiary"
          size="small"
          onClick={() =>
            navigate(`/evidence/${dataBlockId}`, {
              state: { showBackButton: false },
            })
          }
        >
          {t("buttons.cancel")}
        </BaseButton>

        <BaseButton
          onClick={handleFormSubmit}
          size="small"
          loading={isSubmissionStarted}
        >
          {t("buttons.saveChanges")}
        </BaseButton>
      </div>

      {isBlockRequestSuccess && (
        <ListItemsCard
          className="update-data-block-info-card"
          title={blockRequest.status}
          items={[
            {
              icon: <BaseSvgRenderer component={SurveyIcon} />,
              content: blockRequest.message,
            },
            {
              icon: <BaseSvgRenderer component={CalendarIcon} />,
              content: DateTime.fromISO(blockRequest.expiresAt).toFormat(
                "dd MMM yyyy",
              ),
            },
          ]}
        />
      )}

      <div className="data-block-header-content">
        <BaseTag color={dataTemplate.color} label={dataTemplate.title} />

        <h1 className="header-l-medium">
          {titleFieldValue &&
            getContentItemForField(titleFieldValue, { label: undefined })}
        </h1>
      </div>

      <FormProvider {...form}>
        <div className="update-datablock-content-container">
          <div className="update-datablock-content-details">
            <h2 className="header-m-medium">
              {t("datablocks.labels.datablocksDetails")}
            </h2>

            <DataBlockDetailsForm
              dataTemplate={dataTemplate}
              excludeFields={dataBlockDetailsFields}
            />
          </div>

          <div className="update-datablock-content-content">
            <h2 className="header-m-medium">
              {t("datablocks.labels.datablocksContent")}
            </h2>

            <DataBlockDetailsForm
              dataTemplate={dataTemplate}
              excludeFields={dataBlockContentFields}
            />
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

const UpdateDataBlock = () => {
  return (
    <DataBlockProvider>
      <UpdateDataBlockContent />
    </DataBlockProvider>
  );
};

export default UpdateDataBlock;
