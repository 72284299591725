import { Tooltip } from "antd";

import { BaseTooltipProps } from "./types";

const BaseTooltip = ({ children, ...props }: BaseTooltipProps) => {
  return (
    <Tooltip classNames={{ root: "base-tooltip" }} color="white" {...props}>
      {/* Do not remove span, otherwise tooltip doesn't work. */}
      <span>{children}</span>
    </Tooltip>
  );
};

export default BaseTooltip;
