import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { App, theme, Typography } from "antd";

import { useResetPasswordMutation } from "../../redux/service.js";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import Input from "../../components/form/Input.jsx";
import { handleErrors } from "../../utilities/index.js";
import FormField from "../../components/form/FormField.jsx";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const resetPasswordSchema = (t) =>
  yup
    .object()
    .shape({
      email: yup
        .string()
        .typeError(t("form.validation.emailNotValid"))
        .email(t("form.validation.enterValidEmail"))
        .required(t("form.validation.enterValidEmail")),
    })
    .required();

const ResetPassword = () => {
  const navigate = useNavigate();
  const { notification } = App.useApp();
  const { token } = theme.useToken();
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();
  const { t } = useTranslation();

  const { control, handleSubmit, setError } = useForm({
    resolver: yupResolver(resetPasswordSchema(t)),
    mode: "onChange",
  });

  const onSubmit = (values) => {
    resetPassword(values)
      .unwrap()
      .catch((errors) => {
        handleErrors(errors, setError, notification);
        if (errors?.data?.errors[0]?.code === "email_verification") {
          navigate("/email-verification");
        }
      });
  };

  return (
    <div className="m-auto form-wrapper" style={{ width: "60%" }}>
      <div
        className="text-center mt-3 mb-4 w-75 m-auto"
        style={{ maxWidth: "400px" }}
      >
        <div className="text-center mb-4 mt-5">
          <Title
            level={3}
            style={{
              color: token.colorPrimaryText,
              fontSize: "clamp(18px, 2vw, 25px)",
            }}
          >
            {t("titles.resetPassword")}
          </Title>
        </div>
        {isSuccess ? (
          <div>
            <Title
              level={4}
              style={{
                color: token.colorPrimaryText,
                fontSize: "clamp(15px, 2vw, 20px)",
              }}
            >
              {t("messages.successChangePassword")} <br />
              {t("messages.successChangePasswordDetails")}
            </Title>
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className="text-start">
            <div>
              <FormField
                label={t("form.labels.email")}
                required
                field={
                  <Input
                    placeholder={t("form.placeholders.enterYourEmail")}
                    name="email"
                    control={control}
                  />
                }
              />
            </div>
            <div className="mt-3 text-center">
              <PrimaryButton
                className="mb-3"
                disabled={isLoading}
                htmlType="submit"
              >
                {t("buttons.submit")}
              </PrimaryButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
