import { Steps } from "antd";

import { BaseStepperProps } from "./types";

import "./styles.css";

const BaseStepper = ({ ...props }: BaseStepperProps) => {
  return (
    <Steps
      size="small"
      labelPlacement="vertical"
      className="base-stepper"
      {...props}
    />
  );
};

export default BaseStepper;
