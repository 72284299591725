import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { App } from "antd";
import { InferType } from "yup";

import { DataBlockEditRequestFormHookProps } from "@/views/datablocks/types/types";
import { getDataBlockTitle } from "@/views/datablocks/core/hooks/useDataBlockField";
import {
  useLazyGetDataBlockByIdQuery,
  useLazyGetDataBlockRequestByIdQuery,
  useRequestDataBlockEditMutation,
} from "@/redux/service";

const useEditDataBlockRequestForm = ({
  request,
  onSuccess,
}: DataBlockEditRequestFormHookProps) => {
  const { t } = useTranslation();
  const [requestEdit, { isLoading: isSubmitting }] =
    useRequestDataBlockEditMutation();
  const { notification } = App.useApp();

  const [getDataBlock, { data: dataBlock, isFetching }] =
    useLazyGetDataBlockByIdQuery();
  const [getDataBlockRequestById, { data: dataBlockRequest }] =
    useLazyGetDataBlockRequestByIdQuery();

  const editRequestSchema = yup.object().shape({
    message: yup
      .string()
      .typeError(t("datablocks.errors.messageNotValid"))
      .required(t("datablocks.errors.messageRequired")),
  });
  type EditRequestFormData = InferType<typeof editRequestSchema>;

  const form = useForm({
    resolver: yupResolver(editRequestSchema),
    mode: "onChange",
    defaultValues: {
      message: "",
    },
  });

  useEffect(() => {
    if (request) {
      getDataBlockRequestById(request);
    }
  }, [request]);

  useEffect(() => {
    if (dataBlockRequest) {
      getDataBlock(dataBlockRequest.dataBlock);
    }
  }, [dataBlockRequest]);

  const dataBlockTitle = useMemo(() => {
    return getDataBlockTitle(dataBlock);
  }, [dataBlock]);

  const handleSubmit = (data: EditRequestFormData) => {
    if (!dataBlockRequest) return;

    requestEdit({ id: dataBlockRequest.id, data })
      .unwrap()
      .then(() => {
        notification.success({
          message: t("datablocks.labels.editRequestedForDataBlock"),
        });
        if (onSuccess) {
          onSuccess();
        }
      });
  };

  return {
    form,
    dataBlockRequest,
    dataBlockTitle,
    isFetching,
    isSubmitting,
    handleSubmit: () => form.handleSubmit(handleSubmit)(),
  };
};

export default useEditDataBlockRequestForm;
