import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";

import BaseModal from "../../../../components_v2/Modal/BaseModal";
import BaseTabs from "../../../../components_v2/Tabs/BaseTabs";
import BaseButton from "../../../../components_v2/Button/BaseButton";
import {
  useGetCurrentUserQuery,
  usePaginateFilterDataTemplatesQuery,
} from "@/redux/service";
import RequestsTable from "./RequestsTable";
import RequestForm from "./RequestForm";
import DataBlocksTable from "../../core/components/DataBlocksTable";
import BaseSvgRenderer from "../../../../components_v2/Svg/BaseSvgRenderer";
import { useDataBlockPermissions } from "../../core/hooks/useDataBlockPermissions";
import { useDataBlockRequestForm } from "../hooks/useDataBlockRequestsForm";
import {
  activeTabs,
  DataBlockEditRequestsSurveyModalFooterProps,
  DataBlockRequestsSurveyModalFooterProps,
  DataBlockRequestsTabProps,
  DataBlocksTabProps,
  SurveyDataBlockModalProps,
  ViewTypes,
} from "../../types/types";
import { DataBlock } from "../../types/blocks";
import DataBlockInviteIcon from "@/assets/icons/datablock-invites.svg?react";
import EditRequestForm from "@/views/datablocks/invites/components/EditRequestForm";
import useEditDataBlockRequestForm from "@/views/datablocks/invites/hooks/useEditDataBlockRequestForm";
import { RequestStatus } from "@/views/datablocks/types/requests";
import BaseTooltip from "@/components_v2/Tooltip/BaseTooltip";
import CreateDataBlock from "@/views/datablocks/core/components/CreateDatablock";
import { DataTemplate } from "../../types/templates";

const RequestFormModalFooter = ({
  formHook,
  onBackToTabs,
}: DataBlockRequestsSurveyModalFooterProps) => {
  const { t } = useTranslation();

  const { currentStep, handleNextStep, handlePreviousStep, isSubmitting } =
    formHook;

  return (
    <div className="survey-data-blocks-modal-footer-buttons">
      {currentStep > 0 && (
        <BaseButton
          variant="tertiary"
          size="large"
          onClick={handlePreviousStep}
        >
          {t("buttons.back")}
        </BaseButton>
      )}

      {currentStep === 0 && (
        <BaseButton variant="tertiary" size="large" onClick={onBackToTabs}>
          {t("buttons.cancel")}
        </BaseButton>
      )}

      <BaseButton onClick={handleNextStep} loading={isSubmitting}>
        {t(
          currentStep === 0
            ? "datablocks.labels.next"
            : "datablocks.labels.send",
        )}
      </BaseButton>
    </div>
  );
};

const EditRequestFormModalFooter = ({
  formHook,
  onBackToTabs,
}: DataBlockEditRequestsSurveyModalFooterProps) => {
  const { t } = useTranslation();

  const { handleSubmit, isSubmitting } = formHook;

  return (
    <div className="survey-data-blocks-modal-footer-buttons">
      <BaseButton variant="tertiary" size="large" onClick={onBackToTabs}>
        {t("buttons.cancel")}
      </BaseButton>

      <BaseButton onClick={handleSubmit} loading={isSubmitting}>
        {t("datablocks.labels.send")}
      </BaseButton>
    </div>
  );
};

const DataBlocksTab = ({
  filters,
  selectedBlocks,
  setSelectedBlocks,
  onEditRequestClick,
  onCreateDataBlockClick,
}: DataBlocksTabProps) => {
  const { t } = useTranslation();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { canCreate } = useDataBlockPermissions();
  const { data: dataTemplates } = usePaginateFilterDataTemplatesQuery({
    pagination: "off",
    disabled: false,
  }) as { data: DataTemplate[] };

  const allowedDataTemplates = useMemo(() => {
    const filterDataTemplates = filters?.dataTemplates;
    if (!dataTemplates || !filterDataTemplates) return;

    return dataTemplates.filter((template) => {
      return filterDataTemplates.includes(template.id);
    });
  }, [dataTemplates, filters]);

  const formatTemplateList = (items: string[]) => {
    if (items.length === 0) return "";
    if (items.length === 1) return items[0];
    if (items.length === 2) return `${items[0]} and ${items[1]}`;

    const lastItem = items.pop();
    return `${items.join(", ")} and ${lastItem}`;
  };

  return (
    currentUser && (
      <div className="survey-data-blocks-evidence">
        <div className="survey-data-blocks-evidence-header">
          <h2 className="header-s-medium">
            {t("datablocks.labels.chooseEvidence")}
          </h2>
          {canCreate && (
            <BaseButton
              size="small"
              leadingIcon={<PlusOutlined />}
              onClick={onCreateDataBlockClick}
            >
              {t("datablocks.labels.createDataBlock")}
            </BaseButton>
          )}
        </div>

        <div className="survey-data-blocks-evidence-content">
          {allowedDataTemplates ? (
            <p className="body-m-regular">
              {allowedDataTemplates.length === 0
                ? t("datablocks.labels.evidenceTabDescriptionAll")
                : t("datablocks.labels.evidenceTabDescription", {
                    dataTemplates: formatTemplateList(
                      allowedDataTemplates.map((template) => template.title),
                    ),
                    isPlural:
                      allowedDataTemplates.length === 1
                        ? t("datablocks.labels.is")
                        : t("datablocks.labels.are"),
                  })}
            </p>
          ) : null}

          <DataBlocksTable
            defaultFilters={{
              organizations: [currentUser.organization.id],
              ...filters,
            }}
            filterNames={["createdBy", "search"]}
            columnNames={[
              "request",
              "title",
              "type",
              "createdBy",
              "updatedAt",
              "actions",
            ]}
            additionalColumns={{
              actions: {
                title: t("table.actions"),
                key: "actions",
                render: (value) => {
                  if (!value.request) return;

                  return (
                    <BaseTooltip
                      title={
                        value.request.status ===
                        RequestStatus.REVISION_NEEDED.value
                          ? t(
                              "datablocks.labels.editAlreadyRequestForDataBlock",
                            )
                          : ""
                      }
                    >
                      {value.createdBy.id !== currentUser.id && (
                        <BaseButton
                          size="small"
                          variant="secondary"
                          disabled={
                            value.request.status ===
                            RequestStatus.REVISION_NEEDED.value
                          }
                          onClick={() => onEditRequestClick(value.request.id)}
                        >
                          {t("datablocks.labels.requestEdit")}
                        </BaseButton>
                      )}
                    </BaseTooltip>
                  );
                },
              },
            }}
            rowSelection={{
              enabled: true,
              multiple: true,
              selectedRows: selectedBlocks || [],
              onSelect: (blocks) => setSelectedBlocks(blocks),
            }}
          />
        </div>
      </div>
    )
  );
};

const DataBlockRequestsTab = ({
  onCreateRequestsClick,
}: DataBlockRequestsTabProps) => {
  const { t } = useTranslation();
  const { data: currentUser } = useGetCurrentUserQuery();

  return (
    currentUser && (
      <div className="survey-data-blocks-evidence">
        <div className="survey-data-blocks-evidence-header">
          <h2 className="header-s-regular">{t("datablocks.labels.invites")}</h2>
          <BaseButton size="small" onClick={onCreateRequestsClick}>
            <BaseSvgRenderer
              width="20"
              height="20"
              stroke="white"
              component={DataBlockInviteIcon}
            />
            {t("datablocks.labels.sendRequests")}
          </BaseButton>
        </div>
        <div className="survey-data-blocks-invites-content">
          <p className="body-m-regular">
            {t("datablocks.labels.invitesTabDescription")}
          </p>

          <RequestsTable
            className="data-block-requests-table"
            filterNames={["search", "dataTemplateType"]}
            columnNames={["user", "type", "status", "dataBlock", "actions"]}
          />
        </div>
      </div>
    )
  );
};

const SurveyDataBlockModal = ({
  open,
  setOpen,
  filters,
  selectedBlocks,
  setSelectedBlocks,
}: SurveyDataBlockModalProps) => {
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState(ViewTypes.Tabs);
  const [activeTab, setActiveTab] = useState(activeTabs.dataBlocks);
  const [_selectedBlocks, _setSelectedBlocks] = useState<DataBlock[]>([]);
  const [editRequestId, setEditRequestId] = useState<number>();
  const [openCreateBlockModal, setOpenCreateBlockModal] = useState(false);

  useEffect(() => {
    if (!open) {
      setActiveView(ViewTypes.Tabs);
      setActiveTab(activeTabs.dataBlocks);
    }
  }, [open]);

  useEffect(() => {
    _setSelectedBlocks(selectedBlocks || []);
  }, [selectedBlocks]);

  const requestFormHook = useDataBlockRequestForm({
    onSuccess: () => setActiveView(ViewTypes.Tabs),
  });
  const editRequestFormHook = useEditDataBlockRequestForm({
    request: editRequestId,
    onSuccess: () => setActiveView(ViewTypes.Tabs),
  });

  const handleBackToTabs = () => {
    requestFormHook.resetForm();
    editRequestFormHook.form.reset();
    setActiveView(ViewTypes.Tabs);
  };

  const renderFooter = () => {
    if (activeView === ViewTypes.RequestsForm) {
      return (
        <RequestFormModalFooter
          formHook={requestFormHook}
          onBackToTabs={handleBackToTabs}
        />
      );
    } else if (activeView === ViewTypes.EditRequestForm) {
      return (
        <EditRequestFormModalFooter
          formHook={editRequestFormHook}
          onBackToTabs={handleBackToTabs}
        />
      );
    } else if (
      activeView === ViewTypes.Tabs &&
      activeTab === activeTabs.dataBlocks
    ) {
      return (
        <div className="survey-data-blocks-modal-footer-buttons">
          <BaseButton
            disabled={
              _selectedBlocks.length === 0 && selectedBlocks.length === 0
            }
            onClick={() => setSelectedBlocks(_selectedBlocks)}
          >
            {selectedBlocks.length === 0
              ? t("buttons.attachEvidence")
              : t("buttons.updateEvidence")}
          </BaseButton>
        </div>
      );
    }
    return null;
  };

  const ComponentViews = {
    [ViewTypes.Tabs]: (
      <BaseTabs
        items={[
          {
            label: t("datablocks.labels.dataBlock"),
            key: activeTabs.dataBlocks,
            children: (
              <DataBlocksTab
                selectedBlocks={_selectedBlocks}
                setSelectedBlocks={_setSelectedBlocks}
                filters={filters}
                onEditRequestClick={(requestId) => {
                  setEditRequestId(requestId);
                  setActiveView(ViewTypes.EditRequestForm);
                }}
                onCreateDataBlockClick={() => setOpenCreateBlockModal(true)}
              />
            ),
          },
          {
            label: t("datablocks.labels.invites"),
            key: activeTabs.requests,
            children: (
              <DataBlockRequestsTab
                onCreateRequestsClick={() =>
                  setActiveView(ViewTypes.RequestsForm)
                }
              />
            ),
          },
        ]}
        onChange={(key) => setActiveTab(key as activeTabs)}
        defaultActiveKey={activeTabs.dataBlocks}
      />
    ),
    [ViewTypes.RequestsForm]: <RequestForm formHook={requestFormHook} />,
    [ViewTypes.EditRequestForm]: (
      <EditRequestForm formHook={editRequestFormHook} />
    ),
  };

  const modalTitle = useMemo(() => {
    if (activeView === ViewTypes.Tabs) {
      return t("datablocks.labels.dataBlock");
    } else {
      return (
        <div className="survey-data-block-modal-title-container">
          <span onClick={handleBackToTabs}>
            <LeftOutlined style={{ fontSize: 16 }} />
          </span>
          {activeView === ViewTypes.EditRequestForm
            ? t("datablocks.labels.editRequest")
            : t("datablocks.labels.inviteUsers")}
        </div>
      );
    }
  }, [activeView]);

  return (
    <BaseModal
      title={modalTitle || ""}
      open={open}
      onCancel={() => setOpen(false)}
      width={1000}
      footer={renderFooter()}
    >
      {ComponentViews[activeView]}

      <CreateDataBlock
        open={openCreateBlockModal}
        setOpen={() => setOpenCreateBlockModal(false)}
        filters={filters}
      />
    </BaseModal>
  );
};

export default SurveyDataBlockModal;
