import { useMemo } from "react";

import { OrganizationRoles } from "@/utilities/constants";
import { useDisabledDataTemplates } from "./useDataTemplates";
import { DataBlockPermissionsOptions } from "../../types/types";
import { useGetCurrentUserQuery } from "@/redux/service";
import { getDataBlockContributors } from "@/views/datablocks/core/hooks/useDataBlockField";

export const useDataBlockPermissions = (
  options: DataBlockPermissionsOptions = {},
) => {
  const { dataBlock, dataTemplate } = options;
  const disabledTemplates = useDisabledDataTemplates();
  const { data: currentUser } = useGetCurrentUserQuery();

  const disabledTemplateTitles = useMemo(() => {
    return (disabledTemplates || []).reduce<string[]>((acc, template) => {
      acc.push(template.title);
      return acc;
    }, []);
  }, [disabledTemplates]);

  return useMemo(() => {
    const canCreate =
      currentUser?.organizationRole &&
      [
        OrganizationRoles.ADMIN.value,
        OrganizationRoles.SUPERVISOR.value,
        OrganizationRoles.CONTRIBUTOR.value,
      ].includes(currentUser.organizationRole);

    return {
      canCreate,
      canDelete: dataBlock?.canDelete || false,
      canEdit: dataBlock?.canEdit || false,
      isOwner: currentUser?.id === dataBlock?.createdBy.id,
      isContributor:
        dataBlock && currentUser
          ? getDataBlockContributors(dataBlock).includes(currentUser.id)
          : false,
    };
  }, [dataBlock, dataTemplate, disabledTemplateTitles, currentUser]);
};
