import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";

import { DataBlockFormFields } from "../../types/types";
import { DynamicValue } from "../../types/blocks";
import { useDataBlockContext } from "./useDataBlockContext";
import { User } from "@/views/users/types/types";

export const useDataBlockSubmission = (currentUser: User | undefined) => {
  const { dataBlockContextState } = useDataBlockContext();
  const [isSubmissionStarted, setIsSubmissionStarted] = useState(false);
  const dataBlockContextStateRef = useRef(dataBlockContextState);

  useEffect(() => {
    dataBlockContextStateRef.current = dataBlockContextState;
  }, [dataBlockContextState]);

  const formatValue = (value: DynamicValue) => {
    if (value && DateTime.isDateTime(value)) {
      return (
        value
          .toUTC()
          .toISO({ suppressMilliseconds: true, includeOffset: false }) + "Z"
      );
    }
    if (value == null) {
      return null;
    }
    return value;
  };

  const waitForOngoingUpload = () => {
    return new Promise<void>((resolve) => {
      const checkUpload = () => {
        if (!dataBlockContextStateRef.current.isUploading) {
          resolve();
        } else {
          setTimeout(checkUpload, 100);
        }
      };
      checkUpload();
    });
  };

  const prepareFormData = (data: DataBlockFormFields) => {
    return {
      ...data,
      organization: (currentUser as User).organization.id,
      fieldValues: data.fieldValues.map((fieldValue) => ({
        ...fieldValue,
        value: formatValue(fieldValue.value),
      })),
    };
  };

  const handleSubmit = async (
    data: DataBlockFormFields,
    submitFn: (data: DataBlockFormFields) => Promise<any>,
    onSuccess: (result: any) => void,
    onError: (result: any) => void,
  ) => {
    if (!currentUser) return;
    try {
      setIsSubmissionStarted(true);

      if (dataBlockContextState.isUploading) {
        await waitForOngoingUpload();
      }

      const processedData = prepareFormData(data);
      const result = await submitFn(processedData);
      onSuccess(result);
    } catch (errors) {
      onError(errors);
    } finally {
      setIsSubmissionStarted(false);
    }
  };

  return {
    isSubmissionStarted,
    handleSubmit,
  };
};
