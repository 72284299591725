import { OrganizationRoles } from "@/utilities/constants";
import { OrganizationRole } from "@/views/users/types/types";

export const getRolesByPermissionLevel = (
  currentUserRole?: OrganizationRole,
  rolesObject = OrganizationRoles,
) => {
  if (!currentUserRole) return [];
  const currentRole = rolesObject.getItemByValue(currentUserRole);

  if (!currentRole || typeof currentRole.priority !== "number") {
    return [];
  }

  const currentPriority = currentRole.priority;

  return rolesObject.asList().filter((roleOption: { value: string }) => {
    const role = rolesObject.getItemByValue(roleOption.value);
    return (
      role &&
      typeof role.priority === "number" &&
      role.priority <= currentPriority
    );
  });
};
