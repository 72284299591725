// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Duration, Settings } from "luxon";

Settings.defaultZoneName = "utc";

interface ExtendedToHumanOptions extends Intl.DateTimeFormatOptions {
  precision?: Record<string, number>;
  smallestUnit?: string;
  biggestUnit?: string;
  maxUnits?: number;
  stripZeroUnits?: "all" | "end";
}

// Declare the module augmentation
declare module "luxon" {
  interface Duration {
    __toHuman__: (opts?: Intl.DateTimeFormatOptions) => string;
    toHuman: (opts?: ExtendedToHumanOptions) => string;
  }
}

// Backup original
(Duration.prototype as never).__toHuman__ = Duration.prototype.toHuman;

// Override with new implementation
(Duration.prototype as never).toHuman = function (
  opts: ExtendedToHumanOptions = {},
) {
  let duration = this.normalize();
  let durationUnits: string[] = [];
  let precision;
  if (typeof opts.precision == "object") {
    precision = Duration.fromObject(opts.precision);
  }
  let remainingDuration = duration;

  const allUnits = [
    "years",
    "months",
    "days",
    "hours",
    "minutes",
    "seconds",
    "milliseconds",
  ];
  let smallestUnitIndex;
  let biggestUnitIndex;
  if (opts.smallestUnit) {
    smallestUnitIndex = allUnits.indexOf(opts.smallestUnit);
  }
  if (opts.biggestUnit) {
    biggestUnitIndex = allUnits.indexOf(opts.biggestUnit);
  }
  if (!(smallestUnitIndex >= 0 && smallestUnitIndex < allUnits.length))
    smallestUnitIndex = allUnits.indexOf("seconds");
  if (
    !(
      biggestUnitIndex <= smallestUnitIndex &&
      biggestUnitIndex < allUnits.length
    )
  )
    biggestUnitIndex = allUnits.indexOf("years");

  for (const unit of allUnits.slice(biggestUnitIndex, smallestUnitIndex + 1)) {
    const durationInUnit = remainingDuration.as(unit);
    if (durationInUnit >= 1) {
      durationUnits.push(unit);
      const tmp = { [unit]: Math.floor(remainingDuration.as(unit)) };
      remainingDuration = remainingDuration
        .minus(Duration.fromObject(tmp))
        .normalize();

      if (remainingDuration < precision) {
        break;
      }
    }

    if (durationUnits.length >= opts.maxUnits) {
      break;
    }
  }
  duration = duration.minus(remainingDuration).normalize();
  duration = duration.shiftTo(...durationUnits);
  if (opts.stripZeroUnits == "all") {
    durationUnits = durationUnits.filter((unit) => duration.values[unit] > 0);
  } else if (opts.stripZeroUnits == "end") {
    let mayStrip = true;
    durationUnits = durationUnits.reverse().filter((unit) => {
      if (!mayStrip) return true;
      if (duration.values[unit] == 0) {
        return false;
      } else {
        mayStrip = false;
      }
      return true;
    });
  }
  return duration.shiftTo(...durationUnits).__toHuman__(opts);
} as never;

export { Duration };
