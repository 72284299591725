import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";

import FormFieldWrapper from "@/components_v2/Wrapper/FormFieldWrapper";
import { HookFormTextArea } from "@/components_v2/FormFields/ReactHookForm/fields";
import BaseSpinner from "@/components_v2/Spiner/BaseSpinner";
import { DataBlockRequestEditFormProps } from "@/views/datablocks/types/types";

const EditRequestForm = ({ formHook }: DataBlockRequestEditFormProps) => {
  const { t } = useTranslation();

  const { form, dataBlockRequest, dataBlockTitle, isFetching } = formHook;

  if (isFetching || !dataBlockTitle) return <BaseSpinner />;

  return (
    dataBlockRequest && (
      <FormProvider {...form}>
        <div className="edit-request-form">
          <h6 className="header-s-medium">
            {t("datablocks.labels.editRequestContent", {
              email: dataBlockRequest.createdBy.fullName,
              title: dataBlockTitle,
            })}
          </h6>

          <FormFieldWrapper
            label={t("datablocks.labels.message")}
            helpText={t("datablocks.labels.requestEditHelpText")}
          >
            <HookFormTextArea
              name="message"
              placeholder={t("datablocks.placeholders.message")}
              control={form.control}
            />
          </FormFieldWrapper>
        </div>
      </FormProvider>
    )
  );
};

export default EditRequestForm;
