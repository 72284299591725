import { useEffect, useState } from "react";

import { ContentType } from "../../types/templates";
import { ContentTypeRelations } from "../../types/blocks";
import { getQueryPropsForContentType } from "../fields/contentTypeConfig";

export const useDataBlockRelatedData = (
  ids: number | number[],
  type?: ContentType,
): {
  data: ContentTypeRelations;
  isLoading: boolean;
  isSuccess: boolean;
} => {
  const [data, setData] = useState<ContentTypeRelations>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const idsArray = Array.isArray(ids) ? ids : ids ? [ids] : [];
  const queryParams = { ids: idsArray, pagination: "off" as const };

  const queryConfig = type ? getQueryPropsForContentType(type) : null;

  const { data: queryData, isLoading: queryLoading } = queryConfig
    ? queryConfig.useQuery(queryParams, { skip: idsArray.length === 0 })
    : { data: null, isLoading: false };

  useEffect(() => {
    if (!queryLoading) {
      setData(queryData as ContentTypeRelations);
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [type, queryData, queryLoading]);

  return { data, isLoading, isSuccess };
};
