import { Col, Row, Typography } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const SectionStatistics = ({ section }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{ display: "flex", marginLeft: "auto", color: "red !important" }}
    >
      <div style={{ display: "flex" }} className="me-3">
        <Text
          style={{ textTransform: "uppercase", color: section.color }}
          className="me-2"
        >
          {t("labels.questions")}
        </Text>
        <div>
          <Title
            level={5}
            style={{
              fontWeight: "bold",
              color: section.color,
            }}
          >
            {section.answeredRequiredQuestionsCount +
              section.answeredOptionalQuestionsCount}
            /{section.requiredQuestionsCount + section.optionalQuestionsCount}
          </Title>
        </div>
      </div>
      <div style={{ display: "flex" }} className="me-3">
        <div>
          <Text
            style={{ textTransform: "uppercase", color: section.color }}
            className="me-2"
          >
            {t("survey.labels.dataBlock")}
          </Text>
        </div>
        <div>
          <Title
            level={5}
            style={{
              fontWeight: "bold",
              color: section.color,
            }}
          >
            {section.answeredBlocksCount}/{section.requiredBlocksCount}
          </Title>
        </div>
      </div>
      <div style={{ display: "flex" }} className="me-2">
        <Text
          style={{ textTransform: "uppercase", color: section.color }}
          className="me-2"
        >
          {t("labels.narratives", { plural: "s" })}
        </Text>
        <div>
          <Title
            level={5}
            style={{
              fontWeight: "bold",
              color: section.color,
            }}
          >
            {section.answeredNarrativesCount}/{section.requiredNarrativesCount}
          </Title>
        </div>
      </div>
    </div>
  );
};

SectionStatistics.propTypes = {
  section: PropTypes.object.isRequired,
};

const SectionVerticalBar = ({ section }) => {
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "4px",
          borderRadius: "50px",
          backgroundColor: section.color,
        }}
      />
      <div className="section-vertical-bar-content">
        <h4 className="header-m-medium" style={{ color: section.color }}>
          {section.title}
        </h4>
        {section?.statistics && (
          <SectionStatistics section={section.statistics} />
        )}
      </div>
    </div>
  );
};

SectionVerticalBar.propTypes = {
  section: PropTypes.object.isRequired,
};

export default SectionVerticalBar;
