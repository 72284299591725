import { UploadFile } from "antd/es/upload/interface";

import {
  ChoiceOption,
  ContentType,
  DataField,
  FieldType,
  ValidationRuleTypes,
} from "../../types/templates";
import { FileRepositoryUploadWithHook } from "./editors";
import { FieldDependencies, FormChoiceOption } from "../../types/types";
import { Roles } from "@/utilities/constants";
import {
  useGetChoiceOptionsQuery,
  useGetDataFilesQuery,
  useGetSubjectsQuery,
  useGetUsersQuery,
} from "@/redux/service";
import { BaseUser } from "@/views/users/types/types";
import { ContentTypeRelations, DataFile } from "../../types/blocks";
import { TagsElement } from "@/components_v2/ContentElements/TagsElement";
import TextElement from "../../../../components_v2/ContentElements/TextElement";
import {
  FileElement,
  FileGroupElement,
} from "@/components_v2/ContentElements/FileElement";
import SliderDropdownElement from "../../../../components_v2/ContentElements/SliderDropdownElement";
import { Subject } from "@/views/organizations/types/types";
import {
  HookFormCustomChoiceDropdown,
  HookFormBaseDropdown,
  HookFormInfiniteScrollDropdown,
  HookFormSliderDropdown,
} from "@/components_v2/FormFields/ReactHookForm/fields";
import {
  BaseFile,
  UploadFunctionReturnProps,
} from "@/components_v2/FormFields/Upload/types";
import { FileUploadError } from "@/components_v2/FormFields/Upload/errors";

const sortByIdOrOrder = (a: ChoiceOption, b: ChoiceOption) => {
  if (a.order !== undefined && b.order !== undefined) {
    return a.order - b.order;
  } else {
    return a.id - b.id;
  }
};

const getOptions = (options: ChoiceOption[]): FormChoiceOption[] => {
  return [...options].sort(sortByIdOrOrder).map((option) => ({
    label: option.label,
    relatedOptions: getOptions(option?.relatedOptions || []),
    value: option.id,
    category: option?.category,
  }));
};

export const CONTENT_TYPE_PROPS = {
  [ContentType.Options]: {
    query: {
      useQuery: useGetChoiceOptionsQuery,
      dataKey: "optionData",
      loadingKey: "optionLoading",
    },
    rendererProps: (field: DataField, data: ContentTypeRelations) => {
      const options = data as ChoiceOption[];
      if ([FieldType.SINGLE_CHOICE, FieldType.SLIDER].includes(field.type)) {
        const selectedOption = options[0];
        return { value: selectedOption ? selectedOption.label : undefined };
      }

      return {
        value: options,
      };
    },
    rendererComponent: (field: DataField) => {
      if (field.type === FieldType.MULTIPLE_CHOICE) {
        return TagsElement;
      } else if (field.type === FieldType.MULTIPLE_CHOICE_WITH_SCALE) {
        return SliderDropdownElement;
      }
      return TextElement;
    },
    editorComponent: (field: DataField) => {
      if (field.type === FieldType.MULTIPLE_CHOICE_WITH_SCALE) {
        return HookFormSliderDropdown;
      }
      if (field.hasOther) {
        return HookFormCustomChoiceDropdown;
      }
      return HookFormBaseDropdown;
    },
    editorProps: (field: DataField, dependencies: FieldDependencies) => {
      const { dataTemplate } = dependencies;
      const fieldIndexMap = dataTemplate.templateFields.reduce<
        Record<number, number>
      >((acc, field, index) => {
        acc[field.id] = index;
        return acc;
      }, {});

      return {
        options: getOptions(field?.options || []),
        constraints: (field?.constraints || []).map((constraint) => ({
          fieldName: `fieldValues.${fieldIndexMap[constraint.parentField]}.value`,
          allowedOptions: constraint.allowedChildOptions,
          parentOption: constraint.parentOption,
          affectsMainOptions: constraint.affectsMainOptions,
        })),
      };
    },
  },

  [ContentType.Users]: {
    query: {
      useQuery: useGetUsersQuery,
      dataKey: "userData",
      loadingKey: "userLoading",
    },
    rendererProps: (field: DataField, data: ContentTypeRelations) => {
      const users = data as BaseUser[];
      if (field.type === FieldType.SINGLE_CHOICE) {
        const selectedUser = users[0];
        return {
          value: selectedUser
            ? {
                label: selectedUser.fullName,
                link: `/profile/${selectedUser.id}`,
              }
            : undefined,
        };
      }
      return {
        value: users.map((user) => ({
          label: user.fullName,
          link: `/profile/${user.id}`,
        })),
      };
    },
    rendererComponent: (field: DataField) => {
      if (field.type === FieldType.MULTIPLE_CHOICE) {
        return TagsElement;
      }
      return TextElement;
    },
    editorComponent: () => HookFormInfiniteScrollDropdown,
    editorProps: (field: DataField, dependencies: FieldDependencies) => {
      const { getUsers, currentUser } = dependencies;
      const userOrganizationFilterParams =
        currentUser && currentUser.role !== Roles.ADMIN.value
          ? { organization: currentUser.organization.id }
          : {};

      return {
        fetchFn: getUsers,
        labelFieldName: "fullName",
        valueFieldName: "id",
        searchFieldName: "fullName",
        defaultFilters: userOrganizationFilterParams,
      };
    },
  },

  [ContentType.Subjects]: {
    query: {
      useQuery: useGetSubjectsQuery,
      dataKey: "subjectsData",
      loadingKey: "subjectsLoading",
    },
    rendererProps: (field: DataField, data: ContentTypeRelations) => {
      const subjects = data as Subject[];
      if (field.type === FieldType.SINGLE_CHOICE) {
        const selectedOption = subjects[0];
        return { value: selectedOption ? selectedOption.name : undefined };
      }

      return {
        value: subjects.map((subject) => ({ label: subject.name })),
      };
    },
    rendererComponent: (field: DataField) => {
      if (field.type === FieldType.MULTIPLE_CHOICE) {
        return TagsElement;
      }
      return TextElement;
    },
    editorComponent: () => HookFormInfiniteScrollDropdown,
    editorProps: (_: DataField, dependencies: FieldDependencies) => {
      const { getSubjects, currentUser } = dependencies;
      const userOrganizationFilterParams =
        currentUser && currentUser.role !== Roles.ADMIN.value
          ? { organization: currentUser.organization.id }
          : {};

      return {
        fetchFn: getSubjects,
        labelFieldName: "name",
        valueFieldName: "id",
        searchFieldName: "name",
        defaultFilters: userOrganizationFilterParams,
      };
    },
  },

  [ContentType.Files]: {
    query: {
      useQuery: useGetDataFilesQuery,
      dataKey: "fileData",
      loadingKey: "fileLoading",
    },
    rendererProps: (field: DataField, data: ContentTypeRelations) => {
      const files = data as DataFile[];
      if (field.type === FieldType.SINGLE_CHOICE) {
        const selectedFile = files[0];
        return {
          value: selectedFile
            ? {
                file: selectedFile.file,
                name: selectedFile.name,
                downloadUrl: selectedFile.downloadUrl,
              }
            : undefined,
        };
      }
      return {
        value: files.map((file) => ({
          file: file.file,
          name: file.name,
          downloadUrl: file.downloadUrl,
        })),
      };
    },
    rendererComponent: (field: DataField) => {
      if (field.type === FieldType.MULTIPLE_CHOICE) {
        return FileGroupElement;
      }
      return FileElement;
    },
    editorComponent: () => FileRepositoryUploadWithHook,
    editorProps: (field: DataField, dependencies: FieldDependencies) => {
      const { getDataFiles, createDataFile, currentUser } = dependencies;
      const userOrganizationFilterParams =
        currentUser && currentUser.role !== Roles.ADMIN.value
          ? { organization: currentUser.organization.id }
          : {};

      const getRuleValue = (ruleType: ValidationRuleTypes) =>
        field?.rules?.find((rule) => rule.type === ruleType)?.value;

      const fileExtensions = getRuleValue(
        ValidationRuleTypes.ALLOWED_FILE_TYPES,
      );
      const maxFileSize = getRuleValue(ValidationRuleTypes.MAX_FILE_SIZE);

      const uploadFiles = async (
        files: UploadFile[],
      ): Promise<UploadFunctionReturnProps> => {
        const results = await Promise.allSettled(
          files.map(async (file) => {
            const response = await createDataFile({
              organization: currentUser?.organization?.id,
              name: file.name,
              file,
            });

            if (response?.error) {
              throw new FileUploadError(
                response.error.data.errors[0].detail,
                response.status,
                file,
              );
            }

            return {
              ...response.data,
              oldId: file.uid,
            };
          }),
        );

        const successful: BaseFile[] = [];
        const failed: FileUploadError[] = [];

        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            successful.push(result.value);
          } else {
            if (result.reason instanceof FileUploadError) {
              const error = result.reason as FileUploadError;
              failed.push(error);
            } else {
              failed.push(
                new FileUploadError(
                  result.reason?.message || "Unknown error",
                  500,
                  files[index] as UploadFile,
                ),
              );
            }
          }
        });

        return { successful, failed };
      };

      const fetchFilesWithFilters = (queryParams: Record<string, string>) => {
        const searchParams = {
          ...queryParams,
          extensions: "",
          ...userOrganizationFilterParams,
        };

        if (fileExtensions) {
          searchParams.extensions = (fileExtensions as string[]).join(",");
        }

        return getDataFiles(searchParams);
      };

      return {
        fetchFn: fetchFilesWithFilters,
        labelFieldName: "name",
        valueFieldName: "id",
        supportedExtensions: fileExtensions,
        maxFileSize,
        uploadFn: uploadFiles,
      };
    },
  },
};

const getContentTypeProp = (
  propName: string,
  contentType?: ContentType,
  ...args: any
) => {
  if (!contentType) return;

  const configForType = CONTENT_TYPE_PROPS[contentType];

  if (!configForType) {
    throw new Error(`Unknown content type ${contentType}`);
  }

  // @ts-expect-error must exist
  const propOrFn = configForType[propName];

  if (typeof propOrFn === "function") {
    return propOrFn(...args);
  }

  return propOrFn;
};

export const getEditorComponentForContentType = (field: DataField) =>
  getContentTypeProp("editorComponent", field.contentType, field);

export const getRendererComponentForContentType = (field: DataField) =>
  getContentTypeProp("rendererComponent", field.contentType, field);

export const getEditorPropsForContentType = (
  field: DataField,
  dependencies: FieldDependencies,
) => getContentTypeProp("editorProps", field.contentType, field, dependencies);

export const getRendererPropsForContentType = (
  field: DataField,
  data: ContentTypeRelations,
) => getContentTypeProp("rendererProps", field.contentType, field, data);

export const getQueryPropsForContentType = (contentType: ContentType) =>
  getContentTypeProp("query", contentType);
