import React, { useEffect, useRef } from "react";
import { useFieldArray } from "react-hook-form";
import { Card } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { BaseFormFieldsListProps } from "./types";

import "./styles.css";
import BaseButton from "../Button/BaseButton";

const BaseFormFieldsList = ({
  name,
  form,
  title,
  description,
  fields,
  addButtonText,
  defaultValues = {},
}: BaseFormFieldsListProps) => {
  const {
    fields: formFields,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name,
  });
  const initialAppendRef = useRef(false);

  useEffect(() => {
    if (formFields.length === 0 && !initialAppendRef.current) {
      initialAppendRef.current = true;
      append(defaultValues);
    }
  }, [formFields.length, append]);

  const handleAddItem = async () => {
    const isValid = await form.trigger(name);

    if (isValid) {
      append(defaultValues);
    }
  };

  const handleRemoveItem = (index: number) => {
    if (formFields.length === 1) {
      return;
    }
    remove(index);
  };

  return (
    <div className="base-form-fields-list-container">
      <div className="base-form-fields-list-header">
        {title && <h6 className="body-s-medium">{title}</h6>}
        {description && <p className="body-s-regular">{description}</p>}
      </div>

      {formFields.map((formField, index) => (
        <Card
          key={formField.id}
          size="small"
          extra={
            formFields.length > 1 ? (
              <BaseButton
                variant="link"
                size="small"
                leadingIcon={<CloseOutlined />}
                onClick={() => handleRemoveItem(index)}
              />
            ) : null
          }
          title={`${index + 1}.`}
        >
          <div className="base-form-fields-list-item">
            {fields.map((field) => {
              const originalComponent = field.component;
              const fieldName = `${name}.${index}.${field.name}`;

              let disabled;
              if (field.disabled && typeof field.disabled == "function") {
                disabled = field.disabled(index);
              }

              return React.cloneElement(originalComponent, {
                key: `${formField.id}-${field.name}`,
                // @ts-expect-error name is prop of the original component
                name: fieldName,
                disabled,
              });
            })}
          </div>
        </Card>
      ))}

      <BaseButton
        variant="link"
        size="small"
        leadingIcon={<PlusOutlined />}
        onClick={handleAddItem}
      >
        {addButtonText}
      </BaseButton>
    </div>
  );
};

export default BaseFormFieldsList;
