import { useState } from "react";

import { Badge, List, Popover, theme, Typography, Divider, App } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import OutsideClickHandler from "./PopoverClickHandler.jsx";
import { useLogOutMutation } from "../../redux/service.js";
import { logout, setIsAuthenticated } from "../../redux/users/reducer.js";
import { LocalStorageName } from "../../utilities/constants.jsx";
import RoleBadge from "../RoleBadge.jsx";
import UserAvatar from "../../components_v2/Avatar/UserAvatar.js";

const { Title, Text } = Typography;

const styles = {
  profileMenuItems: {
    width: "230px",
  },
};

const Profile = ({ currentUser }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [logOut] = useLogOutMutation();
  const { notification } = App.useApp();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const MenuItems = [
    {
      label: t("profile"),
      onClick: () => navigate(`/profile/${currentUser.id}`),
    },
    {
      label: t("profileMenuItems.manageAccount"),
      onClick: () => navigate("/settings"),
    },
    {
      label: t("profileMenuItems.signOut"),
      onClick: () => {
        logOut()
          .unwrap()
          .then(async () => {
            await dispatch(setIsAuthenticated({ isAuthenticated: false }));
            await dispatch(logout());
            notification.success({
              message: t("messages.logOut"),
            });
            window.localStorage.removeItem(LocalStorageName);
            navigate("/login");
          });
      },
    },
  ];

  return (
    <OutsideClickHandler
      onOutsideClick={() => setIsPopoverOpen(false)}
      isPopoverOpen={isPopoverOpen}
    >
      <Popover
        placement="bottomRight"
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        open={isPopoverOpen}
        onClick={() => setIsPopoverOpen((oldVal) => !oldVal)}
        title={
          <div>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <UserAvatar user={currentUser} />
              </div>
              <div className="mb-auto">
                <RoleBadge
                  roleName={currentUser.organizationRole || currentUser.role}
                />
              </div>
            </div>
            <div className="mt-2">
              {currentUser.fullName !== currentUser.email && (
                <Title level={5} className="mt-2">
                  {currentUser.fullName}
                </Title>
              )}
              <Text
                style={
                  currentUser.fullName !== currentUser.email
                    ? { fontWeight: 400, color: token.colorTextSecondary }
                    : { fontWeight: 500, color: token.colorPrimaryText }
                }
              >
                {currentUser.email}
              </Text>
              <div style={{ width: "200px" }}>
                <Text
                  style={{ fontWeight: 400, color: token.colorTextSecondary }}
                >
                  {currentUser.organization?.name}
                </Text>
              </div>
            </div>
            <Divider className="mt-3 mb-0 me-0 ms-0" />
          </div>
        }
        trigger="click"
        content={
          <List
            style={styles.profileMenuItems}
            dataSource={MenuItems}
            renderItem={(item) => (
              <a
                onClick={() => {
                  item.onClick();
                  setIsPopoverOpen(false);
                }}
              >
                <List.Item
                  className="border-0 pt-2 pb-1"
                  style={{ color: token.colorPrimaryText }}
                >
                  {item.label}
                </List.Item>
              </a>
            )}
          />
        }
      >
        <Badge style={{ cursor: "pointer" }}>
          <UserAvatar user={currentUser} />
        </Badge>
      </Popover>
    </OutsideClickHandler>
  );
};

Profile.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default Profile;
