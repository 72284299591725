import React from "react";
import { DateTime } from "luxon";

import { FieldDependencies, FieldTypeConfig } from "../../types/types";
import { DataField, FieldType } from "../../types/templates";
import { FileRepositoryUploadProps } from "@/components_v2/FormFields/Upload/types";
import {
  UPLOAD_CONTEXT_MAPPER,
  useDataBlockContextHandlers,
} from "../hooks/useDataBlockContextHandlers";
import {
  getEditorComponentForContentType,
  getEditorPropsForContentType,
} from "./contentTypeConfig";
import {
  HookFormCheckbox,
  HookFormDatePicker,
  HookFormDateRangePicker,
  HookFormFileRepositoryUpload,
  HookFormInput,
  HookFormRichTextInput,
  HookFormSlider,
  HookFormTextArea,
} from "@/components_v2/FormFields/ReactHookForm/fields";
import { HookFormComponentProps } from "@/components_v2/FormFields/ReactHookForm/types";
import { BaseData } from "@/types/base";

export const FileRepositoryUploadWithHook = <T extends BaseData>(
  props: HookFormComponentProps<FileRepositoryUploadProps<T>>,
) => {
  const contextHandlers = useDataBlockContextHandlers(UPLOAD_CONTEXT_MAPPER);
  return <HookFormFileRepositoryUpload {...contextHandlers} {...props} />;
};

const createFieldTypes = (
  dependencies: FieldDependencies,
): Record<string, FieldTypeConfig> => {
  return {
    [FieldType.TEXT]: {
      component: HookFormInput,
      getProps: () => ({
        type: "text",
      }),
    },
    [FieldType.TEXT_AREA]: {
      component: HookFormTextArea,
    },
    [FieldType.RICH_TEXT]: {
      component: HookFormRichTextInput,
    },
    [FieldType.NUMBER]: {
      component: HookFormInput,
      getProps: () => ({
        type: "number",
      }),
    },
    [FieldType.CHECKBOX]: {
      component: HookFormCheckbox,
      getProps: (field: DataField) => ({
        label: field.title,
      }),
    },
    [FieldType.DATE]: {
      component: HookFormDatePicker,
      getProps: () => ({
        defaultValue: DateTime.now(),
      }),
    },
    [FieldType.DATE_RANGE]: {
      component: HookFormDateRangePicker,
    },
    [FieldType.SINGLE_CHOICE]: {
      isComponentGetter: true,
      component: (field: DataField) => getEditorComponentForContentType(field),
      getProps: (field: DataField) => ({
        multiple: false,
        ...getEditorPropsForContentType(field, dependencies),
      }),
    },
    [FieldType.MULTIPLE_CHOICE]: {
      isComponentGetter: true,
      component: (field: DataField) => getEditorComponentForContentType(field),
      getProps: (field: DataField) => {
        const contentTypeProps = getEditorPropsForContentType(
          field,
          dependencies,
        );
        return {
          multiple: true,
          ...contentTypeProps,
        };
      },
    },
    [FieldType.MULTIPLE_CHOICE_WITH_SCALE]: {
      isComponentGetter: true,
      component: (field: DataField) => getEditorComponentForContentType(field),
      getProps: (field: DataField) => {
        const contentTypeProps = getEditorPropsForContentType(
          field,
          dependencies,
        );
        return {
          multiple: true,
          helpText: field.sliderHelpText,
          customInputOptions: {
            show: field.hasOther,
          },
          ...contentTypeProps,
        };
      },
    },
    [FieldType.SLIDER]: {
      component: HookFormSlider,
      getProps: (field: DataField) => {
        const options = field?.options || [];
        return {
          options: options.map((option) => ({
            value: option.id,
            label: option.label,
          })),
        };
      },
    },
  };
};

/**
 * Retrieves the form components for the specific field type along with its properties
 */
export const getFieldTypeComponent = (
  field: DataField,
  dependencies: FieldDependencies,
): {
  component: React.ComponentType;
  props: ReturnType<NonNullable<FieldTypeConfig["getProps"]>>;
} => {
  const fieldTypes = createFieldTypes(dependencies);
  const fieldType = fieldTypes[field.type];

  if (!fieldType) {
    throw new Error(`Can't find component for field of type ${field.type}`);
  }
  let component = fieldType.component as React.ComponentType;
  if (fieldType?.isComponentGetter) {
    const componentGetter = fieldType.component as (
      field: DataField,
    ) => React.ComponentType;
    component = componentGetter(field);
  }

  return {
    component,
    props: fieldType.getProps?.(field) ?? {},
  };
};
