import { useEffect, useState } from "react";

import { App, Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import BaseButton from "../../../../components_v2/Button/BaseButton.js";
import SurveyViewsTable from "./SurveyViewsTable.jsx";
import BaseModal from "../../../../components_v2/Modal/BaseModal.js";

const { Title } = Typography;

const SurveySelectModal = ({
  open,
  onCancel,
  onSelect,
  multiple,
  required,
  initialSelectedSurveys,
  filters,
}) => {
  const { t } = useTranslation();
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const { notification } = App.useApp();

  useEffect(() => {
    setSelectedSurveys(initialSelectedSurveys);
  }, [initialSelectedSurveys]);

  const columns = [
    {
      title: t("table.title"),
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    {
      title: t("table.owner"),
      dataIndex: "createdBy.fullName",
      addSearch: true,
      ellipsis: true,
      key: "organization",
      render: (_, record) => {
        if (record.organization) {
          return record.organization.name;
        }
        return record.createdBy.fullName;
      },
    },
  ];

  const handleConfirm = () => {
    if (
      ((Array.isArray(selectedSurveys) && selectedSurveys.length === 0) ||
        selectedSurveys === null) &&
      required
    ) {
      notification.error({ message: "You must select a report view." });
      return;
    }

    if (multiple) {
      onSelect(selectedSurveys);
    } else {
      onSelect([selectedSurveys[0]]);
    }
  };

  return (
    <BaseModal
      open={open}
      title={t("labels.selectSurvey")}
      onCancel={onCancel}
      destroyOnClose
      footer={
        <Flex wrap justify="space-between" align="center" gap={"small"}>
          <BaseButton variant="tertiary" key="cancel" onClick={onCancel}>
            {t("buttons.cancel")}
          </BaseButton>

          <BaseButton variant="primary" key="confirm" onClick={handleConfirm}>
            {t("buttons.confirm")}
          </BaseButton>
        </Flex>
      }
      modalRender={(element) => <div>{element}</div>}
    >
      <SurveyViewsTable
        columns={columns}
        rowSelection={{
          type: multiple ? "checkbox" : "radio",
          onSelect: multiple
            ? (record, selected) => {
                if (!selected) {
                  setSelectedSurveys((oldVal) => {
                    oldVal.filter((i) => i.uuid !== record.uuid);
                  });
                } else {
                  setSelectedSurveys((oldval) => [...oldval, record]);
                }
              }
            : (record) => setSelectedSurveys([record]),
          hideSelectAll: true,
          selectedRowKeys: selectedSurveys.map((i) => i.uuid),
        }}
        filters={filters}
      />
    </BaseModal>
  );
};

SurveySelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  initialSelectedSurveys: PropTypes.array,
  filters: PropTypes.object,
};

SurveySelectModal.defaultProps = {
  multiple: false,
  required: false,
  initialSelectedSurveys: [],
  filters: {},
};

export default SurveySelectModal;
