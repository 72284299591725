import { useEffect, useState } from "react";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Typography, Flex } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import FormField from "../../../components/form/FormField.jsx";
import Input from "../../../components/form/Input.jsx";
import { useLazyGetUsersQuery } from "../../../redux/service.js";
import SurveySelectModal from "../../survey/components/survey-views/SurveySelectModal.jsx";
import { SharingRights } from "../../../utilities/constants.jsx";
import BaseModal from "../../../components_v2/Modal/BaseModal.tsx";
import BaseButton from "../../../components_v2/Button/BaseButton.js";
import DataBlocksTable from "../../datablocks/core/components/DataBlocksTable.js";

import "./styles.css";

const { Title } = Typography;

const ContentField = ({ form, children, label, inputRows }) => {
  const { t } = useTranslation();

  const [getUsers] = useLazyGetUsersQuery();
  const [surveySelectModalOpen, setSurveySelectModalOpen] = useState(false);
  const [blocksModalOpen, setDataBlocksModalOpen] = useState(false);
  const [selectedBlocks, setSelectedBlocks] = useState([]);

  const attachedBlocks = form.watch("blocks") || [];
  const attachedNarratives = form.watch("narratives") || [];
  const attachedSurveyViews = form.watch("surveyViews") || [];

  useEffect(() => {
    const values = form.getValues();
    if (values?.blocks) {
      setSelectedBlocks(values?.blocks);
    }
  }, [form]);

  const removeBlock = (blockId) => {
    form.setValue(
      "blocks",
      attachedBlocks.filter((i) => i.id !== blockId),
    );
  };

  const removeSurveyView = (surveyViewID) => {
    form.setValue(
      "surveyViews",
      attachedSurveyViews.filter((i) => i.uuid !== surveyViewID),
    );
  };

  const handleAddSelectedBlocks = () => {
    form.setValue("blocks", selectedBlocks);
    setDataBlocksModalOpen(false);
  };

  return (
    <div>
      <FormField
        label={label}
        required
        field={
          <>
            <Input
              name="content"
              rows={inputRows}
              type="richtexteditor"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link", "image", "code-block"],
                  ["blockquote"],
                  ["clean"],
                ],
                mention: {
                  mentionDenotationChars: ["@", "#"],
                  source: async function (searchTerm, renderList) {
                    const response = await getUsers({
                      fullName: searchTerm,
                      pagination: searchTerm.length === 0 ? "on" : "off",
                    });

                    let values = [];
                    if (response.isSuccess) {
                      values = (
                        searchTerm.length === 0
                          ? response.data.results
                          : response.data
                      ).map((i) => ({ id: i.id, value: i.fullName }));
                    }

                    if (searchTerm.length === 0) {
                      renderList(values, searchTerm);
                    } else {
                      const matches = [];
                      for (let i = 0; i < values.length; i++)
                        if (
                          ~values[i].value
                            .toLowerCase()
                            .indexOf(searchTerm.toLowerCase())
                        )
                          matches.push(values[i]);
                      renderList(matches, searchTerm);
                    }
                  },
                },
              }}
              placeholder={t("form.placeholders.content")}
              control={form.control}
            />
            {attachedBlocks.length ||
            attachedNarratives.length ||
            attachedSurveyViews.length ? (
              <div className="mt-3">
                <div className="mb-3">
                  {attachedBlocks.map((block) => (
                    <div
                      key={block.id}
                      className="py-2 px-3 mb-2"
                      style={{
                        backgroundColor: "rgba(242, 242, 245, 1)",
                        display: "flex",
                      }}
                    >
                      <CloseOutlined onClick={() => removeBlock(block.id)} />
                      <Title
                        level={5}
                        className="ms-3"
                        style={{ color: "rgba(74, 105, 156, 1)" }}
                      >
                        {
                          block.fieldValues.find(
                            (value) =>
                              value.field === block.fieldsMapping.title,
                          )?.value
                        }
                      </Title>
                    </div>
                  ))}
                </div>
                <div>
                  {attachedNarratives.map((narrative) => (
                    <div
                      key={narrative.id}
                      className="py-2 px-3 mb-2"
                      style={{
                        backgroundColor: "rgba(230, 237, 254, 1)",
                        display: "flex",
                      }}
                    >
                      <CloseOutlined
                        onClick={() => removeNarrative(narrative.id)}
                      />
                      <Title
                        level={5}
                        className="ms-3"
                        style={{ color: "rgba(74, 105, 156, 1)" }}
                      >
                        {narrative.title}
                      </Title>
                    </div>
                  ))}
                </div>
                <div>
                  {attachedSurveyViews.map((surveyView) => (
                    <div
                      key={surveyView.uuid}
                      className="py-2 px-3 mb-2"
                      style={{
                        backgroundColor: "rgba(230, 237, 254, 1)",
                        display: "flex",
                      }}
                    >
                      <CloseOutlined
                        onClick={() => removeSurveyView(surveyView.uuid)}
                      />
                      <Title
                        level={5}
                        className="ms-3"
                        style={{ color: "rgba(74, 105, 156, 1)" }}
                      >
                        {surveyView.title}
                      </Title>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            <Flex wrap gap={4}>
              <BaseButton
                variant="link"
                leadingIcon={<PlusOutlined />}
                onClick={() => setDataBlocksModalOpen(true)}
              >
                {t("addEvidence")}
              </BaseButton>

              <BaseButton
                variant="link"
                leadingIcon={<PlusOutlined />}
                onClick={() => setSurveySelectModalOpen(true)}
              >
                {t("addReportView")}
              </BaseButton>
            </Flex>

            <div>{children}</div>
          </>
        }
      />
      <BaseModal
        className="evidence-selection-modal"
        title={t("labels.selectEvidence")}
        open={blocksModalOpen}
        onCancel={() => setDataBlocksModalOpen(false)}
        footer={
          <Flex wrap justify="space-between" align="center" gap={"small"}>
            <BaseButton
              variant="tertiary"
              key="cancel"
              onClick={() => setDataBlocksModalOpen(false)}
            >
              {t("buttons.cancel")}
            </BaseButton>

            <BaseButton
              variant="primary"
              key="confirm"
              onClick={handleAddSelectedBlocks}
            >
              {t("buttons.confirm")}
            </BaseButton>
          </Flex>
        }
      >
        <DataBlocksTable
          columnNames={["title", "type", "sharingRights"]}
          filterNames={["type", "search"]}
          rowSelection={{
            enabled: true,
            multiple: true,
            selectedRows: selectedBlocks,
            onSelect: (blocks) => setSelectedBlocks(blocks),
          }}
        />
      </BaseModal>

      <SurveySelectModal
        onCancel={() => setSurveySelectModalOpen(false)}
        open={surveySelectModalOpen}
        initialSelectedSurveys={attachedSurveyViews}
        multiple
        onSelect={(surveyViews) => {
          form.setValue("surveyViews", surveyViews);
          setSurveySelectModalOpen(false);
        }}
        filters={{
          sharing_rights: [
            SharingRights.PUBLIC.value,
            SharingRights.PRME_COMMUNITY.value,
          ],
        }}
      />
    </div>
  );
};

ContentField.propTypes = {
  form: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  label: PropTypes.string,
  inputRows: PropTypes.number,
};

ContentField.defaultProps = {
  label: null,
  inputRows: null,
};

export default ContentField;
