import React from "react";
import { Input, List } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { BaseListOption, BaseListSelectProps } from "./types";
import BaseError from "../BaseError";
import BaseSpinner from "../../Spiner/BaseSpinner";
import { BaseFormError } from "../types";

import "./styles.css";

const BaseListSelect = <T extends BaseListOption>({
  value,
  onChange,
  options,
  onSearch,
  onScroll,
  renderItem,
  onSelect,
  isLoading = false,
  multiple = false,
  error,
}: BaseListSelectProps<T>) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const defaultRenderItem = (option: T) => (
    <span className="text-gray-900">{option.label}</span>
  );

  const handleSelect = (option: T) => {
    const currentValues = Array.isArray(value) ? value : value ? [value] : [];
    const isSelected = !currentValues.includes(option.value);
    let newValues;

    if (multiple) {
      newValues = isSelected
        ? [...currentValues, option.value]
        : currentValues.filter((v) => v !== option.value);
      onChange(newValues);
    } else {
      newValues = isSelected ? option.value : undefined;
      onChange(newValues);
    }

    if (onSelect) {
      onSelect(option, isSelected);
    }
  };

  const isSelected = (optionValue: number) => {
    if (multiple) {
      return Array.isArray(value) && value.includes(optionValue);
    }
    return value !== undefined && optionValue === value;
  };

  return (
    <div className="base-list-select">
      <div
        className={`base-list-select-content ${error && "base-list-select-content-error"}`}
      >
        <div className="p-2 border-b">
          <Input
            placeholder="Search"
            prefix={<SearchOutlined className="text-gray-400" />}
            onChange={(e) => onSearch?.(e.target.value)}
            className="w-full"
          />
        </div>

        <div className="base-list-select-spinner">
          {isLoading && <BaseSpinner size="large" />}
        </div>

        <div
          ref={containerRef}
          onScroll={onScroll}
          className="base-list-select-items"
        >
          <List
            dataSource={options}
            renderItem={(option) => (
              <List.Item
                className={`base-list-select-list-item ${isSelected(option.value) ? "base-list-select-list-item-selected" : ""}`}
                onClick={() => handleSelect(option)}
              >
                {renderItem ? renderItem(option) : defaultRenderItem(option)}
              </List.Item>
            )}
          />
        </div>
      </div>

      {error && <BaseError error={error as BaseFormError} />}
    </div>
  );
};

export default BaseListSelect;
