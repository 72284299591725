import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { DataBlockFormFields, FormFieldValue } from "../../types/types";
import { DataField } from "../../types/templates";

export const useDataBlockForm = (
  validationSchema: yup.ObjectSchema<DataBlockFormFields>,
) => {
  const form = useForm<DataBlockFormFields>({
    resolver: async (data, context, options) => {
      return yupResolver(validationSchema)(data, context, options);
    },
    mode: "onChange",
    defaultValues: {
      dataTemplate: undefined,
      request: undefined,
      sharingRights: undefined,
      fieldValues: [],
    },
  });

  const initializeFormFieldValues = (fields: DataField[]) => {
    const fieldValues = fields.map((field) => ({
      field: field.id,
      value: field.type === "CHECKBOX" ? false : undefined,
    }));
    form.setValue("fieldValues", fieldValues);
    return fieldValues;
  };

  const orderFieldValuesAccordingToTemplateFields = (
    orderedFieldIds: number[],
    fieldValues: FormFieldValue[],
  ) => {
    const fieldValueMap = new Map(
      fieldValues.map((item) => [item.field, item]),
    );

    return orderedFieldIds.map((fieldId) => {
      return (
        fieldValueMap.get(fieldId) || {
          field: fieldId,
          value: undefined,
        }
      );
    });
  };

  return {
    form,
    initializeFormFieldValues,
    orderFieldValuesAccordingToTemplateFields,
  };
};
