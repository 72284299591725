import React, { forwardRef, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { BaseSelectRef } from "rc-select/lib/BaseSelect";

import {
  BaseDropdownProps,
  Constraint,
  Option,
  SliderOption,
  WithConstraints,
  WithConstraintsProps,
} from "../types";
import { createHookFormComponent } from "../../ReactHookForm/hocs/withHookForm";

/**
 * Filters the available options in a dropdown based on a value in a different field.
 * This works only with the Data Blocks choice options.
 */
export function withConstraints<T extends BaseDropdownProps>(
  WrappedComponent: React.ComponentType<T>,
): WithConstraints<T> {
  const ConstraintsComponent = forwardRef<
    BaseSelectRef,
    WithConstraintsProps<T>
  >(
    (
      {
        constraints,
        options,
        isSlider = false,
        multiple,
        onChange,
        value,
        control,
        ...restProps
      },
      ref,
    ) => {
      const [filteredOptions, setFilteredOptions] = useState<
        SliderOption[] | Option[]
      >(options);

      const watchedFieldNames = constraints
        ? Array.from(new Set(constraints.map((i) => i.fieldName.toString())))
        : [];

      const fieldValues = useWatch({
        control,
        name: watchedFieldNames,
      });

      useEffect(() => {
        if (!constraints || constraints.length === 0) {
          setFilteredOptions(options);
          return;
        }

        const selectedFieldValues: number[] = (
          Array.isArray(fieldValues) ? fieldValues : [fieldValues]
        ).filter((value) => value != null);

        if (selectedFieldValues.length === 0) {
          setFilteredOptions(options);
          return;
        }

        const newOptions = isSlider
          ? filterSliderOptionsForSelectedValues(
              selectedFieldValues,
              constraints,
              options as SliderOption[],
            )
          : filterOptionsForSelectedValues(
              selectedFieldValues,
              constraints,
              options as Option[],
            );

        if (value) {
          const currentValues = Array.isArray(value) ? value : [value];

          const validValues = currentValues.filter((val) =>
            newOptions.some(
              (opt) =>
                opt.value === (typeof val === "object" ? val.choice : val) ||
                typeof val === "string",
            ),
          );

          if (validValues.length !== currentValues.length) {
            if (onChange) {
              onChange(multiple ? [] : undefined);
            }
          }
        }
        setFilteredOptions(newOptions);
      }, [fieldValues, options, constraints, isSlider]);

      const filterOptionsForSelectedValues = (
        selectedFieldValues: number[],
        constraints: Constraint[],
        options: Option[],
      ) => {
        const parentOptionAllowedOptionsMapping = constraints.reduce<
          Record<number, number[]>
        >((acc, item) => {
          acc[item.parentOption] = item.allowedOptions;
          return acc;
        }, {});

        const allowedOptions = selectedFieldValues.reduce<(number | string)[]>(
          (acc, i) => {
            const allowedOptionsForSelectedValue =
              parentOptionAllowedOptionsMapping[i] || [];
            if (i in parentOptionAllowedOptionsMapping) {
              return [...acc, ...allowedOptionsForSelectedValue];
            }
            return acc;
          },
          [],
        );

        const newOptions = options.filter((option) =>
          allowedOptions.includes(option.value as number | string),
        );

        if (newOptions.length === 0) {
          return options;
        }

        return newOptions;
      };

      const filterSliderOptionsForSelectedValues = (
        selectedFieldValues: number[],
        constraints: Constraint[],
        options: SliderOption[],
      ) => {
        const parentOptionAllowedOptionsMapping = constraints.reduce<
          Record<number, number[]>
        >((acc, item) => {
          acc[item.parentOption] = item.allowedOptions;
          return acc;
        }, {});

        const allowedOptions = selectedFieldValues.reduce<number[]>(
          (acc, i: number) => {
            const val = parentOptionAllowedOptionsMapping[i];
            if (val) {
              const allowedOptionsForSelectedValue = val || [];
              return [...acc, ...allowedOptionsForSelectedValue];
            }
            return acc;
          },
          [],
        );

        const hasMainConstraints = constraints.some(
          (constraint) => constraint.affectsMainOptions,
        );
        const hasSecondaryConstraints = constraints.some(
          (constraint) => !constraint.affectsMainOptions,
        );

        return options.map((option) => {
          const newOption = { ...option };

          if (hasMainConstraints) {
            newOption.disabled = !allowedOptions.includes(
              option.value as number,
            );
          }

          if (hasSecondaryConstraints && option.relatedOptions?.length) {
            newOption.relatedOptions = (newOption?.relatedOptions || [])
              .map((relatedOption) => {
                if (allowedOptions.includes(relatedOption.value as number))
                  return relatedOption;
                return undefined;
              })
              .filter((relatedOption) => !!relatedOption);
          }

          return newOption;
        });
      };

      return (
        <WrappedComponent
          {...(restProps as unknown as T)}
          control={control}
          onChange={onChange}
          value={value}
          multiple={multiple}
          options={filteredOptions}
          ref={ref}
        />
      );
    },
  );

  ConstraintsComponent.displayName = `withConstraints(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`;

  return ConstraintsComponent;
}

export function createConstraintsComponent<T extends BaseDropdownProps>(
  BaseComponent: React.ComponentType<T>,
  isSlider = false,
): WithConstraints<T> {
  const ConstraintsComponent = withConstraints<T>(BaseComponent);
  return forwardRef<BaseSelectRef, WithConstraintsProps<T>>((props, ref) => {
    return <ConstraintsComponent {...props} isSlider={isSlider} ref={ref} />;
  }) as WithConstraints<T>;
}

export function createHookFormConstraintsComponent<T extends BaseDropdownProps>(
  BaseComponent: React.ComponentType<T>,
  isSlider = false,
) {
  const ConstraintsComponent = createConstraintsComponent<T>(
    BaseComponent,
    isSlider,
  );

  return createHookFormComponent<WithConstraintsProps<T>>(
    ConstraintsComponent as unknown as React.ComponentType<
      WithConstraintsProps<T>
    >,
  );
}
