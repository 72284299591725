import { useTranslation } from "react-i18next";
import { App, Flex } from "antd";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  useGetCurrentUserQuery,
  useLazyGetUsersQuery,
  usePatchDataBlockMutation,
} from "@/redux/service";
import BaseModal from "../../../../components_v2/Modal/BaseModal";
import BaseButton from "../../../../components_v2/Button/BaseButton";
import { Roles } from "@/utilities/constants";
import {
  AddContributorModalProps,
  AddContributorsFormValues,
} from "../../types/types";
import { User } from "@/views/users/types/types";
import FormFieldWrapper from "../../../../components_v2/Wrapper/FormFieldWrapper";
import { HookFormInfiniteScrollDropdown } from "@/components_v2/FormFields/ReactHookForm/fields";

const AddContributorsModal = ({
  open,
  setOpen,
  dataBlock,
  currentContributorIds,
}: AddContributorModalProps) => {
  const { t } = useTranslation();
  const { notification } = App.useApp();

  const [getUsers] = useLazyGetUsersQuery();
  const [patchDataBlock] = usePatchDataBlockMutation();
  const { data: currentUser, isSuccess } = useGetCurrentUserQuery();

  const contributorsSchema = yup.object().shape({
    contributors: yup
      .array()
      .of(
        yup
          .number()
          .typeError(t("datablocks.errors.datablockTypeNotValid"))
          .required(t("datablocks.errors.required")),
      )
      .required(t("datablocks.errors.required"))
      .typeError(t("datablocks.errors.datablockTypeNotValid")),
  });

  const form = useForm<AddContributorsFormValues>({
    resolver: yupResolver(contributorsSchema),
    mode: "onChange",
    defaultValues: {
      contributors: [],
    },
  });

  const dropdownKey = `contributors-${open}-${currentContributorIds.join(",")}`;

  const addContributors = (value: AddContributorsFormValues) => {
    const data = {
      fieldValues: [
        {
          field: dataBlock.fieldsMapping.contributors,
          value: [...currentContributorIds, ...value.contributors],
        },
      ],
    };

    patchDataBlock({ id: dataBlock.id, data })
      .unwrap()
      .then(() => {
        setOpen(false);
        form.reset();
        notification.success({
          message: t("datablocks.labels.contributorsAdded"),
        });
      });
  };

  return (
    isSuccess && (
      <BaseModal
        title={t("datablocks.labels.addContributors")}
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <Flex wrap justify="space-between" align="center" gap={"small"}>
            <BaseButton
              variant="tertiary"
              size="small"
              onClick={() => setOpen(false)}
            >
              {t("buttons.cancel")}
            </BaseButton>

            <BaseButton
              variant="primary"
              size="small"
              onClick={form.handleSubmit(addContributors)}
            >
              {t("buttons.addContributors")}
            </BaseButton>
          </Flex>
        }
      >
        <FormFieldWrapper label={t("datablocks.labels.contributors")}>
          <HookFormInfiniteScrollDropdown<User>
            key={dropdownKey}
            name="contributors"
            control={form.control}
            fetchFn={getUsers}
            multiple={true}
            labelFieldName="fullName"
            valueFieldName="id"
            searchFieldName="full_name"
            defaultFilters={
              currentUser.role === Roles.ADMIN.value
                ? {}
                : {
                    organization: currentUser.organization.id,
                    exclude: currentContributorIds,
                  }
            }
          />
        </FormFieldWrapper>
      </BaseModal>
    )
  );
};

export default AddContributorsModal;
