import { Input } from "antd";

import { BaseTextAreaProps } from "./types";
import BaseError from "../BaseError";

import "./styles.css";

const BaseTextArea = ({ error, ...props }: BaseTextAreaProps) => {
  return (
    <div className="base-text-area">
      <Input.TextArea
        className={error && "base-text-area-error"}
        rows={5}
        {...props}
      />

      <BaseError error={error} />
    </div>
  );
};

export default BaseTextArea;
