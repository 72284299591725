import { useMemo } from "react";

import { usePaginateFilterDataTemplatesQuery } from "@/redux/service";
import { DataTemplate } from "../../types/templates";

export const useDataTemplates = () => {
  const { data: templates } = usePaginateFilterDataTemplatesQuery({
    pagination: "off",
  }) as { data: DataTemplate[] | undefined };
  return templates;
};

export const useEnabledDataTemplates = (templateIds?: number[]) => {
  const templates = useDataTemplates();

  return useMemo(() => {
    if (templates == null) return;

    return templates.filter(
      (i) =>
        !i.disabled &&
        (templateIds && templateIds.length > 0
          ? templateIds.includes(i.id)
          : true),
    );
  }, [templates, templateIds]);
};

export const useDisabledDataTemplates = () => {
  const templates = useDataTemplates();

  return useMemo(() => {
    if (templates == null) return;

    return templates.filter((i) => i.disabled);
  }, [templates]);
};
