import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DatePicker } from "antd";
import luxonGenerateConfig from "rc-picker/lib/generate/luxon";
import type { DateTime as LuxonDateTime } from "luxon";
import { DateTime } from "luxon";

import { BaseDatePickerHandle, BaseDatePickerProps } from "./types";
import BaseError from "../BaseError";
import { BaseFormError } from "../types";

import "./styles.css";

const LuxonDatePicker =
  DatePicker.generatePicker<LuxonDateTime>(luxonGenerateConfig);

const BaseDatePicker = forwardRef<BaseDatePickerHandle, BaseDatePickerProps>(
  ({ value, onChange, error, defaultValue, ...props }, ref) => {
    const datePickerRef = useRef(null);
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      close: () => {
        setOpen(false);
      },
    }));

    useEffect(() => {
      if (defaultValue) onChange(defaultValue);
    }, [defaultValue]);

    const handleChange = (newValue: DateTime | string) => {
      let dateTimeValue;

      if (newValue == null) {
        dateTimeValue = null;
      } else if (!DateTime.isDateTime(newValue)) {
        dateTimeValue = DateTime.fromISO(newValue);
      } else {
        dateTimeValue = newValue;
      }
      onChange(dateTimeValue);
    };

    useEffect(() => {
      if (!DateTime.isDateTime(value) && typeof value === "string") {
        handleChange(value);
      }
    }, [value]);

    const displayValue = useMemo(() => {
      if (!value) {
        return undefined;
      }

      if (DateTime.isDateTime(value)) {
        return value;
      }

      if (typeof value === "string") {
        return DateTime.fromISO(value);
      }

      return undefined;
    }, [value]);

    return (
      <div>
        <LuxonDatePicker
          ref={datePickerRef}
          className={`base-date-picker ${error && "base-date-picker-error"}`}
          value={displayValue}
          defaultValue={defaultValue}
          open={open}
          onOpenChange={setOpen}
          onChange={handleChange}
          {...props}
        />

        <BaseError error={error as BaseFormError} />
      </div>
    );
  },
);

export default BaseDatePicker;
