import { useMemo } from "react";

import { theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { useGetCurrentUserQuery } from "../../../redux/service.js";
import {
  OrganizationCommunicationStatuses,
  OrganizationRoles,
  Roles,
} from "../../../utilities/constants.jsx";
import Surveys from "../components/Surveys.jsx";
import ProfileForm from "./ProfileForm.jsx";
import Members from "../components/Members.jsx";
import Settings from "../components/Settings.jsx";
import CommunicatingStatus from "../components/CommunicatingStatus.jsx";
import BaseTabs from "../../../components_v2/Tabs/BaseTabs.tsx";

const { Title } = Typography;

const PrivateOrganizationProfile = ({ organization }) => {
  const { token } = theme.useToken();
  const { t } = useTranslation();

  const { data: currentUser, isSuccess: currentUserQuerySuccess } =
    useGetCurrentUserQuery();
  const canUserEdit = useMemo(
    () =>
      currentUser &&
      (currentUser.role === Roles.ADMIN.value ||
        [
          OrganizationRoles.ADMIN.value,
          OrganizationRoles.SUPERVISOR.value,
        ].includes(currentUser.organizationRole)),
    [currentUser],
  );

  return (
    <div>
      <div className="mb-3" style={{ display: "flex" }}>
        <Title level={2} style={{ color: token.colorPrimaryText }}>
          {organization.name}
        </Title>
        <div className="ps-4">
          <CommunicatingStatus
            status={OrganizationCommunicationStatuses.getItemByValue(
              organization?.communicatingStatus,
            )}
          />
        </div>
      </div>
      {currentUserQuerySuccess && (
        <BaseTabs
          type="card"
          items={[
            {
              label: t("labels.reports"),
              key: "reports",
              children: <Surveys organizationId={organization.id} />,
            },
            {
              label: t("profile"),
              key: "profile",
              children: <ProfileForm organization={organization} />,
            },
            {
              label: t("members"),
              key: "members",
              children: (
                <Members
                  currentUser={currentUser}
                  organization={organization}
                />
              ),
            },
            ...(canUserEdit
              ? [
                  {
                    label: t("settings"),
                    key: "settings",
                    children: <Settings organization={organization} />,
                  },
                ]
              : []),
          ]}
        />
      )}
    </div>
  );
};

PrivateOrganizationProfile.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default PrivateOrganizationProfile;
