import { useController } from "react-hook-form";
import { Checkbox } from "antd";
import PropTypes from "prop-types";

import FieldError from "./FieldError.jsx";

const CheckboxField = ({ label, name, control, disabled, defaultValue }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div>
      <Checkbox
        {...field}
        disabled={disabled}
        checked={field.value}
        defaultChecked={defaultValue}
      >
        {label}
      </Checkbox>
      {error && <FieldError errors={error} />}
    </div>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
};

CheckboxField.defaultProps = {
  disabled: false,
  defaultValue: false,
};

export default CheckboxField;
