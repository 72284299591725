import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { InfiniteScrollDropdownProps } from "./types";
import BaseSpinner from "../../Spiner/BaseSpinner";
import useInfiniteScrollOptions from "./hooks/useInfiniteScrollOptions";
import BaseDropdown from "./BaseDropdown";

const InfiniteScrollDropdown = <T,>({
  value,
  onChange,
  fetchFn,
  labelFieldName,
  valueFieldName,
  searchFieldName,
  debounceMs = 300,
  defaultFilters = {},
  multiple,
  className,
  ...props
}: InfiniteScrollDropdownProps<T>) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const {
    options,
    isLoading,
    search,
    setSearch,
    handleScroll,
    resetSearch,
    fetchOptions,
    initialValueFetched,
  } = useInfiniteScrollOptions<T>({
    fetchFn,
    defaultFilters,
    labelFieldName,
    valueFieldName,
    searchFieldName,
    debounceMs,
    initialValue: value,
  });

  const handleDropdownVisibilityChange = useCallback(
    (visible: boolean) => {
      setIsOpen(visible);

      if (visible) {
        fetchOptions();
      }
    },
    [fetchOptions],
  );

  useEffect(() => {
    if (!isOpen) {
      resetSearch();
    }
  }, [isOpen, resetSearch]);

  const handleChange = useCallback(
    (value: number | string) => {
      onChange(value);
      if (search) {
        setSearch("");
      }
      if (!multiple) {
        setIsOpen(false);
      }
    },
    [onChange, search, multiple],
  );

  const currentValue = useMemo(() => {
    if (value && !initialValueFetched) {
      return multiple ? [] : undefined;
    }

    return value !== undefined ? value : multiple ? [] : undefined;
  }, [value, initialValueFetched, multiple]);

  const emptyContent = useMemo(() => {
    if (isLoading) {
      return <BaseSpinner size="small" />;
    }

    return (
      <div className="text-center p-4">
        <InboxOutlined className="text-2xl mb-2" />
        <p>{t("noData")}</p>
      </div>
    );
  }, [isLoading, t]);

  return (
    <BaseDropdown
      className={`base-dropdown ${className && className}`}
      {...props}
      options={options}
      loading={isLoading}
      onSearch={setSearch}
      searchValue={search}
      filterOption={false}
      onPopupScroll={handleScroll}
      onChange={handleChange}
      value={currentValue}
      onDropdownVisibleChange={handleDropdownVisibilityChange}
      open={isOpen}
      multiple={multiple}
      notFoundContent={emptyContent}
    />
  );
};

export default InfiniteScrollDropdown;
