import { useEffect, useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "antd";

import PrimaryButton from "../../../components/PrimaryButton.jsx";
import {
  useCreateCommentMutation,
  useGetCurrentUserQuery,
  useUpdateCommentMutation,
} from "../../../redux/service.js";
import ContentField from "./ContentField.jsx";
import UserAvatar from "../../../components_v2/Avatar/UserAvatar.js";

const { Text } = Typography;

const CommentForm = ({
  initialData,
  hideCommentBox,
  submitCommentCallback,
}) => {
  const { t } = useTranslation();
  const { discussionId } = useParams();

  const [createComment] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const { data: currentUser, isSuccess } = useGetCurrentUserQuery();
  const [showCommentForm, setShowCommentForm] = useState(initialData?.content);

  const commentSchema = yup.object().shape({
    content: yup
      .string()
      .typeError(t("form.validation.commentNotValid"))
      .required(t("form.validation.commentRequired")),
    narratives: yup.array(),
    blocks: yup.array(),
    surveyViews: yup.array(),
  });

  const defaultValues = {
    parent: null,
    discussion: discussionId,
    content: "",
    narratives: [],
    blocks: [],
    surveyViews: [],
  };
  const form = useForm({
    resolver: yupResolver(commentSchema),
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    if (initialData) {
      form.reset({
        parent: initialData?.parent,
        discussion: initialData?.discussion || discussionId,
        content: initialData?.content,
        narratives: initialData?.narratives,
        blocks: initialData?.attachedBlocks,
        surveyViews: initialData?.surveyViews,
      });
    }
  }, [initialData]);

  const cancelComment = () => {
    form.reset(defaultValues);
    hideCommentBox();
  };

  const cleanUpAfterRequest = () => {
    setShowCommentForm(false);
  };

  const handleCommentSubmit = (values) => {
    const data = {
      ...values,
      attachedBlockIds: (values?.blocks || []).map((i) => i.id),
      attachedNarrativeIds: (values?.narratives || []).map((i) => i.id),
      attachedSurveyViewUuids: (values?.surveyViews || []).map((i) => i.uuid),
    };
    if (initialData?.id) {
      updateComment({ id: initialData.id, data })
        .unwrap()
        .then((data) => {
          submitCommentCallback(data);
          form.reset(defaultValues);
          hideCommentBox();
        })
        .finally(cleanUpAfterRequest);
    } else {
      createComment(data)
        .unwrap()
        .then((data) => {
          submitCommentCallback(data);
          form.reset(defaultValues);
          hideCommentBox();
        })
        .finally(cleanUpAfterRequest);
    }
  };

  const handleShowInput = () => {
    setShowCommentForm(true);
  };

  return showCommentForm ? (
    <ContentField form={form}>
      <PrimaryButton type="tertiary" onClick={cancelComment} className="me-3">
        {t("buttons.cancel")}
      </PrimaryButton>
      <PrimaryButton onClick={form.handleSubmit(handleCommentSubmit)}>
        {t("buttons.post")}
      </PrimaryButton>
    </ContentField>
  ) : (
    <div
      style={{
        border: "1px solid rgba(185, 187, 198, 1)",
        alignItems: "stretch",
        display: "flex",
      }}
    >
      <div className="py-2 px-2">
        {isSuccess && <UserAvatar user={currentUser} />}
      </div>
      <div
        style={{ cursor: "text", display: "flex", alignItems: "center" }}
        onClick={handleShowInput}
      >
        <Text style={{ color: "rgba(139, 141, 152, 1)" }} className="ms-2">
          {t("form.placeholders.discussionCommentForm")}
        </Text>
      </div>
      <div style={{ margin: "0 0 0 auto" }} className="py-2 px-2">
        <PrimaryButton disabled>{t("buttons.postComment")}</PrimaryButton>
      </div>
    </div>
  );
};

CommentForm.propTypes = {
  initialData: PropTypes.object,
  hideCommentBox: PropTypes.func,
  submitCommentCallback: PropTypes.func,
};

CommentForm.defaultProps = {
  initialData: null,
  submitCommentCallback: () => {},
  hideCommentBox: () => {},
};

export default CommentForm;
