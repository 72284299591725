// @ts-nocheck
import { createUrl } from "../../utilities";
import { User } from "../../views/users/types/types";
import { BuilderType } from "../../types/base";
import { UserTags } from "./types";

const usersService = (builder: BuilderType<UserTags>) => ({
  login: builder.mutation({
    query: (data) => ({
      url: "/auth/login/",
      method: "POST",
      data,
    }),
  }),
  register: builder.mutation({
    query: (data) => ({
      url: "/auth/register/",
      method: "POST",
      data,
    }),
  }),
  resetPassword: builder.mutation({
    query: (data) => ({
      url: "/auth/password/reset/",
      method: "POST",
      data,
    }),
  }),
  resetPasswordConfirm: builder.mutation({
    query: (data) => ({
      url: "/auth/password/reset/confirm/",
      method: "POST",
      data,
    }),
  }),
  verifyEmail: builder.mutation({
    query: (data) => ({
      url: "/auth/email-verification/",
      method: "POST",
      data,
    }),
  }),
  verifyEmailResend: builder.mutation({
    query: (data) => ({
      url: "/auth/email-verification/resend/",
      method: "POST",
      data,
    }),
  }),
  logOut: builder.mutation({
    query: () => ({
      url: "/auth/logout/",
      method: "POST",
    }),
  }),
  getUsers: builder.query({
    query: (filters) => createUrl("/users/", filters),
    providesTags: (result) =>
      result
        ? [
            { type: "Users" },
            ...(result?.results || result).map((i) => ({
              type: "User",
              id: i.survey,
            })),
          ]
        : [],
  }),
  getCurrentUser: builder.query<User, void>({
    query: () => "/users/me/",
    providesTags: (result) => (result ? [{ type: "CurrentUser" }] : []),
  }),
  updateCurrentUser: builder.mutation({
    query: (data) => ({
      url: "/users/me/",
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "CurrentUser" }, { type: "Users" }] : [],
  }),
  getUserById: builder.query({
    query: (id) => `/users/${id}/`,
    providesTags: (result) =>
      result ? [{ type: "User", id: result.id }, { type: "Users" }] : [],
  }),
  updateUser: builder.mutation({
    query: ({ id, data }) => ({
      url: `/users/${id}/`,
      method: "PATCH",
      data,
    }),
    invalidatesTags: (result) =>
      result ? [{ type: "User", id: result.id }, { type: "Users" }] : [],
  }),
  deleteCurrentUser: builder.mutation({
    query: (data) => ({
      url: "/users/me/",
      method: "DELETE",
      data,
    }),
  }),
  exportUserData: builder.mutation({
    query: (id) => ({
      url: `/users/${id}/export/`,
      method: "POST",
    }),
  }),
  impersonateUser: builder.query({
    query: (id) => ({
      url: `/users/${id}/impersonate/`,
      method: "GET",
    }),
  }),
  getExportedUserData: builder.query({
    query: (id) => `/users/${id}/export/`,
  }),
  followUser: builder.mutation({
    query: (data) => ({
      url: "/followers/",
      method: "POST",
      data,
    }),
    invalidatesTags: (result) =>
      result
        ? [
            {
              type: "User",
              id: result.followed,
            },
          ]
        : [],
  }),
  unfollowUser: builder.mutation({
    query: ({ userFollowerId }) => ({
      url: `/followers/${userFollowerId}/`,
      method: "DELETE",
    }),
    invalidatesTags: (result, error, { userId }) =>
      error
        ? []
        : [
            {
              type: "User",
              id: userId,
            },
          ],
  }),
});

export default usersService;
