import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import BaseAvatar from "./BaseAvatar";

import { UserAvatarProps } from "./types";

const UserAvatar = ({
  user,
  shouldClickRedirect = false,
  ...props
}: UserAvatarProps) => {
  const navigate = useNavigate();

  const label = useMemo(() => {
    if (Boolean(user.firstName?.trim()) && Boolean(user.lastName?.trim())) {
      return (user.firstName.charAt(0) + user.lastName.charAt(0)).toUpperCase();
    }
    return user.fullName.slice(0, 2).toUpperCase();
  }, [user]);

  return (
    <BaseAvatar
      className="base-user-avatar"
      label={label}
      imageUrl={user.profilePicture}
      onClick={
        shouldClickRedirect ? () => navigate(`/profile/${user.id}`) : undefined
      }
      style={shouldClickRedirect ? { cursor: "pointer" } : {}}
      {...props}
    />
  );
};

export default UserAvatar;
